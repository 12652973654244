<template>
    <div class="registration-form-widget">
        <template v-if="!thankYouMessage">
            <h1>{{ data.name }}</h1>
            <p>{{ eventWhenWhere(data) }}</p>
            <p>
                {{ lang === "it" ? data.descriptionIt : data.descriptionEn }}
            </p>
        </template>
        <div class="row">
            <div class="col-sm-12">
                <template v-if="data && data.isEventFull">
                    <h2 class="text-center">{{ string.registrationPage._eventFull }}</h2>
                </template>
                <template v-else-if="thankYouMessage && !isPending">
                    <SuccessAnimation />
                    <h2 class="text-justify">{{thankYouMessage}}</h2>
                    <br/>
                    <br/>
                    <h3 class="text-center" style="width: 100%; font-size: 24px;">(Potrebbero volerci alcuni minuti)</h3>
                </template>
                <template v-else-if="thankYouMessage && isPending">
                    <SuccessAnimation />
                    <h2 class="text-center">{{thankYouMessage}}</h2>
                    <br/>
                    <h2 class="text-justify"> La tua pratica verrà presa in esame dalla Commissione Federale Agenti Sportivi. 
                        Al termine della fase istruttoria, l’accoglimento o il rigetto della richiesta verrà comunicato sulla tua casella postale.
                    </h2>
                    <br/>
                     <h3 v-if="data.customCategory === 'rinnovo-iscrizione'" class="text-center" style="width: 100%; font-size: 24px;"><b><u>IMPORTANTE:</u> Qualora non lo avessi già fatto ti ricordiamo, inoltre, di trasmettere tutta la documentazione anche all'indirizzo pec rinnovi.agenti@pec.figc.it</b></h3>
                     <h3 v-else class="text-center" style="width: 100%; font-size: 24px;"><b><u>IMPORTANTE:</u> Qualora non lo avessi già fatto ti ricordiamo, inoltre, di trasmettere tutta la documentazione anche all'indirizzo pec iscrizioni.agenti@pec.figc.it</b></h3>
                </template>
                <form v-else class="text-left" @submit.prevent="registerDo()">
                    <!-- <h2>{{ string.registrationPage._title }}</h2> -->
                    <template v-if="loggedInUserData && !hideLoginBar">
                        <p>{{ string.registrationPage._loggedInAs }} {{loggedInUserData.name}} {{loggedInUserData.surname}}, <a href="#" @click.prevent="logoutDo()">{{ string.registrationPage._logout }}</a></p>
                    </template>
                    <template v-else-if="!loggedInUserData">
                        <p v-if="!hideLoginBar">{{ string.registrationPage._alreadyAccount }} <a href="#" @click.prevent="openLoginModal()">{{ string.registrationPage._login }}</a> {{ string.registrationPage._prefillData }}</p>
                        <h3 class="section-title">{{ string.registrationPage._basicInformation }}</h3>
                        <div class="form-group">
                            <label for="firstName" class="control-label">{{ string.registrationPage._firstName }} {{ "*" }}</label>
                            <input id="firstName" name="firstName" type="text" required="required" class="form-control" v-model="signupForm.name">
                        </div>
                        <div class="form-group">
                            <label for="lastName" class="control-label">{{ string.registrationPage._lastName }} {{ "*" }}</label>
                            <input id="lastName" name="lastName" type="text" required="required" class="form-control" v-model="signupForm.surname">
                        </div>
                        <div class="form-group">
                            <label for="email" class="control-label">Email {{ "*" }}</label>
                            <input id="email" name="email" type="text" required="required" class="form-control" v-model="signupForm.email">
                        </div>
                        <h3 class="section-title">{{ string.registrationPage._setPassword }}</h3>
                        <div class="form-group">
                            <label for="password" class="control-label">{{ string.registrationPage._password }} {{ "*" }}</label>
                            <input id="password" name="password" type="password" required="required" class="form-control" v-model="signupForm.password">
                        </div>
                        <small>{{ string.commonActions.passwordPolicy }}</small>
                        <div class="form-group">
                            <label for="password2" class="control-label">{{ string.registrationPage._retypePassword }} {{ "*" }}</label>
                            <input id="password2" name="password2" type="password" required="required" class="form-control" v-model="signupForm.password2">
                        </div>
                    </template>
                    <template v-if="isConfirmed">
                        <p class="error-message">
                            {{ string.registrationPage._alreadyJoined }}
                        </p>
                    </template>
                    <template v-else-if="isPending && includeDeferredUploads">
                        <h3 style="font-style:italic"><b>Il sistema accetta solo documenti in formato pdf</b></h3>
                        <h3 class="section-title">{{ string.registrationPage._attachments }}</h3>
                            <div v-if="deferredUploads.length > 0" style="margin: 15px 0px;">
                                <div v-for="[slug, cField] in deferredUploads" :key="slug" class="form-group">
                                    <label v-if="cField.type !== TEXT_ONLY" class="control-label">
                                    {{ cField.options.question }} {{ cField.registrationFormMandatory ? "*" : "" }}
                                    </label>
                                    <FileUpload
                                        :mandatory="cField.registrationFormMandatory"
                                        v-on:file-changed="changeFile(slug, $event)"
                                        ></FileUpload>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="error-message" v-html="joinMessage"></div>
                                <div class="button-bar">
                                    <router-link
                                        class="btn btn-secondary btn--cancel"
                                        :to="{ name: 'OrganizerPublicProfile', params: { code: code } }"
                                        tag="button"
                                        >
                                        {{ string.registrationPage._cancel }}
                                    </router-link>
                                    <button v-if="!uploaded" name="submit" type="submit" class="btn btn-primary btn--submit">Upload file</button>
                                    <div v-else class="btn btn-primary btn--submit">Uploading...</div>
                                </div>
                            </div>
                    </template>
                    <template v-else>
                        <div v-if="memberCustomFieldArray.length > 0" style="margin: 15px 0px;">
                            <!-- Custom Fields -->
                            <template v-for="[slug, cField] in memberCustomFieldArray">
                                <div v-if="!isMemberCustomFieldAlreadyFilled(slug)" :key="slug" class="form-group">
                                    <label class="control-label">
                                        {{ cField.options.question }} {{ cField.registrationFormMandatory ? "*" : "" }}
                                    </label>
                                    <el-select
                                        v-if="cField.type === 'MULTIPLE_CHOICE'"
                                        v-model="memberAnswerList[slug]"
                                        popper-class="grayscale-popper"
                                        multiple
                                        collapse-tags
                                        filterable
                                        :multiple-limit="cField.options.maxSelection"
                                        :placeholder="cField.options.question"
                                        class="select form-control"
                                        style="color: #555 !important;"
                                        :class="{invalid: showErrors && cField.registrationFormMandatory && memberAnswerList[slug].length < 1}"
                                        @change="value => onUpdateMultipleChoice(slug, value, 'team')"
                                    >
                                        <el-option
                                            v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
                                            :key="answerSlug"
                                            :hidden="answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)"
                                            :label="answer.label"
                                            :value="answerSlug"
                                        >
                                            {{ answer.label }}
                                        </el-option>
                                    </el-select>
                                    <input
                                        v-else
                                        v-model="memberCustomFieldDataMap[slug]"
                                        aria-label=""
                                        type="text"
                                        :placeholder="cField.options.question"
                                        :required="cField.registrationFormMandatory"
                                        class="form-control"
                                        :class="{invalid: showErrors && cField.registrationFormMandatory && !memberCustomFieldDataMap[slug]}"
                                    />
                                </div>
                            </template>
                        </div>
                        <div v-if="customFieldArrayNotDeferred.length > 0" style="margin: 15px 0px;">
                            <!-- Custom Fields -->
                            <h3 v-if="memberCustomFieldArray.length === 0" class="section-title">{{ string.registrationPage._moreInformation }}</h3>
                            <div v-for="[slug, cField] in customFieldArrayNotDeferred" :key="slug" class="form-group">
                                <label v-if="cField.type !== TEXT_ONLY && cField.type !== 'MAX_CHARACTER_ANSWER'" class="control-label">
                                    {{ cField.options.question }} {{ cField.registrationFormMandatory ? "*" : "" }}
                                </label>
                                <label v-if="cField.type === 'MAX_CHARACTER_ANSWER'" class="control-label">
                                    {{ cField.options.question }} {{ `(Max ${cField.options.maxCharacters} caratteri)` }}{{ cField.registrationFormMandatory ? "*" : "" }}
                                </label>
                                <el-select
                                    v-if="cField.type === 'MULTIPLE_CHOICE'"
                                    v-model="answerList[slug]"
                                    popper-class="grayscale-popper"
                                    multiple
                                    collapse-tags
                                    filterable
                                    :multiple-limit="cField.options.maxSelection"
                                    :placeholder="cField.options.question"
                                    class="select form-control"
                                    style="color: #555 !important;"
                                    :class="{
                                        invalid: showErrors && cField.registrationFormMandatory && answerList[slug].length < 1,
                                    }"
                                    @change="value => onUpdateMultipleChoice(slug, value, 'event')"
                                >
                                    <el-option
                                        v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
                                        :key="answerSlug"
                                        :hidden="answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)"
                                        :label="answer.label"
                                        :value="answerSlug"
                                    >
                                        {{ answer.label }}
                                    </el-option>
                                </el-select>
                                <div v-else-if="cField.type === TEXT_ONLY"
                                    class="control-label ql-editor no-horizontal-padding"
                                    :style="{textAlign: cField.options.alignment? cField.options.alignment:'left'}"
                                    v-html="`${cField.options.content}`">
                                </div>
                                <div v-else-if="cField.type === FILE_UPLOAD"
                                    class="control-label no-horizontal-padding">
                                    <FileUpload
                                        v-on:file-changed="changeFile(slug, $event)"></FileUpload>
                                </div>
                                <input v-else-if="cField.type === 'DATE'" 
                                        aria-label=""
                                        type="date"
                                        :required="cField.registrationFormMandatory"
                                        class="form-control"
                                        :class="{invalid: showErrors && cField.registrationFormMandatory && !memberCustomFieldDataMap[slug]}"
                                        @change="value => onUpdateDateField(slug, value.target.value)"
                                />
                                <input v-else-if="cField.type === 'MAX_CHARACTER_ANSWER'" 
                                        v-model="customFieldDataMap[slug]"
                                        aria-label=""
                                        type="text"
                                        :placeholder="cField.options.question"
                                        :required="cField.registrationFormMandatory"
                                        :maxlength="cField.options.maxCharacters"
                                        class="form-control"
                                        :class="{invalid: showErrors && cField.registrationFormMandatory && !memberCustomFieldDataMap[slug]}"
                                />
                                <input
                                    v-else
                                    v-model="customFieldDataMap[slug]"
                                    aria-label=""
                                    type="text"
                                    :placeholder="cField.options.question"
                                    :required="cField.registrationFormMandatory"
                                    class="form-control"
                                    :class="{invalid: showErrors && cField.registrationFormMandatory && !customFieldDataMap[slug]}"
                                />
                            </div>
                        </div>
                        <div v-if="timeSlotArray && timeSlotArray.length > 0" style="margin: 15px 0px;">
                            <h3 v-if="memberCustomFieldArray.length === 0" class="section-title">{{ string.registrationPage._bookTimeSlots }}</h3>
                            <div v-for="[slug, cField] in timeSlotArray" :key="slug" class="single-action">
                                <label>
                                    {{ cField.options.question }}
                                    {{ cField.registrationFormMandatory ? "(" + string.joinPage._mandatory + ")" : "" }}
                                </label>
                                <el-select
                                    v-model="answerList[slug]"
                                    popper-class="grayscale-popper"
                                    clearable
                                    :placeholder="cField.options.question"
                                    class="rsvp-select form-control"
                                    style="display: block;"
                                    :class="{
                                        invalid: showErrors && cField.registrationFormMandatory && answerList[slug].length < 1
                                    }"
                                    @change="value => onUpdateTimeSlot(slug, value)"
                                >
                                    <el-option
                                        v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
                                        :key="answerSlug"
                                        :hidden="answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)"
                                        :label="answer.label"
                                        :value="answerSlug"
                                    >
                                        {{ answer.label }}
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div v-if="data.privacyPolicies && data.privacyPolicies.policies.length > 0">
                            <!-- Privacy policy -->
                            <!-- <h3>{{ string.joinPage._privacy_policy }}</h3> -->
                            <div v-for="policy in data.privacyPolicies.policies" :key="policy.id" class="privacy-policy single-action">
                                <el-checkbox v-model="policy.checked"
                                    :required="policy.mandatory"
                                    type="checkbox"
                                    @change="checkPolicy(policy._id, policy.checked)"
                                >
                                    <div class="privacy-text" v-html="`${policy.teamPrivacyPolicyId.text} ${policy.mandatory?('(' + string.joinPage._mandatory + ')'):''}`"></div>
                                </el-checkbox>
                            </div>
                        </div>
                        <template v-if="data && data.maxGuestsPerMember && data.maxGuestsPerMember > 0">
                            <h3>{{ string.registrationPage._takeSomeone }}</h3>
                            <div class="form-group">
                                <!-- <label for="plusOnes" class="control-label">{{ string.registrationPage._personsWithYou }}</label> -->
                                <p>{{ string.registrationPage._personsUpTo}} {{data.maxGuestsPerMember}} {{ string.registrationPage._persons }}</p>
                                <input id="plusOnes" v-model="maxAcquaintance" name="plusOnes" type="number" required="required" :max="data.maxGuestsPerMember" min=0 class="form-control" @keyup="resizeAquaitanceInfoList" @change="resizeAquaitanceInfoList">
                            </div>
                            <template v-for="(plusOne, index) in acquaintanceInfoList">
                                <h3 :key="`person-${index}`" class="section-title">{{ string.registrationPage._person }} {{ index + 1 }}</h3>
                                <div :key="`firstName-${index}`" class="form-group">
                                    <label :for="`firstName-${index}`" class="control-label">{{ string.registrationPage._firstName }} {{ "*" }}</label>
                                    <input :id="`firstName-${index}`" v-model="plusOne.name" :name="`firstName-${index}`" type="text" required="required" class="form-control">
                                </div>
                                <div :key="`lastName-${index}`" class="form-group">
                                    <label :for="`lastName-${index}`" class="control-label">{{ string.registrationPage._lastName }} {{ "*" }}</label>
                                    <input :id="`lastName-${index}`" v-model="plusOne.surname" :name="`lastName-${index}`" type="text" required="required" class="form-control">
                                </div>
                                <div :key="`email-${index}`" class="form-group">
                                    <label :for="`email-${index}`" class="control-label">Email {{ "*" }}</label>
                                    <input :id="`email-${index}`" v-model="plusOne.email" :name="`email-${index}`" type="text" required="required" class="form-control">
                                </div>
                            </template>
                        </template>
                        <div class="form-group">
                            <div class="error-message" v-html="joinMessage"></div>
                            <div class="button-bar">
                                <router-link
                                    class="btn btn-secondary btn--cancel"
                                    :to="{ name: 'OrganizerPublicProfile', params: { code: code } }"
                                    tag="button"
                                    >
                                    {{ string.registrationPage._cancel }}
                                </router-link>
                                <button name="submit" type="submit" class="btn btn-primary btn--submit">Genera modulo</button>
                            </div>
                        </div>
                    </template>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    import { CFPrefix } from '@/types/enums';
    import { TEXT_ONLY, FILE_UPLOAD } from '../../utils/constants';
    import SuccessAnimation from '../ui/SuccessAnimation.vue';
    import moment from "moment";
    import FileUpload from '../FileUpload.vue';

    export default {
        components: {
            SuccessAnimation,
            FileUpload
        },
        props: {
            teamId: {
                type: String,
                required: true
            },
            eventId: {
                type: String,
                required: true
            },
            identifier: {
                type: String,
                required: false,
                default: () => ''
            },
            data: {
                type: Object,
                required: true,
                default: () => {}
            },
            string: {
                type: Object,
                required: true,
                default: () => {}
            },
            store: {
                type: Object,
                required: true
            },
            root: {
                type: Object,
                required: true
            },
            loggedInUser: {
                type: Object,
                required: false,
                default: () => {}
            },
            hideLoginBar: {
                type: Boolean,
                required: false,
                default: false
            },
            code: {
                type: String,
                required: true
            }
        },
        data (){
            return {
                componentName : 'page-registration-form-widget',
                loggedInUserData: null,
                answerList: {},
                memberAnswerList: {},
                customFieldDataMap: {},
                memberCustomFieldDataMap: {},
                checkedPolicies: {},
                showErrors: false,
                signupForm: {
                    name: '',
                    surname: '',
                    country: '',
                    telephone: '',
                    email: '',
                    password: '',
                    password2: ''
                },
                maxAcquaintance: 0,
                acquaintanceInfoList: [],
                thankYouMessage: null,
                joinMessage: "",
                uploaded: false,
                TEXT_ONLY,
                FILE_UPLOAD
            };
        },
        computed: {
            customFieldArray: function() {
                return this.data && this.data.customFieldMap ? Object.entries(this.data.customFieldMap) : [];
            },
            customFieldArrayNotDeferred: function() {
                return this.customFieldArray.filter(([_,cf]) => !cf.options.deferred);
            },
            memberCustomFieldArray: function() {
                return this.data && this.data.memberCustomFieldMap ? Object.entries(this.data.memberCustomFieldMap) : [];
            },
            timeSlotArray: function() {
                return this.data.timeSlotMap ? Object.entries(this.data.timeSlotMap) : [];
            },
            isMemberAlreadyJoined: function() {
                return this.loggedInUserData && this.loggedInUserData.myEventAccesses.filter(item => item.eventId === this.eventId).length > 0;
            },
            deferredUploads: function() {
                return this.customFieldArray.filter(([_, cf]) => cf.type === FILE_UPLOAD && cf.options.deferred);
            },
            includeDeferredUploads: function() {
                return this.deferredUploads.length > 0;
            },
            isCreated: function() {
                return this.data.joinInfo.status === 0; // 'CREATED'
            },
            isPending: function() {
                return this.data.joinInfo.status === 1; // 'PENDING'
            },
            isConfirmed: function() {
                return this.data.joinInfo.status === 2; // 'CONFIRMED'
            },
        },
        watch: {
            data: {
                immediate: true,
                handler(newVal, oldVal) {
                    const tempAnswerList = {};
                    for (const [key, cFieldData] of Object.entries(newVal)) {
                        if (Array.isArray(cFieldData)) {
                            tempAnswerList[key] = cFieldData.map(answer => answer.key);
                        } else if (typeof cFieldData !== "string") {
                            if (cFieldData && cFieldData.key) {
                                tempAnswerList[key] = cFieldData.key;
                            }
                        }
                    }
                    this.answerList = tempAnswerList;
                    this.checkedPolicies = {};
                    if (this.data.privacyPolicies) {
                        for (const acceptedPolicyId of this.data.privacyPolicies.acceptedPolicies) {
                            this.checkedPolicies[acceptedPolicyId] = true;
                        }
                    }
                }
            }
        },
        // mounted(){
        //     const required = document.getElementsByClassName('required');
        //     for (let i = 0; i < required.length; i++) {
        //         required[i].firstChild.firstElementChild.setAttribute('required', 'required');
        //     }
        // },
        created() {
            if (this.loggedInUser) {
                this.loggedInUserData = this.loggedInUser;
            }
            this.root.bus.addListener(
                "guestProfile",
                profile => {
                    this.loggedInUserData = profile;
                },
                this
            );
            const context = this;
            this.string = this.$root.strings;
            this.$root.bus.addListener(
            "setLanguage",
            () => {
                context.string = context.$root.strings;
            },
            this
            );
            const language = window.navigator.language;
            if (language === "it-IT") {
                this.$root.strings = this.$root.messages_it;
                this.lang = "it";
                this.$root.currentLanguage = "it";
            } else if (language === "en-GB" || language === "en-US" || language === "en") {
                this.$root.strings = context.$root.messages_en;
                this.lang = "en";
                this.$root.currentLanguage = "en";
            } else if (language === "fr-FR" || language === "fr") {
                this.$root.strings = context.$root.messages_fr;
                this.lang = 'fr';
                this.$root.currentLanguage = 'fr';
            } else if (language === "ar-AR" || language === "ar") {
                context.$root.strings = context.$root.messages_ar;
                context.lang = "ar";
                context.$root.currentLanguage = "ar";
            } else {
                this.$root.strings = context.$root.messages_en;
                this.lang = "en";
                this.$root.currentLanguage = "en";
            }
            this.$root.bus.emit("setLanguage");
        },
        methods: {
            resizeAquaitanceInfoList() {
                if ( this.maxAcquaintance < 0) {
                    this.maxAcquaintance = 0;
                }
                if ( this.maxAcquaintance > this.data.maxGuestsPerMember) {
                    this.maxAcquaintance = this.data.maxGuestsPerMember;
                }
                if (this.maxAcquaintance > this.acquaintanceInfoList.length) {
                    const toBeAdded = this.maxAcquaintance - this.acquaintanceInfoList.length;
                    for (let i = 0; i < toBeAdded; i++) {
                        this.acquaintanceInfoList.push({
                            name: "",
                            surname: "",
                            email: ""
                        });
                    }
                } else {
                    this.acquaintanceInfoList.splice(this.maxAcquaintance);
                }
            },
            eventWhenWhere(event) {
                const eventAddress = JSON.parse(event.address);
                return `${moment.utc(event.startDate).add(1,"hour").format("DD/MM/YYYY HH:mm")} - ${eventAddress.city}, ${
                    eventAddress.address
                }`;
            },
            isMemberCustomFieldAlreadyFilled(slug) {
                if (this.loggedInUserData && this.loggedInUserData.memberCustomFieldDataMap && typeof this.loggedInUserData.memberCustomFieldDataMap[slug] !== 'undefined' && this.loggedInUserData.memberCustomFieldDataMap[slug] !== "") {
                    return true;
                }
                return false;
            },
            getCookie(cname) {
                const name = cname + "=";
                const decodedCookie = decodeURIComponent(document.cookie);
                const ca = decodedCookie.split(';');
                for (let i = 0; i <ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            openLoginModal() {
                document.getElementById('login-modal').style.display = 'flex';
                document.getElementById('cover-background').style.display = 'flex';
            },
            logoutDo() {
                document.cookie = `guest-token-${this.teamId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.penguinpass.it`;
                document.location.reload();
            },
            onUpdateMultipleChoice(slug, value, type) {
                let result = [];
                if (
                    type === "event" && this.data.customFieldMap[slug] && this.data.customFieldMap[slug].options.answers ||
                    type === "team" && this.data.memberCustomFieldMap[slug] && this.data.memberCustomFieldMap[slug].options.answers                
                ) {
                    if (Array.isArray(value)) {
                        let answer;
                        for (const answerSlug of value) {
                            if (type === "event") {
                                answer = this.data.customFieldMap[slug].options.answers[answerSlug];
                            } else if (type === "team") {
                                answer = this.data.memberCustomFieldMap[slug].options.answers[answerSlug];
                            }
                            if (answer) {
                                const newAnswer = {
                                    key: answer.key,
                                    label: answer.label
                                };
                                result = result && result.length > 0 ? [...result, newAnswer] : [newAnswer];
                            }
                        }
                    }
                }
                if (type === "event") {
                    this.customFieldDataMap[slug] = result;
                } else if (type === "team") {
                    this.memberCustomFieldDataMap[slug] = result;
                }
            },
            onUpdateTimeSlot(slug, value) {
                let result = null;
                if (this.data.timeSlotMap[slug] && this.data.timeSlotMap[slug].options.answers) {
                    const answer = this.data.timeSlotMap[slug].options.answers[value];
                    if (answer) {
                    result = {
                        key: answer.key,
                        label: answer.label,
                        startDate: answer.startDate,
                        endDate: answer.endDate,
                        title: answer.title
                    };
                    }
                }
                this.customFieldDataMap[slug] = result;
            },
            onUpdateDateField(slug, value) {
                const parsedDate = moment(value).format("DD-MM-YYYY");
                this.customFieldDataMap[slug] = parsedDate;
            },
            checkPolicy: function(policy_id, checked) {
                this.checkedPolicies[policy_id] = checked;
            },
            async registerDo() {
                const context = this;
                //check identificativo
                if (!/^[A-Za-z0-9]*$/.test(this.customFieldDataMap['gcf-identificativo'])){
                    this.joinMessage = "Identificativo deve contenere solo lettere e numeri e non deve contenere caratteri speciali e/o spazi vuoti";
                    return;
                }

                //check policies
                let policyMandatoryChecked = true;
                let areCustomFieldsValid = true;
                if (this.data.privacyPolicies) {
                    for (const policy of this.data.privacyPolicies.policies) {
                        if (policy.mandatory && !this.checkedPolicies[policy._id]) {
                            policyMandatoryChecked = false;
                        }
                    }
                }

                // time slot control
                if (this.timeSlotArray && this.timeSlotArray.length && this.data.timeSlotMap) {
                    let hasTimeSlotAnswer = false;
                    for (const [slug, cField] of Object.entries(this.data.timeSlotMap)) {
                        if (this.customFieldDataMap[slug] && this.customFieldDataMap[slug].key) {
                                hasTimeSlotAnswer = true;
                        }
                    }
                    if (!hasTimeSlotAnswer) {
                        this.$alert(`${this.string.customFieldsManager._selectTimeSlotWarning}`,"",{ customClass: "grayscale" });
                    return;
                    }
                }

                //check custom fields
                if (this.data.customFieldMap) {
                    for (const [slug, cField] of Object.entries(this.data.customFieldMap)) {
                        if (cField.registrationFormMandatory) {
                            switch (cField.type) {
                                case "MULTIPLE_CHOICE": {
                                    if ((this.isCreated) && (!this.customFieldDataMap[slug] || this.customFieldDataMap[slug].length < 1)) {
                                        areCustomFieldsValid = false;
                                    }
                                    break;
                                }
                                case "TIME_SLOT": {
                                    break;
                                }
                                case FILE_UPLOAD: {
                                    // to be checked if (it's not deferred and !isMemberAlreadyJoined) or (if deferred and isMemberAlreadyJoined)
                                    const toCheck = (this.isPending && !cField.options.deferred)
                                        || (this.isPending && cField.options.deferred);
                                    if (!toCheck) {
                                        break;
                                    }
                                    if (!this.customFieldDataMap[slug] || !this.customFieldDataMap[slug].file) {
                                        areCustomFieldsValid = false;
                                    }
                                    break;
                                }
                                case "OPEN_ANSWER":
                                default: {
                                    if ((this.isCreated) && (!this.customFieldDataMap[slug] || this.customFieldDataMap[slug] === "")) {
                                        areCustomFieldsValid = false;
                                    }
                                    break;
                                }
                            }
                        }
                    }
                }
                //check member custom fields
                if (this.data.memberCustomFieldMap) {
                    for (const [slug, cField] of Object.entries(this.data.memberCustomFieldMap)) {
                        if (cField.registrationFormMandatory && !this.isMemberCustomFieldAlreadyFilled(slug)) {
                        switch (cField.type) {
                            case "MULTIPLE_CHOICE": {
                                if (!this.memberCustomFieldDataMap[slug] || this.memberCustomFieldDataMap[slug].length < 1) {
                                    areCustomFieldsValid = false;
                                }
                                break;
                            }
                            case "TIME_SLOT": {
                                break;
                            }
                            case FILE_UPLOAD: {
                                // if (it's not deferred and !isPending) or (if deferred and isPending)
                                const toCheck = (this.isPending && !cField.options.deferred)
                                    || (this.isPending && cField.options.deferred);
                                if (!toCheck) {
                                    break;
                                }
                                if (!this.memberCustomFieldMap[slug] || !this.memberCustomFieldMap[slug].file) {
                                    areCustomFieldsValid = false;
                                }
                                break;
                            }
                            case "OPEN_ANSWER":
                            default: {
                            if (!this.memberCustomFieldDataMap[slug] || this.memberCustomFieldDataMap[slug] === "") {
                                areCustomFieldsValid = false;
                            }
                            break;
                            }
                        }
                        }
                    }
                }
                if (!policyMandatoryChecked) {
                    this.$alert(`${this.string.joinPage._join_alert_policy}`,"",{ customClass: "grayscale" });
                    return;
                }

                if (!areCustomFieldsValid) {
                    this.$alert(`${this.string.joinPage._join_alert_fields}`,"",{ customClass: "grayscale" });
                    return;
                }

                let acceptedPolicies = Object.keys(this.checkedPolicies);
                acceptedPolicies = acceptedPolicies.filter(key => {
                    return context.checkedPolicies[key];
                });

                if (!context.getCookie('guest-token-' + context.teamId)) {
                    const result = await this.store.dispatch("guestRegister", {
                        teamId: this.teamId,
                        postSignUpJoinPayload: {
                            eventId: this.eventId,
                            isUserInput: true,
                            acceptedPolicies: acceptedPolicies,
                            customFieldDataMap: this.customFieldDataMap,
                            memberCustomFieldDataMap: this.memberCustomFieldDataMap,
                            maxAcquaintance: parseInt(this.maxAcquaintance),
                            acquaintanceInfoList: this.acquaintanceInfoList,
                            source: this.data.registrationFormId ? "REGISTRATION_FORM" : "",
                            registrationFormId: this.data.registrationFormId
                        },
                        ...this.signupForm });
                    // console.log("register result", result);
                    if (result && result.status === "SUCCESS" && result.message === "VERIFY_ACCOUNT") {
                        this.thankYouMessage = this.string.registrationPage._thankYouWaiting;
                    } else if (result && result.status === "SUCCESS" && result.data && result.data.joinStatus === "OK") {
                        this.thankYouMessage = this.string.registrationPage._thankYouWaiting;
                    } else if (result && result.status === "ERROR" && result.message === "EMAIL_IN_USE") {
                        this.joinMessage = this.string.registrationPage._emailInUse;
                    } else if (result && result.status === "ERROR" && result.message === "PASSWORD_NOT_MATCHING_POLICY") {
                        this.joinMessage = this.string.commonActions.passwordPolicy;
                    }
                } else {
                    this.eventJoinDo(acceptedPolicies);
                }
            },
            eventJoinDo(acceptedPolicies) {
                this.uploaded = true;
                const context = this;
                this.store
                .dispatch("publicEventJoin", {
                    token: this.getCookie('guest-token-' + this.teamId),
                    teamId: this.teamId,
                    eventId: this.eventId,
                    isUserInput: true,
                    acceptedPolicies: acceptedPolicies,
                    customFieldDataMap: this.customFieldDataMap,
                    memberCustomFieldDataMap: this.memberCustomFieldDataMap,
                    maxAcquaintance: parseInt(this.maxAcquaintance),
                    acquaintanceInfoList: this.acquaintanceInfoList,
                    source: this.data.registrationFormId ? "REGISTRATION_FORM" : "",
                    registrationFormId: this.data.registrationFormId,
                    isSecondStep: this.isPending,
                    identifier: this.identifier
                })
                .then(result => {
                    if (result && result.status === "SUCCESS" && result.data && result.data.joinStatus === "OK") {
                        if (!this.isPending) {
                            if(this.data.customCategory === 'rinnovo-iscrizione')
                                this.thankYouMessage = `Grazie per aver compilato online la domanda di rinnovo dell'iscrizione al Registro Federale. Riceverai il modulo compilato nella tua casella di posta che dovrai firmare, scansionare e caricare sul portale unitamente a tutti gli allegati richiesti per completare il deposito presso la Commissione Federale Agenti Sportivi.`;
                            else 
                                this.thankYouMessage = `Grazie per aver compilato online la domanda di iscrizione al Registro Federale. Riceverai il modulo compilato nella tua casella di posta che dovrai firmare, scansionare e caricare sul portale unitamente a tutti gli allegati richiesti per completare il deposito presso la Commissione Federale Agenti Sportivi.`;
                        } else {
                            this.thankYouMessage = "Grazie per aver caricato i file necessari.";
                        }
                    } else if (result && result.status === "SUCCESS" && result.data && result.data.joinStatus === "WAITING") {
                        if (!result.data.validationWithError) {
                            this.joinMessage ="Campo/i a scelta multipla non compilato/i";
                        }
                        if (result.data.limitReachedAnswer) {
                            const { type, customFieldSlug, answerSlug } = result.data.limitReachedAnswer;
                            const question =
                                type === "MULTIPLE_CHOICE"
                                ? this.data.customFieldMap[customFieldSlug].options.question
                                : this.data.timeSlotMap[customFieldSlug].options.question;
                            const answer =
                                type === "MULTIPLE_CHOICE"
                                ? this.data.customFieldMap[customFieldSlug].options.answers[answerSlug].label
                                : this.data.timeSlotMap[customFieldSlug].options.answers[answerSlug].label;
                            this.joinMessage = `${this.string.customFieldsManager._limitReached} <br/> <strong>${this.string.customFieldsManager._questionTitle}:</strong> ${question} <br/> <strong>${this.string.customFieldsManager._answerTitle}:</strong> ${answer} <br/> ${this.string.customFieldsManager._limitAction}`;
                        }
                        if (result.data.validationWithError) {
                            const { slug, type } = result.data.validationWithError;
                            let question = '';
                            if (slug.startsWith(CFPrefix.CONTACT)){
                                question =
                                type === this.data.memberCustomFieldMap[slug].options.question;
                            } else {
                                question = this.data.customFieldMap[slug].options.question;
                            }
                            this.joinMessage = `${this.string.customFieldsManager._validationWithInvalid} <br/> <strong>${this.string.customFieldsManager._fieldName}:</strong> ${question} <br/>`;
                        }
                    } else if (result && result.status === "SUCCESS" && result.data && result.data.joinStatus === "EVENT_IS_FULL") {
                        this.joinMessage = this.string.registrationPage._eventFull2;
                    } else if (result && result.status === "ERROR" && result.data && result.data.joinStatus) {
                        switch (result.data.joinStatus) {
                            case "ALREADY_JOINED":
                                this.joinMessage = this.string.registrationPage._alreadyJoined;
                            break;
                            case "EVENT_IS_FULL":
                                this.joinMessage = this.string.registrationPage._eventFull2;
                            break;
                        }
                    }
                });
            },
            changeFile(slug, content) {
                // TODO: for now we support a single file
                this.customFieldDataMap[slug] = {
                    type: "FILE_UPLOAD",
                    isFile: true,
                    file: content[0]
                };
            },
        }
    };
</script>
<style>
.registration-form-widget {
    padding: 40px;
    padding-top: 44px;
    font-family: "Raleway";
}
.registration-form-widget .el-input__inner, .registration-form-widget input:focus {
    border: none !important;
    outline: none !important;
}
.registration-form-widget .el-select .el-input__inner {
    padding-left: 0px;
}
.registration-form-widget .el-input__inner::placeholder {
    color: #999999 !important;
}
.registration-form-widget .control-label, .registration-form-widget .single-action > label {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 400;
}
.registration-form-widget .privacy-policy.single-action > label {
    margin-bottom: 0px;
}
.registration-form-widget .form-control, .registration-form-widget .form-control:focus {
    background-color: #F2F2F2 !important;
    border: none !important;
    height: 48px !important;
}
.registration-form-widget .form-control:focus {
    border-bottom: 3px solid #000000 !important;
}
.registration-form-widget .el-select > .el-input > .el-input__inner {
    background-color: #ffffff !important;
    border: none !important;
    padding: 0px;
}
.registration-form-widget .el-select.form-control {
    height: auto !important;
    min-height: 48px !important;
}
.registration-form-widget .form-group {
    margin-bottom: 0px;
}
.registration-form-widget p {
    color: #231F20;
    font-size: 16px;
    font-weight: 300;
}
.registration-form-widget h3 {
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 18px;
    font-weight: 500;
}
.registration-form-widget h2 {
    font-size: 32px;
    color: #231F20;
}

.button-bar .btn {
    padding: 1rem 2rem;
}

.btn--submit {
    margin-top: 40px;
    margin-bottom: 40px;

    width: auto;
    padding: 6px 12px;
    border-radius: 4px;
}

.btn--cancel {
    background-color: white;
    color: black;
    border: 1px solid black;

    margin-right: 1rem;
}

.registration-form-widget a, .registration-form-widget a:active, .registration-form-widget a:focus {
    color: #231f20;
    font-weight: 400;
    text-decoration: underline;
}
.registration-form-widget ::selection {
    background-color: #231f20 !important;
}
.registration-form-widget ::-moz-selection {
    background-color: #231f20 !important;
}
.registration-form-widget label + p {
    margin-bottom: 4px;
}
.registration-form-widget .section-title {
    display: flex;
    flex-direction: row;
    font-weight: 300;
    color: #7A7E7E;
    font-size: 14px;
    margin-bottom: 16px;
}
.registration-form-widget .section-title:before {
    content: "";
    flex-basis: 16px;
    border-bottom: 1px solid;
    margin: auto;
}
.registration-form-widget .section-title:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
}
.registration-form-widget .section-title:before {
  margin-right: 10px
}
.registration-form-widget .section-title:after {
  margin-left: 10px
}
.registration-form-widget .el-select {
    padding: 0px;
    background-color: #ffffff !important;
    border: 1px solid #D3D2D2 !important;
    line-height: 48px;
    border-radius: 5px;
}
.registration-form-widget .el-select > .el-input > .el-input__inner {
    height: 48px;
    padding-left: 15px;
}
.el-popper[x-placement^=top] .popper__arrow::after {
    border-top-color: #000000;
}
.el-popper[x-placement^=bottom] .popper__arrow::after {
    border-bottom-color: #000000;
}
.registration-form-widget .privacy-policy {
    display: flex;
}
.registration-form-widget .privacy-policy .el-checkbox__inner {
    background-color: #D3D2D2;
}
.registration-form-widget .privacy-policy .el-checkbox__input.is-checked .el-checkbox__inner, .registration-form-widget .privacy-policy .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #231F20;
}
.registration-form-widget .privacy-policy .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #231F20;
}
</style>
<style scoped>
.registration-form-widget, .control-label, .form-control {
    color: #555555 !important;
}
.btn-primary {
    background-color: #000000 !important;
    border: none;
}
.row {
    color: #555555;
}
.error-message {
    color: red;
    margin-top: 12px;
    margin-bottom: 24px;
}
.no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
}

</style>