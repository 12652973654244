<template>
  <div class="custom-field-filter-area">
    <el-row :gutter="20" class="custom-field-filter-area-header">
      <el-col>
        {{ $root.strings.customFieldFilter._custom_field_filter_header }}
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-select
          v-model="customFieldFilter.question"
          style="width: 100%; height: 45px;"
          :placeholder="string.customFieldFilter._custom_field_filter_question_choose"
          clearable
          popper-class="default-popper"
          size="large"
          @change="
            value =>
              onCustomFieldFilterQuestionChanged(
                customFieldMap[customFieldFilter.question].type,
                value,
                customFieldMap[customFieldFilter.question].source,
              )
          "
        >
          <el-option
            v-for="[slug, cField] in customFieldArray"
            :key="slug"
            class="custom-field"
            :label="cField.options.question"
            :value="slug"
          >
            {{ cField.options.question }}
          </el-option>
        </el-select>
      </el-col>
      <el-col
        v-if="customFieldFilter.question && customFieldMap[customFieldFilter.question]"
        :span="12"
      >
        <div
          v-if="
            customFieldMap[customFieldFilter.question].type === 'MULTIPLE_CHOICE' ||
              customFieldMap[customFieldFilter.question].type === 'TIME_SLOT'
          "
        >
          <el-select
            v-model.trim="customFieldFilter.answer"
            style="width: 100%; height: 45px;"
            :placeholder="string.customFieldFilter._custom_field_filter_answer_choose"
            clearable
            popper-class="default-popper"
            size="large"
            @change="
              value =>
                onCustomFieldFilterAnswerChanged(
                  customFieldMap[customFieldFilter.question].type,
                  value,
                  customFieldMap[customFieldFilter.question].source,
                )
            "
          >
            <el-option
              v-for="[answerSlug, answer] in answers[customFieldFilter.question] || []"
              :key="answerSlug"
              class="custom-field"
              :label="answer.label"
              :value="answerSlug"
            >
              {{ answer.label }}
            </el-option>
          </el-select>
        </div>
        <div v-else-if="customFieldMap[customFieldFilter.question].type === 'OPEN_ANSWER'">
          <custom-input
            v-model.trim="customFieldFilter.answer"
            :placeholder="string.customFieldFilter._custom_field_filter_answer_input"
            @change="()=> onCustomFieldFilterAnswerChanged(customFieldMap[customFieldFilter.question].type,customFieldFilter.answer, customFieldMap[customFieldFilter.question].source)"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import CustomInput from '@/components/ui/CustomInput.vue';
import { FILE_UPLOAD, TEXT_ONLY } from '@/utils/constants';
export default {
  name: "CustomFieldFilter",
  components: { CustomInput },
  props: {
    customFieldMap: {
      type: Object,
      required: true
    },
    onFilterChanged: {
      type: Function,
      required: false,
      default: customFieldFilter => {}
    },
    initialValue: {
      type: Object,
      required: false,
      default: () => ({ question: "", answer: "", type: "", source: "" })
    }
  },
  data() {
    return {
      string: {},
      customFieldFilter: { question: "", answer: "", type: "", source: "" },
      customFieldArray: [],
      answers: {},
      revalidateListener: null,
    };
  },
  computed: {},
  watch: {
    customFieldMap: {
      immediate: true,
      handler: function(newVal, oldVal) {
        const newCFArray = newVal ? Object.entries(newVal) : [];
        this.customFieldArray = newCFArray.filter( ([slug, cField]) => ![TEXT_ONLY, FILE_UPLOAD].includes(cField.type));
        const answers = {};
        for (const [slug, cField] of newCFArray) {
          if (cField.type === "MULTIPLE_CHOICE" || cField.type === "TIME_SLOT") {
            answers[slug] = Object.entries(cField.options.answers);
          }
        }
        this.answers = { ...answers };
        this.customFieldFilter = { question: "", answer: "", type: "", source: ""  };
      }
    },
    initialValue: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal && newVal.question && this.customFieldMap[newVal.question]) {
          this.customFieldFilter = newVal;
        }
      },
      deep: true,
    }
  },
  created() {
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      this.string = this.$root.strings;
    });
  },
  methods: {
    // UI helpers
    onCustomFieldFilterQuestionChanged(type, value, source) {
      this.customFieldFilter.answer = "";
      this.customFieldFilter.type = type;
      this.customFieldFilter.source = source;
      if (this.onFilterChanged && typeof this.onFilterChanged === "function") {
        this.onFilterChanged(this.customFieldFilter);
      }
    },
    onCustomFieldFilterAnswerChanged(type, value, source) {
      this.customFieldFilter.type = type;
      this.customFieldFilter.source = source;
      if (this.onFilterChanged && typeof this.onFilterChanged === "function") {
        this.onFilterChanged(this.customFieldFilter);
      }
    }
  }
};
</script>

<style scoped>
.custom-field-filter-area-header {
  padding: 20px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
}
.custom-field-filter-area {
  border: 1px solid #0057B8;
  padding: 5px 0 30px 0;
  margin: 20px 0 !important;
}
.custom-field-filter-area > div.el-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>
