<template>
  <div class="container">
    <div v-if="!pageLoaded" id="preloader">
      <div class="pulse"></div>
    </div>
    <JoinHeader :join-status="joinStatus" :guest-name="guest.name" :guest-surname="guest.surname" :team-unique-name="teamUniqueName" :srcImage="srcImage" :logoEnabled="logoEnabled"/>

    <JoinIcon :join-status="joinStatus" />
    <JoinInteractions
      :join-action="action"
      :join-status="joinStatus"
      :virtual-venue="virtualVenue"
      :virtualroom-code="virtualRoomCode"
      :privacy-policies="privacyPolicies"
      :accepted-policies="acceptedPolicies"
      :max-acquaintance="guest.maxAcquaintance"
      :acquaintance-added="guest.acquaintanceAdded"
      :set-max-acquaintance-on-join="setMaxAcquaintanceOnJoin"
      :set-acquaintance-info-on-join="setAcquaintanceInfoOnJoin"
      :custom-field-map="customFieldMap"
      :custom-field-data-map="customFieldDataMap"
      :member-custom-field-map="memberCustomFieldMap"
      :member-custom-field-data-map="memberCustomFieldDataMap"
      :time-slot-map="timeSlotMap"
      :time-slot-enabled="timeSlotEnabled"
      @confirmAccept="confirmAccept"
      @confirmDecline="confirmDecline"
      @setAcquaintance="onSetAcquaintance"
    />
    <footer style="margin-top:5px; margin-bottom: 0;">
      <p class="footer-text">
        Utilizziamo Penguinpass come nostra piattaforma di marketing. Procedendo è consapevole che
        le informazioni che fornisce saranno trasferite a Penguinpass per essere processate in
        accordo con le loro
        <a
          :href="string.termsAndConditions._privacypolicyURL"
          title="Privacy policy"
          class="footer-text"
          target="_blank"
        >
          Privacy policy
        </a>
        e
        <a
          :href="string.termsAndConditions._linkTerms"
          title="Termini e condizioni"
          class="footer-text"
          target="_blank"
        >
          Termini e condizioni </a
        >.
      </p>
      <p class="en footer-text">
        We use Penguinpass as our marketing platform. Proceeding you are aware that information you
        provide will be transferred to Penguinpass to be processed in accordance with their
        <a
          :href="string.termsAndConditions._privacypolicyURL"
          title="Privacy policy"
          target="_blank"
          class="footer-text"
        >
          Privacy policy
        </a>
        and
        <a
          :href="string.termsAndConditions._linkTerms"
          title="Terms & conditions"
          target="_blank"
          class="footer-text"
        >
          Terms & conditions
        </a>
      </p>
    </footer>
  </div>
</template>

<script>
import "html5shiv/dist/html5shiv.min";
import "bootstrap/dist/js/bootstrap.min";
import "wowjs/dist/wow.min";
import "owl.carousel/dist/owl.carousel.min";
import "magnific-popup/dist/jquery.magnific-popup.min";
import "isotope-layout/dist/isotope.pkgd.min";
import "imagesloaded/imagesloaded.pkgd.min";
import "smoothscroll-for-websites/SmoothScroll";
import "jquery.easing/jquery.easing.min";
import "../assets/js/v2/theme";
import JoinHeader from "@/components/join/JoinHeader";
import JoinIcon from "@/components/join/JoinIcon";
import JoinInteractions from "@/components/join/JoinInteractions";
import { CFPrefix } from '@/types/enums';

export default {
  components: {
    JoinInteractions,
    JoinIcon,
    JoinHeader
  },
  props: {
    code: String,
    action: String
  },
  data() {
    return {
      guest: {
        name: "",
        surname: "",
        maxAcquaintance: 0,
        acquaintanceAdded: false
      },
      string: {},
      pageLoaded: false,
      toastMessage: "",
      toastError: "",
      /**
       * 0 Ok
       * 1 Waiting
       * 2 Declined_OK
       * 3 Already_Joined
       * 4 Already_Declined
       * 5 Bad Token
       * 6 Event not found
       * 7 Event is full
       * 8 Guest not found
       * 9 Policies are not accepted
       * 10 Unhandled
       */
      teamUniqueName: null,
      joinStatus: 0,
      privacyPolicies: [],
      acceptedPolicies: [],
      acquaintanceInfoList: [],
      setMaxAcquaintanceOnJoin: false,
      setAcquaintanceInfoOnJoin: false,
      virtualVenue: "",
      virtualRoomCode: "",
      timeSlotMap: {}, //from event
      timeSlotEnabled: false, //from event
      customFieldMap: {}, //from event
      customFieldDataMap: {}, //for guest
      memberCustomFieldMap: {}, //from team
      memberCustomFieldDataMap: {},//for member
      srcImage: "",
      logoEnabled: false
    };
  },
  computed: {
    // isLoggedIn : function (){ return this.$store.getters.isLoggedIn;}
  },
  metaInfo: {
    title: "Join - Penguinpass",
    bodyAttrs: {
      class: "join"
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener(
      "setLanguage",
      () => {
        context.string = context.$root.strings;
      },
      this
    );
    const language = window.navigator.language;
    if (language === "it-IT") {
      context.$root.strings = context.$root.messages_it;
      context.lang = "it";
      context.$root.currentLanguage = "it";
    } else if (language === 'en-GB' || language === 'en-US' || language === 'en'){
      context.$root.strings = context.$root.messages_en;
      context.lang = 'en';
      context.$root.currentLanguage = 'en';
    } else if (language === 'fr-FR' || language === 'fr'){
      context.$root.strings = context.$root.messages_fr;
      context.lang = 'fr';
      context.$root.currentLanguage = 'fr';
    } else if (language === "ar-AR" || language === "ar") {
      context.$root.strings = context.$root.messages_ar;
      context.lang = "ar";
      context.$root.currentLanguage = "ar";
    } else {
      context.$root.strings = context.$root.messages_en;
      context.lang = 'en';
      context.$root.currentLanguage = 'en';
    }
    this.$root.bus.emit("setLanguage");
    this.loadInvitationData(this.code, this.action, [], {}, {}, null, null, false);
  },
  methods: {
    confirmAccept: function(data) {
      this.loadInvitationData(
        this.code,
        this.action,
        data.acceptedPolicies,
        data.customFieldDataMap,
        data.memberCustomFieldDataMap,
        data.maxAcquaintance,
        data.acquaintanceInfoList,
        true
      );
    },
    confirmDecline: function(data) {
      this.loadInvitationData(
        this.code,
        this.action,
        [],
        {},
        {},
        null,
        null,
        true
      );
    },
    onSetAcquaintance: function(data) {
      const context = this;
      this.pageLoaded = false;
      this.$store
        .dispatch("setAcquaintance", {
          code: this.code,
          acquaintanceInfoList: data.acquaintanceInfoList,
          maxAcquaintance: data.maxAcquaintance
        })
        .then(result => {
          if (result.status === "SUCCESS" && result.data) {
            const data = result.data;
            context.joinStatus = data.joinStatus;
            if (data.joinStatus === 0 || data.joinStatus === 1) {
              //populate guest fields
              context.guest.name = data.member.name;
              context.guest.surname = data.member.surname;
              context.guest.maxAcquaintance = data.member.maxAcquaintance;
              context.guest.acquaintanceAdded = data.member.acquaintanceAdded;
            }
          }
          context.pageLoaded = true;
        })
        .catch(error => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            context.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000
            });
          } else {
            console.log("error in on Set Acquaintance " + error.message);
          }
          context.pageLoaded = true;
        });
    },
    loadInvitationData: function(code, action, acceptedPolicies, customFieldDataMap, memberCustomFieldDataMap, maxAcquaintance, acquaintanceInfoList, isUserInput) {
      const context = this;
      this.pageLoaded = false;
      this.$store
        .dispatch("eventJoin", { code, action, acceptedPolicies, customFieldDataMap, memberCustomFieldDataMap, maxAcquaintance, acquaintanceInfoList, isUserInput })
        .then(result => {
          if (result.status === "SUCCESS") {
            if (result.data) {
              const data = result.data;
              context.joinStatus = data.joinStatus;
              context.teamUniqueName = data.team.uniqueName;
              
              if (data.virtualVenue) {
                context.virtualVenue = data.virtualVenue;
              }
              if (data.virtualRoomCode) {
                context.virtualRoomCode = `${document.location.origin}/room/${data.virtualRoomCode}`;
              }

              if (data.joinStatus === 0 || data.joinStatus === 1) {
                //populate guest fields
                context.guest.name = data.member.name;
                context.guest.surname = data.member.surname;
                context.guest.maxAcquaintance = data.member.maxAcquaintance;
                context.guest.acquaintanceAdded = data.member.acquaintanceAdded;
                context.customFieldMap = data.event.customFieldMap;
                context.memberCustomFieldMap = data.team.memberCustomFieldMap;
                context.memberCustomFieldDataMap = data.team.memberCustomFieldDataMap;
                context.timeSlotMap = data.event.timeSlotMap;
                context.timeSlotEnabled = data.event.timeSlotEnabled;
                context.customFieldDataMap = data.member.customFieldDataMap;
                if (data.joinStatus === 1 && data.limitReachedAnswer) {
                  const { type, customFieldSlug, answerSlug } = data.limitReachedAnswer;
                  let question = '';
                  let answer = '';
                  if (customFieldSlug.startsWith(CFPrefix.CONTACT)){
                    question =
                      type === "MULTIPLE_CHOICE"
                        ? context.memberCustomFieldMap[customFieldSlug].options.question
                        : context.memberCustomFieldMap[customFieldSlug].options.question;
                    answer =
                      type === "MULTIPLE_CHOICE"
                        ? context.memberCustomFieldMap[customFieldSlug].options.answers[answerSlug].label
                        : context.memberCustomFieldMap[customFieldSlug].options.answers[answerSlug].label;
                  } else {
                    question =
                      type === "MULTIPLE_CHOICE"
                        ? context.customFieldMap[customFieldSlug].options.question
                        : context.timeSlotMap[customFieldSlug].options.question;
                    answer =
                      type === "MULTIPLE_CHOICE"
                        ? context.customFieldMap[customFieldSlug].options.answers[answerSlug].label
                        : context.timeSlotMap[customFieldSlug].options.answers[answerSlug].label;
                  }
                  this.$alert(
                    `${this.$root.strings.customFieldsManager._limitReached} <br/> <strong>${this.$root.strings.customFieldsManager._questionTitle}:</strong> ${question} <br/> <strong>${this.$root.strings.customFieldsManager._answerTitle}:</strong> ${answer} <br/> ${this.$root.strings.customFieldsManager._limitAction}`,
                    "",
                    { dangerouslyUseHTMLString: true, customClass: "grayscale" }
                  );
                }
                
                if (data.joinStatus === 1 && data.validationWithError) {
                  const { slug, type } = data.validationWithError;
                  let question = '';
                  if (slug.startsWith(CFPrefix.CONTACT)){
                    question =
                      type === context.memberCustomFieldMap[slug].options.question;
                  } else {
                    question = context.customFieldMap[slug].options.question;
                  }
                  this.$alert(
                    `${this.$root.strings.customFieldsManager._validationWithInvalid} <br/> <strong>${this.$root.strings.customFieldsManager._fieldName}:</strong> ${question} <br/>`,
                    "",
                    { dangerouslyUseHTMLString: true, customClass: "grayscale" }
                  );
                }
              }

              context.srcImage = data.event.logoUrl;
              context.logoEnabled = data.event.logoEnabled;
              context.setMaxAcquaintanceOnJoin = data.setMaxAcquaintanceOnJoin;
              context.setAcquaintanceInfoOnJoin = data.setAcquaintanceInfoOnJoin;

              //get custom policies
              context.privacyPolicies = data.policies;
              context.acceptedPolicies = data.acceptedPolicies;
              if (context.privacyPolicies && context.privacyPolicies.length) {
                for (const policy of context.privacyPolicies) {
                  if (context.acceptedPolicies.indexOf(policy._id) > -1) {
                    policy.checked = true;
                  }
                }
              }
            }
          }
          context.pageLoaded = true;
        })
        .catch(error => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            context.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000
            });
          } else {
            console.log("Error in load invitation " + error.message);
          }
          context.pageLoaded = true;
        });
    }
  }
};
</script>

<style>
.control-checkbox * {
  color: #555 !important;
}
</style>

<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../assets/css/v2/style.css";
@import "../assets/css/v2/custom.css";
@import "../assets/css/v2/join.css";

.field {
  padding: 10px 0 10px 0;
  margin: 0 0 5px 0;
}

.footer-text {
  color: #666666;
}
a.footer-text {
  text-decoration: underline;
}
.actions {
  display: inline-block;
  width: 20%;
  text-align: left;
  vertical-align: top;
}

/*checkbox*/

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  font-weight: 400;
  cursor: pointer;
  color: #555555;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #000;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #000;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}

.confirm-button {
  display: block;
  color: #777;
  border: 1px solid #000;
  background: #fff;
  width: 100%;
  max-width: 50%;
  margin: 50px auto 30px;
  padding: 13px;
  cursor: pointer;
  transition: 1s ease;
}

.confirm-button:hover {
  color: #fff;
  background: #000;
  border: 1px solid transparent;
}

.actions .title {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}
.main-wrapper-first {
  width: 68.75%;
  margin: 4rem auto 3rem;
}
@media (min-width: 1200px) {
  .main-wrapper-first {
    width: 75%;
  }
}
@media (max-width: 1199px) {
  .main-wrapper-first {
    width: 90%;
  }
}
@media (max-width: 767px) {
  .main-wrapper-first {
    margin-top: 3rem;
    width: 97%;
  }

  .banner-area {
    padding: 60px;
  }
}
.banner-area {
  background: #000;
}
.banner-area .height {
  height: 600px;
}
.banner-area .generic-height {
  height: 300px;
}
.sample-text-area {
  background: #fff;
  padding: 50px 0 70px;
  text-align: center;
}
.sample-text-area-black {
  background: #000;
  padding: 70px 0 90px;
  margin-top: 50px;
  text-align: center;
}

.sample-text-area i {
  color: #000;
  font-size: 8rem;
}
.sample-text-area-black i {
  color: #fff;
  font-size: 8rem;
}
.banner-content {
  font-size: 28px;
}
</style>
