var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicLayout", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row wow fadeIn" }, [
        _c("div", { staticClass: "col-md-7" }, [
          _c(
            "div",
            {
              staticClass: "heading heading-md heading-uppercase heading-hover",
            },
            [
              _c("br"),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "heading-title text-white",
                  attrs: { id: "title" },
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.string.loginPage._login_title) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "hr-width-1 hr-5x border-main" }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "heading-tescription lead text-gray-2",
                  attrs: { id: "description" },
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.string.loginPage._login_description) +
                      "\n            "
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-5 text-gray-2 padding-left-40" }, [
          _c("h5", { staticClass: "margin-top-40 margin-bottom-20" }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            staticClass: "margin-top-80 text-white wow fadeIn",
            attrs: { id: "contact-form", "data-wow-delay": "0.3s" },
          },
          [
            _c(
              "div",
              {
                staticClass: "col-lg-6",
                staticStyle: { margin: "2px", "padding-left": "0" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return (function () {})($event)
                  },
                },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { staticClass: "sr-only", attrs: { for: "email" } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.string.loginPage._email_hint) +
                            "\n                  "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.email,
                          expression: "user.email",
                        },
                      ],
                      staticClass: "form-control no-rounded",
                      attrs: {
                        id: "email",
                        type: "email",
                        name: "email",
                        value: "",
                        placeholder: "Email Address",
                        required: "",
                      },
                      domProps: { value: _vm.user.email },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return (function (e) {
                            e.preventDefault()
                            _vm.login()
                          })($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.user, "email", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      { staticClass: "sr-only", attrs: { for: "password" } },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.string.loginPage._password_hint) +
                            "\n                  "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.password,
                          expression: "user.password",
                        },
                      ],
                      staticClass: "form-control no-rounded",
                      attrs: {
                        id: "password",
                        type: "password",
                        name: "password",
                        placeholder: "Password",
                        required: "",
                      },
                      domProps: { value: _vm.user.password },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return (function (e) {
                            e.preventDefault()
                            _vm.login()
                          })($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.user, "password", $event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "p",
                    {
                      staticClass: "text-policy",
                      staticStyle: { "font-size": "14px", "margin-top": "2px" },
                    },
                    [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.string.loginPage._password_recovery) +
                          "\n                  "
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            tag: "a",
                            to: { path: "/forgot-password" },
                            title: "click here",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.string.loginPage._click_here) +
                              "\n                  "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row public-last-row" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-primary btn-block btn-warning active btn-lg",
                      attrs: { type: "submit" },
                      on: {
                        click: function (e) {
                          e.preventDefault()
                          this$1.login()
                        },
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return (function (e) {
                            e.preventDefault()
                            _vm.login()
                          })($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.string.loginPage._submit) +
                          "\n              "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("label", { staticClass: "control control-checkbox" }, [
                    _c(
                      "label",
                      {
                        staticStyle: {
                          "font-size": "12px",
                          color: "#fff !important",
                        },
                        attrs: { for: "rememberMe" },
                      },
                      [
                        _vm._v(
                          "\n                     " +
                            _vm._s(_vm.string.loginPage._remember_me) +
                            "\n                  "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.remember_me,
                          expression: "user.remember_me",
                        },
                      ],
                      attrs: { id: "rememberMe", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.user.remember_me)
                          ? _vm._i(_vm.user.remember_me, null) > -1
                          : _vm.user.remember_me,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.user.remember_me,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.user,
                                  "remember_me",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.user,
                                  "remember_me",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.user, "remember_me", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "control_indicator",
                      staticStyle: { top: "5px" },
                    }),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("br"),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }