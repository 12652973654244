<template>
  <header class="header black-bg">
    <div class="sidebar-toggle-box">
      <div
              class="fa fa-bars tooltips"
              data-placement="right"
              data-original-title="menu"
              @click="toogleSideBar"></div>
    </div>
    <!-- <a
            href="/"
            class="logo"
    > -->
     <router-link
      :to="{path: '/'}"
      tag="a"
      class="logo">
      <b>{{ appNameLocal }}</b>
     </router-link>

    <div class="containerHeaderRightMenu">
      <!-- <el-dropdown @command="handleCommand" trigger="click" style="margin-right:10px;">
        <Icon icon="sort-down" bgColor="#00000000" size="2x" :text="userNameLocal"/>
        <el-dropdown-menu  slot="dropdown">
          <el-dropdown-item command="settings" icon="el-icon-setting" >{{ string.sidebar._settings }}</el-dropdown-item>
          <el-dropdown-item v-if="features && features.teamMembers && $can('access', teamMembersPage)" command="team" icon="el-icon-user" >{{ string.sidebar._members }}</el-dropdown-item>
          <el-dropdown-item divided command="logout"  icon="el-icon-moon" >{{ string.header._logout }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <el-dropdown @command="handleCommand" trigger="click">
        <p>{{ this.langSet }}</p>
        <el-dropdown-menu  slot="dropdown">
          <el-dropdown-item command="it">{{ string.header._italian }}</el-dropdown-item>
          <el-dropdown-item command="en">{{ string.header._english }}</el-dropdown-item>
          <el-dropdown-item command="fr">{{ string.header._francaise }}</el-dropdown-item>
          <el-dropdown-item command="ar">{{ string.header._arab }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
  </header>
</template>
<style>
  /*@import "~bootstrap/dist/css/bootstrap.min.css";*/
  /*@import "../assets/css/dashboard/main.css";*/
  .black-bg >header {
    font-family: Arial,sans-serif !important;
  }

  .dropdown-menu{
    color: #333 !important;
    background: #000d !important;
  }
  .dropdown-menu>li>a {
    color: #fff !important;
  }
  .dropdown-menu>li>a:hover {
    background:rgba(255,255,255,0.2) !important;
  }
  .fa-user{
    color: #ffffff;
    background-color: transparent;
  }

  .menu-admin a{
    color: #000000 !important;
    cursor: pointer !important;;
  }

  .containerHeaderRightMenu{
    display: flex !important;;
    position: relative !important;;
    justify-content: flex-end !important;;
    align-items: center !important;
    padding:10px;
    margin-top: 10px;
  }

  .containerHeaderRightMenu p{
    margin: 0 !important;;
    color: #ffffff !important;;
    letter-spacing: 2px !important;;
    text-transform: uppercase !important;;
  }
  .header.black-bg>a.logo:hover{
    color: #fff;
  }
  .el-dropdown-menu,
  .el-dropdown-menu__item {
    background: rgba(0,0,0,.87)!important;
    border-color: rgba(0,0,0,.87)!important;
    cursor: pointer !important;
    color: #fff !important;
  }
  .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #0057BB !important;
    background:rgba(255,255,255,0.2) !important;
  }
  .el-dropdown-menu__item--divided:before{
    background:rgba(255,255,255,0.2) !important;

  }

  .el-dropdown-menu__item--divided {
    border-top: 1px solid rgba(0,0,0,.52)!important; ;
  }

</style>
<script>

  import { onLogout } from '@/vue-apollo';
  import { UPDATE_MY_USER } from '@/graphql';
  import Icon from '@/components/Icon.vue'
  import { mapGetters } from 'vuex';
  export default {
    name: 'HeaderComponent',
    components: {
      Icon
    },
    props: {
      propAppName: {
        type: String,
        default: function () {
          return 'hello';
        }
      },

      propUserName: {
        type: String,
        default: function () {
          return 'hello';
        }
      },
    },
    data (){
      return {
        componentName: 'header',
        appNameLocal: this.propAppName,
        // userNameLocal: '',
        string:{
          header:{
            _language : 'language',
            _italian : 'italiano',
            _english : 'english',
            _francaise : 'française',
            _arab : 'arab',
            _logout : 'Log Out',
          }
        }
      };
    },
    computed : {
      ...mapGetters(['features', 'team', 'language', 'user', 'sidebarOn']),
      teamMembersPage() {
        return this.$root.setSubject('team_members_page', {...this.team})
      },
      userNameLocal: function (){
        return  this.user && this.user.name ? this.user.name : 'Organizer';
        },
      langSet: function (){
        return this.language && this.language.toUpperCase();
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      });
    },
    methods: {
      handleCommand: function(command) {
        if(command === 'settings') {
          this.$router.push({ path: '/settings' }).catch(err => {});
        } else if(command === 'team') {
          this.$router.push({ path: '/team/members' }).catch(err => {});
        } else if(command === 'logout') {
          this.onLogout();
        } else if(command === 'it') {
          this.changeLanguageToIt();
        } else if(command === 'en') {
          this.changeLanguageToEn();
        } else if(command === 'fr') {
          this.changeLanguageToFr();
        }
        else if(command === 'ar') {
          this.changeLanguageToAr();
        }

      },
      toogleSideBar: function (){
        this.$store.commit("switchSidebar", !this.sidebarOn);
      },
      changeLanguage: function (language){
        this.apiChangeLanguageSettings(language);
      },
      changeLanguageToIt: function (){
        this.changeLanguage('it');
      },
      changeLanguageToEn: function (){
        this.changeLanguage('en');
      },
      changeLanguageToFr: function (){
        this.changeLanguage('fr');
      },
      changeLanguageToAr: function (){
        this.changeLanguage('ar');
      },
      apiChangeLanguageSettings : function (language){
        const context = this;
        return this.$apollo.mutate({
          mutation: UPDATE_MY_USER,
          variables: {
              updates: {
                  language: language
              }
          }
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            return;
          }
          if(response.data && response.data.updateMyUser) {
            context.$store.commit('SET_USER_FIELD',{language: language});
            context.$root.bus.emit('changeLanguage',{ lang : language });
            context.$root.bus.emit('simpleToast', context.string.header._languagechanged);
          }
        }).catch(error => {
          console.log(error);
        });
      },
      onLogout: function () {
        // console.log('logout');
        this.$store.dispatch('logout', this.user)
                .then(async () => {
                  await onLogout(this.$apollo.getClient());
                  await this.$router.push('/login').catch(error =>{});
                });

      }
    }
  };
</script>
