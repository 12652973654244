<template>
  <div class="default-layout">
    <div id="imageUpload" class="imageupload-component">
      <div class="imageupload accordion">
            <div class="row mt-3">
                <div class="col-sm-4">
                       <el-upload
                          ref="upload"
                          action="#"
                          :auto-upload="false"
                          :show-file-list="true"
                          :multiple="false"
                          :on-change="uploadActionUrl"
                          :on-remove="logoRemove"
                        >
                        <el-button size="small" type="primary">{{ string.badgePrinting._formFileUpload }}</el-button>
                          <div slot="tip" class="el-upload__tip">{{ string.badgePrinting._formFileUploadTips }}</div>
                        </el-upload>
                </div>                         
            </div>     
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  UPDATE_EVENT
  } 
  from "@/graphql";

export default {
  components: {
  },
  props: {

  eventId: String
 },
  data() {
    return {
      componentName: "ImageUpload",
      string: {},
      logo: null
    };
  },
  computed: {
    getDefaultEmail() {
      return this.$store.getters.defaultEmail;
    },
    getIdentityOption() {
      return this.$store.getters.identity;
    }
  },
  watch: {

  },
  created() {
    const context = this;
    this.string = this.$root.strings;

    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {

    uploadActionUrl(file) {
      this.fileToBase64(file.raw).then(result => {
        this.logo = result;
      })
      this.logo = URL.createObjectURL(file.raw);
    },

    logoRemove(file, fileList) { 
      this.logo = null;
    },

    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },

      
  }
};
</script>
