<template xmlns:v-model="http://www.w3.org/1999/xhtml">
    <div class="row list-single-item">
        <h4>{{ string.listSingleItem._title }}</h4>
        <div class="col-sm-12 col-md-12 col-lg-12">
            <transition
                    name="component-fade-fast"
                    mode="out-in"
                    appear>
                <LoadingCover
                        v-if="loadingEffectshow"
                        :loading-cover-msg="string.listSingleItem._loadingMessage"/>
            </transition>
            <form
                    class="form-horizontal style-form"
                    autocomplete="off">
                <div class="form-group no-margin-bottom nomarginleft">
                    <div class="col-sm-6">
                        <input
                                :id="inputPrivateName"
                                v-model:value="privateGuestName"
                                autocomplete="new-password"
                                aria-label=""
                                type="text"
                                class="form-control"
                                :placeholder="string.listSingleItem._name">

                    </div>

                    <div class="col-sm-6">
                        <input
                                :id="inputPrivateSurname"
                                v-model:value="privateGuestSurname"
                                autocomplete="new-password"
                                aria-label=""
                                type="text"
                                class="form-control"
                                :placeholder="string.listSingleItem._surname">

                    </div>
                </div>
                <div class="form-group no-margin-bottom nomarginleft">
                    <div class="col-sm-6">
                        <input
                                :id="inputPrivateEmail"
                                v-model:value="privateGuestEmail"
                                autocomplete="new-password"
                                aria-label=""
                                type="email"
                                class="form-control"
                                :placeholder="string.listSingleItem._email">

                    </div>
                    <div class="col-sm-6">
                        <input
                                :id="inputPrivateTel"
                                v-model:value="privateGuestTel"
                                autocomplete="new-password"
                                aria-label=""
                                type="text"
                                class="form-control"
                                :placeholder="string.listSingleItem._tel">
                    </div>
                </div>
                <div class="formbutton">
                    <a
                            class="btn gray-btn save"
                            title="avanti"
                            @click="submit">
                        {{ string.listSingleItem._save }}
                    </a>
                </div>
            </form>
        </div>
    </div>
</template>
<style scoped>
    .formbutton{
        position: relative;
    }

    .formbutton > a{
        margin: 0 auto;
        display: block;
        width: 100%;
    }

    .list-single-item .form-control {
        border-radius: 0;
        border-color: #333;
    }

    .list-single-item label {
        color: #333;
        font-weight: bold;
        padding-left: 0 !important;
        text-transform: capitalize;
    }

    .list-single-item h4 {
        margin-bottom: 20px;
        text-transform: uppercase;
        text-align: center;
    }

</style>


<script>
    import LoadingCover from './LoadingCover.vue';
    import { REGISTER_MEMBER_TO_EVENT } from '@/graphql';


    export default {
        components:{
            LoadingCover
        },
        props: {
            eventId: String,
            fatherName: String,
            team: Object
            // items,
        },
        data (){
            return {
                componentName : 'list-single-item',
                loadingEffectshow : false,
                string: {
                    /*
                        listSingleItem: {
                            _title : "Inserisci i dati di un partecipante",
                            _name : "Nome",
                            _surname : "Cognome",
                            _email : "E-Mail",
                            _clear : "cancella",
                            _save : "salva",
                            _loadingMessage : "Attendere...",
                            _mandatoryFields : "I campi contrassegnati con l'asterisco sono obbligatori",
                            _unManagedError : "Errore non gestito",
                            },
                            */
                },
                newGuest:{ name: "", surname: "", email:"", telephone: "" },
                privateGuestName:'',
                privateGuestSurname:'',
                privateGuestEmail:'',
                privateGuestTel:'',
                inputPrivateName : this.fatherName + 'Name',
                inputPrivateSurname : this.fatherName + 'Surname',
                inputPrivateEmail : this.fatherName + 'Email',
                inputPrivateTel : this.fatherName + 'Tel',
                reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
            };
        },
        watch: {
            //items: function(){
            //    this.guests = this.items;
            //}
        },
        created (){
            const context = this;
            this.string = this.$root.strings;
            this.$root.bus.addListener('setLanguage', () => {
                context.string = context.$root.strings;
            });
        },
        methods: {
            isEmailValid: function() {
                return (this.privateGuestEmail === "")? "" : (this.reg.test(this.privateGuestEmail)) ? 'has-success' : 'has-error';
            },
            canSubmit(){

                return true;
            },
            submit : function () {
                const context = this;

                context.loadingEffectshow = true; // Loading Effect

                if (!this.guestDataValid()){ // Error Effect
                    context.warnErrorForMandatoryFields();
                    return false;
                }
                const name = document.getElementById(context.inputPrivateName).value.trim(),
                    surname = document.getElementById(context.inputPrivateSurname).value.trim(),
                    email = document.getElementById(context.inputPrivateEmail).value.trim(),
                    tel = document.getElementById(context.inputPrivateTel).value.trim();

                if (email.length > 0) {
                    if(!this.reg.test(email)){
                        context.warnEmailSyntax();
                        return false;
                    }
                }

                return this.$apollo.mutate({
                    mutation: REGISTER_MEMBER_TO_EVENT,
                    variables: {
                        memberInput: {
                            name: name,
                            surname: surname,
                            email: email,
                            telephone: tel,
                            status: "NULL",
                        },
                        eventId: context.eventId
                    }
                }).then(response => {
                    if (response.errors){
                        console.log(response.errors);
                        context.loadingEffectshow = false;
                        return;
                    }
                    if (response.data && response.data.addMemberToEvent) {
                        context.loadingEffectshow = false;
                        context.$emit('component_event', {
                            'name' : context.privateGuestName,
                            'surname' : context.privateGuestSurname,
                            'email' : context.privateGuestEmail,
                            'telephone' : context.privateGuestTel,
                        });
                        context.emptyFields();
                    }
                }).catch(error => {
                    if (error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].message === 'GUEST_EXIST') {
                        context.warnEmailAlreadyPresent();
                    } else if (error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].message === 'GUEST_LIST_FULL') {
                        context.$root.bus.emit("simpleToast", context.string.guestlist._maxGuestSizeReached + context.team.guestListLimit);
                    }
                    context.loadingEffectshow = false;

                });
            },
            emptyFields : function () {
                const context = this;
                document.getElementById(context.inputPrivateName).value = '';
                document.getElementById(context.inputPrivateSurname).value = '';
                document.getElementById(context.inputPrivateEmail).value = '';
                document.getElementById(context.inputPrivateTel).value = '';
                context.privateGuestName = '';
                context.privateGuestSurname = '';
                context.privateGuestEmail = '';
                context.privateGuestTel = '';
            },
            guestDataValid : function () {
                const context = this;
                if ( document.getElementById(context.inputPrivateName).value.trim().length <= 0) {return false;}
                return document.getElementById(context.inputPrivateSurname).value.trim().length > 0;
            },
            warnEmailAlreadyPresent : function () {
                this.loadingEffectshow = false;
                this.$root.bus.emit('simpleToast', this.string.listSingleItem._emailAlreadyPresentError);
            },
            warnEmailSyntax : function () {
                this.loadingEffectshow = false;
                this.$root.bus.emit('simpleToast', this.string.listSingleItem._emailSyntaxError);
            },
            warnErrorForMandatoryFields : function () {
                this.loadingEffectshow = false;
                this.$root.bus.emit('simpleToast', this.string.listSingleItem._mandatoryFields);
            },
            warnUnManagedError : function () {
                this.loadingEffectshow = false;
                this.$root.bus.emit('simpleToast', this.string.listSingleItem._unManagedError);
            }

        }
    };
</script>
