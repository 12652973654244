<template>
  <div id="attachments" class="attachments-component">
    <h4 class="form-section-title first">
      {{ string.attachments._selectattachments }}
      <small>{{ string.attachments._putattachmentstoevent }}</small>
    </h4>
    <div class="smallseparator"></div>

    <div class="form-section upload">
      <transition name="component-fade-fast" mode="out-in" appear>
        <LoadingCover
          v-if="loadingEffectshow"
          :loading-cover-msg="string.attachments._loadingMessage"
        ></LoadingCover>
      </transition>

      <div class="alert alert-warning" role="alert">{{ string.attachments._help }}</div>

      <!--<form method="POST" :action="routeUploadPdf" enctype="multipart/form-data" id="uploadAttachments" class="uploadattchmentform">-->

      <select
        id="select-attachments"
        v-model="selectedAttachmentType"
        name="select-attachments"
        class="form-control"
        @change="onSelectAttachment"
      >
        <option
          v-for="type in filetypes"
          :key="type.id"
          :value="type.id"
          :selected="type.selected"
        >{{ type.name }}</option>
      </select>

      <div style="margin-bottom: 10px">
        <input
          id="fileToUploadAttachments"
          type="file"
          name="fileToUpload"
          class="btn btn-default uploadfilefield"
          accept="application/pdf"
          @change="onFileChange"
        />
      </div>

      <div class="row">
        <div class="col-sm-1">
          <button
            id="pdf-attachments-upload-btn"
            type="save"
            class="save"
            @mousedown="submit"
          >{{ string.attachments._upload }}</button>
        </div>
      </div>

      <!--</form>-->

      <div v-if="documentUploaded.length > 0" class="attachmentsTable">
        <h4 class="form-section-title second">Lista allegati caricati</h4>

        <ul class="list-group">
          <!-- <li
            v-for="document in documentUploaded"
            :key="document.id"
            class="list-group-item">
            <span class="attachment-category">
              {{ filetypes[(document.category+1)].name }} -
            </span>
            <a
              class="attachment-item"
              :href="document.path"
              target="_blank">
              {{ document.file }}
            </a>
            <a
              class="delete-attachment"
              data-toggle="tooltip_attachment"
              data-placement="top"
              :title="string.attachments._deleteAttachment"
              style="margin-top:-12px;"
              @click="deleteItem(document.id)">
              <i class="fa fa-times">
&nbsp;
              </i>
            </a>
          </li>-->
          <li v-for="document in documentUploaded" :key="document.Key" class="list-group-item">
            <span class="attachment-category">{{ document.Key }} -</span>
            <a class="attachment-item" :href="document.Key" target="_blank">{{ document.Key }}</a>
            <a
              class="delete-attachment"
              data-toggle="tooltip_attachment"
              data-placement="top"
              :title="string.attachments._deleteAttachment"
              style="margin-top:-12px;"
              @click="deleteItem(document.id)"
            >
              <i class="fa fa-times">&nbsp;</i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
.attachments-component {
  margin-top: 50px;
}

.form-section-title {
  padding-bottom: 5px;
  margin-top: 20px;
  color: #0057B8;
  margin-bottom: 10px;
}
.form-section-title:last-child {
  margin-bottom: 30px;
}

input[type="file"] {
  display: block;
  position: relative;
  opacity: 1 !important;
  width: auto !important;
  height: auto;
  filter: alpha(opacity=1) !important;
  margin-bottom: 20px !important;
}

input,
select {
  border-radius: 0;
  border: 1px solid #333333;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}

.form-section select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent
    url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right center;
  border: 1px solid #333;
  background-position: 98%;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  border-radius: 0 !important;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

#attachments .no-visible {
  display: none;
}

#attachmentsTable {
  margin-top: 80px;
}

#attachments .list-group-item:hover {
  background: transparent;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid #ddd;
}

#attachments .list-group-item:hover .delete-attachment {
  display: inline-block;
}

#attachments .list-group-item .delete-attachment {
  position: absolute;
  width: 40px;
  text-align: right;
  background: transparent;
  top: 1px;
  right: 14px;
  font-size: 27px;
  color: #bcbcbc;
  display: none;
}

.attachment-item {
  background-color: transparent !important;
  color: #0057B8 !important;
}

.list-group-item {
  font-size: 18px;
}

.second {
  margin-top: 50px !important;
  margin-bottom: 20px !important;
}

.delete-attachment {
  color: #111;
  cursor: pointer;
}
</style>
<script>
import userdata from "./../userdata.js";
import LoadingCover from "./LoadingCover.vue";

export default {
  components: {
    LoadingCover
  },
  props: {
    eventId: String,
    event: Object
  },
  data() {
    return {
      componentName: "attachments",
      eventObject: {},
      routeUploadPdf: "",
      selectedAttachmentType: "-1",
      documentUploaded: [],
      string: {
        /*
                    attachments: {
                        _selectattachments : "Seleziona il tuo allegato",
                        _putattachmentstoevent : "Allega documenti al tuo evento, che desideri far visualizzare in App",
                        _help : "In questa sezione è possibile caricare solo documenti in PDF.",
                        _attachmentTypeSelect : "Seleziona tipo di allegato",
                        _map : "Planimetria",
                        _brochure : "Brochure",
                        _invite : "Invito",
                        _press : "Comunicato stampa",
                        _upload : "Upload",
                        _loadingMessage : "attendere",
                        _mandatoryFields : "Seleziona un tipo di documento e un file dal tuo archivio",
                        _filetype : "Tipo di documento",
                        _uploadsuccess : "Caricamento eseguito",
                        _uploadfailed : "Caricamento fallito",
                        _deleteAttachment : "Elimina Allegato",
                    },*/
      },
      filetypes: [],
      loadingEffectshow: false,
      fileList: "",
      app_settings: userdata.app_settings
    };
  },
  watch: {
    event: function() {
      this.eventObject = this.event;
      this.loadAttachmentList();
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  mounted() {
    this.setFileType();
  },
  methods: {
    deleteItem: function(id) {
      this.deleteAttachmentList(id);
    },
    emptyFields: function() {
      document.getElementById("select-attachments").value = "-1";
      this.selectedAttachmentType = "-1";
      document.getElementById("fileToUploadAttachments").value = "";
    },
    onSelectAttachment: function() {
      //
    },
    onFileChange: function(e) {
      this.fileToSelect = false;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.fileList = files[0];
      this.fileListName = files[0].name;
    },
    setFileType: function() {
      if (this.app_settings.app_name === "gobella") {
        this.filetypes = [
          { name: this.string.attachments._filetype, id: -1, selected: true },
          { name: this.string.attachments._cv, id: 0, selected: false },
          { name: this.string.attachments._brochure, id: 1, selected: false }
        ];
      } else {
        this.filetypes = [
          { name: this.string.attachments._filetype, id: -1, selected: true },
          { name: this.string.attachments._map, id: 0, selected: false },
          { name: this.string.attachments._brochure, id: 1, selected: false },
          { name: this.string.attachments._invite, id: 2, selected: false },
          { name: this.string.attachments._press, id: 3, selected: false }
        ];
      }
    },
    loadAttachmentList: function() {
      const context = this;
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const data = new FormData();
      // TODO REPLACE WITH Attachments QUERY

      // data.append("client_security_code", "trest");
      // data.append("event_id", this.eventId);

      // return this.$root.axios
      //   .post("/event/files", data, config)
      //   .then(response => {
      //     const e = response.data.Contents;
      //     context.documentUploaded = e;
      //     context.loadingEffectshow = false;
      //   })
      //   .catch(error => {
      //     context.loadingEffectshow = false;
      //     context.$root.bus.emit(
      //       "simpleToast",
      //       "Error: " + error
      //     ); /** Emit di al gestore globale */
      //   });
    },
    deleteAttachmentList: function(id) {
      const context = this;
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const data = new FormData();

      data.append("client_security_code", userdata.client_security_code);
      data.append("file_id", id);
      context.loadingEffectshow = true;
      // TODO REPLACE WITH DELETE ATTACHMENT MUTATIOn

      // return this.$root.axios
      //   .post("/event/file/delete", data, config)
      //   .then(response => {
      //     context.loadAttachmentList();
      //   })
      //   .catch(error => {
      //     context.loadingEffectshow = false;
      //     context.$root.bus.emit(
      //       "simpleToast",
      //       "Error: " + error
      //     ); /** Emit di al gestore globale */
      //   });
    },
    isDataValid: function(data) {
      const context = this;

      const fileType = context.selectedAttachmentType;
      if (fileType < 0) {
        return false;
      }

      const file = context.fileList;
      if (file == "" || file == null || typeof file == "undefined") {
        return false;
      }

      return true;
    },
    submit: function() {
      // TODO REPLACE WITH UPLOAD ATTACHMENT CALL

      // context.loadingEffectshow = true;
      //
      // const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      // const data = new FormData();
      //
      // data.append('client_security_code', userdata.client_security_code);
      // data.append('fileToUpload', context.fileList);
      // data.append('name', context.selectedAttachmentType);
      // data.append('event_id', context.eventId);
      //
      // if (context.isDataValid(data) == false){
      //     context.$root.bus.emit('modalView', 'error', context.string.attachments._mandatoryFields);  /** Emit di al gestore globale */
      //     context.$root.bus.emit('simpleToast', context.string.attachments._mandatoryFields);  /** Emit di al gestore globale */
      //     context.emptyFields();
      //     context.loadingEffectshow = false;
      //     return false;
      // }
      //
      // return this.$root.axios.post('/web/api/upload/pdf', data, config)
      //     .then((response) => {
      //         const e = response.data.data;
      //         context.loadAttachmentList();
      //         context.$root.bus.emit('simpleToast', context.string.attachments._uploadsuccess);  /** Emit di al gestore globale */
      //         context.emptyFields();
      //
      //     }).catch((error) => {
      //         context.loadingEffectshow = false;
      //         context.$root.bus.emit('simpleToast',  context.string.attachments._uploadfailed + ' ' + error);  /** Emit di al gestore globale */
      //         context.emptyFields();
      //
      //     });
      const context = this;
      context.loadingEffectshow = true;
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const data = new FormData();
      if (context.fileList !== null) {
        data.append("fileToUpload", context.fileList);
      }
      data.append("eventId", context.eventId);
      data.append("name", context.selectedAttachmentType);
      return this.$root.axios
        .post("/upload/attachment", data, config)
        .then(response => {
          const e = response.data.Contents;
          // context.loadAttachmentList();
          context.$root.bus.emit(
            "simpleToast",
            context.string.attachments._uploadsuccess
          ); /** Emit di al gestore globale */
          context.emptyFields();
          context.loadingEffectshow = false;
        })
        .catch(error => {
          context.loadingEffectshow = false;
          context.$root.bus.emit(
            "simpleToast",
            context.string.attachments._uploadfailed + " " + error
          ); /** Emit di al gestore globale */
          context.emptyFields();
        });
    }
  }
};
</script>
