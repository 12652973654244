<template>
  <MainLayout>
    <div v-if="!showModal">
      <transition name="component-fade-fast" mode="out-in" appear>
        <div>
          <FunctionBar style="padding-bottom:10px;" />

          <div class="event-list-title" style="height: auto !important;">
            <h3>{{ string.events._search_result }}</h3>
          </div>
          <div v-if="!!loading">
            <transition name="component-fade-fast" mode="out-in" appear>
              <ClipLoader
                :loading="loading === true"
                color="#0057B8"
                style="margin: 0 auto;"
                size="45px"
              ></ClipLoader>
            </transition>
          </div>
          <div v-else-if="data.length <= 0" class="center-it container">
            <transition name="component-fade-fast" mode="out-in" appear>
              <div style="text-align: center;">
                {{ string.events._noEvents }}
              </div>
            </transition>
          </div>
          <div v-else>
            <transition name="component-fade-fast" mode="out-in" appear>
              <div>
                <EventList :items="data" />
                <Paginate
                  v-model="currentPage"
                  :page-count="pageCount"
                  :click-handler="getData"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  :container-class="'pagination'"
                ></Paginate>
              </div>
            </transition>
          </div>
        </div>
      </transition>
    </div>
    <div v-else class="new-event-box">
      <transition name="component-fade-fast" mode="out-in" appear>
        <div id="new-event-box-row" class="row">
          <div
            id="function-selector-id"
            class="col-md-1
                      col-sm-1
                      col-xs-1
                      no-padding
                      function-selector"
            style="min-height: 80vh;"
          >
            <div class="label-function-selector">
              <i class="fa fa-calendar-o fa-2x" aria-hidden="true">
                &nbsp;
              </i>
              {{ string.searchEvent._search }}
              <br />
              {{ string.newEvent._event }}
            </div>
          </div>

          <div
            class="col-md-11
                      col-sm-11
                      col-xs-11
                      no-padding
                      event-box-form-container"
          >
            <div class="tab-content">
              <div id="home" role="tabpanel" class="tab-pane active">
                <div class="form-panel" style="min-height: 80vh;">
                  <h4 class="mb">
                    {{ string.searchEvent._search }}
                  </h4>
                  <div class="smallseparator" style="margin-top:-10px;"></div>

                  <form class="form-horizontal style-form">
                    <div class="form-group no-margin-bottom">
                      <div class="col-sm-10">
                        <select id="selcategory" v-model="customCategoryObj"  class="form-control">
                          <option :value="{key: '', label: ''}" disabled hidden>
                            {{ string.searchEvent._eventType }}
                          </option>
                          <option
                            v-for="category in customCategories"
                            :key="category.key"
                            :label="category.label"
                            :value="category"
                          />
                        </select>
                        <!--
                                      <span class="alert-error">&nbsp;</span> -->
                      </div>
                    </div>

                    <div class="form-group no-margin-bottom">
                      <div class="col-sm-10">
                        <input
                          v-model.trim="filter"
                          type="text"
                          class="form-control"
                          :placeholder="string.searchEvent._eventName"
                        />
                        <!-- <span class="alert-error">&nbsp;</span> -->
                      </div>
                    </div>

                    <div class="form-group no-margin-bottom">
                      <div class="col-sm-10">
                        <el-date-picker
                          id="startDateId"
                          v-model="dateTimeRange"
                          type="datetimerange"
                          class="form-control"
                          :editable="false"
                          :clearable="false"
                          align="left"
                          size="large"
                          :start-placeholder="string.newEvent._startdate"
                          :end-placeholder="string.newEvent._enddate"
                          :picker-options="pickerOptions"
                          format="yyyy-MM-dd HH:mm"
                          value-format="timestamp"
                          @change="onDateTimeRangeChanged"
                        >
                        </el-date-picker>
                      </div>
                    </div>

                    <!-- <div class="form-group no-margin-bottom">
                      <div class="col-sm-10">
                        <Places
                          id="addressInput"
                          class="form-control"
                          :place-holder="string.searchEvent._address"
                          model-value=""
                          @place-changed="showChange"
                        />
                      </div>
                    </div> -->
                    <div class="form-group">
                      <div class="col-sm-10">
                        <input
                          v-model.trim="address"
                          type="text"
                          class="form-control"
                          :placeholder="string.searchEvent._address"
                        />
                        <!-- <span class="alert-error">&nbsp;</span> -->
                      </div>
                    </div>
                    <div class="form-group no-margin-bottom" style="margin-top: 15px;">
                       <div class="col-sm-10">
                        <a class="btn gray-btn save" :title="string.searchEvent._search" @click="submit">
                          {{ string.searchEvent._search }}
                        </a>
                       </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </MainLayout>
</template>

<script>
import EventList from "../components/EventList.vue";
import MainLayout from "../layouts/Main.vue";
import $ from "jquery";
import Paginate from "vuejs-paginate";
import FunctionBar from "../components/FunctionBar.vue";
import Places from "../components/Places.vue";
import { ENUM_QUERY, MY_EVENTS, MY_PAGINATED_EVENTS } from "@/graphql";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    MainLayout,
    EventList,
    FunctionBar,
    Paginate,
    Places,
    ClipLoader
  },
  props: {},
  data() {
    return {
      startDate: "",
      endDate: "",
      startdateRome: "",
      enddateRome: "",

      validatePlaceSwitch: false,
      items: [],
      componentName: "Search",

      eventLocationObject: {},

      eventLocationResolvingTimeOut: "",
      locationHasError: false,
      locationErrorMessage: "",
      resolvingAddress: false,

      string: {},

      loadingEffectshow: false,
      loadingMessage: "",

      myEvents: [],
      // Date range picker options
      pickerOptions: {
        /*disabledDate(picker){
            return picker.getTime() < Date.now() - 8.64e7;
          },*/
        firstDayOfWeek: 1
      },
      dateTimeRange: [],

      currentPage: 1,
      pageSize: 12,
      sortField: "createdAt",
      sortOrder: -1,
      filter: "",
      customCategoryObj: {key: "", label: ""},
      address: "",
      total: 0,
      data: [],
      showModal: true,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["customCategories"]),
    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    }
  },
  watch: {},
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
    this.$root.bus.addListener("SearchButtonClicked", () => {
      //clean the page once the search button is clicked again
      context.showModal = true;
      context.customCategoryObj = { key: "", label: "" };
      context.currentPage = 1;
      context.filter = "";
      context.startDate = "";
      context.endDate = "";
    });
  },
  mounted() {
    this.fixsidebarHeight();
    window.addEventListener("resize", this.fixsidebarHeight);
  },
  metaInfo: {
    title: "Search - Penguinpass",
    bodyAttrs: {
      class: "dashboard"
    }
  },
  methods: {
    showChange: function(p) {
      if (p != null) {
        this.validatePlaceSwitch = true;
        this.eventLocationObject.city = p.city;
        this.eventLocationObject.address = p.address;
        this.eventLocationObject.postalcode =
          typeof p.postalcode == "undefined" && p.postalcode != "undefined" ? "" : p.postalcode;
        this.eventLocationObject.latitude = p.lat;
        this.eventLocationObject.longitude = p.lng;
        this.eventLocationObject.country = p.country;
        this.eventLocationObject.inputValue = p.inputValue;
      } else {
        this.validatePlaceSwitch = false;
      }
    },
    isPlaceValid: function() {
      let isValid = false;
      const context = this;

      if (
        Math.abs(context.eventLocationObject.latitude) > 0 &&
        Math.abs(context.eventLocationObject.longitude) > 0
      ) {
        isValid = true;
      }

      return isValid;
    },
    submit: function() {
      this.showModal = false;
      this.getData();
    },
    fixsidebarHeight() {
      $(".function-selector").height(0);
      $(".function-selector").height($("#new-event-box-row").height());
    },
    addressFilter: function(current, expected) {
      //console.log(current);
      //console.log(expected);
      console.log($("#addressInput").val());
      if ($("#addressInput").val() != "") {
        if (expected.country !== "" && expected.country === current.country) {
          if (expected.city !== "" && expected.city === current.city) {
            if (expected.address !== "" && expected.address !== current.address) {
              return false;
            }
            return true;
          } else if (expected.city !== "" && expected.city !== current.city) {
            return false;
          }
          return true;
        } else if (expected.country !== "" && expected.country !== current.country) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    },
    onDateTimeRangeChanged: function() {
      if (this.dateTimeRange && this.dateTimeRange.length > 0) {
        this.startDate = new Date(this.dateTimeRange[0]).toISOString();
        this.endDate = new Date(this.dateTimeRange[1]).toISOString();
      }
    },
    async getData(currentPage) {
      this.loading = true;
      const context = this;
      try {
        await this.$apollo
          .query({
            fetchPolicy: "no-cache",
            query: MY_PAGINATED_EVENTS,
            variables: {
              statuses: ["ACTIVE", "INACTIVE"],
              address: this.address && this.address !== "" ? this.address : null,
              filter: this.filter && this.filter !== "" ? this.filter : null,
              pageInfo: {
                pageSize: this.pageSize,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
                currentPage: currentPage || this.currentPage
              },
              customCategories: this.customCategoryObj && this.customCategoryObj.key !== "" ? [this.customCategoryObj.key] : null,
              startDate: this.startDate && this.startDate !== "" ? this.startDate : null,
              endDate: this.endDate && this.endDate !== "" ? this.endDate : null
            }
          })
          .then(response => {
            if (response.errors) {
              console.log(response.errors);
              return;
            }
            if (response.data && response.data.myPaginatedEvents) {
              const payload = response.data.myPaginatedEvents;
              context.data = payload.data;
              context.total = payload.total;
            }
          })
          .catch(error => {
            console.error(error.message);
          });
      } finally {
        context.loading = false;
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>

<style scoped></style>
