<template class="createEventBody">
  <MainLayout>
    <div class="new-event-box ">
      <transition
              name="component-fade-fast"
              mode="out-in"
              appear>
        <LoadingCover
                v-if="loadingEffectshow"
                :loading-cover-msg="loadingMessage"/>
      </transition>

      <div
              id="new-event-box-row"
              class="row">
        <div
                id="function-selector-id"
                class="col-md-1
                            col-sm-1
                            col-xs-1
                            no-padding
                            function-selector">
          <div class="label-function-selector">
            <i
                    class="fa fa-calendar-o fa-2x"
                    aria-hidden="true">
              &nbsp;
            </i>
            {{ string.newEvent._create }}
            <br />
            {{ string.newEvent._event }}
          </div>
        </div>


        <div
                class="col-md-11
                            col-sm-11
                            col-xs-11
                            no-padding
                            event-box-form-container">
          <div class="tab-content">
            <div
                    id="home"
                    role="tabpanel"
                    class="tab-pane active">
              <div class="form-panel">
                <h4 class="mb">
                  {{ _pagetitle }}
                </h4>
                <div
                        class="smallseparator"
                        style="margin-top:-10px;"></div>

                <form
                        class="form-horizontal style-form">
                  <div class="form-group no-margin-bottom">
                    <div class="col-sm-5">
                      <input
                              autocomplete="off"
                              id="eventNameId"
                              v-model="paramName"
                              type="text"
                              class="form-control"
                              :placeholder="string.newEvent._eventname">
                      <!-- <span class="alert-error">&nbsp;</span> -->
                    </div>
                    <div class="col-sm-5">
                      <select
                              id="selectCat"
                              v-model="categoryObj"
                              aria-label=""
                              class="form-control"
                              @click="changecolor">
                        <option :value="{key: '', label: ''}" disabled hidden>
                          {{ string.newEvent._eventtype }}
                        </option>
                        <option
                                v-for="category in customCategories"
                                :key="category.key"
                                :label="category.label"
                                :hidden="!!category.deletedAt"
                                :value="category" />
                      </select>
                    </div>
                  </div>

                  <div class="form-group no-margin-bottom">
                    <div class="col-sm-5">
                      <Places
                              id="addressInput"
                              class="form-control"
                              :place-holder="addressPlaceholder"
                              model-value=""
                              @place-changed="onAddressChanged"/>
                    </div>
                    <div class="col-sm-5">
                      <input
                              v-model="paramVenue"
                              autocomplete="off"
                              type="text"
                              class="form-control"
                              :placeholder="string.newEvent._venue">
                    </div>
                  </div>

                  <div class="form-group no-margin-bottom">
                    <div class="col-sm-10">
                      <input
                        v-model="paramVirtualVenue"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        :placeholder="string.newEvent._virtualVenue">
                    </div>
                  </div>

                  <div class="form-group no-margin-bottom">
                    <div class="col-sm-10">
                      <el-date-picker
                              id="startDateId"
                              v-model="dateTimeRange"
                              type="datetimerange"
                              class="form-control"
                              :editable="false"
                              :clearable="false"
                              align="left"
                              size="large"
                              :start-placeholder="string.newEvent._startdate"
                              :end-placeholder="string.newEvent._enddate"
                              :picker-options="pickerOptions"
                              format="yyyy-MM-dd HH:mm"
                              value-format="timestamp"
                              v-on:change="onDateTimeRangeChanged">
                      </el-date-picker>
                    </div>
                  </div>
                  <div class="form-group no-margin-bottom">
                    <div class="col-sm-10">
                      <textarea
                              v-model="paramDescriptionIt"
                              type="text"
                              class="form-control"
                              rows="5"
                              :placeholder="string.newEvent._descriptionIt"/>
                    </div>
                  </div>
                  <div class="form-group no-margin-bottom">
                    <div class="col-sm-10">
                      <textarea
                              v-model="paramDescriptionEn"
                              type="text"
                              class="form-control"
                              rows="5"
                              :placeholder="string.newEvent._descriptionEn"/>
                    </div>
                  </div>
                </form>
                <div class="formbutton">
                  <a
                          class="save"
                          :title="string.newEvent._next"
                          @click="submit">
                    {{ string.newEvent._next }}
                  </a>
                  <a
                          class="save"
                          :title="string.newEvent._clear"
                          @click="cancel">
                    {{ string.newEvent._clear }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>
<style scoped>

  .rd-time {
    z-index: 9 !important;
  }

  .component-fade-fast-enter-active, .component-fade-fast-leave-active {
    transition: opacity .3s ease;
  }
  .component-fade-fast-enter, .component-fade-fast-leave-active {
    opacity: 0;
  }
  .component-fade-enter-active, .component-fade-leave-active {
    transition: opacity .9s ease;
  }
  .component-fade-enter, .component-fade-leave-active {
    opacity: 0;
  }
  #selectCat{
    color: #a9a7a7 !important;
  }
</style>
<style>
  .el-date-editor .el-range-input::placeholder{
      color: #a9a7a7 !important;
    }
</style>

<script>
    import $ from 'jquery';
    import MainLayout from '../layouts/Main.vue';
    import LoadingCover from './LoadingCover.vue';
    import Places from '../components/Places.vue';
    import { CREATE_EVENT, ENUM_QUERY } from '@/graphql';
    import { mapGetters } from 'vuex';

    export default {
    components: {
      MainLayout,
      LoadingCover,
      Places
    },
    props: {
      propPrivate: {
        type: Boolean,
        default: function () {
          return true;
        }
      },
    },
    data () {
      return {
        componentName: 'new-event',
        string: {},
        loadingEffectshow: false,
        loadingMessage: '',
        addressPlaceholder:'',

        eventLocationObject:{
          city:'',
          address:'',
          postalcode:'',
          latitude:'',
          longitude:'',
          country:'',
          inputValue:''
        },
        // Date range picker options
        pickerOptions: {
          disabledDate(picker){
            return picker.getTime() < Date.now() - 8.64e7;
          },
          firstDayOfWeek: 1
        },
        dateTimeRange: [],

        // Payload for creating event
        paramDateTimeStart:'',
        paramDateTimeEnd:'',
        paramName:'',
        paramDescriptionIt:'',
        paramDescriptionEn:'',
        paramAddress:'',
        paramVenue:'',
        paramVirtualVenue:'',
        categoryObj: { key: "", label: "" }
      };
    },
    computed:{
      ...mapGetters(["customCategories"])
    },
    created () {
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
        this._pagetitle = this.propPrivate ? this.string.newEvent._pagetitlePrivate : this.string.newEvent._pagetitlePublic;
        this.addressPlaceholder = this.string.newEvent._location;
      });
      this.addressPlaceholder = this.string.newEvent._location;
      this._pagetitle = this.propPrivate ? this.string.newEvent._pagetitlePrivate : this.string.newEvent._pagetitlePublic;
    },
    mounted () {
      this.fixsidebarHeight();
      window.addEventListener('resize', this.fixsidebarHeight);
    },
    methods: {
      goSingleEvent (id) {
        this.$router.push({ path:'/event/' + id.toString(), props: { eventId: id.toString() } });
      },
      cancel: function () {
        this.$root.bus.emit('component_event', this.componentName+'|cancel');  /** Emit di al gestore globale */
      },
      changecolor () {
        $('#selcategory').css({ 'color': 'white !important' });
      },
      submit: function () {
        const context = this;
        if (!this.isDataValid()) {
          this.$root.bus.emit('simpleToast', this.string.newEvent._mandatoryFields);
          return false;
        }

        if (!this.isPlaceValid()) {
          this.$root.bus.emit('simpleToast', this.string.newEvent._placeNotValid);
          return false;
        }
        this.$apollo
                .mutate({
                  mutation: CREATE_EVENT,
                  variables: {
                    createEventInput: {
                      name: this.paramName.trim(),
                      address: JSON.stringify(this.eventLocationObject),
                      venue: this.paramVenue.trim(),
                      virtualVenue: this.paramVirtualVenue.trim(),
                      startDate:this.paramDateTimeStart,
                      endDate:this.paramDateTimeEnd,
                      descriptionIt:this.paramDescriptionIt,
                      descriptionEn:this.paramDescriptionEn,
                      customCategory:this.categoryObj.key,
                      customCategoryLabel: this.categoryObj.label,
                      genre: this.propPrivate ? 'PRIVATE' :'PUBLIC',
                    }
                  }
                })
                .then(value => {
                  if(value.errors){
                    console.log('error ' + value.errors);
                    context.$root.bus.emit('simpleToast', this.string.newEvent._creationFailed);
                  } else {
                    if(value.data && value.data.createEvent){
                      context.$root.bus.emit('simpleToast', context.string.newEvent._event + ' ' + context.string.newEvent._created);
                      context.goSingleEvent(value.data.createEvent.id);
                    }
                  }
                })
                .catch(reason => {
                  console.log(reason.message);
                  context.$root.bus.emit('simpleToast', this.string.newEvent._creationFailed);
                });
      },
      isDataValid: function () {
        const context = this;
        const title = context.paramName.trim();
        const category = context.categoryObj.key;
        const startdate = context.paramDateTimeStart;
        const enddate = context.paramDateTimeEnd;
        return title.length > 0 && category.length > 0 && startdate.length > 0 && enddate.length > 0;
      },
      isPlaceValid: function () {
        return Math.abs(this.eventLocationObject.latitude) > 0 && Math.abs(this.eventLocationObject.longitude) > 0;
      },
      fixsidebarHeight (){
        $('.function-selector').height(0);
        $('.function-selector').height($('#new-event-box-row').height());
      },
      onDateTimeRangeChanged: function () {
        if(this.dateTimeRange && this.dateTimeRange.length > 0) {
          this.paramDateTimeStart = new Date(this.dateTimeRange[0]).toISOString();
          this.paramDateTimeEnd = new Date(this.dateTimeRange[1]).toISOString();
        }
      },
      onAddressChanged: function (p){
        // console.log('onAddressChanged@  ' + JSON.stringify(p));
        this.eventLocationObject.city = p.city;
        this.eventLocationObject.address = p.address;
        this.eventLocationObject.postalcode = (typeof p.postalcode == 'undefined' && p.postalcode !== 'undefined') ? '' : p.postalcode;
        this.eventLocationObject.latitude = p.lat;
        this.eventLocationObject.longitude = p.lng;
        this.eventLocationObject.country = p.country;
        this.eventLocationObject.inputValue = p.inputValue;
      }
    }
  };
</script>
