var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "container" } },
    [
      _c(
        "div",
        { staticClass: "higherPosition" },
        [
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.modal.template.windowVisible
                ? _c("ModalTemplateComponent")
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.modal.error.windowVisible
                ? _c("ModalErrorComponent", {
                    attrs: { "prop-msg": _vm.modal.error.propMsg },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.modal.warning.windowVisible
                ? _c("ModalWarningComponent", {
                    attrs: {
                      "download-item-id":
                        _vm.modal.warning.itemDiscardedListDownloadToken,
                      "item-discarded": _vm.modal.warning.itemDiscarded,
                      "prop-msg": _vm.modal.warning.propMsg,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.modal.emailBuilder.windowVisible
                ? _c("ModalEmailBuilderComponent", {
                    attrs: {
                      "client-security-code":
                        _vm.modal.emailBuilder.clientSecurityCode,
                      "event-id": _vm.modal.emailBuilder.eventId,
                      "is-private-event": _vm.modal.emailBuilder.isPrivateEvent,
                      "is-campaign": _vm.modal.emailBuilder.isCampaign,
                      "is-confirmation-email":
                        _vm.modal.emailBuilder.isConfirmationEmail,
                      "user-id": _vm.modal.emailBuilder.userId,
                      lang: _vm.modal.emailBuilder.lang,
                      "skip-selection": _vm.modal.emailBuilder.skipSelection,
                      email: _vm.modal.emailBuilder.email,
                      "custom-field-tags":
                        _vm.modal.emailBuilder.customFieldTags,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.modal.templateEmailBuilder.windowVisible
                ? _c("ModalTemplateEmailBuilderComponent", {
                    attrs: {
                      "client-security-code":
                        _vm.modal.templateEmailBuilder.clientSecurityCode,
                      "event-id": _vm.modal.templateEmailBuilder.eventId,
                      "user-id": _vm.modal.templateEmailBuilder.userId,
                      lang: _vm.modal.templateEmailBuilder.lang,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.modal.pageBuilder.windowVisible
                ? _c("ModalPageBuilderComponent", {
                    attrs: {
                      "event-id": _vm.modal.pageBuilder.eventId,
                      "virtual-room-page-id":
                        _vm.modal.pageBuilder.virtualRoomPageId,
                      "video-rooms": _vm.modal.pageBuilder.videoRooms,
                      page: _vm.modal.pageBuilder.page,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.modal.templatePageBuilder.windowVisible
                ? _c("ModalTemplatePageBuilderComponent", {
                    attrs: {
                      "event-id": _vm.modal.templatePageBuilder.eventId,
                    },
                    on: {
                      templateSelected:
                        _vm.modal.templatePageBuilder.templateSelected,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.selecteventshow
                ? _c("CreateSelectEventComponent", {
                    on: { component_event: _vm.manageComponentEvent },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.modal.bulkAction.windowVisible
                ? _c("ModalBulkAction", {
                    attrs: {
                      users: _vm.modal.bulkAction.users,
                      title: _vm.modal.bulkAction.title,
                      visible: _vm.modal.bulkAction.windowVisible,
                      action: _vm.modal.bulkAction.action,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-container",
        { style: _vm.screenHeight },
        [
          _c(
            "el-header",
            { attrs: { height: "60px" } },
            [
              _c("HeaderComponent", {
                attrs: {
                  "prop-app-name": _vm.appName,
                  "prop-user-name": _vm.userName,
                },
                on: { component_event: _vm.manageComponentEvent },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-container",
            { style: _vm.screenHeight },
            [
              _c(
                "el-aside",
                {
                  staticClass: "sidebar-container",
                  attrs: { id: "sidebar", width: "241px" },
                },
                [
                  _c("SidebarComponent", {
                    attrs: { height: _vm.screenHeightVal },
                    on: { component_event: _vm.manageComponentEvent },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-container",
                { attrs: { direction: "vertical" } },
                [
                  _c(
                    "el-main",
                    {
                      ref: "main-container",
                      staticStyle: {
                        padding: "0",
                        background: "rgba(0, 0, 0, 0.65)",
                      },
                    },
                    [
                      _c(
                        "section",
                        {
                          class: _vm.bg,
                          style: _vm.screenHeight,
                          attrs: { id: "main-content" },
                        },
                        [
                          _c(
                            "section",
                            { staticClass: "wrapper wrapper-index" },
                            [
                              _c(
                                "div",
                                { staticClass: "container-home" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "component-fade",
                                        mode: "out-in",
                                        appear: "",
                                      },
                                    },
                                    [_vm._t("default")],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("public-footer", {
                            staticClass: "dashboard-footer",
                            attrs: { "full-width": true },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }