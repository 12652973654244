<template>
    <div v-if="joinStatus === 0" id="display-result" class="sample-text-area">
        <i class="icon fa fa-check-circle fa-3x" style="font-weight: 900" aria-hidden="true"></i>
    </div>
    <div v-else-if="joinStatus !== 0 && joinStatus !== 1" class="sample-text-area">
        <i class="icon fa fa-times-circle fa-3x" style="font-weight: 900"></i>
    </div>
</template>
<script>

    export default {
        props: {
            /**
             * 0 Ok
             * 1 Waiting
             * 2 Declined_OK
             * 3 Already_Joined
             * 4 Already_Declined
             * 5 Bad Token
             * 6 Event not found
             * 7 Event is full
             * 8 Guest not found
             * 9 Policies are not accepted
             * 10 Unhandled
             */
            joinStatus: {
                type: Number,
                required: true,
                default: 0
            }
        },
        data (){
            return {
                componentName : 'join-header',
                string: {},
            };
        },
        created (){
            const context = this;
            this.string = this.$root.strings;
            this.$root.bus.addListener('setLanguage', () => {
                context.string = context.$root.strings;
            });
        },
    };
</script>

<style scoped>
    @import '~bootstrap/dist/css/bootstrap.min.css';
    @import '~font-awesome/css/font-awesome.min.css';
    @import '../../assets/css/v2/style.css';
    @import '../../assets/css/v2/custom.css';
    @import '../../assets/css/v2/join.css';

    .field{
        padding: 10px 0 10px 0;
        margin: 0 0 5px 0;
    }

    .actions{
        display: inline-block;
        width: 20%;
        text-align: left;
        vertical-align: top;
    }

    /*checkbox*/

    .control {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;
        padding-top: 3px;
        font-weight: 400;
        cursor: pointer;
        color: #555555;
    }
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .control_indicator {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background: #e6e6e6;
        border: 0px solid #000000;
    }

    .control:hover input ~ .control_indicator,
    .control input:focus ~ .control_indicator {
        background: #cccccc;
    }

    .control input:checked ~ .control_indicator {
        background: #000;
    }
    .control:hover input:not([disabled]):checked ~ .control_indicator,
    .control input:checked:focus ~ .control_indicator {
        background: #000;
    }
    .control input:disabled ~ .control_indicator {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        box-sizing: unset;
        content: '';
        position: absolute;
        display: none;
    }
    .control input:checked ~ .control_indicator:after {
        display: block;
    }
    .control-checkbox .control_indicator:after {
        left: 8px;
        top: 4px;
        width: 3px;
        height: 8px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .control-checkbox input:disabled ~ .control_indicator:after {
        border-color: #7b7b7b;
    }

    .confirm-button {
        display: block;
        color: #777;
        border: 1px solid #000;
        background: #fff;
        width: 100%;
        max-width: 50%;
        margin: 50px auto 30px;
        padding: 13px;
        cursor: pointer;
        transition: 1s ease;
    }

    .confirm-button:hover {
        color: #fff;
        background: #000;
        border: 1px solid transparent;
    }

    .actions .title {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 30px;
    }
</style>
<style>

</style>
