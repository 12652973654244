<template>
  <div id="template-list-content">
    <div
      id="template-selector-container"
      class="row selector-container"
    >
    <div
              v-if="templateList.length > 0"
              class="template-list-list "
      >
        <div
                v-for="template in templateList"
                :key="template.id"
                class="card-container"
        >
            <div class="card-body">
            <a @click="templateSelected(template.code)">
              <div class="template-icon">
                <div class="template-name-first-letter">
                  {{ template.displayName.charAt(0) }}
                </div>
              </div>
            </a>
            </div>
            <div class="card-footer">
              <div class="template-name">
              <p class="card-title">
                {{ template.displayName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageBuilderSelectTemplates',
    props: {
      lang: String,
      userId: String,
      templateList: Array
    },
    data () {
      return {
        string: {}
      };
    },
    created () {
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      });
    },
    methods: {
      templateSelected (code) {
        this.$emit('templateSelected', JSON.parse(code));
      }
    }
  };
</script>
<style lang="less" scoped>
  a {
    color: #ffffff;
    text-decoration: none;
  }

  a.btn {
    width: 100%;
    font-size: 30px;
    color: #ffffff;
    text-transform: uppercase;
    background-color: #0057B8;
    padding: 10px;
  }

  .template-sub-name{
    font-size: 11px;
    font-style: italic;
    color: #ffffff;
  }

  .template-icon:hover{
    background-color: #b3b3b3;
    cursor: pointer;
  }

  .template-delete-icon-action{
    color: #fff;
    position: absolute;
    right: 16px;
    font-size: 20px;
    z-index: 1;
  }

  #template-list-content{
    height: 100%;
    padding-top: 6px;
  }

  #template-selector-container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .align-middle{
    max-width: 50%;
    font-size: 30px;
    color: #d3d3d3;
    text-align: center;
  }

  .template-list-status-message{
    margin-bottom: 12px;
  }

  // .template-list-list {
  //   overflow: auto;
  //   height: 100%;
  //   padding-top: 10px;
  // }

  .cel{
    cursor: pointer;
    margin-bottom: 30px;
  }

  .template-icon{
    background-color: #0057B8;
    text-align: center;
    color: white;
    // margin-bottom: 8px;
  }

  .template-name-first-letter{
    // padding-top: 140px;
    font-size: 112px;
    text-transform: uppercase;
  }

  .template-name{
    color: #ffffff;
    text-transform: capitalize;
    font-size: 18px;
  }

  .cel-internal{
    height: 100%;
    width: 100%;
  }

</style>
