var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainLayout", [
    _c(
      "div",
      [
        _c("FunctionBar", { staticStyle: { "padding-bottom": "10px" } }),
        _vm._v(" "),
        _c("div", { staticClass: "event-list-title" }, [
          _c("h3", [_vm._v(_vm._s(_vm.string.home._titleEventList))]),
        ]),
        _vm._v(" "),
        !!_vm.loading
          ? _c(
              "div",
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _c("ClipLoader", {
                      staticStyle: { margin: "0 auto" },
                      attrs: {
                        loading: _vm.loading === true,
                        color: "#0057B8",
                        size: "45px",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm.data.length <= 0
          ? _c(
              "div",
              { staticClass: "center-it container" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.string.events._noEvents) +
                          "\n        "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("EventList", { attrs: { items: _vm.data } }),
                        _vm._v(" "),
                        _c("Paginate", {
                          attrs: {
                            "page-count": _vm.pageCount,
                            "click-handler": _vm.getData,
                            "prev-text": "Prev",
                            "next-text": "Next",
                            "container-class": "pagination",
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }