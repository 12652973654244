var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "header-wrap" } }, [
    _c(
      "div",
      {
        staticClass: "header-md fixed-top header-dark transparent",
        staticStyle: { padding: "15px" },
        attrs: { id: "header" },
      },
      [
        _c(
          "div",
          {
            staticClass: "container header-container",
            attrs: { id: "scrollspy" },
          },
          [
            _c("div", { staticClass: "logo font-alter-1" }, [
              _vm.enableLink
                ? _c("div", { attrs: { href: "/" } }, [
                    _c("h1", [
                      _c("img", {
                        staticClass: "item-img",
                        staticStyle: { "object-fit": "cover" },
                        attrs: {
                          src: _vm.logoSource,
                          height: _vm.logoHeight,
                          width: _vm.logoWidth,
                          alt: "image",
                        },
                      }),
                    ]),
                  ])
                : _c("h1", [
                    _c("img", {
                      staticClass: "item-img",
                      staticStyle: { "object-fit": "cover" },
                      attrs: {
                        src: _vm.logoSource,
                        height: "84",
                        width: "210",
                        alt: "image",
                      },
                    }),
                  ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }