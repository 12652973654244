<template>
  <PublicLayout>
      <div class="container">
        <div class="row wow fadeIn">
          <!-- Left column -->
          <div class="col-md-7">
            <!-- Begin heading -->
            <div class="heading heading-md heading-uppercase heading-hover">
              <br>
              <h2 v-if="registerSuccess === false"
                      id="title"
                      class="heading-title text-white">
                {{ string.registerPage._register_title }}
              </h2>
              <h2 v-if="registerSuccess === true"
                  id="titleSuccess"
                  class="heading-title text-white">
                {{ string.registerPage._register_title_success }}
              </h2>

              <!-- Begin divider -->
              <hr class="hr-width-1 hr-5x border-main">
              <!-- End divider -->

              <p v-if="registerSuccess === false"
                      id="description"
                      class="heading-tescription lead text-gray-2">
                {{ string.registerPage._register_description }}
              </p>
            </div>
            <!-- End heading -->
          </div> <!-- /.col -->

          <!-- Right column -->
          <div class="col-md-5 text-gray-2 padding-left-40">
            <h5 class="margin-top-40 margin-bottom-20"/>
          </div> <!-- /.col -->
        </div> <!-- /.row -->

        <div v-if="registerSuccess === false" class="row">
          <div class="col-md-12">
            <!-- Begin contact form -->
            <form id="contact-form" autocomplete="off" class="margin-top-80 text-white wow fadeIn" data-wow-delay="0.3s">
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <input type="text" class="form-control no-rounded" aria-autocomplete="new-password" v-model="user.name"
                           :placeholder="string.registerPage ? string.registerPage._p_name : 'Name*'" required autofocus>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <input type="text" class="form-control no-rounded" aria-autocomplete="new-password" name="organizer_surname" v-model="user.surname"
                           :placeholder="string.registerPage ? string.registerPage._p_surname : 'Surname*'" required>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <input type="email" class="form-control no-rounded"  aria-autocomplete="new-password" name="organizer_email" value=""
                           :placeholder="string.registerPage ? string.registerPage._p_email : 'E-mail*'" v-model="user.email" required>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <input type="text" class="form-control no-rounded"  id="organizer_company_name" name="account_name"
                           value=""
                           v-model="user.company"
                           :placeholder="string.registerPage ? string.registerPage._p_company : 'Company Name*'" required>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <input type="text" class="form-control no-rounded"  id="accountVat" name="account_vatnumber"
                           value=""
                           v-model="user.vatNumber"
                           :placeholder="string.registerPage ? string.registerPage._p_vat_number : 'Vat Number'">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <input type="text" class="form-control no-rounded" name="account_web"
                           value=""
                           v-model="user.site"
                           :placeholder=" string.registerPage ? string.registerPage._p_site : 'Website' ">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <input type="text" class="form-control no-rounded" name="account_city" value="" v-model="user.city"
                           :placeholder="string.registerPage ? string.registerPage._p_city : 'City'">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <input type="text" class="form-control no-rounded" name="country" value=""
                           v-model="user.nation"
                           :placeholder="string.registerPage ? string.registerPage._p_nation : 'Nationality'">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <input autocomplete="off" type="password" class="form-control no-rounded" name="password" v-model="user.password"
                           :placeholder="string.registerPage ? string.registerPage._p_password : 'Password* (minimum 8 characters)'" required>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <input autocomplete="off" type="password" class="form-control no-rounded" name="password_confirmation" v-model="passwordConfirm"
                           :placeholder="string.registerPage ? string.registerPage._p_password_confirm : 'Password Confirm*'" required>
                    <small>{{ string.commonActions.passwordPolicy }}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                                 <textarea class="form-control no-rounded" name="message" rows="8" v-model="user.description"
                                           :placeholder="string.registerPage ? string.registerPage._p_description : 'If you want, enter a message for us'"/>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <!--<label class="checkeddd">
                  <input type="checkbox" v-model="user.privacyCondition" name="disclaimer">
                  <span class="checkmark"/>
                </label>-->
                <label class="control control-checkbox" style="margin-bottom: 30px;">
                  <input
                      v-model="user.privacyCondition"
                      type="checkbox"
                      name="disclaimer">
                  <div class="control_indicator"></div>
                </label>
                <p class="text-policy">
                    {{ string.termsAndConditions._privacy_accept }}
                    <a :href="string.termsAndConditions._privacypolicyURL" :title="string.termsAndConditions._privacy_title" target="_blank">
                        {{ string.termsAndConditions._privacy_title }}
                    </a>
                    {{ string.termsAndConditions._terms_accept }}
                    <a :href="string.termsAndConditions._linkTerms" :title="string.termsAndConditions._terms_title" target="_blank">
                        {{ string.termsAndConditions._terms_title }}
                    </a>
                    {{ string.termsAndConditions._mandatory }}
                </p>
              </div>

              <div class="col-lg-4">
                <!--<label class="checkeddd">
                  <input type="checkbox"  v-model="user.dataCondition">
                  <span class="checkmark"/>
                </label>-->
                <label class="control control-checkbox" style="margin-bottom: 30px;">
                  <input
                      v-model="user.dataCondition"
                      type="checkbox">
                  <div class="control_indicator"></div>
                </label>
                <p class="text-policy">{{ string.termsAndConditions._updates }}</p>
              </div>

              <div class="col-lg-4">
                <!--<label class="checkeddd">
                  <input type="checkbox"  v-model="user.processingCondition">
                  <span class="checkmark"/>
                </label>-->
                <label class="control control-checkbox" style="margin-bottom: 30px;">
                  <input
                      v-model="user.processingCondition"
                      type="checkbox">
                  <div class="control_indicator"></div>
                </label>
                <p class="text-policy">{{string.termsAndConditions._marketing}}</p>
              </div>

              <div class="row public-last-row">
                <div class="col-lg-12">
                  <button type="submit" v-on:click="(e) => { e.preventDefault(); this.register();}" class="btn btn-primary btn-warning active btn-block btn-lg">{{ string.registerPage._submit }}</button>
                </div>
              </div>
            </form>
            <!-- End contact form -->
          </div> <!-- /.col-->
        </div> <!-- /.row -->
        <div v-else class="row">
          <div class="col-md-12">
            <p class="heading-tescription lead text-gray-2">
              Benvenuto in FIGC forms, dopo la registrazione riceverai una mail di verifica per attivare il tuo account.
            </p>
          </div>
        </div>
      </div>
  </PublicLayout>
</template>

<script >
  import PublicLayout from '../layouts/PublicLayout';

  export default {
    components: {
        PublicLayout
    },
    data (){
      return {
        string: {},
        user: {
          email: '',
          name: '',
          surname: '',
          password: '',
          city: '',
          nation: '',
          company: '',
          vatNumber: '',
          site: '',
          description: '',
          privacyCondition: false,
          dataCondition: false,
          processingCondition: false
        },
        passwordConfirm: '',
        pageLoaded: false,
        emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        toastMessage: '',
        toastError:'',
        registerSuccess: false
      };

    },
    computed : {
      // isLoggedIn : function (){ return this.$store.getters.isLoggedIn;}
    },
    metaInfo: {
      title: 'Register - Penguinpass',
      bodyAttrs: {
        class: 'login'
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        }, this);
        const language = window.navigator.language;
        if (language === 'it-IT'){
          context.$root.strings = context.$root.messages_it;
          context.lang = 'it';
          context.$root.currentLanguage = 'it';
        } else if (language === 'en-GB' || language === 'en-US' || language === 'en'){
          context.$root.strings = context.$root.messages_en;
          context.lang = 'en';
          context.$root.currentLanguage = 'en';
        } else if (language === 'fr-FR' || language === 'fr'){
          context.$root.strings = context.$root.messages_fr;
          context.lang = 'fr';
          context.$root.currentLanguage = 'fr';
        } else if (language === "ar-AR" || language === "ar") {
          context.$root.strings = context.$root.messages_ar;
          context.lang = "ar";
          context.$root.currentLanguage = "ar";
        } else {
          context.$root.strings = context.$root.messages_en;
          context.lang = 'en';
          context.$root.currentLanguage = 'en';
        }
        this.$root.bus.emit('setLanguage');
    },
    mounted () {
      $(document).ready(() => {
        this.pageLoaded = true;
      });
    },
    methods: {
      register: function () {
        const context = this;
        // do body validation
        if (this.isDataValid()){
          this.$store.dispatch('register', this.user)
          .then(() => {
            context.registerSuccess = true;
          })
          .catch(error => {
            if (error.data && error.data.status && error.data.status === 'FAILED'){
              if (error.data.message === "PASSWORD_NOT_MATCHING_POLICY") {
                context.$notify({
                  title: 'Oops !',
                  message: context.string.commonActions.passwordPolicy,
                  position: 'bottom-right',
                  type: 'error',
                  duration: 3000,
                });
              } else {
                context.$notify({
                  title: 'Oops !',
                  message: error.data.message,
                  position: 'bottom-right',
                  type: 'error',
                  duration: 3000,
                });
              }
            }
          });
        }
      },
      isDataValid: function () {
        const context = this;
        if (this.passwordConfirm === ''  || this.user.password === '') {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._password_mandatory,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        if (this.passwordConfirm !== this.user.password) {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._passwords_not_match,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        if (this.user.name === '' || this.user.surname === '' ) {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._name_surname_mandatory,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        if (this.user.email === '' || !this.emailRegex.test(this.user.email)) {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._email_not_valid,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        if (this.user.company === '' ) {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._company_mandatory,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        if (this.user.privacyCondition === false) {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._privacy_policy_mandatory,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        return true;
      }

    }
  };

</script>

<style scoped>

</style>
