<template>
  <div class="advance-settings-component-container accordion">
    <h4 class="form-section-title first accordion-toggle">
      {{ string.categoryManager._title }}
    </h4>
    <div class="form-container accordion-content">
      <small>{{ string.categoryManager._subtitle }}</small>
      <el-row>
        <el-col :span="18" :offset="3">
          <el-select
            v-model="customCategoryObj"
            aria-label=""
            value-key="key"
            placeholder="Category"
            popper-class="default-popper"
            size="large"
            style="width: 100%"
          >
            <el-option
              v-for="category in customCategories"
              :key="category.key"
              class="optionStyle"
              :hidden="!!category.deletedAt"
              :label="category.label"
              :value="category"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18" :offset="3">
          <a
            class="btn gray-btn save"
            @click="
              (e) => {
                e.preventDefault();
                submit();
              }
            "
          >
            {{ string.categoryManager._submit }}
          </a>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { ENUM_QUERY, UPDATE_EVENT } from "@/graphql";
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    eventId: String,
    event: Object,
  },
  data() {
    return {
      componentName: "categoryManager",
      string: {},
      inAction: false,
      componentDisabled: false /** Disable Component */,
      inputValue: "",
      selectedCategory: {},
      customCategoryObj: { key: "", label: "" },
    };
  },
  computed: {
    ...mapGetters(["customCategories"]),
  },
  watch: {
    event: function () {
      this.customCategoryObj = { key: this.event.customCategory, label: this.event.customCategoryLabel };
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    disableLayerClicked: function () {
      this.$root.bus.emit("notAuthorizedOptionToast"); /** Emit di al gestore globale */
    },
    submit: function () {
      try {
        const context = this;
        this.inAction = true;
        return this.$root.$apollo
          .mutate({
            mutation: UPDATE_EVENT,
            variables: {
              eventId: context.eventId,
              updateEventInput: { customCategory: this.customCategoryObj.key, customCategoryLabel: this.customCategoryObj.label },
            },
          })
          .then((value) => {
            if (value.errors) {
              console.log("error " + value.errors[0]);
            } else {
              context.inAction = false;
              context.$root.bus.emit("simpleToast", context.string.attendeeMaxLimit._updateDone);
              context.$root.bus.emit("categoryChanged", {
                customCategory: this.customCategoryObj.key,
                customCategoryLabel: this.customCategoryObj.label,
              });
            }
          })
          .catch((reason) => {
            console.log(reason.message);
            context.inAction = false;
            context.$root.bus.emit(
              "simpleToast",
              context.string.attendeeMaxLimit._error + reason.message
            );
          });
      } catch (e) {
        console.log(e.message);
      }
    },
  },
};
</script>

<style scoped>
/** Disable Classes */

.form-container {
  position: relative;
}

/** /Disable Classes */

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #0057B8;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
select.form-control::placeholder {
  color: #fff !important;
}
.form-control option.optionStyle {
  background: #000d;
}
</style>
