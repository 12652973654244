var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audience-option-group" },
    [
      _vm.calculatingAudience && !_vm.hideCounter
        ? _c(
            "div",
            { staticClass: "selected-guests" },
            [
              _c("div"),
              _vm._v(" "),
              _c("i", {
                staticClass: "fa fa-users",
                staticStyle: { "margin-right": "15px" },
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(_vm._s(_vm.string.datagrid._selected) + ":"),
              _c("ClipLoader", {
                staticStyle: { display: "inline-block", "margin-left": "5px" },
                attrs: {
                  loading: _vm.calculatingAudience,
                  color: "#0057B8",
                  size: "15px",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.calculatingAudience && !_vm.hideCounter
        ? _c("div", { staticClass: "selected-guests" }, [
            _c("i", {
              staticClass: "fa fa-users",
              staticStyle: { "margin-right": "15px" },
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(
              _vm._s(_vm.string.datagrid._selected) +
                ": " +
                _vm._s(_vm.audienceSize) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-radio-group",
        {
          staticClass: "audience-group",
          attrs: { disabled: _vm.calculatingAudience },
          on: { change: _vm.onChanged },
          model: {
            value: _vm.audienceOption,
            callback: function ($$v) {
              _vm.audienceOption = $$v
            },
            expression: "audienceOption",
          },
        },
        _vm._l(_vm.audienceOptions, function (option) {
          return _c(
            "el-radio-button",
            {
              key: option.value,
              staticClass: "audience-option",
              attrs: { label: option.value },
            },
            [
              _c("span", [
                _vm._v(
                  "\n      " + _vm._s(_vm.string.datagrid[option.label]) + " "
                ),
                option.canExpandable
                  ? _c("i", {
                      class:
                        "fa " +
                        (_vm.audienceOption === option.value
                          ? " fa-chevron-up "
                          : " fa-chevron-down "),
                      style:
                        "float:right; color: " +
                        (_vm.audienceOption === option.value
                          ? "#0057B8; "
                          : " #fff; "),
                      attrs: { "aria-hidden": "true" },
                    })
                  : _vm._e(),
              ]),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }