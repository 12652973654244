<template>
  <div class="advance-settings-component-container accordion">
    <h4 class="form-section-title first accordion-toggle">
      {{ string.attendeeMaxLimit._title }}
    </h4>
    <div class="form-container accordion-content">
      <!--      <small>{{ string.attendeeMaxLimit._subtitle }}</small>-->
      <el-row>
        <el-col :span="18" :offset="3">
          <input
            id="purchasesLimit"
            v-model="inputValue"
            type="number"
            min="0"
            class="form-control input-lg"
            name="purchases_limit"
            :placeholder="string.attendeeMaxLimit._placeholder"
          />
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="18" :offset="3">
          <a class="btn gray-btn save" @click.prevent="submit">
            {{ string.attendeeMaxLimit._submit }}
          </a>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<style scoped>
.external-puchase-component {
  margin-bottom: 30px;
}

/** Disable Classes */

.form-container {
  position: relative;
}

.disable-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(255, 255, 255, 0.8);*/
  background-color: #00000000;
}

/** /Disable Classes */

/** In Action Layer */
.inaction-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
/** In Action Layer */

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #0057B8;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
</style>
<script>
import { UPDATE_EVENT } from "@/graphql";

export default {
  components: {},
  props: {
    eventId: String,
    event: Object,
  },
  data() {
    return {
      componentName: "attendeeMaxLimit",
      string: {
        /*
                        attendeeMaxLimit:{
                            _title: "Attendee Max Limit",
                            _subtitle: "Purchase",
                            _placeholder: "Numero massimo prenotazioni",
                            _submit: "Salva impostazione",
                            _updateDone: "aggiornamento eseguito",
                            _error: "errore non gestito"
                        }*/
      },
      inAction: false,
      inputValue: 0,
    };
  },
  watch: {
    event: function () {
      try {
        this.inputValue = this.event.maxGuests;
      } catch (e) {
        // Nothing
      }
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    submit: function () {
      const context = this;
      this.inAction = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: this.event.id,
            updateEventInput: {
              maxGuests: parseInt(this.inputValue),
            },
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
          } else {
            context.$root.bus.emit("simpleToast", this.string.organizerSettings._settingsUpdated);
            this.inAction = false;
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
        });
    },
  },
};
</script>
