var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "default-layout" }, [
    _c(
      "div",
      { staticClass: "messages-component", attrs: { id: "messages" } },
      [
        _vm.loadingEffectshow
          ? _c(
              "div",
              { staticClass: "higherPosition" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.loadingEffectshow
                      ? _c("LoadingCover", {
                          attrs: {
                            "loading-cover-msg":
                              _vm.string.messages._loadingMessage,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "inmail accordion" }, [
          _c(
            "h4",
            { staticClass: "form-section-title second accordion-toggle" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.string.messages._registrationFormTitle) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "accordion-content" }, [
            _c("div", { staticClass: "inMailToggleSelection" }, [
              _c("label", { staticClass: "control control-checkbox" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.string.messages._registrationFormDescription) +
                    "\n            "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.showEditor,
                      expression: "showEditor",
                    },
                  ],
                  attrs: { id: "checkbox", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.showEditor)
                      ? _vm._i(_vm.showEditor, null) > -1
                      : _vm.showEditor,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.showEditor,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.showEditor = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.showEditor = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.showEditor = $$c
                        }
                      },
                      _vm.toggleConfirmationEmailPermission,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "control_indicator" }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "inmail-message-intent" }, [
              _vm.showEditor
                ? _c(
                    "div",
                    { staticClass: "email-settings" },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass:
                            "form-field-row zero-margin-row form-section",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-label",
                              attrs: { span: 3 },
                            },
                            [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.string.directEmailMarketing._name
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              staticStyle: { "padding-left": "0" },
                              attrs: { span: 21 },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.confirmationEmailSenderName,
                                    expression: "confirmationEmailSenderName",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  autocomplete: "new-password",
                                  "aria-label": "",
                                  placeholder:
                                    _vm.string.directEmailMarketing._name,
                                  type: "text",
                                },
                                domProps: {
                                  value: _vm.confirmationEmailSenderName,
                                },
                                on: {
                                  change: function ($event) {
                                    $event.preventDefault()
                                    return _vm.autoSave($event)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.confirmationEmailSenderName =
                                      $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.identity === "DOMAIN" &&
                      _vm.verifiedDomainIdentities.length >= 1
                        ? _c(
                            "el-row",
                            {
                              staticClass:
                                "form-field-row zero-margin-row form-section",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.string.directEmailMarketing
                                              ._email
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-field",
                                  staticStyle: { "padding-left": "0" },
                                  attrs: { span: 5 },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.confirmationEmailDomainUser,
                                        expression:
                                          "confirmationEmailDomainUser",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { "text-align": "right" },
                                    attrs: {
                                      autocomplete: "new-password",
                                      "aria-label": "",
                                      type: "text",
                                    },
                                    domProps: {
                                      value: _vm.confirmationEmailDomainUser,
                                    },
                                    on: {
                                      change: function ($event) {
                                        $event.preventDefault()
                                        return _vm.autoSave($event)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.confirmationEmailDomainUser =
                                          $event.target.value.trim()
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-field",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "domain-selector",
                                      attrs: {
                                        "popper-class": "default-popper",
                                        size: "large",
                                        clearable: "",
                                        placeholder: "Select your domain",
                                      },
                                      on: { change: _vm.autoSave },
                                      model: {
                                        value: _vm.confirmationEmailDomainName,
                                        callback: function ($$v) {
                                          _vm.confirmationEmailDomainName = $$v
                                        },
                                        expression:
                                          "confirmationEmailDomainName",
                                      },
                                    },
                                    _vm._l(
                                      _vm.verifiedDomainIdentities,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item,
                                          attrs: {
                                            label: "@" + item,
                                            value: item,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.identity === "EMAIL" &&
                      _vm.verifiedEmailIdentities.length >= 1
                        ? _c(
                            "el-row",
                            {
                              staticClass:
                                "form-field-row zero-margin-row form-section",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.string.directEmailMarketing
                                              ._email
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-field",
                                  staticStyle: { "padding-left": "0" },
                                  attrs: { span: 21 },
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.confirmationEmailSenderEmail,
                                          expression:
                                            "confirmationEmailSenderEmail",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "select-email-sender",
                                        "aria-label": "",
                                      },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.confirmationEmailSenderEmail =
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                          },
                                          function ($event) {
                                            $event.preventDefault()
                                            return _vm.autoSave($event)
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: {
                                            value: "",
                                            disabled: "",
                                            hidden: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.string.directEmailMarketing
                                                  ._selectOneVerifiedEmail
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.verifiedEmailIdentities,
                                        function (verifiedIdentity) {
                                          return _c(
                                            "option",
                                            {
                                              key: verifiedIdentity,
                                              domProps: {
                                                value: verifiedIdentity,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(verifiedIdentity) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.identity === "DEFAULT"
                        ? _c(
                            "el-row",
                            {
                              staticClass:
                                "form-field-row zero-margin-row form-section",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.string.directEmailMarketing
                                              ._email
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass:
                                    "form-field-col-field default-email",
                                  staticStyle: { "padding-left": "0" },
                                  attrs: { span: 8 },
                                },
                                [
                                  _c("p", { staticClass: "textLabel" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.defaultEmail) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass:
                                    "form-field-col-field default-email-message",
                                  attrs: { span: 13 },
                                },
                                [
                                  _c("p", { staticClass: "textLabel" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.string.directEmailMarketing
                                            ._defaultEmailExplanation1
                                        ) +
                                        "\n                  " +
                                        _vm._s(
                                          _vm.string.directEmailMarketing
                                            ._defaultEmailExplanation2
                                        ) +
                                        "\n                  "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          id: "page-link-advanced",
                                          href: "/settings#domain-setup",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Domain Setup Section\n                  "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.confirmationEmailSubjectDisabled === false
                        ? _c(
                            "el-row",
                            {
                              staticClass:
                                "form-field-row zero-margin-row form-section",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.string.directEmailMarketing
                                              ._subject
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-field",
                                  staticStyle: { "padding-left": "0" },
                                  attrs: { span: 21 },
                                },
                                [
                                  _c("div", { staticClass: "subject-field" }, [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "subject-section-title first",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.confirmationEmailSubject
                                            ) +
                                            "\n                    "
                                        ),
                                        _c("small", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.string.messages
                                                  ._subjectExplanation
                                              )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.confirmationEmailPreview
                        ? _c(
                            "el-row",
                            {
                              staticClass:
                                "form-field-row zero-margin-row form-section",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [_vm._v(" Email preview ")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass:
                                    "form-field-col-field preview-style",
                                  attrs: { span: 21 },
                                },
                                [
                                  _c("iframe", {
                                    attrs: {
                                      id: "emailPreviewFrameConfirmation",
                                      width: "100%",
                                      srcdoc: _vm.confirmationEmailPreview,
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "clear" }, [_vm._v(" ")]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass:
                            "form-field-row zero-margin-row form-section",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _vm.confirmationEmailTemplate !== null
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn gray-btn full-column-button",
                                    on: { click: _vm.loadTemplateBuilder },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.string.messages._editmsg) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn gray-btn full-column-button",
                                    on: { click: _vm.LoadTemplates },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.string.messages._customizemsg
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass:
                            "form-field-row zero-margin-row form-section",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _vm.showEditor
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn gray-btn full-column-button",
                                    on: { click: _vm.updateEmailConfirmation },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.string.messages._savesettings
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass:
                            "form-field-row zero-margin-row form-section",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _vm.showEditor
                                ? _c(
                                    "el-input",
                                    {
                                      staticClass: "new-form-control",
                                      attrs: {
                                        id: "registrationFormCopy",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.registrationFormId,
                                        callback: function ($$v) {
                                          _vm.registrationFormId = $$v
                                        },
                                        expression: "registrationFormId",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "prepend" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s("Form id:") +
                                            "\n                  "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "gray-btn",
                                          staticStyle: { margin: "0" },
                                          attrs: { slot: "append" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.copy($event)
                                            },
                                          },
                                          slot: "append",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-copy-document",
                                          }),
                                        ]
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }