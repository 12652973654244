<template>
  <div class="direct-email-marketing-component-container">
    <section-content :has-icon="true" :icon-progress="steps.campaign.progress" :icon-text="steps.campaign.progressLabel">
      <section-title
        :title="$root.strings.directEmailMarketing._title"
        :subtitle="$root.strings.directEmailMarketing._subtitle"
      />
      <transition name="component-fade-fast" mode="out-in" appear>
        <PulseLoader
          v-if="loading === true"
          :loading="loading"
          color="#0057B8"
          size="15px"
        ></PulseLoader>
        <button
          v-else-if="demEntryVisible === false && loading === false"
          class="button save"
          style="width: 100% !important"
          @click="createCampaign"
        >
          {{ $root.strings.directEmailMarketing._create }}
        </button>
        <!-- <a
                id="delete-campaign-button"
                class="button btn-warning save"
                style="width: 100% !important;"
                @click="warnAndDeleteCampaign">
                {{ $root.strings.directEmailMarketing._deleteCampaign }}
              </a> -->
        <LoadingCover v-else-if="loadingEffectshow" :loading-cover-msg="loadingMessage" />

        <div v-else class="">
          <el-row :gutter="20" class="form-field-row zero-margin-row">
            <el-col :span="3" class="form-field-col-label">
              <span>
                {{ $root.strings.directEmailMarketing._name }}
              </span>
            </el-col>
            <el-col :span="21" class="form-field-col-field">
              <custom-input
                v-model.trim="name"
                :placeholder="$root.strings.directEmailMarketing._senderName"
                @change="
                  () => {
                    onChanges(false);
                    autoSaveCampaign(this, { eventId, senderName: name });
                  }
                "
              />
            </el-col>
          </el-row>
          <el-row :gutter="20" class="form-field-row zero-margin-row">
            <el-col :span="3" class="form-field-col-label">
              <span>
                {{ $root.strings.directEmailMarketing._email }}
              </span>
            </el-col>
            <el-col :span="18">
              <custom-input
                v-if="
                  !editEmail &&
                  !(
                    identity === 'DEFAULT' ||
                    (verifiedEmailIdentities.length < 1 && verifiedDomainIdentities.length < 1)
                  )
                "
                v-model.trim="email"
                placeholder="Email"
                readonly
              />
              <custom-input
                v-else-if="
                  identity === 'DEFAULT' ||
                  (verifiedEmailIdentities.length < 1 && verifiedDomainIdentities.length < 1)
                "
                v-model.trim="defaultEmail"
                placeholder="Email"
                readonly
              />
              <el-row
                v-else-if="
                  editEmail && identity === 'DOMAIN' && verifiedDomainIdentities.length >= 1
                "
                style="padding: 0"
              >
                <el-col :span="11">
                  <custom-input
                    v-model.trim="domainUser"
                    :placeholder="$root.strings.directEmailMarketing._username"
                    @change="() => onDomainInputChanged()"
                  />
                </el-col>
                <el-col :span="2">
                  <custom-input :value="'@'" readonly style="width: 100%; text-align: center" />
                </el-col>
                <el-col :span="11">
                  <el-select
                    v-model="domainName"
                    class="domain-selector"
                    popper-class="default-popper"
                    size="large"
                    clearable
                    :placeholder="$root.strings.directEmailMarketing._selectOneVerifiedDomain"
                    @change="() => onDomainInputChanged()"
                  >
                    <el-option
                      v-for="item in verifiedDomainIdentities"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row
                v-else-if="editEmail && identity === 'EMAIL' && verifiedEmailIdentities.length >= 1"
                style="padding: 0"
              >
                <el-select
                  v-model="email"
                  popper-class="default-popper"
                  size="large"
                  clearable
                  aria-label=""
                  class="domain-selector"
                  style="width: 100%"
                  :placeholder="$root.strings.directEmailMarketing._selectOneVerifiedEmail"
                  @change="
                    (value) => {
                      email = value;
                      onChanges(false);
                      autoSaveCampaign(this, { eventId, senderEmail: value });
                    }
                  "
                >
                  <el-option value="" disabled hidden>
                    {{ $root.strings.directEmailMarketing._selectOneVerifiedEmail }}
                  </el-option>
                  <el-option
                    v-for="verifiedIdentity in verifiedEmailIdentities"
                    :key="verifiedIdentity"
                    :label="verifiedIdentity"
                    :value="verifiedIdentity"
                  >
                    {{ verifiedIdentity }}
                  </el-option>
                </el-select>
              </el-row>
            </el-col>
            <el-col :span="3">
              <a
                class="btn gray-btn save full-column-button"
                @click="() => (editEmail = !editEmail)"
              >
                {{
                  editEmail &&
                  identity !== "DEFAULT" &&
                  (verifiedEmailIdentities.length !== 0 || verifiedDomainIdentities.length !== 0)
                    ? $root.strings.directEmailMarketing._save
                    : $root.strings.directEmailMarketing._modify
                }}
              </a>
            </el-col>
          </el-row>
          <el-row
            v-if="
              (identity === 'DEFAULT' ||
                (verifiedEmailIdentities.length < 1 && verifiedDomainIdentities.length < 1)) &&
              editEmail
            "
            :gutter="20"
            style="margin-bottom: 30px"
          >
            <el-col :span="3" class="form-field-col-label"> &nbsp; </el-col>
            <el-col :span="21" class="form-field-col-field">
              <p style="padding: 5px">
                {{ $root.strings.directEmailMarketing._defaultEmailExplanation1 }}
                <!--                                        <a href="/settings" class="page-link"> Settings</a>&nbsp; > &nbsp; -->
                <a class="page-link" href="/settings#domain-setup">
                  {{ $root.strings.directEmailMarketing._goToProfileSettings }}
                </a>
                {{ $root.strings.directEmailMarketing._defaultEmailExplanation2 }}
              </p>
            </el-col>
          </el-row>
          <el-row
            v-if="subjectDisabled === false"
            :gutter="20"
            class="form-field-row zero-margin-row"
          >
            <el-col :span="3" class="form-field-col-label">
              <span>
                {{ $root.strings.directEmailMarketing._subject }}
              </span>
            </el-col>
            <el-col :span="21" class="form-field-col-field">
              <el-tooltip
                effect="dark"
                :content="$root.strings.directEmailMarketing._subjectExplanation"
                placement="bottom-start"
              >
                <custom-input v-model.trim="subject" readonly />
              </el-tooltip>
            </el-col>
          </el-row>
          <section-content v-if="demEntryVisible === true && loading === false">
            <section-title
              :title="$root.strings.directEmailMarketing._emailBuilderTitle"
              :subtitle="$root.strings.directEmailMarketing._emailBuilderSubtitle"
            />
            <transition name="component-fade-fast" mode="out-in" appear>
              <el-row>
                <el-row :gutter="30" class="form-field-row zero-margin-row">
                  <el-col :span="12">
                    <a class="btn gray-btn" @click.prevent="()=>loadTemplates()">
                      {{ $root.strings.directEmailMarketing._createEmail }}
                    </a>
                  </el-col>
                  <el-col :span="12">
                    <a class="btn gray-btn" @click.prevent="()=>loadTemplates()">
                      {{ $root.strings.directEmailMarketing._startfromtemplate }}
                    </a>
                  </el-col>
                </el-row>
                <section-title
                  v-if="campaignEmailPreview"
                  title="Email preview"
                  :show-separator="false"
                />
                <el-row v-if="campaignEmailPreview" class="form-field-row zero-margin-row">
                  <el-col :span="24" class="email-preview">
                    <iframe id="emailPreviewFrame" :srcdoc="campaignEmailPreview"></iframe>
                  </el-col>
                </el-row>

                <el-row
                  v-if="campaignEmailPreview"
                  :gutter="30"
                  class="form-field-row zero-margin-row"
                >
                  <el-col :span="24">
                    <a
                      class="btn gray-btn"
                      @click.prevent="() => loadTemplateBuilder(campaignEmailTemplate)"
                    >
                      {{ $root.strings.directEmailMarketing._modifyEmail }}
                    </a>
                  </el-col>
                </el-row>
              </el-row>
            </transition>
          </section-content>
          <section-content v-if="demEntryVisible === true && loading === false">
            <section-title
              :title="$root.strings.directEmailMarketing._additionalSettingsTitle"
              :show-separator="false"
            />
            <el-row v-if="isPrivate" :gutter="10" class="form-field-row zero-margin-row" style="margin-bottom: 30px">
              <el-col :span="3" class="form-field-col-label">
                <span> {{ $root.strings.directEmailMarketing._setpartnermax }}: </span>
              </el-col>
              <el-col
                :span="1"
                class="form-field-col-field"
                style="align-self: center; vertical-align: middle; text-align: center"
              >
                <custom-checkbox v-model="setMaxAcquaintanceOnJoin" @change="() => onChanges()" />
              </el-col>
              <el-col :span="20" class="form-field-col-field">
                <p style="margin: 0; padding: 0">
                  {{ $root.strings.directEmailMarketing._setpartnermax_explain }}
                </p>
              </el-col>
            </el-row>
            <el-row v-if="isPrivate" :gutter="10" class="form-field-row zero-margin-row" style="margin-bottom: 30px">
              <el-col :span="3" class="form-field-col-label">
                <span> {{ $root.strings.directEmailMarketing._setnote }}: </span>
              </el-col>
              <el-col
                :span="1"
                class="form-field-col-field"
                style="align-self: center; vertical-align: middle; text-align: center"
              >
                <custom-checkbox v-model="setAcquaintanceInfoOnJoin" @change="() => onChanges()" />
              </el-col>
              <el-col :span="20" class="form-field-col-field">
                <p style="margin: 0; padding: 0">
                  {{ $root.strings.directEmailMarketing._setnote_explain }}
                </p>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="form-field-row zero-margin-row" style="margin-bottom: 30px">
              <el-col :span="3" class="form-field-col-label">
                <span> {{ $root.strings.directEmailMarketing._customFields_title }}: </span>
              </el-col>
              <el-col
                :span="1"
                class="form-field-col-field"
                style="align-self: center; vertical-align: middle; text-align: center"
              >
                <custom-checkbox hidden />
              </el-col>
              <el-col :span="20" class="form-field-col-field">
                <p style="margin: 0; padding: 0">
                  {{ $root.strings.directEmailMarketing._customFields_explain1 }}
                  <a class="page-link" @click.prevent="goToSettings">
                    {{ $root.strings.directEmailMarketing._goToSettings }}
                  </a>
                  {{ $root.strings.directEmailMarketing._customFields_explain2 }}
                </p>
              </el-col>
            </el-row>
            <el-row :gutter="10" class="form-field-row zero-margin-row">
              <el-col :span="3" class="form-field-col-label">
                <span> {{ $root.strings.directEmailMarketing._customPolicy_title }}: </span>
              </el-col>
              <el-col
                :span="1"
                class="form-field-col-field"
                style="align-self: center; vertical-align: middle; text-align: center"
              >
                <custom-checkbox hidden />
              </el-col>
              <el-col :span="20" class="form-field-col-field">
                <p style="margin: 0; padding: 0">
                  {{ $root.strings.directEmailMarketing._customPolicy_explain1 }}
                  <a class="page-link" @click.prevent="goToSettings">
                    {{ $root.strings.directEmailMarketing._goToSettings }}
                  </a>
                  {{ $root.strings.directEmailMarketing._customPolicy_explain2 }}
                </p>
              </el-col>
            </el-row>
          </section-content>
        </div>
      </transition>
    </section-content>

    <section-content
      v-if="steps.confirmationEmail.enabled && demEntryVisible === true && loading === false"
      :has-icon="true"
      :icon-progress="steps.confirmationEmail.progress"
      :icon-text="steps.confirmationEmail.progressLabel"
    >
      <section-title
        :title="$root.strings.directEmailMarketing._confirmationEmail_title"
        :subtitle="$root.strings.directEmailMarketing._confirmationEmail_subtitle"
      />
      <button-tab
        style="margin-bottom: 30px"
        :tabs="confirmationEmailTabs"
        :initial-tab="confirmationEmailTab"
        @tabChanged="toggleConfirmationEmail"
      />
      <transition name="component-fade-fast" mode="out-in" appear>
        <div v-if="confirmationEmailTab === 'CONFIRMATION_EMAIL_ENABLED'">
          <el-row :gutter="20" class="form-field-row zero-margin-row">
            <el-col :span="3" class="form-field-col-label">
              <span>
                {{ $root.strings.directEmailMarketing._name }}
              </span>
            </el-col>
            <el-col :span="21" class="form-field-col-field">
              <custom-input
                v-model.trim="confirmationEmailSenderName"
                :placeholder="$root.strings.directEmailMarketing._senderName"
                @change="
                  () => {
                    onChanges(false);
                    autoSaveConfirmationEmail(this, {
                      eventId,
                      campaignId,
                      senderName: confirmationEmailSenderName,
                    });
                  }
                "
              />
            </el-col>
          </el-row>
          <el-row :gutter="20" class="form-field-row zero-margin-row">
            <el-col :span="3" class="form-field-col-label">
              <span>
                {{ $root.strings.directEmailMarketing._email }}
              </span>
            </el-col>
            <el-col :span="18">
              <custom-input
                v-if="
                  !editConfirmationEmail &&
                  !(
                    identity === 'DEFAULT' ||
                    (verifiedEmailIdentities.length < 1 && verifiedDomainIdentities.length < 1)
                  )
                "
                v-model.trim="confirmationEmailSenderEmail"
                placeholder="Email"
                readonly
              />
              <custom-input
                v-else-if="
                  identity === 'DEFAULT' ||
                  (verifiedEmailIdentities.length < 1 && verifiedDomainIdentities.length < 1)
                "
                v-model.trim="defaultEmail"
                placeholder="Email"
                readonly
              />
              <el-row
                v-else-if="
                  editConfirmationEmail &&
                  identity === 'DOMAIN' &&
                  verifiedDomainIdentities.length >= 1
                "
                style="padding: 0"
              >
                <el-col :span="11">
                  <custom-input
                    v-model.trim="confirmationEmailDomainUser"
                    :placeholder="$root.strings.directEmailMarketing._username"
                    @change="() => onDomainInputChanged(true)"
                  />
                </el-col>
                <el-col :span="2">
                  <custom-input :value="'@'" readonly style="width: 100%; text-align: center" />
                </el-col>
                <el-col :span="11">
                  <el-select
                    v-model="confirmationEmailDomainName"
                    class="domain-selector"
                    popper-class="default-popper"
                    size="large"
                    clearable
                    :placeholder="$root.strings.directEmailMarketing._selectOneVerifiedDomain"
                    @change="() => onDomainInputChanged(true)"
                  >
                    <el-option
                      v-for="item in verifiedDomainIdentities"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row
                v-else-if="
                  editConfirmationEmail &&
                  identity === 'EMAIL' &&
                  verifiedEmailIdentities.length >= 1
                "
                style="padding: 0"
              >
                <el-select
                  v-model="confirmationEmailSenderEmail"
                  popper-class="default-popper"
                  size="large"
                  clearable
                  aria-label=""
                  class="domain-selector"
                  style="width: 100%"
                  :placeholder="$root.strings.directEmailMarketing._selectOneVerifiedEmail"
                  @change="
                    (value) => {
                      confirmationEmailSenderEmail = value;
                      autoSaveConfirmationEmail(this, {
                        eventId,
                        campaignId,
                        senderEmail: confirmationEmailSenderEmail,
                      });
                      onChanges(false);
                    }
                  "
                >
                  <el-option value="" disabled hidden>
                    {{ $root.strings.directEmailMarketing._selectOneVerifiedEmail }}
                  </el-option>
                  <el-option
                    v-for="verifiedIdentity in verifiedEmailIdentities"
                    :key="verifiedIdentity"
                    :label="verifiedIdentity"
                    :value="verifiedIdentity"
                  >
                    {{ verifiedIdentity }}
                  </el-option>
                </el-select>
              </el-row>
            </el-col>
            <el-col :span="3">
              <a
                class="btn gray-btn save full-column-button"
                @click="() => (editConfirmationEmail = !editConfirmationEmail)"
              >
                {{
                  editConfirmationEmail &&
                  identity !== "DEFAULT" &&
                  (verifiedEmailIdentities.length !== 0 || verifiedDomainIdentities.length !== 0)
                    ? $root.strings.directEmailMarketing._save
                    : $root.strings.directEmailMarketing._modify
                }}
              </a>
            </el-col>
          </el-row>
          <el-row
            v-if="
              (identity === 'DEFAULT' ||
                (verifiedEmailIdentities.length < 1 && verifiedDomainIdentities.length < 1)) &&
              editConfirmationEmail
            "
            :gutter="20"
          >
            <el-col :span="3" class="form-field-col-label"> &nbsp; </el-col>
            <el-col :span="21" class="form-field-col-field">
              <p style="padding: 5px">
                {{ $root.strings.directEmailMarketing._defaultEmailExplanation1 }}
                <!--                                        <a href="/settings" class="page-link"> Settings</a>&nbsp; > &nbsp; -->
                <a class="page-link" href="/settings#domain-setup">
                  {{ $root.strings.directEmailMarketing._goToProfileSettings }}
                </a>
                {{ $root.strings.directEmailMarketing._defaultEmailExplanation2 }}
              </p>
            </el-col>
          </el-row>
          <el-row
            v-if="confirmationEmailSubjectDisabled === false"
            :gutter="20"
            class="form-field-row zero-margin-row"
          >
            <el-col :span="3" class="form-field-col-label">
              <span>
                {{ $root.strings.directEmailMarketing._subject }}
              </span>
            </el-col>
            <el-col :span="21" class="form-field-col-field">
              <el-tooltip
                effect="dark"
                :content="$root.strings.directEmailMarketing._subjectExplanation"
                placement="bottom-start"
              >
                <custom-input v-model.trim="confirmationEmailSubject" readonly />
              </el-tooltip>
            </el-col>
          </el-row>
          <section-content v-if="demEntryVisible === true && loading === false">
            <section-title
              :title="$root.strings.directEmailMarketing._emailBuilderTitle"
              :subtitle="$root.strings.directEmailMarketing._emailBuilderSubtitle"
            />
            <transition name="component-fade-fast" mode="out-in" appear>
              <el-row>
                <el-row :gutter="30" class="form-field-row zero-margin-row">
                  <el-col :span="12">
                    <a class="btn gray-btn" @click.prevent="() => loadTemplates(true)">
                      {{ $root.strings.directEmailMarketing._createEmail }}
                    </a>
                  </el-col>
                  <el-col :span="12">
                    <a class="btn gray-btn" @click.prevent="() => loadTemplates(true)">
                      {{ $root.strings.directEmailMarketing._startfromtemplate }}
                    </a>
                  </el-col>
                </el-row>
                <section-title
                  v-if="confirmationEmailPreview"
                  title="Email preview"
                  :show-separator="false"
                />
                <el-row v-if="confirmationEmailPreview" class="form-field-row zero-margin-row">
                  <el-col :span="24" class="email-preview">
                    <iframe
                      id="confirmationEmailPreviewFrame"
                      :srcdoc="confirmationEmailPreview"
                    ></iframe>
                  </el-col>
                </el-row>

                <el-row
                  v-if="confirmationEmailPreview"
                  :gutter="30"
                  class="form-field-row zero-margin-row"
                >
                  <el-col :span="24">
                    <a
                      class="btn gray-btn"
                      @click.prevent="() => loadTemplateBuilder(confirmationEmailTemplate, true)"
                    >
                      {{ $root.strings.directEmailMarketing._modifyEmail }}
                    </a>
                  </el-col>
                </el-row>
              </el-row>
            </transition>
          </section-content>
        </div>
      </transition>
    </section-content>
    <!-- Audience Options -->
    <section-content
      v-if="demEntryVisible === true && loading === false"
      :has-icon="true"
      :icon-progress="steps.audience.progress"
      :icon-text="steps.audience.progressLabel"
    >
      <section-title
        :title="$root.strings.directEmailMarketing._audience_title"
        :subtitle="$root.strings.directEmailMarketing._audience_subtitle"
      />
      <div class="group-manager-members-list">
        <i
          class="fa fa-users"
          aria-hidden="true"
          style="margin-right: 15px; font-size: 20px; margin-bottom: 20px"
        />
        <span style="font-size: 20px; margin-bottom: 20px">
          {{ $root.strings.datagrid._selected }}: {{ audienceSize }}
        </span>
        <data-table
          ref="table"
          :get-data="getData"
          :columns="columns"
          :selection-enabled="audienceOption === 'CUSTOM'"
          :page-size="15"
          :on-selection-change="onSelectionChange"
          :bulk-options="['clearSelection']"
          :on-bulk-action="onBulkAction"
          :bulk-option-placeholder="'_options'"
        >
          <span slot="options">
            <a
              class="btn btn-gray full-column-button"
              @click.prevent="() => (filtersVisible = !filtersVisible)"
            >
              <font-awesome-icon icon="filter" transform="grow-4" />
            </a>
          </span>
          <span slot="filterView">
            <audience-option-group
              v-show="filtersVisible"
              :audience-options="audienceOptions"
              :event-id="eventId"
              :custom-field-filter="customFieldFilter"
              :on-audience-option-changed="onAudienceOptionChanged"
              :initial-value="initialOption"
              :hide-counter="true"
              :stop-calculate-eligible-recipient-count="true"
            />
            <custom-field-filter
              v-if="audienceOption === 'CUSTOM_FIELD' && filtersVisible"
              key="CUSTOM_FIELD"
              :custom-field-map="event.combinedCustomFields"
              :on-filter-changed="onFilterChanged"
              :initial-value="initialFilter"
            />
            <custom-field-filter
              v-else-if="audienceOption === 'TIME_SLOT' && filtersVisible"
              key="TIME_SLOT"
              :custom-field-map="event.timeSlotMap"
              :on-filter-changed="onFilterChanged"
              :initial-value="initialFilter"
            />
          </span>
          <span slot="memberName" slot-scope="{ row }">
            {{ row.member.name }}
          </span>
          <span slot="memberSurname" slot-scope="{ row }">
            {{ row.member.surname }}
          </span>
          <span slot="memberEmail" slot-scope="{ row }">
            {{ row.member.email.includes("internal.penguinpass.it") ? "" : row.member.email }}
          </span>
          <span slot="deliveryStatus" slot-scope="{ row }">
            <div
              v-if="
                [
                  'SEND',
                  'DELIVERED',
                  'OPENED',
                  'CLICKED',
                  'BOUNCED',
                  'REJECTED',
                  'COMPLAINED',
                ].indexOf(row.deliveryStatus) !== -1
              "
              class="p-table-cell"
              style="text-align: left !important"
            >
              <el-tooltip
                effect="dark"
                :content="
                  row.deliveryStatus
                    ? row.deliveryStatus === 'COMPLAINED'
                      ? 'unsubscribed'
                      : row.deliveryStatus.toLowerCase()
                    : '-'
                "
                placement="bottom"
              >
                <font-awesome-layers class="fa-lg">
                  <font-awesome-icon :icon="['fas', 'circle']" transform="grow-6">
                  </font-awesome-icon>
                  <font-awesome-icon
                    :icon="deliveryStatusIcons[row.deliveryStatus]"
                    transform="shrink-4"
                    :style="{ color: 'black' }"
                  >
                  </font-awesome-icon>
                </font-awesome-layers>
              </el-tooltip>
            </div>
            <div v-else class="p-table-cell" style="text-align: left !important">-</div>
          </span>

          <span slot="status" slot-scope="{ row }">
            <div class="p-table-cell" style="text-align: left !important">
              <font-awesome-layers class="fa-lg" style="margin-right: 10px">
                <font-awesome-icon
                  :icon="['fas', 'circle']"
                  transform="grow-6"
                  :style="statusStyle[row.status]"
                >
                </font-awesome-icon>
                <font-awesome-icon
                  :icon="statusIcons[row.status]"
                  transform="shrink-4"
                  :style="{ color: 'white' }"
                >
                </font-awesome-icon>
              </font-awesome-layers>
              <span>{{ statusDescription[row.status] }}</span>
            </div>
          </span>
        </data-table>
      </div>
      <el-row class="zero-margin-row">
        <el-col :span="24">
          <a v-if="hasChanges" class="btn gray-btn" @click.prevent="updateCampaign">
            {{ $root.strings.directEmailMarketing._save_changes }}
          </a>
          <a v-else class="btn gray-btn" @click.prevent="sendCampaign">
            {{ $root.strings.directEmailMarketing._send }}
          </a>
        </el-col>
      </el-row>
    </section-content>
    <section-content :has-icon="true">
      <section-title
        :title="$root.strings.directEmailMarketing._recap"
        :subtitle="$root.strings.directEmailMarketing._recapsubtitle"
      />

      <div class="group-manager-members-list">
        <data-table
          ref="campaignsTable"
          :get-data="getPastCampaigns"
          :columns="campaignGridColumns"
          :selection-enabled="false"
          :filter-enabled="false"
          :no-data-text="$root.strings.directEmailMarketing._noCampaigns"
        >
          <div slot="refresh-header" slot-scope="{ row }" style="text-align: center">
            <a
              type="button"
              style="background-color: transparent; padding: 0; margin: 0"
              @click.prevent="updateCampaignTableStatistics"
            >
              <font-awesome-icon icon="sync" :spin="refreshingPastCampaigns" />
            </a>
          </div>
          <div slot="refresh" slot-scope="{ row }" style="text-align: center">
            <font-awesome-icon v-if="row.status === 'QUEUED'" icon="sync" spin />
          </div>
          <span slot="sentAt" slot-scope="{ row }">
            <a
              v-if="row.status === 'QUEUED'"
              class="action_link"
              style=""
              @click.prevent="() => onCancelCampaign(row)"
            >
              <font-awesome-icon icon="times" />
              {{ $root.strings.directEmailMarketing._cancel_campaign }}
            </a>
            <span v-else>
              {{ row.sentAt ? new Date(row.sentAt).format() : " - " }}
            </span>
          </span>
        </data-table>
      </div>
    </section-content>
    <!-- Campaigns sent-->
  </div>
</template>
<style>
div.domain-selector > div.el-input > input.el-input__inner {
  height: 50px;
}
.domain-selector,
.domain-selector > div > input {
  height: 45px !important;
  text-align: left;
  width: 100%;
}
span.domain-selector {
  line-height: 45px !important;
}
.domain-selector > .el-button:focus,
.el-button:hover {
  color: black !important;
  background: white !important;
}
button.el-button.domain-selector.el-button--default {
  color: white !important;
  background: #0057B8 !important;
}
/** Rotating Animation */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.el-message-box {
  background-color: #000000;
  border: 1px solid #0057B8;
}
.el-message-box.grayscale {
  border: 1px solid #ffffff;
}
.el-message-box.grayscale .el-message-box__btns .el-button {
  color: #ffffff !important;
}
.el-message-box.grayscale .el-message-box__btns .el-button:hover {
  color: #000 !important;
}

.el-message-box__content {
  color: #ffffff;
}
.email-preview {
  text-align: left;
  border: solid 1px #000;
  padding: 0;
  background: white;
}
iframe#emailPreviewFrame,
iframe#confirmationEmailPreviewFrame {
  display: block;
  overflow: auto;
  border: 0;
  width: 100%;
  height: 100%;
  min-height: 500px;
  margin: 0;
  cursor: pointer;
}
</style>
<style scoped>
#delete-campaign-button {
  background: rgba(247, 163, 12, 0.5);
}
.refresh-stats-button {
  margin-bottom: 15px;
}
a.simple {
  cursor: pointer;
  color: #333 !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.simple:hover {
  cursor: pointer;
  color: #333;
  font-size: 20px !important;
  text-decoration: underline;
}
.pagination > li {
  color: white;
}
ul .pagination {
  background-color: white;
}

.createEmail a.btn {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
  margin-bottom: 0px;
}

.squaredThree {
  width: 20px;
  position: relative;
  margin: 0 auto;
}

.joincommand {
  text-align: center;
  border: solid 1px #fff;
  padding: 20px;
}

.joincommand p {
  margin-bottom: 0 !important;
}

.textLabel {
  padding: 20px 20px 0 20px;
}

.btn {
  border: 0;
}

.direct-email-marketing-component-container .form-control {
  border-radius: 0;
  border-color: #333;
}

.sendCampaignArea {
  margin-bottom: 50px;
}

.sendCampaignArea a.btn {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 15px;
}

.form-section-title {
  padding-bottom: 5px;
  margin-top: 20px;
  color: #0057B8;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}

.subject-section-title {
  color: #0057B8;
}

.subject-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 10px;
  color: #e8e8e8;
}

.direct-email-marketing-component-container {
  margin-top: 50px;
  margin-bottom: 30px;
}

.control-label {
  text-align: right;
}
.default-email {
  text-align: start;
  font-size: medium;
}

.subject-field {
  text-align: start;
  border: solid 1px #fff;
  padding: 0px 5px;
}
.page-link {
  background: transparent !important;
  text-decoration: underline !important;
  text-underline-position: under;
  text-decoration-color: #0057B8 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  margin: 5px;
  padding: 0 !important;
}
.control.control-checkbox {
  display: inline;
}
.formStyle {
  padding-bottom: 10px;
}
.form-field-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.form-field-col-label {
  align-self: center;
}
/* .form-field-col-field {
}
*/
</style>

<script>
import $ from "jquery";
import DataGridCampaign from "../components/DataGridCampaign.vue";
import LoadingCover from "./LoadingCover.vue";
import Paginate from "vuejs-paginate";
import DataTable from "./DataTable";
import SectionTitle from "./ui/SectionTitle";
import CustomCheckbox from "./ui/CustomCheckbox";
import SectionContent from "./ui/SectionContent";
import {
  CREATE_CAMPAIGN,
  DELETE_CAMPAIGN,
  GET_DRAFT_CAMPAIGN,
  GET_PAST_CAMPAIGNS,
  GET_MY_VERIFIED_IDENTITIES,
  SEND_CAMPAIGN,
  UPDATE_CAMPAIGN,
  UPDATE_RECIPIENT_LIST,
  UPDATE_PAST_CAMPAIGNS,
  SUBSCRIBE_TO_SEND_CAMPAIGN,
  GUEST_LIST,
  UPDATE_EMAIL_CONFIRMATION,
  CREATE_EMAIL_CONFIRMATION,
  DELETE_EMAIL_CONFIRMATION,
} from "@/graphql";

import { customFieldMapToMergeTagArray, findMustacheTokens, getAllTokens } from "../utils";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import CustomFieldFilter from "@/containers/CustomFieldFilter.vue";
import AudienceOptionGroup from "@/containers/AudienceOptionGroup.vue";
import CustomInput from "./ui/CustomInput.vue";
import ButtonTab from "./ui/ButtonTab.vue";
import LoadingIcon from "./LoadingIcon.vue";
import debounce from "lodash/debounce";

export default {
  components: {
    DataGridCampaign,
    LoadingCover,
    Paginate,
    PulseLoader,
    DataTable,
    CustomFieldFilter,
    AudienceOptionGroup,
    SectionTitle,
    SectionContent,
    CustomCheckbox,
    CustomInput,
    ButtonTab,
    LoadingIcon,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 100,
    },
    campaignTableSize: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data() {
    const steps =
      this.event.genre === "PRIVATE"
        ? {
            campaign: { enabled: true, progress: 0, progressLabel: "1" },
            confirmationEmail: { enabled: true, progress: 0, progressLabel: "2" },
            audience: { enabled: true, progress: 0, progressLabel: "3" },
          }
        : {
            campaign: { enabled: true, progress: 0, progressLabel: "1" },
            confirmationEmail: { enabled: false, progress: 0, progressLabel: "" },
            audience: { enabled: true, progress: 0, progressLabel: "2" },
          };

    return {
      msg: "DirectEmailMarketing",

      recipientGridData: [],
      recipientIds: [],

      loading: false,
      campaign: {},
      campaignId: null,
      demEntryVisible: false,

      campaignEmailTemplate: null,
      campaignEmailPreview: "",

      /** Campaign default data */
      verifiedEmailIdentities: [],
      verifiedDomainIdentities: [],
      domainName: "",
      domainUser: "",
      name: "",
      email: "",
      subject: "",
      subjectDisabled: true,

      /** Sent Campaign data */
      campaignGridColumns: [
        {
          prop: "refresh",
          minWidth: "50",
          maxWidth: "50",
          width: "50",
        },
        {
          prop: "finalSubject",
          label: "Subject",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "total",
          label: "Total",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "queued",
          label: "Queued",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "rejected",
          label: "Rejected",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "send",
          label: "Sent",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "delivered",
          label: "Delivered",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "opened",
          label: "Opened",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "clicked",
          label: "Clicked",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "failed",
          label: "Failed",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "sentAt",
          label: "Sent at",
          minWidth: "150",
          sortable: true,
        },
      ],
      refreshingPastCampaigns: false,

      setMaxAcquaintanceOnJoin: false,
      setAcquaintanceInfoOnJoin: false,

      confirmationEmailTabs: [
        { key: "CONFIRMATION_EMAIL_ENABLED" },
        { key: "CONFIRMATION_EMAIL_DISABLED" },
      ],
      confirmationEmailTab: "",
      confirmationEmailTemplate: null,
      confirmationEmailPreview: "",
      confirmationEmailSavedListener: null,
      confirmationEmailId: "",
      confirmationEmailSenderName: "",
      confirmationEmailSenderEmail: "",
      confirmationEmailDomainUser: "",
      confirmationEmailDomainName: "",
      confirmationEmailSubject: "",
      confirmationEmailSubjectDisabled: true,

      loadingEffectshow: false,
      loadingMessage: "",
      recipientTableColumns: [
        { key: "name", label: "Name" },
        { key: "surname", label: "Surname" },
        { key: "email", label: "Email" },
        { key: "deliveryStatus", label: "Campaign Status" },
        { key: "status", label: "Status" },
      ],
      currentRecipientPage: 0,
      currentCampaignPage: 0,

      /** Campaign stats */
      updatingCampaign: false,
      hasChanges: false,
      campaignUpdatedSubscription: null,
      emailSavedListener: null,

      //audience management
      audienceSize: 0,
      customFieldFilter: { question: "", answer: "", type: "", source: "" },
      initialFilter: { question: "", answer: "", type: "", source: "" },
      initialOption: "CUSTOM",
      audienceOption: "CUSTOM",
      audienceOptionFilter: undefined,
      audienceOptions: [
        {
          label: "_selectPending",
          value: "MARKETING_PENDING",
        },
        {
          label: "_selectConfirmed",
          value: "MARKETING_CONFIRMED",
        },
        {
          label: "_selectAllMarketingGuests",
          value: "ALL_MARKETING_GUESTS",
        },
        {
          label: "_selectCustom",
          value: "CUSTOM",
        },
        {
          label: "_selectCustomField",
          value: "CUSTOM_FIELD",
          canExpandable: true,
        },
        {
          label: "_selectNotSend",
          value: "NOT_SEND",
        },
        {
          label: "_selectNotSendConfirmed",
          value: "NOT_SEND_CONFIRMED",
        },
        {
          label: "_selectNotRead",
          value: "NOT_READ",
        },
      ],

      columns: [
        {
          prop: "memberName",
          label: "Name",
          minWidth: "150",
          sortable: true,
        },
        {
          prop: "memberSurname",
          label: "Surname",
          minWidth: "150",
          sortable: true,
        },
        {
          prop: "memberEmail",
          label: "Primary Email",
          minWidth: "250",
          sortable: true,
        },
        {
          prop: "deliveryStatus",
          label: "Campaign",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: "200",
          sortable: true,
        },
      ],
      deliveryStatusIcons: {
        SEND: ["far", "paper-plane"],
        DELIVERED: ["far", "envelope"],
        OPENED: ["far", "envelope-open"],
        CLICKED: ["fas", "envelope-open-text"],
        BOUNCED: ["fas", "times"],
        REJECTED: ["fas", "times"],
        COMPLAINED: ["fas", "times"],
      },
      statusIcons: {
        NULL: "times",
        PENDING: "exclamation",
        CONFIRMED: "thumbs-up",
        CHECK_IN: "check",
        DECLINE: "times",
        WILL_BE_BACK: "undo",
        CHECKED_OUT: "sign-out-alt",
      },
      statusStyle: {
        NULL: { color: "#DC143C" },
        PENDING: { color: "#555555" },
        CONFIRMED: { color: "#2980b9" },
        CHECK_IN: { color: "#0057BB" },
        DECLINE: { color: "#DC143C" },
        WILL_BE_BACK: { color: "#9b59b6" },
        CHECKED_OUT: { color: "#1abc9c" },
      },
      statusDescription: {
        NULL: "-",
        PENDING: "pending",
        CONFIRMED: "confirmed",
        CHECK_IN: "check-in",
        DECLINE: "declined",
        WILL_BE_BACK: "will be back",
        CHECKED_OUT: "check-out",
      },
      statusOptions: [
        { value: "PENDING", label: "pending" },
        { value: "CONFIRMED", label: "confirmed" },
        { value: "CHECK_IN", label: "check-in" },
        { value: "DECLINE", label: "declined" },
        { value: "WILL_BE_BACK", label: "will be back" },
        { value: "CHECKED_OUT", label: "check-out" },
      ],
      editEmail: false,
      editConfirmationEmail: false,
      steps,
      filtersVisible: false,
    };
  },
  computed: {
    ...mapGetters(["identity", "defaultEmail"]),
    isPrivate() {
      return this.event.genre === "PRIVATE";
    },
  },
  watch: {
    email: function () {
      this.onChanges();
    },
    event: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal && newVal.timeSlotEnabled) {
          const tempArray = this.audienceOptions;
          let found = false;
          tempArray.forEach((val) => {
            if (val.value === "TIME_SLOT") {
              found = true;
            }
          });
          if (!found) {
            tempArray.push({
              label: "_selectTimeSlot",
              value: "TIME_SLOT",
              canExpandable: true,
            });
            this.audienceOptions = [...tempArray];
          }
        }
      },
    },
  },
  beforeDestroy() {
    try {
      this.unsubscribeFromUpdates();
      if (this.emailSavedListener) {
        this.emailSavedListener.remove();
        this.emailSavedListener = null;
      }
      if (this.confirmationEmailSavedListener) {
        this.confirmationEmailSavedListener.remove();
        this.confirmationEmailSavedListener = null;
      }
      this.unsubscribeFromPreviewClicks();
      this.unsubscribeFromConfirmationPreviewClicks();
    } catch {}
  },
  created() {},
  mounted() {
    this.emailSavedListener = this.$root.bus.addListener("campaign.emailSaved", (e) => {
      this.campaignEmailTemplate = e;
      let temp = e.bodyHtml;
      temp = temp.replaceAll("\n", "");
      temp = temp.replaceAll("\r", "");
      temp = temp.replaceAll("\t", "");
      this.campaignEmailPreview = temp;
      this.subscribeToCampaignPreviewClicks();
      this.subject = e.subject;
      this.subjectDisabled = false;
      this.autoSaveCampaign(this, { emailTemplateId: e.id, eventId: this.eventId });
      this.updateProgress();
    });
    this.confirmationEmailSavedListener = this.$root.bus.addListener("campaign.confirmationEmailSaved", (e) => {
      this.confirmationEmailTemplate = e;
      let temp = e.bodyHtml;
      temp = temp.replaceAll("\n", "");
      temp = temp.replaceAll("\r", "");
      temp = temp.replaceAll("\t", "");
      this.confirmationEmailPreview = temp;
      this.subscribeToConfirmationPreviewClicks();
      this.confirmationEmailSubject = e.subject;
      this.confirmationEmailSubjectDisabled = false;
      this.autoSaveConfirmationEmail(this, {
        emailTemplateId: e.id,
        eventId: this.eventId,
        campaignId: this.campaignId,
      });
      this.updateProgress();
    });

    if (this.identity === "EMAIL") {
      this.getMyVerifiedEmailIdentities();
    } else if (this.identity === "DOMAIN") {
      this.getMyVerifiedDomainIdentities();
    }
    this.loadDraftCampaign();
  },
  methods: {
    subscribeToCampaignPreviewClicks() {
      const context = this;
      const iFrameId = "#emailPreviewFrame";
      const height = "500px";
      $(iFrameId).contents().find("body").css({ height });
      $(iFrameId).css({ height });
      $(iFrameId)
        .contents()
        .find("body")
        .on("mouseup", (event) => {
          context.loadTemplateBuilder(context.campaignEmailTemplate);
        });
      $(document).ready(() => {
        $(iFrameId).on("load", function () {
          const contents = $(this).contents();
          $(this).css({ height });
          $(contents).find("body").css({ height });
          $(contents)
            .find("body")
            .on("mouseup", (event) => {
              context.loadTemplateBuilder(context.campaignEmailTemplate);
            });
        });
      });
    },
    subscribeToConfirmationPreviewClicks() {
      const context = this;
      const iFrameId = "#confirmationEmailPreviewFrame";
      const height = "500px";
      $(iFrameId).contents().find("body").css({ height });
      $(iFrameId).css({ height });
      $(iFrameId)
        .contents()
        .find("body")
        .on("mouseup", (event) => {
          context.loadTemplateBuilder(context.confirmationEmailTemplate, true);
        });
      $(document).ready(() => {
        $(iFrameId).on("load", function () {
          const contents = $(this).contents();
          $(this).css({ height });
          $(contents).find("body").css({ height });
          $(contents)
            .find("body")
            .on("mouseup", (event) => {
              context.loadTemplateBuilder(context.confirmationEmailTemplate, true);
            });
        });
      });
    },
    unsubscribeFromConfirmationPreviewClicks() {
      const iFrameId = "#confirmationEmailPreviewFrame";
      $(iFrameId)
        .contents()
        .find("body")
        .off("mouseup", (event) => {});
    },
    unsubscribeFromPreviewClicks() {
      const iFrameId = "#emailPreviewFrame";
      $(iFrameId)
        .contents()
        .find("body")
        .off("mouseup", (event) => {});
    },
    // UI helpers
    onFilterChanged(filter) {
      this.customFieldFilter = filter;
      if (
        (this.audienceOption === "CUSTOM_FIELD" || this.audienceOption === "TIME_SLOT") &&
        filter &&
        filter.question &&
        filter.type
      ) {
        this.audienceOptionFilter = {
          customFieldFilter: { ...filter },
        };
      } else {
        this.audienceOptionFilter = undefined;
      }
      this.$refs.table.getTableData();
      this.onChanges();
    },
    onAudienceSizeChanged(size) {
      if (this.audienceOption !== "CUSTOM") {
        this.audienceSize = size;
      }
      this.onChanges();
    },
    onAudienceOptionChanged(option) {
      this.audienceOption = option;
      this.audienceOptionFilter = undefined;
      this.customFieldFilter = { question: "", answer: "", type: "", source: "" };
      this.$refs.table.getTableData();
      if (option === "CUSTOM") {
        this.audienceSize = 0;
      }
      this.onChanges();
    },
    onDomainInputChanged(confirmationEmail = false) {
      if (confirmationEmail) {
        this.confirmationEmailSenderEmail =
          this.confirmationEmailDomainUser && this.confirmationEmailDomainName
            ? `${this.confirmationEmailDomainUser}@${this.confirmationEmailDomainName}`
            : "";
        this.autoSaveConfirmationEmail(this, {
          eventId: this.eventId,
          campaignId: this.campaignId,
          senderEmail: this.confirmationEmailSenderEmail,
        });
      } else {
        this.email =
          this.domainUser && this.domainName ? `${this.domainUser}@${this.domainName}` : "";
        this.autoSaveCampaign(this, { eventId: this.eventId, senderEmail: this.email });
      }
      this.onChanges(false);
    },
    updateSelectedRecipients(data) {
      this.recipientIds = data;
      this.onChanges();
    },
    // Bee builder
    loadTemplateBuilder(email, isConfirmationEmail = false) {
      const customFieldTags = customFieldMapToMergeTagArray(this.event.allCombinedCustomFields);
      this.$root.bus.emit("modalEmailBuilderView", {
        eventId: this.eventId,
        email,
        customFieldTags,
        isPrivateEvent: this.isPrivate,
        isConfirmationEmail,
        isCampaign: true,
      });
    },
    loadTemplates(isConfirmationEmail = false) {
      const customFieldTags = customFieldMapToMergeTagArray(this.event.allCombinedCustomFields);
      this.$root.bus.emit("modalTemplateEmailBuilderView", {
        eventId: this.eventId,
        customFieldTags,
        isPrivateEvent: this.isPrivate,
        isConfirmationEmail,
        isCampaign: true,
      });
    },
    // Payload verifications
    canSendCampaign() {
      const context = this;
      const inputEmail = "";
      if (context.identity === "EMAIL") {
        if (
          context.verifiedEmailIdentities &&
          context.verifiedEmailIdentities.length &&
          context.verifiedEmailIdentities.indexOf(context.email) === -1
        ) {
          context.$root.bus.emit(
            "simpleToast",
            this.$root.strings.directEmailMarketing._warning_email
          );
          return false;
        }
        if (
          (!context.verifiedEmailIdentities || context.verifiedEmailIdentities.length < 1) &&
          context.email !== context.defaultEmail
        ) {
          context.$root.bus.emit(
            "simpleToast",
            this.$root.strings.directEmailMarketing._warning_email_2
          );
          return false;
        }
      } else if (context.identity === "DOMAIN") {
        if (
          !context.domainUser ||
          context.domainUser === "" ||
          !context.domainName ||
          context.domainName === "" ||
          context.verifiedDomainIdentities.indexOf(context.domainName) === -1
        ) {
          context.$root.bus.emit(
            "simpleToast",
            this.$root.strings.directEmailMarketing._warning_domain
          );
          return false;
        }
      } else {
        if (context.email !== context.defaultEmail) {
          context.$root.bus.emit(
            "simpleToast",
            this.$root.strings.directEmailMarketing._warning_default_email
          );
          return false;
        }
      }
      if (context.campaignEmailTemplate === null) {
        context.$root.bus.emit(
          "simpleToast",
          this.$root.strings.directEmailMarketing._warning_template
        );
        return false;
      }
      if (context.audienceSize < 1) {
        context.$root.bus.emit(
          "simpleToast",
          this.$root.strings.directEmailMarketing._warning_recipient
        );
        return false;
      }
      return this.areTokensValid(context.campaignEmailPreview || "");
    },
    areTokensValid(htmlFile) {
      const tokens = findMustacheTokens(htmlFile, true);
      if (tokens && tokens.length) {
        const allTokens = getAllTokens(this.event.allCombinedCustomFields);
        for (const tokenFromHtml of tokens) {
          let exists = false;
          allTokens.map((tokenFromSettings) => {
            if (tokenFromSettings === tokenFromHtml) {
              exists = true;
            }
          });
          if (!exists) {
            this.$root.bus.emit(
              "simpleToast",
              `The token ${tokenFromHtml} is not found in your custom fields but your email has it. Please remove it before sending campaign`
            );
            return false;
          }
        }
      }
      return true;
    },

    // Api calls
    subscribeToUpdates() {
      const context = this;
      this.campaignUpdatedSubscription = this.$apollo
        .subscribe({
          query: SUBSCRIBE_TO_SEND_CAMPAIGN,
          variables: { eventId: context.eventId },
        })
        .subscribe(({ data, errors }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.sendCampaignTask) {
            const payload = data.sendCampaignTask;
            if (payload.status === "NULL") {
              // initial case
            } else {
              const tempArray = context.$refs.campaignsTable.tableData;
              for (const [
                index,
                existingItem,
              ] of context.$refs.campaignsTable.tableData.entries()) {
                if (existingItem.id === payload.campaign.id) {
                  tempArray[index] = { ...tempArray[index], ...payload.campaign };
                }
              }
              context.$refs.campaignsTable.tableData = [...tempArray];
            }
          }
        });
    },
    unsubscribeFromUpdates() {
      try {
        this.campaignUpdatedSubscription.unsubscribe();
        this.campaignUpdatedSubscription = null;
      } catch (e) {}
    },
    async updateCampaign() {
      const context = this;
      if (
        (this.audienceOption === "CUSTOM_FIELD" || this.audienceOption === "TIME_SLOT") &&
        (!this.customFieldFilter ||
          !this.customFieldFilter.question ||
          !this.customFieldFilter.type)
      ) {
        context.$root.bus.emit(
          "simpleToast",
          this.$root.strings.customFieldFilter._custom_field_filter_not_valid
        );
        return;
      }
      context.loadingMessage = "Updating Campaign";
      context.loadingEffectshow = true;
      await this.$apollo
        .mutate({
          mutation: UPDATE_RECIPIENT_LIST,
          variables: {
            eventId: context.eventId,
            campaign: context.campaignId,
            audienceOption: this.audienceOption,
            guestList: this.audienceOption === "CUSTOM" ? context.recipientIds : [],
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
          }
          if (response.data && response.data.updateDraftEmailCommunication) {
            // const payload = response.data.updateDraftEmailCommunication;
            // console.log("update comm result " + JSON.stringify(payload));
          }
        })
        .catch((error) => {
          console.log(error);
        });
      let inputEmail = "";
      if (context.identity === "EMAIL") {
        inputEmail = context.email && context.email !== "" ? context.email : context.defaultEmail;
      } else if (context.identity === "DOMAIN") {
        if (
          !context.domainUser ||
          context.domainUser === "" ||
          !context.domainName ||
          context.domainName === ""
        ) {
          context.$root.bus.emit("simpleToast", "Email Address is not valid");
          context.loadingEffectshow = false;
          return;
        }
        inputEmail = context.domainUser + "@" + context.domainName;
      } else {
        inputEmail = context.defaultEmail;
      }
      let filter = undefined;
      if (
        (this.audienceOption === "CUSTOM_FIELD" || this.audienceOption === "TIME_SLOT") &&
        this.customFieldFilter &&
        this.customFieldFilter.question &&
        this.customFieldFilter.type
      ) {
        filter = {
          customFieldFilter: { ...this.customFieldFilter },
        };
      }
      return await this.$apollo
        .mutate({
          mutation: UPDATE_CAMPAIGN,
          variables: {
            campaignId: context.campaignId,
            campaignInput: {
              senderName: context.name,
              senderEmail: inputEmail,
              setMaxAcquaintanceOnJoin: context.setMaxAcquaintanceOnJoin,
              setAcquaintanceInfoOnJoin: context.setAcquaintanceInfoOnJoin,
              eventId: context.eventId,
              audienceOption: context.audienceOption,
              audienceOptionFilter: filter,
            },
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.updateCampaign) {
            const payload = response.data.updateCampaign;
            context.campaignId = payload.id;
            context.loadingEffectshow = false;
            this.onChanges(false);
          }
        })
        .catch((error) => {
          console.log(error);
          context.loadingEffectshow = false;
        });
    },
    autoSaveCampaign: debounce((context, updates, feedback = false) => {
      context.$apollo
        .mutate({
          mutation: UPDATE_CAMPAIGN,
          variables: {
            campaignId: context.campaignId,
            campaignInput: updates,
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.updateCampaign) {
            context.processCampaignPayload(data.updateCampaign);
            if (feedback) {
              context.$root.bus.emit("simpleToast", "Campaign Updated");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500),
    autoSaveConfirmationEmail: debounce((context, updates, feedback = false) => {
      if (context.confirmationEmailId) {
        context.$apollo
          .mutate({
            mutation: UPDATE_EMAIL_CONFIRMATION,
            variables: {
              emailConfirmationId: context.confirmationEmailId,
              emailConfirmationInput: updates,
            },
          })
          .then(({ errors, data }) => {
            if (errors) {
              console.log(errors);
              context.$root.bus.emit("simpleToast", context.$root.strings.attachments._uploadfailed);
              return;
            }
            if (data && data.updateEmailConfirmation) {
              context.processConfirmationEmail(data.updateEmailConfirmation);
              if (feedback) {
                context.$root.bus.emit(
                  "simpleToast",
                  context.$root.strings.attachments._uploadsuccess
                );
              }
            }
          })
          .catch((error) => {
            console.log(error);
            context.$root.bus.emit("simpleToast", context.$root.strings.attachments._uploadfailed);
          });
      }
    }, 500),
    async toggleConfirmationEmail(tab) {
      this.confirmationEmailTab = tab;
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_CAMPAIGN,
          variables: {
            campaignId: this.campaignId,
            campaignInput: {
              eventId: this.eventId,
              confirmationEmailOption: tab,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log("error " + errors);
          } else {
            if (tab === "CONFIRMATION_EMAIL_ENABLED") {
              return this.createEmailConfirmation();
            } else {
              return this.deleteEmailConfirmation();
            }
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async createEmailConfirmation() {
      return this.$apollo
        .mutate({
          mutation: CREATE_EMAIL_CONFIRMATION,
          variables: {
            emailConfirmationInput: {
              senderEmail: this.email,
              senderName: this.name,
              eventId: this.eventId,
              campaignId: this.campaignId,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
            return;
          }
          if (data && data.createEmailConfirmation) {
            this.processConfirmationEmail(data.createEmailConfirmation);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async deleteEmailConfirmation() {
      if (!this.confirmationEmailId) {
        this.updateProgress();
        return;
      }
      return this.$apollo
        .mutate({
          mutation: DELETE_EMAIL_CONFIRMATION,
          variables: {
            emailConfirmationId: this.confirmationEmailId,
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
            return;
          }
          if (data && data.deleteEmailConfirmation) {
            this.confirmationEmailId = null;
            this.confirmationEmailPreview = "";
            this.confirmationEmailTemplate = null;
            this.confirmationEmailSenderName = "";
            this.confirmationEmailSenderEmail = "";
            this.confirmationEmailDomainName = "";
            this.confirmationEmailDomainUser = "";
            this.confirmationEmailSubject = "";
            this.confirmationEmailSubjectDisabled = true;
            this.loadingEffectshow = false;
            this.unsubscribeFromConfirmationPreviewClicks();
            this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadsuccess);
            this.updateProgress();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async createCampaign() {
      const context = this;
      context.loadingMessage = "Creating Campaign";
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: CREATE_CAMPAIGN,
          variables: {
            campaignInput: {
              eventId: context.eventId,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.createCampaign) {
            this.processCampaignPayload(data.createCampaign);
            context.demEntryVisible = true;
            context.loadingEffectshow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendCampaign() {
      const context = this;
      if (this.canSendCampaign()) {
        const h = this.$createElement;
        this.$msgbox({
          message: h("p", null, [
            h("span", null, this.$root.strings.directEmailMarketing._warnBeforeSend),
            h("br"),
            h("br"),
            h("span", null, this.$root.strings.directEmailMarketing._recap_name),
            h("strong", null, this.name),
            h("br"),
            h("span", null, this.$root.strings.directEmailMarketing._recap_email),
            h("strong", null, this.email),
            h("br"),
            h("span", null, this.$root.strings.directEmailMarketing._recap_subject),
            h("strong", null, this.subject),
            h("br"),
            h("span", null, this.$root.strings.directEmailMarketing._recap_total_recipient),
            h("strong", null, this.audienceSize),
          ]),
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = this.$root.strings.directEmailMarketing._sending_campaign;
              context.$apollo
                .mutate({
                  mutation: SEND_CAMPAIGN,
                  variables: {
                    campaignId: context.campaignId,
                  },
                })
                .then((response) => {
                  if (response.errors) {
                    console.log(response.errors);
                    instance.confirmButtonLoading = false;
                    done();
                    return;
                  }
                  if (response.data && response.data.sendCampaign) {
                    this.$refs.campaignsTable.getTableData();
                    context.demEntryVisible = false;
                    context.demEntryVisible = false;
                    context.campaignId = null;
                    context.subjectDisabled = true;
                    context.campaignEmailTemplate = null;
                    context.campaignEmailPreview = null;
                    context.unsubscribeFromPreviewClicks();
                    context.setMaxAcquaintanceOnJoin = false;
                    context.setAcquaintanceInfoOnJoin = false;
                    context.confirmationEmailTab = "";
                    context.confirmationEmailTemplate = null;
                    context.confirmationEmailPreview = null;
                    context.confirmationEmailSubject = "";
                    context.confirmationEmailSubjectDisabled = true;
                    context.unsubscribeFromConfirmationPreviewClicks();
                    context.loadDraftCampaign();
                    this.onChanges(false);
                  }
                  instance.confirmButtonLoading = false;
                  done();
                  context.$root.bus.emit(
                    "simpleToast",
                    context.$root.strings.directEmailMarketing._campaign_sent
                  );
                })
                .catch((error) => {
                  console.log(error);
                  instance.confirmButtonLoading = false;
                  done();
                });
            } else {
              done();
            }
          },
        })
          .then((action) => {})
          .catch((_) => {});
      }
    },
    async updateCampaignTableStatistics() {
      if (!this.refreshingPastCampaigns) {
        this.refreshingPastCampaigns = true;
        return this.$apollo
          .mutate({
            mutation: UPDATE_PAST_CAMPAIGNS,
            variables: { eventId: this.eventId },
          })
          .then(({ errors, data }) => {
            if (errors) {
              console.log("error " + errors);
            } else {
              if (data.updatePastCampaigns === "SUCCESS") {
                this.refreshingPastCampaigns = false;
                this.$refs.campaignsTable.getTableData();
              }
            }
          })
          .catch((error) => {
            console.error("[updateCampaignTableStatistics]", error.message);
          });
      }
    },
    processConfirmationEmail(confirmationEmail) {
      if (confirmationEmail) {
        this.confirmationEmailId = confirmationEmail.id;
        this.confirmationEmailSenderName = confirmationEmail.senderName;

        if (confirmationEmail.senderEmail && confirmationEmail.senderEmail.includes("@")) {
          if (this.identity === "DOMAIN") {
            const parts = confirmationEmail.senderEmail.split("@");
            this.confirmationEmailDomainUser = parts[0];
            this.confirmationEmailDomainName = parts[1];
            this.confirmationEmailSenderEmail = confirmationEmail.senderEmail;
          } else if (this.identity === "EMAIL") {
            this.confirmationEmailSenderEmail = confirmationEmail.senderEmail;
          } else {
            this.confirmationEmailSenderEmail = this.defaultEmail;
          }
        }
        if (confirmationEmail.emailTemplate) {
          this.confirmationEmailTemplate = confirmationEmail.emailTemplate;
          this.confirmationEmailSubject = confirmationEmail.emailTemplate.subject;
          let temp = confirmationEmail.emailTemplate.bodyHtml;
          temp = temp.replaceAll("\n", "");
          temp = temp.replaceAll("\r", "");
          temp = temp.replaceAll("\t", "");
          this.confirmationEmailPreview = temp;
          this.confirmationEmailSubjectDisabled = false;

          this.subscribeToConfirmationPreviewClicks();
        } else {
          this.confirmationEmailSubjectDisabled = true;
        }
      }
      this.updateProgress();
    },
    processCampaignPayload(campaign) {
      this.campaignId = campaign.id;
      this.initialOption = campaign.audienceOption;
      this.audienceOption = campaign.audienceOption;
      if (campaign.audienceOptionFilter) {
        const { customFieldFilter } = campaign.audienceOptionFilter;
        if (customFieldFilter && customFieldFilter.question && customFieldFilter.type) {
          this.initialFilter = customFieldFilter;
          this.customFieldFilter = customFieldFilter;
        }
      }
      this.name = campaign.senderName;
      if (campaign.senderEmail && campaign.senderEmail.includes("@")) {
        if (this.identity === "DOMAIN") {
          const parts = campaign.senderEmail.split("@");
          this.domainUser = parts[0];
          this.domainName = parts[1];
          this.email = campaign.senderEmail;
        } else if (this.identity === "EMAIL") {
          this.email = campaign.senderEmail;
        } else {
          this.email = this.defaultEmail;
        }
      }
      this.recipientIds = campaign.selectedGuestIdList;

      this.setMaxAcquaintanceOnJoin = campaign.setMaxAcquaintanceOnJoin;
      this.setAcquaintanceInfoOnJoin = campaign.setAcquaintanceInfoOnJoin;
      if (campaign.emailTemplate) {
        this.campaignEmailTemplate = campaign.emailTemplate;
        this.subject = campaign.emailTemplate.subject;
        this.subjectDisabled = false;
        let temp = this.campaignEmailTemplate.bodyHtml;
        temp = temp.replaceAll("\n", "");
        temp = temp.replaceAll("\r", "");
        temp = temp.replaceAll("\t", "");
        this.campaignEmailPreview = temp;
        this.subscribeToCampaignPreviewClicks();
      } else {
        this.subjectDisabled = true;
      }
      this.confirmationEmailTab = campaign.confirmationEmailOption;
      this.processConfirmationEmail(campaign.emailConfirmation);
      this.updateProgress();
    },
    async loadDraftCampaign() {
      const context = this;
      context.loadingMessage = "Loading Campaign";
      context.loading = true;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_DRAFT_CAMPAIGN,
          variables: { eventId: context.eventId },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.loading = false;
            return;
          }
          if (response.data && response.data.Event) {
            const payload = response.data.Event;
            if (payload.draftCampaign) {
              this.processCampaignPayload(payload.draftCampaign);
              context.demEntryVisible = true;
            }
            context.loading = false;
            this.updateProgress();
          }
        })
        .catch((error) => {
          console.log(error);
          context.loading = false;
        });
    },
    async getPastCampaigns({ filter, page, query, sortParams, pageSize }) {
      const context = this;
      this.unsubscribeFromUpdates();
      const input = {};
      if (sortParams && Object.keys(sortParams).length) {
        for (const cField of Object.keys(sortParams)) {
          input["sortField"] = cField;
          input["sortOrder"] = sortParams[cField];
          break;
        }
      } else {
        input["sortField"] = "sentAt";
        input["sortOrder"] = -1;
      }
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_PAST_CAMPAIGNS,
          variables: {
            eventId: this.eventId,
            filter: filter && filter,
            pageInfo: {
              pageSize: pageSize,
              currentPage: page,
              ...input,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            context.updatingCampaign = false;
            return;
          }
          if (data && data.getCampaigns) {
            const payload = data.getCampaigns;
            this.subscribeToUpdates();
            return {
              data: payload.data,
              total: payload.total,
            };
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    warnAndDeleteCampaign() {
      const context = this;
      this.$confirm(this.$root.strings.directEmailMarketing._warnBeforeDelete)
        .then((_) => {
          context.loadingEffectshow = true;
          return this.$apollo
            .mutate({
              mutation: DELETE_CAMPAIGN,
              variables: {
                campaignId: context.campaignId,
              },
            })
            .then((response) => {
              if (response.errors) {
                console.log(response.errors);
                return;
              }
              if (response.data && response.data.deleteCampaign) {
                context.campaignId = null;
                context.demEntryVisible = false;
                context.loadingEffectshow = false;
                context.subjectDisabled = true;
                context.campaignEmailTemplate = null;
                context.campaignEmailPreview = null;
                context.unsubscribeFromPreviewClicks();
                context.unsubscribeFromConfirmationPreviewClicks();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((_) => {});
    },
    async getMyVerifiedEmailIdentities() {
      const context = this;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_MY_VERIFIED_IDENTITIES,
          variables: { identityType: "EMAIL" },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.myMarketingIdentities) {
            const payload = response.data.myMarketingIdentities;
            const newArray = [];
            for (const identityObject of payload) {
              newArray.push(identityObject.identity);
            }
            context.verifiedEmailIdentities = newArray;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getMyVerifiedDomainIdentities() {
      const context = this;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_MY_VERIFIED_IDENTITIES,
          variables: { identityType: "DOMAIN" },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.myMarketingIdentities) {
            const payload = response.data.myMarketingIdentities;
            const newArray = [];
            for (const identityObject of payload) {
              newArray.push(identityObject.identity);
            }
            context.verifiedDomainIdentities = newArray;
            if (newArray.length) {
              context.domainName = newArray[0];
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getData({ filter, page, query, sortParams, pageSize }) {
      const context = this;
      const input = {};
      if (sortParams && Object.keys(sortParams).length) {
        for (const cField of Object.keys(sortParams)) {
          input["sortField"] = cField;
          input["sortOrder"] = sortParams[cField];
          break;
        }
      } else {
        input["sortField"] = "status";
        input["sortOrder"] = -1;
      }
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GUEST_LIST,
          variables: {
            eventId: this.eventId,
            filter: { hasEmail: true, q: filter ? filter : undefined },
            pageInfo: {
              pageSize: pageSize,
              currentPage: page,
              sortField: input["sortField"],
              sortOrder: input["sortOrder"],
            },
            customFields: [],
            audienceOption: this.audienceOption,
            audienceOptionFilter: this.audienceOptionFilter,
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.$router.push("/").catch((_) => {});
            return;
          }
          if (response.data && response.data.guests) {
            const payload = response.data.guests;
            if (context.audienceOption !== "CUSTOM") {
              context.audienceSize = payload.total;
            }
            return {
              data: payload.data,
              total: payload.total,
            };
          }
        })
        .catch((error) => {
          // context.$router.push("/").catch(_ => {});
          console.log(error);
        });
    },
    onSelectionChange(selectedRows) {
      this.audienceSize = selectedRows.length;
      this.recipientIds = selectedRows;
      this.onChanges();
    },
    async onCancelCampaign(row) {
      const context = this;
      return this.$apollo
        .mutate({
          mutation: UPDATE_CAMPAIGN,
          variables: {
            campaignId: row.id,
            campaignInput: {
              eventId: context.eventId,
              isCancelled: true,
              status: "CANCELLED",
            },
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.updateCampaign) {
          }
        })
        .catch((error) => {
          console.log(error);
          context.loadingEffectshow = false;
        });
    },
    onBulkAction(action, selectedRows) {
      if (action === "clearSelection") {
        this.$refs.table.$refs.elTable.clearSelection();
      }
    },
    onChanges(hasChanges = true) {
      this.hasChanges = hasChanges;
      this.updateProgress();
    },
    updateProgress() {
      const currentProgress = {
        campaign: 0,
        confirmationEmail: 0,
        audience: 0,
      };

      if (this.demEntryVisible) {
        currentProgress.campaign += 20;
      }
      if (this.name) {
        currentProgress.campaign += 20;
      }
      if (this.email) {
        currentProgress.campaign += 20;
      }
      if (this.subject) {
        currentProgress.campaign += 20;
      }
      if (this.campaignEmailPreview) {
        currentProgress.campaign += 20;
      }

      if (this.steps.confirmationEmail.enabled) {
        if (this.confirmationEmailTab === "CONFIRMATION_EMAIL_DISABLED") {
          currentProgress.confirmationEmail += 100;
        } else if (this.confirmationEmailTab === "CONFIRMATION_EMAIL_ENABLED") {
          currentProgress.confirmationEmail += 20;
          if (this.confirmationEmailSenderName) {
            currentProgress.confirmationEmail += 20;
          }
          if (this.confirmationEmailSenderEmail) {
            currentProgress.confirmationEmail += 20;
          }
          if (this.confirmationEmailSubject) {
            currentProgress.confirmationEmail += 20;
          }
          if (this.confirmationEmailPreview) {
            currentProgress.confirmationEmail += 20;
          }
        }
      }
      if (this.audienceSize > 0) {
        currentProgress.audience = 100;
      }
      this.steps.campaign.progress = currentProgress.campaign;
      this.steps.confirmationEmail.progress = currentProgress.confirmationEmail;
      this.steps.audience.progress = currentProgress.audience;
    },
    goToSettings() {
      this.$emit("changeTab", "settings");
    },
  },
};
</script>
