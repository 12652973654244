<template>
  <PublicLayout>
    <div class="container">
      <div class="row wow fadeIn">
        <div class="col-md-7">
          <div class="heading heading-md heading-uppercase heading-hover">
            <br />
            <h2 id="title" class="heading-title text-white">
              {{ statusTitle }}
            </h2>

            <hr class="hr-width-1 hr-5x border-main" />
            <p id="description" class="heading-tescription lead text-gray-2">
              {{ statusSubtitle }}<br/>
              {{ joinEventExtraInfo }}
            </p>
          </div>
          <div v-if="showForm" class="row">
            <!-- Begin contact form -->
            <div class="margin-top-80 text-white wow fadeIn" data-wow-delay="0.3s">
              <div class="row" style="margin: 2px">
                <div class="col-lg-8">
                  <div class="form-group">
                    <label for="email" class="sr-only">
                      {{ $root.strings.verifyAccount._email_label }}
                    </label>
                    <input
                      id="email"
                      v-model.trim="email"
                      type="email"
                      class="form-control no-rounded"
                      name="email"
                      value=""
                      placeholder="Email Address"
                      required
                      @keyup.enter.prevent="resendLink"
                    />
                  </div>
                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-warning active btn-block btn-lg"
                      @click.prevent="resendLink"
                      @keyup.enter.prevent="resendLink"
                    >
                      {{ $root.strings.verifyAccount._send_link }}
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </PublicLayout>
</template>

<script >
import PublicLayout from "../layouts/PublicLayout";

export default {
  components: {
    PublicLayout,
  },
  props: {
    t: { type: String, required: true },
    type: { type: String, required: true },
    tid: { type: String, required: true },
  },
  data() {
    return {
      loading: true,
      statusTitle: "Loading...",
      statusSubtitle: "",
      joinEventExtraInfo: "",
      email: "",
      showForm: false,
    };
  },
  metaInfo: {
    title: "Password - Penguinpass",
    bodyAttrs: {
      class: "login",
    },
  },
  computed: {
    token() {
      return this.$route.query.t || "";
    },
    userType() {
      return this.$route.query.type ? this.$route.query.type : "ORGANIZER";
    },
    teamId() {
      return this.$route.query.tid ? this.$route.query.tid : "";
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener(
      "setLanguage",
      () => {
        context.string = context.$root.strings;
      },
      this
    );
  },
  mounted() {
    this.activateUser();
  },
  methods: {
    activateOrganizer() {
      this.$root.axios
        .post("/auth/organizer/verify", {
          token: this.token,
        })
        .then((value) => {
          if (value.data.status === "SUCCESS") {
            this.$router.push("/login");
          } else {
          }
        })
        .catch((reason) => {
          console.error(reason.data.message);
        });
    },
    activateGuest() {
      this.$root.axios
        .post(
          "/api/guest/verification/verify",
          {
            token: this.token,
          },
          { headers: { "x-team-id": this.teamId } }
        )
        .then((value) => {
          if (value.data.status === "SUCCESS") {
            this.statusTitle = this.$root.strings.verifyAccount._verification_success_title;
            this.statusSubtitle = this.$root.strings.verifyAccount._verification_success_guest;

            if (value.data.data && value.data.data.joinStatus === "OK") {
              this.joinEventExtraInfo = this.$root.strings.verifyAccount._joinOk;
            } else if (value.data.data && value.data.data.joinStatus !== "OK") {
              this.joinEventExtraInfo = this.$root.strings.verifyAccount._joinErr;
            }

          } else {
            this.statusTitle = this.$root.strings.verifyAccount._verification_failed_title;
            this.statusSubtitle = this.$root.strings.verifyAccount._verification_expired;
            this.showForm = true;
          }
        })
        .catch((reason) => {
          console.error(reason.data.message);
        });
    },
    resendLinkGuest() {
      this.$root.axios
        .post(
          "/api/guest/verification/send-link",
          {
            email: this.email,
          },
          { headers: { "x-team-id": this.teamId } }
        )
        .then((value) => {
          if (value.data.status === "SUCCESS") {
            this.showForm = false;
            this.statusTitle = this.$root.strings.verifyAccount._resend_success_title;
            this.statusSubtitle = this.$root.strings.verifyAccount._resend_success_subtitle;
          } else {
            this.statusTitle = this.$root.strings.verifyAccount._verification_failed_title;
            this.statusSubtitle = this.$root.strings.verifyAccount._verification_failed;
          }
        })
        .catch((reason) => {
          console.error(reason.data.message);
        });
    },
    activateUser() {
      if (this.userType === "ORGANIZER") {
        // this.activateOrganizer();
      } else if (this.userType === "g") {
        this.activateGuest();
      }
    },
    resendLink() {
      if (this.userType === "ORGANIZER") {
        // this.resendLinkOrganizer();
      } else if (this.userType === "g") {
        this.resendLinkGuest();
      }
    },
  },
};
</script>
<style >
</style>
