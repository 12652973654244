var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal fade in",
        staticStyle: { display: "block" },
        attrs: {
          id: "modalTemplatePageBuilder",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "myModalLabel",
          "aria-hidden": "false",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-hidden": "true" },
                  on: { click: _vm.fadeout },
                },
                [_vm._v("×")]
              ),
              _vm._v(" "),
              _c(
                "h4",
                { staticClass: "modal-title", attrs: { id: "myModalLabel" } },
                [
                  _vm._v(
                    "\n            PENGUINPASS PAGE BUILDER - SELECT TEMPLATE FROM THE OTHER EVENTS\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast-list-template",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _c(
                      "div",
                      { attrs: { id: "template-list" } },
                      [
                        !_vm.templateLoaded
                          ? _c(
                              "div",
                              { staticClass: "loader-container" },
                              [
                                _c("PulseLoader", {
                                  attrs: { color: "#0057B8" },
                                }),
                              ],
                              1
                            )
                          : _c("PageBuilderSelectTemplates", {
                              attrs: { "template-list": _vm.templateList },
                              on: { templateSelected: _vm.templateSelected },
                            }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "modalBackgroundColor",
            on: {
              click: function ($event) {
                false && _vm.fadeout
              },
            },
          },
          [_vm._v(" ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }