<template>
  <div class="create-policy-component">
    <div class="component-subtitle">
      {{ string.createPolicy._subtitle }}
    </div>
    <div class="component-form">
      <div class="form-section name">
        <!-- <label for="policyNameInput">{{string.createPolicy._policyName}}</label> -->
        <input
                aria-label=""
          id="policyNameInput"
          v-model="policyName"
          type="text"
          name="policy_name"
          :placeholder="string.createPolicy._policyName"
          class="form-control"
          style="width:100%;" />
      </div>
      <div class="form-section-editor editor">
        <label>{{ string.createPolicy._policyText }} *</label>
        <div id="editor-container">
          <quill-editor
            ref="messageEditor"
            v-model="policyContent"
            class="quillEditor_style"
            :config="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onEditorChange($event)">
          </quill-editor>
        </div>
      </div>
      <div class="form-section submit">
        <input
          type="button"
          class="save"
          name="submit"
          :value="string.createPolicy._createPolicy"
          @click="createPolicy">
      </div>
    </div>
  </div>
</template>

<script>

import { quillEditor } from 'vue-quill-editor';
import {
  CREATE_TEAM_PRIVACY_POLICY
} from "@/graphql";

export default {
    name: 'CreatePolicy',
    components:{
        quillEditor
    },
    props: {
        event: Object,
    },
    data (){
        return {
            submitBtnLabel: 'crea',
            policyName : '',
            policyNamePlaceHolder : 'Privacy Policy Name *',
            policyContent : '',
            string : {},
            editorOption: {
                placeholder: 'content test',
                modules: {
                    toolbar: [
                        [ 'bold', 'italic', 'underline', 'link' ]        // toggled buttons
                        ,[ { 'header': 1 }, { 'header': 2 } ]
                        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ,[ { 'align': [] } ],
                        [{ 'color': [] }, { 'background': [] }],
                        // See https://quilljs.com/docs/quickstart/
                    ]
                }
            },
        };
    },
    created () {
        const context = this;

        this.string = this.$root.strings;

        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    methods: {
        onEditorBlur (editor) {
            //console.log('editor blur!', "TEST")
        },
        onEditorFocus (editor) {
            //console.log('editor focus!', editor)
        },
        onEditorReady (editor) {
            //console.log('editor ready!', editor)
        },
        onEditorChange ({ editor, html, text }) {
            this.confirmationEmailMessage = html;
        },
        createPolicy: function () {

            const context = this;
            const eventId = this.event.id;
            const policy = {
                'name' : this.policyName,
                'content' : this.policyContent,
                'mandatory' : false
            };

            if(!this.isDataValid()) {
                context.$root.bus.emit('simpleToast', context.string.newEvent._mandatoryFields);
                return false;
            }

            this.$apollo.mutate({
                mutation: CREATE_TEAM_PRIVACY_POLICY,
                variables: {
                    createTeamPrivacyPolicyInput: {
                        name: policy.name.trim(),
                        text: policy.content
                    }
                }
              })
              .then((response) => {
                context.$emit('created', response);
                context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._updateDone);

            }).catch((error) => {
                context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);
            });
        },
        isDataValid: function() {
            let isValid = false;
            const context = this;

            const name = context.policyName.trim();
            const content = context.policyContent.trim();

            if(name && content && name.length > 0 && content.length > 0) {
                isValid = true;
            }

            return isValid;
        }
    }
};
</script>

<style scoped>
    @import '~quill/dist/quill.core.css';
    @import '~quill/dist/quill.snow.css';
    @import '~quill/dist/quill.bubble.css';

    .create-policy-component label{
        vertical-align: top;
        width: 130px;
    }

    .create-policy-component #editor-container{
        display: inline-block;
        border: 2px solid #000;
        width: 80%;
    }

    .create-policy-component .form-section.editor,.create-policy-component .form-section.name{
        width: 100%;
        margin-top: 20px;
    }

    .create-policy-component .form-section button{
        margin: 0 !important;
    }

    .create-policy-component .form-section.submit{
        text-align: center;
        margin-top: 20px;
    }

    .create-policy-component .form-section input.btn {
        color: white;
        text-transform: uppercase;
    }

    .create-policy-component {
        width: 100%;
    }

    .create-policy-component .ql-toolbar .ql-formats button{
        height: auto !important;
    }

    .create-policy-component #policyNameInput{
        width: 80%;
    }

    .create-policy-component .form-section-editor.editor {
        margin-top: 20px;
    }

    .ql-formats button {
        margin: 0 !important;
        border: 0;
    }

    .quill-editor >>> .ql-container.ql-snow .ql-editor {
        color: black;
        background-color: white;
    }

</style>
<style>
    div.ql-toolbar.ql-snow {
        background-color: #0057B8 !important;
    }

</style>
