var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overview" }, [
    _c(
      "div",
      { staticClass: "text-center overview-cell" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.string.overview._total))]),
        _vm._v(" "),
        _vm.loading
          ? _c("ClipLoader", {
              attrs: { loading: _vm.loading, color: "#0057B8", size: "15px" },
            })
          : _c("h4", [_vm._v("\n      " + _vm._s(_vm.stats.TOTAL) + "\n    ")]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center overview-cell" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.string.overview._pending))]),
        _vm._v(" "),
        _vm.loading
          ? _c("ClipLoader", {
              attrs: { loading: _vm.loading, color: "#0057B8", size: "15px" },
            })
          : _c("h4", [
              _vm._v("\n      " + _vm._s(_vm.stats.PENDING) + "\n    "),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center overview-cell" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.string.overview._confirmed))]),
        _vm._v(" "),
        _vm.loading
          ? _c("ClipLoader", {
              attrs: { loading: _vm.loading, color: "#0057B8", size: "15px" },
            })
          : _c("h4", [
              _vm._v("\n      " + _vm._s(_vm.stats.CONFIRMED) + "\n    "),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }