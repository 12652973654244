import store from "@/store";
import { subject } from "@casl/ability";
import { abilitiesPlugin, Can } from "@casl/vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faEnvelopeOpen, faPaperPlane, faMoon } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faCheck,
  faCircle,
  faCog,
  faEdit,
  faEnvelopeOpenText,
  faExclamation,
  faSignOutAlt,
  faSortDown,
  faSync,
  faThumbsUp,
  faTimes,
  faTrash,
  faFilter,
  faUndo,
  faInfo,
  faSpinner,
  faUsers, faPhone, faDownload, faPlus, faQuestionCircle, faUser, faArchive, faTachometerAlt, faCalendar, faCalendarAlt, faUserCircle, faPlayCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import dotenv from "dotenv";
import ElementUi from "element-ui";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import { EventEmitter } from "fbemitter";
import Vue from "vue";
import VueAxios from "vue-axios";
import VueExcelXlsx from "vue-excel-xlsx";
import VueHtmlToPaper from "vue-html-to-paper";
import InfiniteLoading from "vue-infinite-loading";
import App from "./App";
import { default as messages_en } from "./locale/messages_en";
import { default as messages_it } from "./locale/messages_it";
import { default as messages_fr } from "./locale/messages_fr";
import { default as messages_ar } from "./locale/messages_ar";
import router from "./router";
import { whoAmI } from "./services";
import userdata from "./userdata";
import { createProvider } from "./vue-apollo";
import { VERSION, APP_NAME, CALENDAR_CONFIG } from './constants';
import ICS from 'vue-ics';

library.add(
  faExclamation,
  faThumbsUp,
  faCheck,
  faTimes,
  faUndo,
  faSignOutAlt,
  faCircle,
  faEnvelopeOpenText,
  faPaperPlane,
  faEnvelope,
  faEnvelopeOpen,
  faSync,
  faCog,
  faUsers,
  faBars,
  faSortDown,
  faEdit,
  faTrash,
  faMoon,faPhone, faDownload, faPlus, faQuestionCircle, faUser, faArchive, faTachometerAlt, faCalendar, faCalendarAlt, faUserCircle,faFilter, faPlayCircle,
  faInfo,
  faSpinner
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("vue-excel-xlsx", VueExcelXlsx);
Vue.component("Can", Can);
Vue.use(ICS, CALENDAR_CONFIG);
Vue.use(abilitiesPlugin, store.getters.ability);
Vue.use(InfiniteLoading, {
  slots: {
    noMore: ""
  }
});
Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.BASE_URL || "http://localhost:5000";
const token = store.state.token;
axios.defaults.headers.common["Authorization"] = token ? `JWT ${token}` : "";
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.defaults.validateStatus = function() {
  return true;
};

userdata.web_site_root = process.env.BASE_URL || "http://localhost:5000";
userdata.csfr_token = "";
userdata.client_security_code = "";
userdata.user_name = "";
userdata.user_email = "";
userdata.user_id = "";
userdata.user_permissions =
  "updatePrivate,canCreateNONFreeEvent,canPromoteEvent,canHaveExternalJoin,togglePrivacyPolicy_Rule,toggleExternalPurchaseURLEnabled_Rule,toggleImportEvent_Rule";
userdata.user_lang = "en";
userdata.organizer_id = "150";
userdata.user_social_links = "[]";
userdata.organizer_logo = "";
userdata.organizer_contacts = "";
userdata.organizer_apps_store_links =
  "{&quot;applestore_url&quot;:&quot;&quot;,&quot;googlestore_url&quot;:&quot;&quot;}";
userdata.organizer_custom = "1";
userdata.app_settings.app_name = "default";

const printingOptions = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: ["https://media.penguinpass.it/static/css/print-report-cdn.css"]
};

dotenv.config();
Vue.use(VueAxios, axios);
Vue.use(ElementUi, { locale });
Vue.use(VueExcelXlsx);
Vue.use(VueHtmlToPaper, printingOptions);

// Register a global custom directive called `v-focus`
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus();
  }
});
const emitter = new EventEmitter();

const toastOpen = function() {
  this.toast.open();
};
const toastErrorOpen = function() {
  this.toastError.open();
};

const dateFormat = "YYYY-MM-DD HH:mm";

Date.prototype.format = function() {
  const add0 = t => (t < 10 ? "0" + t.toString() : t.toString());
  const year = this.getFullYear().toString();
  const month = add0(this.getMonth() + 1);
  const date = add0(this.getDate());
  const hours = add0(this.getHours());
  const minutes = add0(this.getMinutes());
  return date + "/" + month + "/" + year + " " + hours + ":" + minutes;
};
Date.prototype.formatHours = function() {
  const add0 = t => (t < 10 ? "0" + t.toString() : t.toString());
  const hours = add0(this.getHours());
  const minutes = add0(this.getMinutes());
  return hours + ":" + minutes;
};
String.prototype.formatEnum = function() {
  let result = "";
  const arrayVersion = this.toLowerCase().split("_");
  for (const [index, part] of arrayVersion.entries()) {
    if (index !== arrayVersion.length - 1) {
      result += part.charAt(0).toUpperCase() + part.slice(1) + " ";
    } else {
      result += part.charAt(0).toUpperCase() + part.slice(1);
    }
  }
  return result;
};
String.prototype.testEmail = function() {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  return emailRegex.test(this);
};

const apolloProvider = createProvider(router);
const bootstrap = async () => {
  await whoAmI(store, apolloProvider);

  return new Vue({
    apolloProvider,
    data: {
      currentLanguage: "it",
      currentRoute: window.location.pathname,
      bus: "",
      toast: "",
      toastError: "",
      dateFormat: "",
      mainBackgroundClass: "",
      userPermissions: [],
      strings: {},
      // Temp
      messages_it: {},
      messages_en: {},
      messages_fr: {},
      messages_ar: {}
    },
    created() {
      this.bus = emitter;
      this.toast = toastOpen;
      this.toastError = toastErrorOpen;
      this.dateFormat = dateFormat;
      this.userPermissions = [];

      this.messages_it = messages_it;
      this.messages_en = messages_en;
      this.messages_fr = messages_fr;
      this.messages_ar = messages_ar;

      switch(this.$store.getters.language) {
        case "en":
          this.strings = messages_en;
          break;
        case "fr":
          this.strings = messages_fr;
          break;
        case "ar":
          this.strings = messages_ar;
          break;
        case "it":
        default:
          this.strings = messages_it;
      }
      console.log(APP_NAME,VERSION);
    },
    methods: {
      setSubject(typeName, obj) {
        return subject(typeName, obj);
      }
    },
    render: h => h(App),
    router,
    store
  }).$mount("#app");
};

bootstrap();
