<template>
  <div class="default-layout">
    <div id="virtualroom" class="virtualroom-component">
      <div v-if="loadingEffectshow" class="higherPosition">
        <transition name="component-fade-fast" mode="out-in" appear>
          <LoadingCover
            v-if="loadingEffectshow"
            :loading-cover-msg="string.virtualRoomSettings._loadingMessage"
            />
        </transition>
      </div>
      <div class="vroom accordion">
        <h4 class="form-section-title second accordion-toggle">
          {{ string.virtualRoomSettings._title }}
        </h4>
        <div class="accordion-content" ref="accordionContent">
          <div v-if="!isExhibitor" class="vroomToggleSelection">
            <div class="row">
              <div class="col-sm-6">
                <small class="col-sm-12 text-left">
                Virtual venue
                </small>
                <input
                  v-model="virtualVenue"
                  type="text"
                  placeHolder="Custom Virtual Room URL"
                  class="form-control"
                  />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <label class="control control-checkbox">
                  {{ string.virtualRoomSettings._customizeroom }}
                  <input
                    id="checkbox"
                    v-model="showEditor"
                    type="checkbox"
                    @change="toggleCustomizeVirtualRoom"
                    />
                  <div class="control_indicator"></div>
                </label>
              </div>
              <div class="col-sm-6">
                <label class="control control-checkbox" v-if="pages && pages[selectedPageIndex] && features && features.pageAuthRequired">
                  {{ string.virtualRoomSettings._authneeded }}
                  <input
                    id="checkbox"
                    v-model="pages[selectedPageIndex].authNeeded"
                    type="checkbox"
                    @change="toggleAuthNeeded"
                    />
                  <div class="control_indicator"></div>
                </label>
                <label class="control control-checkbox" v-if="pages && pages[selectedPageIndex] && features && features.pageModeratedChat">
                  {{ string.virtualRoomSettings._moderatedchat }}
                  <input
                    id="checkbox"
                    v-model="pages[selectedPageIndex].roomConfig.chat.moderated"
                    type="checkbox"
                    @change="toggleModeratedChat"
                    />
                  <div class="control_indicator"></div>
                </label>
              </div>
            </div>
          </div>
          <div class="vroom-message-intent">
            <div v-if="showEditor && !isExhibitor" class="email-settings">
              <div class="row">
                <div class="col-sm-6">
                  <a
                    class="btn"
                    @click="LoadTemplateBuilder">
                  {{ string.virtualRoomSettings._createroom }}
                  </a>
                </div>
                <div class="col-sm-6">
                  <a
                    class="btn"
                    @click="LoadTemplateSelector">
                  {{ string.virtualRoomSettings._selectTemplate }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <small class="text-left has-subtitle">
                  {{ string.virtualRoomSettings._roomscheduling }}
                  </small>
                  <small class="text-left subtitle">
                  {{ string.virtualRoomSettings._roomschedulingsubtitle }}
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <el-date-picker
                    id="startDate"
                    v-model="virtualRoomStartDate"
                    type="date"
                    class="form-control"
                    align="left"
                    size="large"
                    placeholder="Start date"
                    format="dd/MM/yyyy"
                    value-format="dd/MM/yyyy"
                    v-on:change="onDateSelected">
                  </el-date-picker>
                </div>
                <div class="col-sm-3">
                  <el-time-select
                    v-model="virtualRoomStartTime"
                    class="form-control"
                    popper-class="virtual-room-timeselect"
                    :picker-options="{
                    start: '08:30',
                    step: '00:05',
                    end: '19:30'
                    }"
                    placeholder="Start time">
                  </el-time-select>
                </div>
                <div class="col-sm-3">
                  <el-select
                    v-model="virtualRoomDuration"
                    class="form-control"
                    placeholder="Duration"
                    >
                    <el-option
                      v-for="item in durationList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-sm-3">
                  <el-select
                    v-model="virtualRoomTimezone"
                    filterable
                    class="form-control"
                    placeholder="Timezone"
                    >
                    <el-option
                      v-for="(item, index) in timezonesList"
                      :key="'timezoneIndx' + index"
                      :label="item.text"
                      :value="'timezoneIndx' + index">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <small class="text-left">
                  OR
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <a
                    class="btn"
                    @click="instantMeeting">
                  {{ string.virtualRoomSettings._instantmeeting }}
                  </a>
                </div>
                <div class="col-sm-6">
                  <a
                    class="btn"
                    @click="openFileSelection">
                  {{ string.virtualRoomSettings._uploadCover }}
                  </a>
                  <input id="fileinput" ref="fileinputVROOM" type="file" accept="image/*" @change="onFileChange" />
                </div>
              </div>
            </div>
            <div class="data-grid-container" v-if="allocatedVirtualRooms.length > 0">
              <div class="data-grid-table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Locked</th>
                      <th>VirtualRoom</th>
                      <th>Moderator PIN</th>
                      <th>Presenter PIN</th>
                      <th>Room Status</th>
                      <th>Call Status</th>
                      <th v-if="isExhibitor">Go to room</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="allocatedRoom in allocatedVirtualRooms">
                      <td>
                        <el-button icon="el-icon-lock" circle v-if="allocatedRoom.locked" @click="toggleRoomLock(allocatedRoom.id, false)"></el-button>
                        <el-button icon="el-icon-unlock" circle v-if="!allocatedRoom.locked" @click="toggleRoomLock(allocatedRoom.id, true)"></el-button>
                      </td>
                      <td>{{videoRoomsById[allocatedRoom.videoRoomId].customSessionId}} ({{videoRoomsById[allocatedRoom.videoRoomId].type}})</td>
                      <td>{{allocatedRoom.moderatorPin}}</td>
                      <td>{{(videoRoomsById[allocatedRoom.videoRoomId].type !== 'MEETING' || videoRoomsById[allocatedRoom.videoRoomId].type !== 'VIRTUALSHOWROOM') ? allocatedRoom.publisherPin : ''}}</td>
                      <td>{{videoRoomStatusEnumType[allocatedRoom.status]}}</td>
                      <td>
                        <span v-if="isExhibitor && videoCallStatusEnumTypeArray[allocatedRoom.callStatus]">{{videoCallStatusEnumTypeArray[allocatedRoom.callStatus]}} {{allocatedRoom.callStatus}}</span>
                        <el-select v-if="!isExhibitor" v-model="allocatedRoom.callStatus" placeholder="Select" @change="updateRoomCallStatus(allocatedRoom.id, allocatedRoom.callStatus)" :disabled="allocatedRoom.callStatus === 'ENDED' || allocatedRoom.status === 'TO_BE_CREATED'">
                          <el-option
                            v-for="item in videoCallStatusEnumTypeArray"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key">
                          </el-option>
                        </el-select>
                      </td>
                      <td v-if="isExhibitor">
                        <a :href='"https://virtual.events.penguinpass.it/vr3/index.html#/" + allocatedRoom.id' target="_blank">Open room</a>
                      </td>
                    </tr>
                    <tr v-if="!isExhibitor">
                      <td colspan="6">
                        <a :href="virtualRoomLink" target="_blank">{{virtualRoomLink}}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="data-grid-container" v-if="allocatedVirtualRooms.length === 0 && virtualRoomLink">
              <div class="data-grid-table-container">
                <table>
                  <thead>
                    <tr>
                      <th>Virtual Room URL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!isExhibitor">
                      <td>
                        <a :href="virtualRoomLink" target="_blank">{{virtualRoomLink}}</a>
                      </td>
                    </tr>
                    <template v-if="isExhibitor">
                      <tr v-for="allocatedVirtualRoom in allocatedVirtualRooms">
                        <td>
                          <a :href='"https://virtual.events.penguinpass.it/vr3/index.html#/" + allocatedVirtualRoom.id' target="_blank">{{allocatedVirtualRoom.sessionId}}</a>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" v-if="isExhibitor">
              <div class="col-sm-6">
                <small class="col-sm-12 text-left">
                Contact Email
                </small>
                <input
                  v-model="contactEmail"
                  type="text"
                  placeHolder="Contact Email"
                  class="form-control"
                  />
                  <a
                    class="btn"
                    @click="saveContactEmail">
                  {{ string.virtualRoomSettings._savesettings }}
                  </a>
                  <a
                    class="btn"
                    @click="downloadFairboothLogs">
                  {{ string.virtualRoomSettings._downloadlogs }}
                  </a>
              </div>
            </div>
          </div>
          <div v-if="!isExhibitor" class="row">
            <div class="col-sm-6">
              <a
                class="btn"
                @click="saveVirtualRoomSettings">
              {{ string.virtualRoomSettings._savesettings }}
              </a>
            </div>
            <div class="col-sm-6">
              <a  v-if="pages[selectedPageIndex] && pages[selectedPageIndex].pageStatus === 'DRAFT'"
                class="btn"
                @click="toggleEnableVirtualRoom">
              {{ string.virtualRoomSettings._publishpage }}
              </a>
              <a  v-if="pages[selectedPageIndex] && pages[selectedPageIndex].pageStatus === 'PUBLISHED'"
                class="btn"
                @click="toggleEnableVirtualRoom">
              {{ string.virtualRoomSettings._unpublishpage }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
    .control {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;
        padding-top: 3px;
        cursor: pointer;
    }
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .control_indicator {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background: #e6e6e6;
        border: 0px solid #000000;
    }
    .control-radio .control_indicator {
        border-radius: 0;
    }

    .control:hover input ~ .control_indicator,
    .control input:focus ~ .control_indicator {
        background: #cccccc;
    }

    .control input:checked ~ .control_indicator {
        background: #000;
    }
    .control:hover input:not([disabled]):checked ~ .control_indicator,
    .control input:checked:focus ~ .control_indicator {
        background: #000;
    }
    .control input:disabled ~ .control_indicator {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        box-sizing: unset;
        content: '';
        position: absolute;
        display: none;
    }
    .control input:checked ~ .control_indicator:after {
        display: block;
    }
    .control-checkbox .control_indicator:after {
        left: 8px;
        top: 4px;
        width: 3px;
        height: 8px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .control-checkbox input:disabled ~ .control_indicator:after {
        border-color: #7b7b7b;
    }
</style>
<style scoped>
    .default-layout a.btn{
        width: 100%;
        font-size: 16px;
        color: #ffffff;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.3);
        padding: 15px;
        margin-bottom: 20px;
    }
    .textLabel{
        padding: 20px 20px;
        font-size: 14px;
    }
    .higherPosition{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    #loadingCover {
        z-index: 1;
        color: #fff !important;
        background-color: rgba(255, 255, 255, 0.6);
    }
    .vroom-message{
        padding-bottom: 47px;
    }
    .default-layout .form-section-title {
        border-bottom: solid 1px #dddddd;
        padding:20px 0;
        color: #ffffff;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    .default-layout small{
        width:100%;
        font-size:15px;
        padding-bottom:20px;
        margin-top: 20px;
        text-transform:uppercase;
    }
    .default-layout small.has-subtitle {
        padding-bottom: 0px;
    }
    .default-layout small.subtitle {
        margin-top: 0;
        font-size: 11px;
    }
    input, select {
        border-radius: 0;
        border: 1px solid #333333;
    }
    .form-section-title small {
        font-size: 12px;
        display: block;
        line-height: 1.5;
        margin-top: 5px;
        color: #e8e8e8;
    }
    .form-section select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: transparent url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right center;
        border: 1px solid #333;
        background-position: 98%;
    }
    .form-control {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #fff;
        border-radius: 0 !important;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-top: 20px;
        margin-bottom: 20px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }
    .second{
        margin-top: 0px !important;
        margin-bottom: 20px !important;
    }
    #page-link-advanced {
        background: transparent !important;
        /*text-decoration: underline !important;*/
        text-decoration-color: #0057B8 !important;
        color: #0057B8 !important;
        text-transform: lowercase !important;
        margin: 5px;
    }
    .form-section-title{
        padding-bottom: 5px;
        margin-top: 20px;
        color: #0057B8;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    .form-section-title small{
        font-size: 12px;
        display: block;
        line-height: 1.5;
        margin-top: 5px;
        color: #e8e8e8;
    }
    .subject-section-title{
        color: #0057B8;
    }
    .subject-section-title small{
        font-size: 12px;
        display: block;
        line-height: 1.5;
        margin-top: 10px;
        color: #e8e8e8;
    }
    .default-email {
        text-align: start;
        font-size: medium;
    }
    .subject-field{
        text-align: start;
        border: solid 1px #fff;
        padding: 0px 5px;
    }
    .noMargin {
        margin: 0 !important;
    }
    .data-grid-container{
        overflow: auto;
    }
    .data-grid-table-container{
        border: 2px solid #0057B8;
        border-right: 0;
        border-bottom:0;
        max-height: 630px;
        overflow-y: auto;
        overflow-x: auto;
    }
    table {
        position: relative;
        border: 2px solid #0057B8;
        border-radius: 3px;
        background-color: #000000;
        width: 100%;
    }
    tr:hover td {
        background-color: rgba(255, 255, 255, 0.4);
        color: #000;
    }
    th {
        background-color: #0057B8;
        color: rgba(255,255,255,0.66);
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    td {
        position: relative;
        max-width: 200px !important;
        text-overflow: ellipsis !important;
        overflow: hidden;
    }
    th, td {
        position: relative;
        min-width: 120px !important;
        padding: 10px 20px;
        /*padding: 7px 15px;*/
        text-align: center;
        color: #fff;
    }
    td{
        background-color: transparent;
    }
</style>
<script>
import moment from 'moment';
import timezones from 'timezones.json';
import LoadingCover from './LoadingCover.vue';
import {
    GET_EVENT_VIRTUAL_ROOM_CODE,
    EVENT_VIDEO_ROOM_PAGES,
    GET_VIDEO_ROOMS,
    EVENT_ALLOCATED_VIDEO_ROOMS,
    ALLOCATE_VIDEO_ROOM,
    UPDATE_EVENT,
    UPDATE_VIDEO_ROOM_PAGE,
    UPDATE_ALLOCATED_VIDEO_ROOM,
    DELETE_VIDEO_ROOM_ALLOCATION
} from '@/graphql';
import {
    VideoRoomFeatureEnumType,
    VideoRoomStatusEnumType,
    VideoCallStatusEnumType
} from "./../types/enums";

import { mapGetters } from "vuex";

export default {
  components: {
    LoadingCover
  },
  props: {
    eventId: String,
    event: Object
  },
  data (){
    return {
      componentName: 'virtualroom',
      string: {},

      //cover upload
      fileToSelect: true,
      fileList: "",
      fileListName: "",

      /** Api Data **/
      // in_appmessage_selected : '',
      // in_appmessage_selected_0 : 0,
      // in_appmessage_selected_1:  0,
      loadingEffectshow : false,
      showEditor: false,
      enableVRoom: false,
      defaultEmail: 'no-reply@penguinpass.it',
      videoRooms: [],
      videoRoomsById: {},
      allocatedVirtualRooms: [],
      pages: [],
      selectedPageIndex: 0,
      virtualVenue: '',
      contactEmail: '',
      virtualRoomLink: null,
      virtualRoomStartDate: '',
      virtualRoomStartTime: '',
      virtualRoomDuration: '',
      virtualRoomTimezone: '',
      durationList: [],
      timezonesList: [],
      videoRoomStatusEnumType: {},
      videoCallStatusEnumType: {},
      videoRoomStatusEnumTypeArray: [],
      videoCallStatusEnumTypeArray: [],
      hasActiveRooms: false,
      roomConfig: {
        chat: {
          moderated: false
        },
        email: false
      }
    };
  },
  computed: {
    ...mapGetters(["features", "user", "isExhibitor"])
  },
  watch: {
    event : function (){
      this.enableVRoom = this.event.virtualRoomEnabled
      this.showEditor = this.event.virtualRoomEditorOpened
      this.virtualVenue = this.event.virtualVenue
      this.contactEmail = this.event.contactEmail
      this.loadEventVirtualRoomPages()
      this.loadMyVirtualRooms()
      this.getEventAllocatedVirtualRooms()
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;

    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });

    this.confirmPageSavedListener = this.$root.bus.addListener("confirmPageSaved", template => {
      if(typeof context.pages[context.selectedPageIndex] === 'undefined') [
        context.pages.push({
          json: '',
          html: ''
        })
      ]
      context.pages[context.selectedPageIndex].json = template.jsoncode;
      context.pages[context.selectedPageIndex].html = template.htmlcode;
      context.pages[context.selectedPageIndex].id = template.virtualRoomPageId;
    });

    for(let d = 1; d <= 5; d++) {
      context.durationList.push({value: d, label: d + ' hour' + (d>1?'s':'')})
    }

    context.timezonesList = timezones;
    context.videoRoomStatusEnumType = VideoRoomStatusEnumType;
    context.videoCallStatusEnumType = VideoCallStatusEnumType;

    for(let k in VideoRoomStatusEnumType) {
      context.videoRoomStatusEnumTypeArray.push({
        key: k,
        value: VideoRoomStatusEnumType[k]
      })
    }

    for(let k in VideoCallStatusEnumType) {
      context.videoCallStatusEnumTypeArray.push({
        key: k,
        value: VideoCallStatusEnumType[k]
      })
    }

  },
  methods: {
    getAllRooms(html) {
      let attributes = []
      let matchResult = html.match(/{penguinroom [\w]+=".+?"}/g);
      if(matchResult !== null) {
        matchResult.forEach(function(attribute) {
          attribute = attribute.match(/([\w-]+)="(.+?)"/);
          if(attribute !== null) { attributes.push({roomId: attribute[2]}); }
        });
      }
      return attributes
    },
    LoadTemplateBuilder: function (){
      this.$root.bus.emit('modalPageBuilderView', this.eventId, this.pages[this.selectedPageIndex]?this.pages[this.selectedPageIndex].id:null, this.videoRooms, this.pages[this.selectedPageIndex]?JSON.parse(this.pages[this.selectedPageIndex].json):null);
    },
    LoadTemplateSelector: function (){
      this.$root.bus.emit('modalTemplatePageBuilderView', this.eventId, this.pages[this.selectedPageIndex]?this.pages[this.selectedPageIndex].id:null, this.videoRooms);
    },
    toggleCustomizeVirtualRoom: function () {
      const context = this;
      context.loadingEffectshow = true;
      return this.$root.$apollo.mutate({
        mutation: UPDATE_EVENT,
        variables: {
          eventId: context.eventId,
          updateEventInput: {
            virtualRoomEditorOpened: context.showEditor,
            virtualVenue: context.virtualVenue,
            contactEmail: context.contactEmail
          }
        }
      }).then(value => {
        if(value.errors) {
          console.log('error ' + value.errors);
          context.loadingEffectshow = false;
        } else {
          //success
          context.loadingEffectshow = false;
        }
      }).catch(reason => {
        context.loadingEffectshow = false;
        context.$root.bus.emit('simpleToast', context.string.attachments._uploadfailed );
      });
    },
    toggleAuthNeeded: function () {
      this.updateRoomPage({
          authNeeded: this.pages[this.selectedPageIndex].authNeeded
        })
    },
    toggleModeratedChat: function () {
      this.updateRoomPage({
          roomConfig: this.pages[this.selectedPageIndex].roomConfig
        })
    },
    toggleEnableVirtualRoom: function () {
      const context = this;

      if(this.pages[this.selectedPageIndex] && this.pages[this.selectedPageIndex].pageStatus === "DRAFT") {
        this.updateRoomPage({
          pageStatus: "PUBLISHED"
        })
      } else {
        this.updateRoomPage({
          pageStatus: "DRAFT"
        })
      }
    },
    saveContactEmail: function () {
      const context = this;

      this.$root.$apollo.mutate({
        mutation: UPDATE_EVENT,
        variables: {
          eventId: context.eventId,
          updateEventInput: {
            contactEmail: context.contactEmail
          }
        }
      }).then(value => {
        if(value.errors) {
          console.log('error ' + value.errors);
          context.loadingEffectshow = false;
        } else {
          //success
          context.loadingEffectshow = false;
          context.$root.bus.emit('simpleToast', context.string.virtualRoomSettings._uploadsuccess );
        }
      }).catch(reason => {
        context.loadingEffectshow = false;
        context.$root.bus.emit('simpleToast', context.string.attachments._uploadfailed );
      });
    },
    saveVirtualRoomSettings: function () {
      const context = this;

      this.$root.$apollo.mutate({
        mutation: UPDATE_EVENT,
        variables: {
          eventId: context.eventId,
          updateEventInput: {
            virtualRoomEditorOpened: context.showEditor,
            virtualVenue: context.virtualVenue,
            contactEmail: context.contactEmail
          }
        }
      }).then(value => {
        if(value.errors) {
          console.log('error ' + value.errors);
          context.loadingEffectshow = false;
        } else {
          //success
          context.loadingEffectshow = false;
        }
      }).catch(reason => {
        context.loadingEffectshow = false;
        context.$root.bus.emit('simpleToast', context.string.attachments._uploadfailed );
      });

      if(this.hasActiveRooms) {
        this.$root.bus.emit('simpleToast', 'Please stop the current active rooms first.' );
        return
      }

      context.loadingEffectshow = true;

      //validation: check scheduled time if not from past
      if(moment().isAfter(moment(this.virtualRoomStartDate + ' ' + this.virtualRoomStartTime, 'DD/MM/YYYY HH:mm'))) {
        context.$root.bus.emit('simpleToast', 'Start date and time must be in the future.' );
        context.loadingEffectshow = false;
        return;
      }

      //check for penguin rooms and allocate if needed
      const dragNdroppedRooms = this.pages[this.selectedPageIndex]?this.getAllRooms(this.pages[this.selectedPageIndex].html):[];
      (async function() {
        let allocatedRoomsIds = []
        if(context.allocatedVirtualRooms.length > 0) {
          for(let allocationRoom of context.allocatedVirtualRooms) {
            await context.deleteVideoRoomAllocation(allocationRoom.id);
          }
        }
        for(let dragNdroppedRoom of dragNdroppedRooms) {
          let newAllocation = await context.createVideoRoomAllocation(dragNdroppedRoom.roomId);
          if(newAllocation.data && newAllocation.data.createVideoRoomAllocation && newAllocation.data.createVideoRoomAllocation.id) {
            allocatedRoomsIds.push(newAllocation.data.createVideoRoomAllocation.id)
          }
        }
        await context.updateRoomPage({
          allocatedVideoRoomIds: allocatedRoomsIds
        })
        if(allocatedRoomsIds.length > 0) { //wait for room opening then refresh the status
          const waitingForRefresh = moment(context.virtualRoomStartDate + ' ' + context.virtualRoomStartTime, 'DD/MM/YYYY HH:mm').diff(moment())
          if(waitingForRefresh > 0) {
            setTimeout(async () => {
              await context.getEventAllocatedVirtualRooms()
            }, waitingForRefresh);
          }
        }
        await context.getEventAllocatedVirtualRooms()
      })()
    },
    createVideoRoomAllocation: function (roomId) {
      const context = this;
      const utcOffset = this.timezonesList[this.virtualRoomTimezone.replace("timezoneIndx", "")].offset;

      context.loadingEffectshow = true;

      return this.$root.$apollo.mutate({
        mutation: ALLOCATE_VIDEO_ROOM,
        variables: {
          input: {
            videoRoomId: roomId,
            eventId: context.eventId,
            publisherPin: ''+Math.floor(Math.random() * 100000),
            moderatorPin: ''+Math.floor(Math.random() * 100000),
            lifespanStart: moment(this.virtualRoomStartDate + ' ' + this.virtualRoomStartTime, 'DD/MM/YYYY HH:mm').utcOffset(utcOffset).toISOString(),
            lifespanEnd: moment(this.virtualRoomStartDate + ' ' + this.virtualRoomStartTime, 'DD/MM/YYYY HH:mm').utcOffset(utcOffset).add(this.virtualRoomDuration, 'hours').toISOString(),
            utcOffset
          }
        }
      }).then(value => {
        if(value.errors) {
          console.log('error ' + value.errors);
          context.loadingEffectshow = false;
        } else {
          //success
          context.loadingEffectshow = false;
          // this.$root.bus.emit('simpleToast', context.string.virtualRoomSettings._uploadsuccess );
        }
        return value;
      }).catch(reason => {
        context.loadingEffectshow = false;
        context.$root.bus.emit('simpleToast', context.string.attachments._uploadfailed );
      });
    },
    deleteVideoRoomAllocation: function (allocationId) {
      const context = this;
      context.loadingEffectshow = true;

      return this.$root.$apollo.mutate({
        mutation: DELETE_VIDEO_ROOM_ALLOCATION,
        variables: {
          id: allocationId
        }
      }).then(value => {
        if(value.errors) {
          console.log('error ' + value.errors);
          context.loadingEffectshow = false;
        } else {
          //success
          context.loadingEffectshow = false;
          // this.$root.bus.emit('simpleToast', context.string.virtualRoomSettings._uploadsuccess );
        }
      }).catch(reason => {
        console.log(reason.message);
        context.loadingEffectshow = false;
        context.$root.bus.emit('simpleToast', context.string.attachments._uploadfailed );
      });
    },
    getEventVirtualRoomCode : function () {
      const context = this;

      if(context.pages.length === 0) return;

      context.loadingEffectshow = true;

      return this.$root.$apollo.query({
        query: GET_EVENT_VIRTUAL_ROOM_CODE,
        variables: {
          eventId: context.eventId,
          videoRoomPageId: context.pages[context.selectedPageIndex].id
        }
      }).then(value => {
        if(value.errors) {
          console.log('error ' + value.errors);
          context.loadingEffectshow = false;
        } else {
          //success
          context.loadingEffectshow = false;
          if(value.data && value.data.getEventVirtualRoomCodeById && value.data.getEventVirtualRoomCodeById.code){
            context.virtualRoomLink = `${document.location.origin}/room/${value.data.getEventVirtualRoomCodeById.code}`
          }
        }
      }).catch(reason => {
        console.log(reason.message);
        context.loadingEffectshow = false;
        context.$root.bus.emit('simpleToast', context.string.attachments._uploadfailed );
      });
    },
    getEventAllocatedVirtualRooms : function () {
      const context = this;
      context.loadingEffectshow = true;
      return this.$root.$apollo.query({
        fetchPolicy: "no-cache",
        query: EVENT_ALLOCATED_VIDEO_ROOMS,
        variables: {
          eventId: context.eventId
        }
      }).then(value => {
        context.loadingEffectshow = false;
        if(value.errors) {
          console.log('error ' + value.errors);
        } else {
          //success
          if(value.data && value.data.getEventAllocatedVideoRooms && value.data.getEventAllocatedVideoRooms) {
            context.allocatedVirtualRooms = value.data.getEventAllocatedVideoRooms;
            context.allocatedVirtualRooms.forEach(allocatedVideoRoom => {
              if(allocatedVideoRoom.status === 'ACTIVE') {
                context.hasActiveRooms = true;
              }
            });
            if(context.allocatedVirtualRooms.length > 0) {
              const roomAllocation = context.allocatedVirtualRooms[0]; //took the first cuz time is the same
              const tmpStartTime = moment.utc(roomAllocation.lifespan.start, 'YYYY-MM-DD h:mm:ss')

              context.virtualRoomStartDate = moment(roomAllocation.lifespan.start, 'YYYY-MM-DD h:mm:ss').format('DD/MM/YYYY')
              context.virtualRoomStartTime = tmpStartTime.subtract(tmpStartTime.isDST()?0:1, 'hours').utcOffset(roomAllocation.utcOffset).format('HH:mm')
              context.virtualRoomDuration = moment(roomAllocation.lifespan.end, 'YYYY-MM-DD h:mm:ss').diff(moment(roomAllocation.lifespan.start, 'YYYY-MM-DD h:mm:ss'), 'hours')
              for(let i = 0; i < context.timezonesList.length; i++) {
                const timezone = context.timezonesList[i];
                if(timezone.offset === roomAllocation.utcOffset) {
                  context.virtualRoomTimezone = `timezoneIndx${i}`;
                  break;
                }
              }
              const waitingForRefresh = moment(context.virtualRoomStartDate + ' ' + context.virtualRoomStartTime, 'DD/MM/YYYY HH:mm').diff(moment())
              if(waitingForRefresh > 0) {
                setTimeout(async () => {
                  if(this.$refs.accordionContent.style.display === 'block') {
                    await context.getEventAllocatedVirtualRooms()
                  }
                }, waitingForRefresh);
              }
            }
          }
        }
      }).catch(reason => {
        console.log(reason.message);
        context.loadingEffectshow = false;
        context.$root.bus.emit('simpleToast', context.string.attachments._uploadfailed );
      });
    },
    loadEventVirtualRoomPages: function(eventId) {
      this.loadingEffectshow = true;
      const context = this;

      return this.$apollo.query({
        fetchPolicy: "no-cache",
        query: EVENT_VIDEO_ROOM_PAGES,
        variables: { eventId: this.event.id }
      }).then(response => {
        context.loadingEffectshow = false;
        if (response.errors) {
          console.log(response.errors);
        } else {
          if (response.data && response.data.getVideoRoomPages) {
            context.pages = response.data.getVideoRoomPages
            context.getEventVirtualRoomCode()
          }
        }
      }).catch(error => {
        context.loadingEffectshow = false;
        if (
          error.graphQLErrors &&
          error.graphQLErrors.length &&
          error.graphQLErrors[0].message === "PERMISSION_DENIED"
        ) {
          context.$router.push("/404").catch(error => {});
          return;
        }
        console.log(error);
      });
    },
    loadMyVirtualRooms: function() {
      this.loadingEffectshow = true;
      const context = this;
      return this.$apollo.query({
        fetchPolicy: "no-cache",
        query: GET_VIDEO_ROOMS,
        variables: {}
      }).then(response => {
        context.loadingEffectshow = false;
        if (response.errors) {
          console.log(response.errors);
        } else {
          if (response.data && response.data.getVideoRooms) {
            context.videoRooms = response.data.getVideoRooms
            context.videoRoomsById = {}
            for(let videoRoom of context.videoRooms) {
              context.videoRoomsById[videoRoom.id] = videoRoom
            }
          }
        }
      }).catch(error => {
        context.loadingEffectshow = false;
        if (
          error.graphQLErrors &&
          error.graphQLErrors.length &&
          error.graphQLErrors[0].message === "PERMISSION_DENIED"
        ) {
          context.$router.push("/404").catch(error => {});
          return;
        }
          console.log(error);
      });
    },
    onDateSelected: function () {
      if(this.virtualRoomStartDate) {
        // this.virtualRoomStartDate = new Date(this.virtualRoomStartDate).toISOString();
      }
    },
    downloadFairboothLogs: function() {
      this.$store
        .dispatch("getFairboothLogs", {
          eventId: this.event.id
        })
        .then(result => {
          if (result.status === "SUCCESS" && result.csv) {

            const byteNumbers = new Array(result.csv.length);
            for (let i = 0; i < result.csv.length; i++) {
                byteNumbers[i] = result.csv.charCodeAt(i);
            }
            const arr = new Uint8Array(byteNumbers);
            const blob = new Blob([arr], { type: 'text/csv' });
            const href = window.URL.createObjectURL(blob, { type: 'text/csv' });
            const theLink = document.createElement('a');
            theLink.href = href;
            theLink.download = 'fairbooth_logs.csv';
            document.body.appendChild(theLink);
            theLink.click();
            document.body.removeChild(theLink);
          }
        })
        .catch(error => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            this.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000
            });
          }
        });
    },
    instantMeeting: function () {
      if(this.hasActiveRooms) {
        this.$root.bus.emit('simpleToast', 'Please stop the current active rooms first.' );
        return
      }

      const   start = moment(),
              remainder = 5 - (start.minute() % 5),
              dateTime = moment(start).add(remainder, "minutes")

      this.virtualRoomStartDate = dateTime.format("DD/MM/YYYY")
      this.virtualRoomStartTime = dateTime.format("HH:mm")
      this.virtualRoomDuration = 2
      this.virtualRoomTimezone = 'timezoneIndx40'
    },
    updateRoomPage: function (updates) {
      const context = this;

      try {
        return this.$root.$apollo.mutate({
          mutation: UPDATE_VIDEO_ROOM_PAGE,
          variables: {
            videoRoomPageId: this.pages[this.selectedPageIndex].id,
            updates: {
              eventId: this.eventId,
              videoRoomType: VideoRoomFeatureEnumType[this.pages[this.selectedPageIndex].videoRoomType],
              ...updates}
          }
        }).then(value => {
          if(value.errors) {
            console.log("error " + value.errors[0])
          } else {
            context.loadEventVirtualRoomPages();
            this.$root.bus.emit('simpleToast', context.string.virtualRoomSettings._uploadsuccess );
          }
          return value;
        }).catch(reason => {
          context.templateSaving = false;
          context.$root.bus.emit("simpleToast", context.string.attendeeMaxLimit._error + error );
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    toggleRoomLock(allocationId, locked) {
      const context = this;

      try {
        return this.$root.$apollo.mutate({
          mutation: UPDATE_ALLOCATED_VIDEO_ROOM,
          variables: {
            allocatedVideoRoomId: allocationId,
            updates: {
              locked
            }
          }
        }).then(value => {
          if(value.errors) {
            console.log("error " + value.errors[0])
          } else {
            context.getEventAllocatedVirtualRooms()
            context.$root.bus.emit('simpleToast', context.string.virtualRoomSettings._uploadsuccess );
          }
          return value;
        }).catch(reason => {
          context.templateSaving = false;
          context.$root.bus.emit("simpleToast", context.string.attendeeMaxLimit._error + error );
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    updateRoomCallStatus(allocationId, callStatus) {
      const context = this;

      try {
        return this.$root.$apollo.mutate({
          mutation: UPDATE_ALLOCATED_VIDEO_ROOM,
          variables: {
            allocatedVideoRoomId: allocationId,
            updates: {
              callStatus
            }
          }
        }).then(value => {
          if(value.errors) {
            console.log("error " + value.errors[0])
          } else {
            context.getEventAllocatedVirtualRooms()
            context.$root.bus.emit('simpleToast', context.string.virtualRoomSettings._uploadsuccess );
          }
          return value;
        }).catch(reason => {
          context.templateSaving = false;
          context.$root.bus.emit("simpleToast", context.string.attendeeMaxLimit._error + error );
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    emptyFields: function() {
      this.fileToSelect = true;
      document.getElementById("fileinput").value = "";
    },
    openFileSelection: function() {
      this.$refs.fileinputVROOM.click();
    },
    updateImage: function(path) {
      const context = this;
      return this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: { eventId: context.eventId, updateEventInput: {vRoomImage: path} }
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.loadingEffectshow = false;
          } else {
            context.srcImage = path;
            context.loadingEffectshow = false;
          }
        })
        .catch(reason => {
          console.log(reason.message);
          context.loadingEffectshow = false;
        });
    },
    onFileChange: function(e) {
      this.fileToSelect = false;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      if(files[0].size>1000000){
        alert(this.string.coverImage._imgSizeExceed)
      }
      else{
        this.fileList = files[0];
        this.fileListName = files[0].name;
        this.submit();
      }
      console.log(files);

    },
    submit: function() {
      const context = this;
      context.loadingEffectshow = true;
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const data = new FormData();
      if (context.fileList !== null) {
        data.append("fileToUpload", context.fileList);
      }
      data.append("eventId", context.eventId);
      return this.$root.axios
        .post("/upload/vroomcover", data, config)
        .then(response => {
          console.log(response);
          context.$root.bus.emit("simpleToast", context.string.coverImage._updateDone);
          context.emptyFields();
          context.updateImage(response.data.filepath);
        })
        .catch(error => {
          console.log(error.message);
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed + " " + error.message );
          context.emptyFields();
        });
    }
  },
  beforeDestroy() {
    try {
      if (this.confirmPageSavedListener) {
        this.confirmPageSavedListener.remove();
        this.confirmPageSavedListener = null;
      }
    } catch {}
  }
};
</script>
