<template>
  <font-awesome-layers class="fa-lg" style="margin-left: 10px;">
      <font-awesome-icon
              :icon="['fas', 'circle']"
              transform="grow-6"
              :style="{color: '#0057BB'}">
      </font-awesome-icon>
      <font-awesome-icon
              icon="sync"
              transform="shrink-4"
              :style="{color: 'white'}"
              spin>
      </font-awesome-icon>
  </font-awesome-layers>
</template>

<script>


export default {
    props: {
        loading: Boolean,
    },
};
</script>

<style scoped>
  @-webkit-keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
