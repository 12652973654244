<template>
  <el-container class="penguin-background-image" :style="dynamicBackground">
    <div v-if="!pageLoaded" id="preloader">
      <div class="pulse"></div>
    </div>
    <el-container v-else direction="vertical" :class="enableGradient ? 'bg-transparent-gradient-1 bg-transparent-7-dark' : 'bg-transparent-7-dark'" style="padding: 0;">
      <el-header height="115px">
      <public-header :logo-source="logoSource" :enable-link="enableLink" :logo-height="logoHeight" :logo-width="logoWidth" />
      </el-header>
      <el-main class="penguin-public-main">
        <slot />
      <public-footer />
      </el-main>
    </el-container>
  </el-container>
</template>

<script >
import $ from "jquery";
import "html5shiv/dist/html5shiv.min";
import "bootstrap/dist/js/bootstrap.min";
import "wowjs/dist/wow.min";
import "owl.carousel/dist/owl.carousel.min";
import "magnific-popup/dist/jquery.magnific-popup.min";
import "isotope-layout/dist/isotope.pkgd.min";
import "imagesloaded/imagesloaded.pkgd.min";
import "smoothscroll-for-websites/SmoothScroll";
import "jquery.easing/jquery.easing.min";
import "../assets/js/v2/theme";
import PublicFooter from "@/components/PublicFooter";
import PublicHeader from "@/components/PublicHeader";

export default {
  components: {
    PublicFooter,
    PublicHeader
  },
  data() {
    return {
      pageLoaded: false,
      string: {},
      screenHeightStyle: " height: 700px;",
      screenHeightVal: 700,
      dynamicBackground: "background-image: url('../assets/img/v2/misc/bg-5.jpg')",
      imageSource: "",
      enableGradient: true,
      logoSource: "https://penguinpass-email.s3.eu-west-1.amazonaws.com/images/userId_5faac6ef48478d2bdd5a7c5c/editor_images/figc-logo.png",
      enableLink: false,
      logoHeight: "150",
      logoWidth: "120",
    };
  },
  watch: {
    imageSource: {
      immediate: true,
      handler: function(newVal, oldVal) {
      if (newVal) {
        this.dynamicBackground = "background-image: " + newVal;
      } else {
        this.dynamicBackground = this.getDefaultImage();
      }
      }
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener(
      "setLanguage",
      () => {
        context.string = context.$root.strings;
      },
      this
    );
    const language = window.navigator.language;
    if (language === "it-IT" || language === "it") {
      context.$root.strings = context.$root.messages_it;
      context.lang = "it";
      context.$root.currentLanguage = "it";
    } else if (language === "en-GB" || language === "en-US" || language === "en") {
      context.$root.strings = context.$root.messages_en;
      context.lang = "en";
      context.$root.currentLanguage = "en";
    } else if (language === "fr-FR" || language === "fr") {
      context.$root.strings = context.$root.messages_fr;
      context.lang = "fr";
      context.$root.currentLanguage = "fr";
    } else if (language === "ar-AR" || language === "ar") {
      context.$root.strings = context.$root.messages_ar;
      context.lang = "ar";
      context.$root.currentLanguage = "ar";
    } else {
      context.$root.strings = context.$root.messages_en;
      context.lang = "en";
      context.$root.currentLanguage = "en";
    }

    this.$root.bus.emit("setLanguage");
    window.addEventListener("resize", this.resize());
  },
  mounted() {
    $(document).ready(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const source = urlParams.get('source') || "";
      if (source.includes("casazzurri.figc.it")) {
        this.imageSource = "url('https://penguin-storage.s3-eu-west-1.amazonaws.com/static/img/casa-bg-logofree.jpeg')";
        this.logoSource = "https://penguin-storage.s3-eu-west-1.amazonaws.com/static/img/casa-logo-horizontal.png";
        this.enableGradient = false;
        this.enableLink = false;
        this.logoHeight = "84";
        this.logoWidth = "210";
      } else {
        this.imageSource = "url('https://penguinpass-email.s3.eu-west-1.amazonaws.com/images/userId_5faac6ef48478d2bdd5a7c5c/editor_images/bg-figc.jpg')";
        this.logoSource = "https://penguinpass-email.s3.eu-west-1.amazonaws.com/images/userId_5faac6ef48478d2bdd5a7c5c/editor_images/figc-logo.png";
        this.enableGradient = false;
        this.enableLink = true;
        this.logoHeight = "150";
        this.logoWidth = "120";
      }
      this.pageLoaded = true;
    });
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.resize());
  },
  methods: {
    resize() {
      this.screenHeightStyle= ' height:'+ (window.innerHeight - 115) +'px; ';
      this.screenHeightVal= window.innerHeight - 115;
    },
    getDefaultImage: function()  {
      return require('../assets/img/v2/misc/bg-5.jpg');
    },
  }
};
</script>


<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "~owl.carousel/dist/assets/owl.carousel.min.css";
@import "~owl.carousel/dist/assets/owl.theme.default.min.css";
@import "~owl.carousel/dist/assets/owl.carousel.min.css";
@import "~magnific-popup/dist/magnific-popup.css";
@import "../assets/css/v2/base.css";
@import "../assets/css/v2/style.css";
@import "../assets/css/v2/cookie.css";
@import "../assets/css/v2/styles/yellow.css";
@import "../assets/css/v2/custom.css";
@import "../assets/css/dashboard/main.css";
</style>
