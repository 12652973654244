var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "banner-area" }, [
    _vm.logoEnabled
      ? _c("img", {
          staticClass: "center",
          staticStyle: { "margin-bottom": "50px" },
          attrs: {
            src: _vm.srcImage,
            "event-id": _vm.eventId,
            alt: "logo Image",
            width: "50%",
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "sample-text- area-black",
        style: { paddingTop: _vm.teamUniqueName === "icc" ? "0px" : "70px" },
      },
      [
        _vm.teamUniqueName === "icc"
          ? _c("img", {
              staticStyle: { "margin-bottom": "50px" },
              attrs: {
                src: "https://penguinpass-media.s3.eu-west-1.amazonaws.com/Banner-icc.png",
                width: "100%",
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "row justify-content-center generic-height align-items-center",
          },
          [
            _c("div", { staticClass: "banner-content text-center" }, [
              _vm.teamUniqueName === "lamborghini"
                ? _c("img", {
                    staticStyle: { "margin-bottom": "50px" },
                    attrs: {
                      src: "https://penguinpass-media.s3.eu-west-1.amazonaws.com/Logo_della_Lamborghini.png",
                      width: "150",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.teamUniqueName === "buccellati"
                ? _c("img", {
                    staticStyle: { "margin-bottom": "50px" },
                    attrs: {
                      src: "https://penguinpass-media.s3.eu-west-1.amazonaws.com/Buccellati_white_logo.png",
                      width: "343",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "text-white top text-uppercase" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _vm.joinStatus === 0 || _vm.joinStatus === 1
                ? _c("h1", { staticClass: "text-white text-uppercase" }, [
                    _vm._v(
                      _vm._s(_vm.guestName) + " " + _vm._s(_vm.guestSurname)
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }