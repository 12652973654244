<template>
  <div id="loadingCover">
    <div class="loadingCoverMessage">
      <PulseLoader
              :loading="loading"
              :color="color"
              :size="size"/>
    </div>
  </div>
</template>
<style >
</style>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    components: {
        PulseLoader
    },
    props: {
        loadingCoverMsg: {
            type: String,
            default: function () {
                return 'loading...';
            }
        },
    },
    data (){
        return {
            string: {
                loadingCover: {
                    _loading: this.loadingCoverMsg,
                },
            },
            color: '#0057B8',
            size: '15px',
            margin: '2px',
            radius: '100%',
            loading: true
        };
    }
};
</script>
