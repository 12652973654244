<template>
  <div>
    <!-- TODO: set the accept property as well -->
    <input :id="fileInputId" ref="fileinput" type="file" accept="application/pdf" :required="mandatory" @change="onFileChange" />
    <transition name="component-fade-fast" mode="out-in" appear>
      <div v-if="fileToSelect === true">
        <a :title="string.listUpload._browse" 
        class="btn btn-secondary text-decoration-none btn--upload"
        @click="open"
        >
        <i aria-hidden="true" class="fa fa-folder-open"></i>
        {{ string.listUpload._browse.toUpperCase() }}
      </a>
      <span v-if="incorrectFormat === true" class="extension-incorrect">
        Caricare file in formato PDF
      </span>
    </div>
      <div v-if="fileToSelect === false">
        <!-- TODO: WE MAY WANT TO SUPPORT MULTIPLE FILES AT ONCE -->
        <span class="filename-container">
          {{ fileListName }}
        </span>
        <a :title="string.listUpload._clear" class="btn btn-secondary text-decoration-none btn--clear" @click="clear">
          <i aria-hidden="true" class="fa fa-times"></i>
          {{ string.listUpload._clear.toUpperCase() }}
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  props: { mandatory: { type: Boolean } } ,
  data() {
    return {
      string: {},
      fileToSelect: true,
      fileList: "",
      fileListName: "",
      fileInputId: null,
      fileListObj: null,
      isUploaded: false,
      incorrectFormat: false
    };
  },
  mounted() {
    this.fileInputId = this._uid;
    this.isUploaded = false;
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    onFileChange: function (e) {
      this.fileToSelect = false;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      if (files[0].type !== "application/pdf"){
        this.clear();
        this.incorrectFormat = true;
        return;
      } else {
        this.incorrectFormat = false;
        
        this.fileListObj = files;
        
        this.fileList = files[0];
        this.fileListName = files[0].name;
        this.isUploaded = true;
        this.$emit("file-changed", this.fileListObj);
      }
    },
    clear: function () {
      const context = this;
      context.fileToSelect = true;
      document.getElementById(context.fileInputId).value = "";
      this.$emit("file-changed", this.fileListObj);
    },
    open: function () {
      this.$refs.fileinput.click();
      console.log(this.mandatory);
    },
  },
};
</script>

<style scoped>
input{
  position: sticky;
  height: 1px;
}
.text-decoration-none {
  text-decoration: none;
}
.btn--upload {
  background-color: white;
  color: black;
  border: 1px solid black;
  margin-right: 1rem;
}

.btn--clear {
  margin-left: 1rem;
}

.filename-container {
  font-size: 1.7rem;
  color: #555555;
  background-color: #F2F2F2;
  padding: 1rem;
}

.extension-incorrect {
  color: red;
}

</style>
