<template>
  <div>
    <div
      id="modalEmailBuilder"
      class="modal fade in"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="false"
      style="display: block;"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" aria-hidden="true" @click="fadeout">
              ×
            </button>
            <h4 id="myModalLabel" class="modal-title">
              PENGUINPASS EMAIL BUILDER
            </h4>
          </div>
          <div class="modal-body">
            <transition name="component-fade-fast" mode="out-in" appear>
              <div
                v-if="templateSaving === true || templateUpdating === true"
                id="template_save_area"
              >
                <div id="template-save-command-area">
                  <input
                    v-model="templateDisplayName"
                    type="text"
                    name="template_name"
                    :placeholder="string.modalEmailBuilder._templateName"
                  />
                  <input
                    v-model="templateSubject"
                    type="text"
                    name="template_subject"
                    :placeholder="string.modalEmailBuilder._templateSubject"
                  />
                  <button
                    v-if="templateSaving === true"
                    id="email-template-save"
                    class="btn btn-primary"
                    @click="saveAsTemplateApi"
                  >
                    {{ string.modalEmailBuilder._save }}
                  </button>
                  <button
                    v-if="templateUpdating === true"
                    id="email-template-update"
                    class="btn btn-primary"
                    @click="updateTemplateApi"
                  >
                    {{ string.modalEmailBuilder._update }}
                  </button>
                  <button
                    id="email-template-cancel"
                    class="btn btn-default"
                    @click="templateSavingAbort"
                  >
                    {{ string.modalEmailBuilder._cancel }}
                  </button>
                </div>
              </div>
            </transition>

            <transition name="component-fade-fast-select-mode" mode="out-in" appear>
              <div v-if="startMenu" id="start-menu">
                <EmailBuilderSelectMode
                  :can-create-template="canCreateTemplate"
                  @selectTemplate="selectTemplate"
                  @newTemplate="newTemplate"
                />
              </div>
            </transition>

            <transition name="component-fade-fast-list-template" mode="out-in" appear>
              <div v-if="startMenu === false && templateList" id="template-list">
                <EmailBuilderSelectTemplates
                  :lang="lang"
                  :client-security-code="clientSecurityCode"
                  :user-id="userId"
                  :can-create-template="canCreateTemplate"
                  @templateSelected="templateSelected"
                  @templateCloned="templateCloned"
                  @goCreateTemplate="newTemplate"
                />
              </div>
            </transition>

            <div
              v-if="startMenu === false && emailBuilder"
              id="backtotemplate"
              @click="selectTemplate"
            >
              {{ string.modalEmailBuilder._backToTemplates }}
            </div>

            <transition name="component-fade-fast-email-builder" mode="out-in" appear>
              <div v-if="startMenu === false && emailBuilder" id="bee-plugin-container">
                <!-- EMAIL BUILDER -->
              </div>
            </transition>
          </div>
        </div>
      </div>

      <div class="modalBackgroundColor" @click="warnThenClose">
        &nbsp;
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.btn-primary {
  color: #fff !important;
  background-color: #0057B8 !important;
  border-color: #0057B8 !important;
}

#backtotemplate {
  position: absolute;
  bottom: 5px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #505659;
  padding: 10px;
  z-index: 999;
  text-transform: uppercase;
  cursor: pointer;
}

#backtotemplate:hover {
  border: 0;
  box-shadow: 0 5px 10px -3px;
}

#template-list {
  height: 100%;
}

#start-menu {
  display: block;
  height: 100%;
}

#template_save_area {
  background-color: #505659;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999;

  #template-save-command-area {
    width: 450px;
    margin: auto;
    position: absolute;
    top: 35%;
    left: 0;
    bottom: 0;
    right: 0;

    input {
      font-size: 30px;
      color: #565656;
      display: block;
      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
    }

    button#email-template-save {
      padding: 10px;
      display: block;
      width: 100%;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    button#email-template-update {
      padding: 10px;
      display: block;
      width: 100%;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    button#email-template-cancel {
      padding: 10px;
      display: block;
      width: 100%;
      font-size: 30px;
      text-transform: uppercase;
    }
  }
}

#modalEmailBuilder {
  background-color: rgba(51, 51, 51, 0.6);

  .modal-header {
    margin-top: 5px !important;
  }

  .modal-dialog {
    width: 90%;
    height: 90%;
    padding: 0;
    background-color: #ffffff;
    z-index: 1;
  }

  .modal-body {
    background-color: #505659;
    height: 93%;
  }

  .modal-content {
    height: 100%;
    border-radius: 0;
  }

  .modal-btn-close {
    background-color: #333333;
    color: #fff !important;
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .modalBackgroundColor {
    background-color: transparent;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 0;
  }
}

#bee-plugin-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #505659;
}
</style>
<script>
import Bee from "@mailupinc/bee-plugin";
import EmailBuilderSelectMode from "../../components/modals/ModalEmailBuilder/EmailBuilderSelectMode.vue";
import EmailBuilderSelectTemplates from "../../components/modals/ModalEmailBuilder/EmailBuilderSelectTemplates.vue";
import { CREATE_EMAIL_TEMPLATE, SEND_TEST_EMAIL, UPDATE_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATES } from "@/graphql";
import { findMustacheTokens } from "../../utils";
import { mapGetters } from 'vuex';
String.prototype.replaceAll = function(find, replace) {
  return this.replace(new RegExp(find, "g"), replace);
};
export default {
  components: {
    EmailBuilderSelectMode,
    EmailBuilderSelectTemplates
  },
  props: {
    lang: String,
    userId: String,
    eventId: String,
    skipSelection: Boolean,
    clientSecurityCode: String,
    email: Object,
    customFieldTags: {
      type: Array,
      required: false,
      default: () => []
    },
    isPrivateEvent: {
      type: Boolean,
      default: false
    },
    isCampaign: {
      type: Boolean,
      default: true
    },
    isConfirmationEmail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mainContext: this,
      canCreateTemplate: true,
      componentName: "modal-email-builder",
      string: {
        modalEmailBuilder: {}
      },
      clientId: "ee277b42-6eb7-4525-bdff-5f11c20d10b8",
      clientSecret: "vch6GuV6CeMcFM3hKFHv2cE7AfdyBipa7od7H1HRdR8w7BHIt44G",
      beeConfig: {
        uid: null,
        container: "bee-plugin-container",
        autosave: 15,
        language: "it-IT"
      },
      template: {
        page: {
          title: "Penguinpass",
          description: "Penguinpass Email template",
          template: {
            name: "template-base",
            type: "basic",
            version: "0.0.1"
          },
          body: {
            type: "mailup-bee-page-proprerties",
            container: {
              style: {
                "background-color": "#FFFFFF"
              }
            },
            content: {
              style: {
                "font-family": "Arial, 'Helvetica Neue', Helvetica, sans-serif",
                color: "#000000"
              },
              computedStyle: {
                linkColor: "#0068A5",
                messageBackgroundColor: "transparent",
                messageWidth: "500px"
              }
            }
          },
          rows: [
            {
              type: "one-column-empty",
              container: {
                style: {
                  "background-color": "transparent"
                }
              },
              content: {
                style: {
                  "background-color": "transparent",
                  color: "#000000",
                  width: "500px"
                }
              },
              columns: [
                {
                  "grid-columns": 12,
                  modules: [
                    {
                      type: "mailup-bee-newsletter-modules-empty",
                      descriptor: {}
                    }
                  ],
                  style: {
                    "background-color": "transparent",
                    "padding-top": "5px",
                    "padding-right": "0px",
                    "padding-bottom": "5px",
                    "padding-left": "0px",
                    "border-top": "0px dotted transparent",
                    "border-right": "0px dotted transparent",
                    "border-bottom": "0px dotted transparent",
                    "border-left": "0px dotted transparent"
                  }
                }
              ]
            }
          ]
        }
      },
      emailWorkingOnId: null,
      templateSaving: false,
      templateUpdating: false,
      templateId: "",
      templateDisplayName: "",
      templateUniqueName: "",
      templateSubject: "",
      templateJsonFileToSave: "",
      templateContentFileToSave: "",
      startMenu: false,
      templateList: false,
      emailBuilder: true,
      isNewTemplate: true,
      isModified: false
    };
  },
  computed: {
    ...mapGetters(["team", "features"])
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },

  mounted() {
    if (this.skipSelection) {
      // if (this.isConfirmationEmail){ // Is A Confirm Email
      //   // console.log('skip : ' + this.clientSecurityCode);
      //
      //   this.getTemplateAttachedToConfirmation();
      // } else { // Is A DirectEmailMarketing Email
      //   this.getTemplateAttachedToCampaign();
      // }
      this.templateId = this.email.id;
      this.templateDisplayName = this.email.displayName;
      this.templateUniqueName = this.email.uniqueName;
      this.templateSubject = this.email.subject;
      this.templateJsonFileToSave = this.email.templateContent;
      this.templateContentFileToSave = this.email.bodyHtml;
      this.template = JSON.parse(this.email.templateContent);
      this.startMenu = false;
      this.isNewTemplate = false;
      this.emailBuilder = true;
      this.initEmailBuilder();
    } else {
      this.startMenu = true;
      this.fetchTemplates();
    }
  },

  methods: {
    fetchTemplates : function (){
        const context = this;
        return this.$apollo.query({
          fetchPolicy: "no-cache",
          query: GET_EMAIL_TEMPLATES
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.getEmailTemplates) {
            context.canCreateTemplate = response.data.getEmailTemplates.length < context.team.emailTemplateLimit;
          }
        }).catch(error => {
          console.log(error);
        });
      },
    templateSelected: function(template) {
      this.templateId = template.id;
      this.templateDisplayName = template.displayName;
      this.templateUniqueName = template.uniqueName;
      this.templateSubject = template.subject;
      this.template = JSON.parse(template.templateContent);
      this.editTemplate();
    },
    templateCloned: function(template) {
      this.templateDisplayName = template.displayName;
      this.templateUniqueName = template.uniqueName;
      this.templateSubject = template.subject;
      this.template = JSON.parse(template.templateContent);
      this.newTemplate();
    },

    // Templates Selection
    selectTemplate: function() {
      this.startMenu = false;
      this.emailBuilder = false;
      this.templateList = true;
    },

    newTemplate: function() {
      this.startMenu = false;
      this.templateList = false;
      this.emailBuilder = true;
      this.isNewTemplate = true;
      this.initEmailBuilder();
    },
    editTemplate: function() {
      this.startMenu = false;
      this.templateList = false;
      this.emailBuilder = true;
      this.isNewTemplate = false;
      this.initEmailBuilder();
    },
    warnThenClose: function() {
      if (this.isModified) {
        this.$confirm(this.string.modalEmailBuilder._areyousure_discard)
          .then(_ => {
            this.fadeout();
          })
          .catch(_ => {});
      } else {
        this.fadeout();
      }
    },
    // Component fadeout
    fadeout: function() {
      this.isModified = false;
      this.$root.bus.emit("modalView", "fadeout");
      /** Emit di al gestore globale */
    },

    // Bee initialization
    initEmailBuilder: function() {
      const context = this;
      const beeConfig = context.beeConfig;
      // const token = this.$store.getters.user;
      // if(!token){
      //   this.$store.dispatch('logout');
      //   return;
      // }
      // const parts = token.split('.');
      // console.log('user token ' + parts[1]);
      // beeConfig.uid = 'userId_' +  parts[1];
      const user = this.$store.getters.user;
      if (!user["id"]) {
        this.$store.dispatch("logout");
        return;
      }
      beeConfig.uid = "userId_" + user["id"];
      const lang = user && user["language"] ? user["language"] : "it";
      beeConfig.language = context.getLocaleForBee(lang);
      beeConfig.onSave = context.saveAsTemplate;
      beeConfig.onSaveAsTemplate = context.disableTemplateAction;
      beeConfig.onAutoSave = context.autoSave;
      beeConfig.onSend = context.sendTest;
      beeConfig.onError = context.error;
      beeConfig.onChange = context.onChange;
      beeConfig.editorFonts = context.getCustomFonts();
      beeConfig.mergeTags = context.getMergeTags();
      beeConfig.specialLinks = context.getSpecialLinks();
      beeConfig.mergeContents = context.getMergeContents();

      const beeEditor = new Bee();
      beeEditor
        .getToken(this.clientId, this.clientSecret)
        .then(() => beeEditor.start(beeConfig, this.template));
    },
    onChange: function() {
      this.isModified = true;
    },
    // Bee language setting
    getLocaleForBee: function(appLang) {
      const it = "it-IT";
      const en = "en-US";

      if (appLang !== "it") {
        return en;
      }

      return it;
    },
    getCustomFonts: function() {
      return {
        showDefaultFonts: true,
        customFonts: [
          {
            name: "GothamPro",
            fontFamily: "GothamPro",
            url:
              "https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.css"
          },
          {
            name: "Nobel Regular",
            fontFamily: "Nobel-Regular",
            url: "https://penguin-storage.s3.eu-west-1.amazonaws.com/static/fonts/nobel/nobel.css"
          },
          {
            name: "Nobel Light",
            fontFamily: "Nobel-Light",
            url: "https://penguin-storage.s3.eu-west-1.amazonaws.com/static/fonts/nobel/nobel.css"
          }
        ]
      };
    },

    getMergeTags: function() {
      const defaultTags = [
        {
          name: "Full Name",
          value: "{{full_name}}"
        },
        {
          name: "First Name",
          value: "{{name}}"
        },
        {
          name: "Last Name",
          value: "{{surname}}"
        }
      ];

      return this.customFieldTags && this.customFieldTags.length
        ? [...defaultTags, ...this.customFieldTags]
        : defaultTags;
    },

    getMergeContents: function() {
      const mergeContentsTmp = [
        {
          name: "QrCode",
          value: "{{qr_code}}"
        }
      ];
      if (this.features['nProtocollo']) {
        mergeContentsTmp.push({
          name: this.string.guestlist._protocolNumber,
          value: "{{n_protocollo}}"
        });
      }
      return mergeContentsTmp;
    },

    getSpecialLinks: function() {
      let result = [
        {
          type: "Token",
          label: "SpecialLink for clients to go to the page already logged in",
          link: "{{page_link_token}}",
        },
        {
          type: "Download Ticket",
          label: "Allow guests to download the pdf version of the ticket, if covid compliance is active this also check for the test validity.",
          link: "{{download_ticket_url}}",
        },
        {
          type: "Virtual venue",
          label: "SpecialLink for clients to go to the page",
          link: "{{page_link}}",
        },
        {
          type: "Summary",
          label: "Download a summary of all informations provided during the join process.",
          link: "{{cf_summary}}",
        },
        {
          type: "Dowload PDF",
          label: "Download the current email as PDF",
          link: "{{show_pdf}}",
        }
      ];

      if (this.isPrivateEvent) {
        result = [
          {
            type: "RSVP",
            label: "SpecialLink to send a RSVP (do not modify the url)",
            link: "{{rsvp}}",
          },
          {
            type: "Decline",
            label: "SpecialLink for clients to decline (do not modify the url)",
            link: "{{decline}}",
          },
          ...result,
        ];
      }
      return result;
    },

    saveAsTemplate: function(jsonFile, htmlFile) {
      if (this.areTokensValid(htmlFile)) {
        this.templateJsonFileToSave = jsonFile;
        this.templateContentFileToSave = htmlFile;
        if (this.isNewTemplate) {
          this.templateSaving = true;
        } else {
          this.templateUpdating = true;
        }
      }
    },
    areTokensValid: function(htmlFile) {
      const tokens = findMustacheTokens(htmlFile, true);
      if (tokens && tokens.length) {
        const allTokens = [
          ...this.getSpecialLinks().map(item => item.link),
          ...this.getMergeContents().map(item => item.value),
          ...this.getMergeTags().map(item => item.value)
        ];
        for (const tokenFromHtml of tokens) {
          let exists = false;
          allTokens.map(tokenFromSettings => {
            if (tokenFromSettings === tokenFromHtml) {
              exists = true;
            }
          });
          if (!exists) {
            this.$root.bus.emit(
              "simpleToast",
              `the token ${tokenFromHtml} is not found in your custom fields but your html file contains it. Please remove it before saving email`
            );
            return false;
          }
        }
      }
      return true;
    },
    autoSave: function(jsonFile) {
      // console.log(jsonFile);
      // console.log("now");
      // console.log("auto save from vue method");
    },

    sendTest: function(htmlFile) {
      const context = this;
      return this.$apollo
        .mutate({
          mutation: SEND_TEST_EMAIL,
          variables: {
            body: htmlFile.toString()
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.$root.bus.emit(
              "simpleToast",
              context.string.modalEmailBuilder._template_sent_error
            );
            return;
          }
          if (response.data && response.data.sendTestEmail) {
            context.$root.bus.emit("simpleToast", context.string.modalEmailBuilder._template_sent);
          }
        })
        .catch(error => {
          console.log(error);
          context.$root.bus.emit(
            "simpleToast",
            context.string.modalEmailBuilder._template_sent_error
          );
        });
    },

    error: function(errorMessage) {
      alert(errorMessage);
      // console.log('onError ', errorMessage);
    },
    disableTemplateAction: function(JsonFile) {
      this.$root.bus.emit("simpleToast", this.string.modalEmailBuilder._use_save_button);
    },

    templateSavingAbort: function() {
      this.templateSaving = false;
      this.templateUpdating = false;
    },

    saveAsTemplateApi: function() {
      const context = this;

      if (!context.templateDisplayName.trim().length > 0 || !context.templateSubject.length > 0) {
        alert(context.string.modalEmailBuilder._nameIsMandatory);
        return;
      }

      if (!context.templateSaving) {
        return;
      }
      // Saving for the first time
      context.templateUniqueName =
        context.templateDisplayName
          .trim()
          .toLowerCase()
          .replaceAll(" ", "_") +
        (Math.floor(Math.random() * 10000) + 1).toString() +
        "_" +
        this.eventId;

      let body = context.templateContentFileToSave;
      body = body.replaceAll("\n", "");
      body = body.replaceAll("\r", "");
      body = body.replaceAll("\t", "");
      return this.$apollo
        .mutate({
          mutation: CREATE_EMAIL_TEMPLATE,
          variables: {
            emailTemplateInput: {
              displayName: context.templateDisplayName,
              uniqueName: context.templateUniqueName,
              subject: context.templateSubject,
              templateContent: context.templateJsonFileToSave,
              bodyHtml: body
            }
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.templateSaving = false;
            return;
          }
          if (response.data && response.data.createEmailTemplate) {
            context.fadeout();
            const emailCreated = response.data.createEmailTemplate;
            const signal = `${this.isCampaign ? 'campaign': 'registrationForm'}.${this.isConfirmationEmail ?'confirmationEmailSaved': 'emailSaved'}`;
            context.$root.bus.emit(signal, emailCreated);
            context.$root.bus.emit("simpleToast", context.string.modalEmailBuilder._templateSaved);
            context.templateSaving = false;
          }
        })
        .catch(error => {
          console.log(error);
          context.templateSaving = false;
          context.$root.bus.emit(
            "simpleToast",
            context.string.modalEmailBuilder._templateCreationError
          );
        });
    },
    updateTemplateApi: function() {
      const context = this;
      if (context.templateSaving) {
        return;
      }

      let body = context.templateContentFileToSave;
      body = body.replaceAll("\n", "");
      body = body.replaceAll("\r", "");
      body = body.replaceAll("\t", "");
      return this.$apollo
        .mutate({
          mutation: UPDATE_EMAIL_TEMPLATE,
          variables: {
            templateId: context.templateId,
            emailTemplateInput: {
              displayName: context.templateDisplayName,
              uniqueName: context.templateUniqueName,
              subject: context.templateSubject,
              templateContent: context.templateJsonFileToSave,
              bodyHtml: body
            }
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.templateUpdating = false;
            return;
          }
          if (response.data && response.data.updateEmailTemplate) {
            context.fadeout();
            const emailCreated = response.data.updateEmailTemplate;
            const signal = `${this.isCampaign ? 'campaign': 'registrationForm'}.${this.isConfirmationEmail ?'confirmationEmailSaved': 'emailSaved'}`;
            context.$root.bus.emit(signal, emailCreated);
            context.$root.bus.emit("simpleToast", context.string.modalEmailBuilder._emailSaved);
            // context.$root.bus.emit('simpleToast', context.string.modalEmailBuilder._templateSaved);
            context.templateUpdating = false;
          }
        })
        .catch(error => {
          context.templateUpdating = false;
          console.log(error);
          // context.$root.bus.emit('modalView', 'error', context.string.modalEmailBuilder._templateCreationError);
          context.$root.bus.emit(
            "simpleToast",
            context.string.modalEmailBuilder._templateCreationError
          );
        });
    }
  }
};
</script>
