import { FILE_UPLOAD, DOCFLY_STATUS_OPEN, DOCFLY_STATUS_STORED } from './constants';
import moment from "moment";

/**
 * Stringifying process of custom field answer. (usage examples; cell of excel report, or guest list table)
 * @param {string | Array<{key: string; label: string;}>} customFieldAnswer
 */
export const customFieldAnswerToString = customFieldAnswer => {
  
  let result = "";
  if ([undefined, null].includes(customFieldAnswer)) {
    return result;
  } else if (Array.isArray(customFieldAnswer)) {
    result = customFieldAnswer.map(cfa => cfa.label).join(", ");
  } else if (typeof customFieldAnswer === "string") {
    result = customFieldAnswer;
  } else if ( customFieldAnswer.type === FILE_UPLOAD) {
    const { status, uploadDate, docFlyPdaUploadTime } = customFieldAnswer.options || {};
    if (docFlyPdaUploadTime){
      const date = moment.utc(new Date(docFlyPdaUploadTime)).subtract(1, "hour").format("DD/MM/YYYY HH:mm");
        status === DOCFLY_STATUS_STORED
          ? (result = `caricato (${date})`)
          : (result = `${DOCFLY_STATUS_OPEN}`);
    } else {
      result = (status === DOCFLY_STATUS_STORED)? `${DOCFLY_STATUS_STORED} (${(new Date(uploadDate)).toISOString()})`: `${DOCFLY_STATUS_OPEN}`;
    }
  } else {
    result = customFieldAnswer && customFieldAnswer.title && customFieldAnswer.label
      ? customFieldAnswer.title + " | " + customFieldAnswer.label
      : "";
  }
  return result;
};
/**
 * Single control method for default answer logic of custom field answers.
 *
 * @param {{
    slug: string;
    rsvp: boolean;
    rsvpMandatory: boolean;
    registrationForm: boolean;
    registrationFormMandatory: boolean;
    checkinapp: boolean;
    type: "MULTIPLE_CHOICE" | "OPEN_ANSWER" | "TIME_SLOT";
    options: any;
    }} customField
 */
export const getDefaultCustomFieldAnswer = customField => {
  if (typeof customField === "undefined") {
    return "";
  }
  switch (customField.type) {
    case "MULTIPLE_CHOICE": {
      return [];
    }
    case "TIME_SLOT": {
      return null;
    }
    case "OPEN_ANSWER":
    default: {
      return "";
    }
  }
};

/**
 * From given custom field Map of event, returns default valid answers based on each custom field type
 * @param {Map<string, any>} customFieldMap
 */
export const getDefaultCustomFieldData = (customFieldMap = new Map()) => {
  const result = new Map();
  for (const [slug, cField] of customFieldMap.entries()) {
    result.set(slug, getDefaultCustomFieldAnswer(cField));
  }
  return result;
};

/**
 * From given custom field Map of event, returns array of merge tags for emailing purposes
 * @param {Map<string, any>} customFieldMap
 */
export const customFieldMapToMergeTagArray = (customFieldMap = new Map()) => {
  const result = [];
  for (const [slug, cField] of Object.entries(customFieldMap)) {
    result.push({
      name: cField.options.question,
      value: `{{${slug}}}`
    });
  }
  return result;
};
