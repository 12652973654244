<template xmlns:v-model="http://www.w3.org/1999/xhtml">
  <div class="data-grid-container">
    <div class="data-grid-table-container">
      <table>
        <thead>
          <tr>
            <th
              v-for="colObject in stateColumns"
              :key="colObject.key"
              :class="{
                active: sortKey === colObject.key,
                headCellStyle: colObject.key === 'listEmails' || colObject.key === 'isRegistered'
              }"
              @click="
                () => {
                  colObject.key === 'firstname' ||
                  colObject.key === 'surname' ||
                  colObject.key === 'email' ||
                  colObject.key === 'status'
                    ? sortBy(colObject.key)
                    : {};
                }
              "
            >
              {{ colObject.label | capitalize }}
              <span
                v-if="
                  colObject.key === 'firstname' ||
                    colObject.key === 'surname' ||
                    colObject.key === 'email' ||
                    colObject.key === 'status'
                "
                class="arrow"
                :class="sortOrders[colObject.key] > 0 ? 'asc' : 'dsc'"
              />
            </th>
            <th class="actions">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="entry in filteredData"
            :id="entry['id']"
            :key="entry['id']"
            class="tableRowItem"
          >
            <!-- FORM  -->
            <!-- <td :colspan="columns.length + 1" class="formContainer" v-if="entry['edit']==true">
                                    <table>
                                        <tr>  riga form -->
            <td v-if="entry['edit'] === true" class="colForm colFormFirst">
              <input
                v-model="editItem_FirstName"
                aria-label=""
                type="text"
                :placeholder="stateColumns[0].label"
                class="form-control"
              />
            </td>
            <td v-if="entry['edit'] === true" class="colForm">
              <input
                v-model="editItem_SurName"
                aria-label=""
                type="text"
                :placeholder="stateColumns[1].label"
                class="form-control"
              />
            </td>
            <td v-if="entry['edit'] === true" class="colForm">
              <input
                v-model="editItem_Email"
                aria-label=""
                type="text"
                :placeholder="stateColumns[2].label"
                class="form-control"
              />
            </td>
            <td v-if="entry['edit'] === true" class="colForm">
              <div class="form-control email-list">
                <!--<button :id="'dropdownMenuButton_'  + entry.id " class="btn btn-secondary dropdown-toggle background-color-transparent style" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Add/Delete Email </button>-->
                <el-popover
                  :id="'newEmail_' + entry.id"
                  popper-class="email-edit-popper"
                  width="567"
                  trigger="click"
                  placement="bottom"
                >
                  <div class="row">
                    <input
                      id="addNewEmail"
                      v-model="newEmail"
                      aria-label=""
                      type="email"
                      class="form-control col-md-8"
                      placeholder="add email"
                      onChange="{}"
                      @keyup.enter="addNewEmail(entry.id)"
                    />
                    <el-button size="mini" class="col-md-1" @click="addNewEmail(entry.id)">
                      <i class="fa fa-plus-circle"></i>
                    </el-button>
                  </div>
                  <!--<el-button size="mini" @click="addNewEmail(entry.id)" :id="'button'+entry.id">New email</el-button>-->
                  <!--                                <ul>-->
                  <div v-for="email in editItem_EmailList" :key="email.title" class="row">
                    <input
                      v-if="email.edit"
                      v-model="email.title"
                      v-focus
                      aria-label=""
                      class="'form-control col-md-11'"
                      style="padding: 10px; text-align: left; background-color: transparent; color: white;"
                      @blur="
                        () => {
                          email.edit = false;
                          $emit('update');
                        }
                      "
                      @keyup.enter="
                        () => {
                          email.edit = false;
                          $emit('update');
                        }
                      "
                    />

                    <div v-else class="'form-control col-md-11'" style="padding-top: 10px;">
                      <p class="col-md-9">
                        {{ email.title }}
                      </p>
                      <el-button class="col-md-1" size="mini" @click="handleEditMail(email)">
                        <i class="fa fa-pencil-square-o"></i>
                      </el-button>
                      <el-button
                        class="col-md-1"
                        size="mini"
                        type="danger"
                        @click="handleDelete(email)"
                      >
                        <i class="fa fa-trash-o"></i>
                      </el-button>
                    </div>
                  </div>
                  <!--                                </ul>-->
                  <div slot="reference" class="name-wrapper">
                    <el-tag>{{ string.datagrid._edit_emails }}</el-tag>
                  </div>
                </el-popover>
              </div>
            </td>
            <td v-if="entry['edit'] === true" class="colForm">
              <input
                v-model="editItem_Tel"
                aria-label=""
                type="tel"
                :placeholder="stateColumns[4].label"
                class="form-control"
              />
            </td>
            <td v-if="entry['edit'] === true" class="colForm">
              <NumericFieldInput
                class="form-control"
                :initial-value="entry['maxAcquaintance']"
                @change="setPartnersMax"
              />
            </td>
            <td v-if="entry['edit'] === true" class="colForm">
              <input
                v-model="entry.deliveryStatus"
                aria-label=""
                type="text"
                readonly="true"
                class="form-control"
              />
            </td>
            <td v-if="entry['edit'] === true" class="colForm">
              <select
                v-if="eventFree === true || (eventFree === false && editItem_Status > 1)"
                v-model="editItem_Status"
                aria-label=""
                class="form-control"
              >
                <option v-if="eventFree === true" value="PENDING">
                  {{ string.datagrid._pending }}
                </option>
                <option value="CONFIRMED">
                  {{ string.datagrid._confirmed }}
                </option>
                <option value="CHECK_IN">
                  {{ string.datagrid._checkin }}
                </option>
                <option value="DECLINE">
                  {{ string.datagrid._declined }}
                </option>
                <option value="WILL_BE_BACK">
                  {{ string.datagrid._will_be_back }}
                </option>
                <option value="CHECKED_OUT">
                  {{ string.datagrid._checkout }}
                </option>
              </select>
            </td>
            <td v-if="entry['edit'] === true" class="colForm">
              <input
                v-model="entry.member.isRegistered === true ? 'Yes' : 'No'"
                aria-label=""
                type="text"
                readonly="true"
                class="form-control"
              />
            </td>
            <!-- Colonne corrispondenti ai custom fields -->
            <td
              v-for="[slug, cField] in getCFieldMapArray()"
              v-if="entry['edit'] === true"
              :key="slug"
              class="colForm"
            >
              <input
                :value="editItem_CustomFieldDataMap[slug]"
                aria-label=""
                type="text"
                :placeholder="cField.label"
                class="form-control"
                @change.prevent="
                  event => {
                    editItem_CustomFieldDataMap[slug] = event.target.value;
                  }
                "
              />
            </td>

            <!-- Colonne corrispondenti ai custom fields -->

            <!--                    <td v-if="entry['edit'] === true"></td>-->
            <td v-if="entry['edit'] === true" class="actions actionsStyle">
              <a
                class="actions_link delete_prospect_link"
                data-toggle="tooltip_invitati"
                data-placement="top"
                title="Save"
                @click="saveEditItem(entry)"
              >
                <i class="fa fa-check">
                  &nbsp;
                </i>
              </a>
              <a
                class="actions_link delete_prospect_link"
                data-toggle="tooltip_invitati"
                data-placement="top"
                title="Discard"
                @click="undoEditItem(entry['id'])"
              >
                <i class="fa fa-times">
                  &nbsp;
                </i>
              </a>
            </td>

            <td
              v-for="entryObject in stateColumns"
              v-if="entry['edit'] !== true"
              :key="entryObject.key"
            >
              <div v-if="entryObject.key === 'listEmails'" class="email-list">
                <el-popover
                  :id="'listEmail_' + entry.id"
                  width="250"
                  trigger="click"
                  placement="bottom"
                  popper-class="email-list-popper"
                >
                  <div
                    v-for="email in entry['member']['listEmails']"
                    :key="email"
                    class="row list-email-row"
                  >
                    {{ email }}
                  </div>
                  <div slot="reference" class="name-wrapper">
                    <el-tag>{{ string.datagrid ? string.datagrid._list_emails : "" }}</el-tag>
                  </div>
                </el-popover>
              </div>
              <div v-else-if="entryObject.key === 'status'">
                <div class="row" style="text-align: left !important; ">
                  <font-awesome-layers class="fa-lg" style="margin-right: 10px;">
                    <font-awesome-icon
                      :icon="['fas', 'circle']"
                      transform="grow-6"
                      :style="statusStyle[entry['status']]"
                    >
                    </font-awesome-icon>
                    <font-awesome-icon
                      :icon="statusIcons[entry['status']]"
                      transform="shrink-4"
                      :style="{ color: 'white' }"
                    >
                    </font-awesome-icon>
                  </font-awesome-layers>
                  <span>{{ statusDescription[entry["status"]] }}</span>
                </div>
              </div>
              <div v-else-if="entryObject.key === 'virtualCheckInAt'">
                <div class="row">
                  <span :alt="entry['virtualCheckInAt']">{{ entry["virtualCheckInAt"]?'Yes':'No' }}</span>
                </div>
              </div>
              <div v-else-if="entryObject.key === 'deliveryStatus'">
                <div
                  v-if="
                    [
                      'SEND',
                      'DELIVERED',
                      'OPENED',
                      'CLICKED',
                      'BOUNCED',
                      'REJECTED',
                      'COMPLAINED'
                    ].indexOf(entry['deliveryStatus']) !== -1
                  "
                  class="row"
                >
                  <el-tooltip
                    effect="dark"
                    :content="
                      entry['deliveryStatus']
                        ? entry['deliveryStatus'] === 'COMPLAINED'
                          ? 'unsubscribed'
                          : entry['deliveryStatus'].toLowerCase()
                        : '-'
                    "
                    placement="bottom"
                  >
                    <font-awesome-layers class="fa-lg">
                      <font-awesome-icon :icon="['fas', 'circle']" transform="grow-6">
                      </font-awesome-icon>
                      <font-awesome-icon
                        :icon="deliveryStatusIcons[entry['deliveryStatus']]"
                        transform="shrink-4"
                        :style="{ color: 'black' }"
                      >
                      </font-awesome-icon>
                    </font-awesome-layers>
                  </el-tooltip>
                </div>
                <div v-else>
                  -
                </div>
              </div>
              <div v-else-if="entryObject.key === 'acquaintance'">
                {{ entry["acquaintance"] + "/" + entry["maxAcquaintance"] }}
              </div>
              <div v-else-if="entryObject.key === 'isRegistered'">
                {{ entry.member.isRegistered ? "Yes" : "No" }}
              </div>

              <!--                        <div v-else-if="entryObject.key === 'acquaintance'" class="acquaintance">-->
              <!--                            <el-popover-->
              <!--                                    placement="bottom"-->
              <!--                                    width="500"-->
              <!--                                    trigger="click"-->
              <!--                                    :boundaries-padding="0"-->
              <!--                                    popper-class="table-popper"-->
              <!--                                    :disabled="!entry['acquaintanceAdded'] || !(entry['acquaintanceInfoList'] && entry['acquaintanceInfoList'].length > 0)" >-->
              <!--                                <el-table-->
              <!--                                        row-class-name="default-row"-->
              <!--                                        header-cell-class-name="default-header"-->
              <!--                                        :data="entry['acquaintanceInfoList']">-->
              <!--                                    <el-table-column  min-width="100" property="name" :label="string.datagrid ? string.datagrid._guest_name: 'Name' "></el-table-column>-->
              <!--                                    <el-table-column  min-width="100" property="surname" :label="string.datagrid ? string.datagrid._guest_surname: 'Surname'"></el-table-column>-->
              <!--                                    <el-table-column  min-width="150" property="email" label="Email"></el-table-column>-->
              <!--                                </el-table>-->
              <!--                                <div slot="reference" class="name-wrapper">-->
              <!--                                    <el-tag >{{ entry['acquaintance'] + '/' + entry['maxAcquaintance']}}</el-tag>-->
              <!--                                </div>-->
              <!--                            </el-popover>-->
              <!--                        </div>-->

              <div v-else style="text-align: left;" v-html="getKey(entry, entryObject.key)"></div>
            </td>
            <td v-if="entry['edit'] !== true" class="actions">
              <a
                v-if="isPrivate === true"
                class="actions_link edit_prospect_link"
                data-toggle="tooltip_invitati"
                data-placement="top"
                title="Modifica invitato"
                @click="editItem(entry)"
              >
                <i class="fa fa-pencil-square-o">
                  &nbsp;
                </i>
              </a>
              <a
                class="actions_link delete_prospect_link"
                data-toggle="tooltip_invitati"
                data-placement="top"
                title="Elimina invitato"
                @click="deleteItem(entry['id'])"
              >
                <i class="fa fa-trash-o">
                  &nbsp;
                </i>
              </a>
              <a
                v-if="event.badgePrintingEnabled"
                class="actions_link badge_prospect_link"
                data-toggle="tooltip_invitati"
                data-placement="top"
                title="Scarica Badge"
                target="_blank"
                @click="downloadBadge(entry)"
              >
                <i class="fa fa-id-badge">
                  &nbsp;
                </i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import NumericFieldInput from "../components/NumericFieldInput.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  filters: {
    capitalize: function(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  components: {
    NumericFieldInput,
    FontAwesomeIcon
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  },
  props: {
    isPrivate: Boolean,
    event: Object,
    data: Array,
    columns: Array
  },
  data: function() {
    const sortOrders = {};
    this.columns.forEach(column => {
      sortOrders[column.key] = 1;
    });
    return {
      stateData: [],
      stateColumns: [],
      customFieldObject: {},
      sortKey: "",

      sortOrders: sortOrders,
      editItem_FirstName: "",
      editItem_SurName: "",
      editItem_Email: "",
      editItem_EmailList: [],
      editItem_Tel: "",
      editItem_CustomFieldDataMap: {},
      editItem_Status: "",
      editItem_Acquaintance: "",
      editItem_MaxAcquaintance: "",
      copy_editItem_FirstName: "",
      copy_editItem_SurName: "",
      copy_editItem_Email: "",
      copy_editItem_Tel: "",
      copy_editItem_Status: "",
      copy_editItem_Acquaintance: "",
      copy_editItem_MaxAcquaintance: "",
      string: {},
      searchPlaceholder: "",
      eventFree: "",
      layerLoadingMarkUpWithCssInline:
        '<div class="layerLoading" style="background-color: #fff; width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 99;">&nbsp;</div>',

      // guest's emails
      counter: 1,
      newEmail: "",
      emailList: [],
      editedTodo: null,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      deliveryStatusIcons: {
        SEND: ["far", "paper-plane"],
        DELIVERED: ["far", "envelope"],
        OPENED: ["far", "envelope-open"],
        CLICKED: ["fas", "envelope-open-text"],
        BOUNCED: ["fas", "times"],
        REJECTED: ["fas", "times"],
        COMPLAINED: ["fas", "times"]
      },
      statusIcons: {
        NULL: "times",
        PENDING: "exclamation",
        CONFIRMED: "thumbs-up",
        CHECK_IN: "check",
        DECLINE: "times",
        WILL_BE_BACK: "undo",
        CHECKED_OUT: "sign-out-alt"
      },
      statusStyle: {
        NULL: { color: "#DC143C" },
        PENDING: { color: "#555555" },
        CONFIRMED: { color: "#2980b9" },
        CHECK_IN: { color: "#0057BB" },
        DECLINE: { color: "#DC143C" },
        WILL_BE_BACK: { color: "#9b59b6" },
        CHECKED_OUT: { color: "#1abc9c" }
      },
      statusDescription: {
        NULL: "Null",
        PENDING: "pending",
        CONFIRMED: "confirmed",
        CHECK_IN: "check-in",
        DECLINE: "declined",
        WILL_BE_BACK: "will be back",
        CHECKED_OUT: "check-out"
      }
    };
  },
  computed: {
    filteredData: function() {
      const sortKey = this.sortKey;
      const order = this.sortOrders[sortKey] || 1;
      let tempData = this.stateData;
      if (sortKey) {
        tempData = tempData.slice().sort((a, b) => {
          if (sortKey === "firstname") {
            a = typeof a.member.name === "string" ? a.member.name.toLowerCase() : a.member.name;
            b = typeof b.member.name === "string" ? b.member.name.toLowerCase() : b.member.name;
            return (a === b ? 0 : a > b ? 1 : -1) * order;
          } else if (sortKey === "surname") {
            a =
              typeof a.member.surname === "string"
                ? a.member.surname.toLowerCase()
                : a.member.surname;
            b =
              typeof b.member.surname === "string"
                ? b.member.surname.toLowerCase()
                : b.member.surname;
            return (a === b ? 0 : a > b ? 1 : -1) * order;
          } else if (sortKey === "status") {
            a = typeof a.status === "string" ? a.status.toLowerCase() : a.status;
            b = typeof b.status === "string" ? b.status.toLowerCase() : b.status;
            return (a === b ? 0 : a > b ? 1 : -1) * order;
          } else if (sortKey === "email") {
            a = typeof a.member.email === "string" ? a.member.email.toLowerCase() : a.member.email;
            b = typeof b.member.email === "string" ? b.member.email.toLowerCase() : b.member.email;
            return (a === b ? 0 : a > b ? 1 : -1) * order;
          }
        });
      }
      return tempData;
    }
  },
  watch: {
    event: {
      handler(newVal, oldVal) {
        this.addToColumns();
      }
    },
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        this.stateData = newVal || [];
        this.addToColumns();
        // this.addToData();
      }
    },
    columns: {
      immediate: true,
      handler(newVal, oldVal) {
        this.stateColumns = newVal || [];
      }
    }
  },
  mounted() {
    const context = this;
    this.string = this.$root.strings;
    this.searchPlaceholder = this.string.datagrid._search;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
      context.searchPlaceholder = context.string.datagrid._search;
    });

    this.$root.bus.addListener("eventPaymentTypeChange", item => {
      context.eventFree = item.free;
    });
  },
  created() {
    this.eventFree = this.event.typology === "FREE";
  },
  methods: {
    getCFieldMapArray: function() {
      return this.customFieldObject ? Object.entries(this.customFieldObject) : [];
    },
    populateEmptyCustomFields: function(objectLiteral = {}) {
      const result = objectLiteral;
      for (const slug of Object.keys(this.customFieldObject)) {
        result[slug] = objectLiteral[slug] ? objectLiteral[slug] : "";
      }
      return result;
    },
    setPartnersMax: function(value) {
      this.editItem_MaxAcquaintance = value;
    },

    // Custom Fields Management
    addToColumns: function() {
      const tempArray = this.columns;
      this.customFieldObject = this.event.customFieldMap;

      for (const [slug, cField] of Object.entries(this.customFieldObject)) {
        let notExist = true;
        tempArray.map(col => {
          if (col.key === slug) {
            notExist = false;
          }
        });
        if (notExist) {
          tempArray.push({ key: slug, label: cField.label });
        }
      }
      this.stateColumns = tempArray;
      this.$forceUpdate();
    },
    addToData: function() {
      if (this.customFieldObject && Object.keys(this.customFieldObject).length) {
        this.stateData.map(guest => {
          guest.customFieldDataMap = this.populateEmptyCustomFields(guest.customFieldDataMap);
        });
      }
    },
    getFirstName: function(string) {
      return string.replace("_*A*_", "");
    },
    editItem: function(item) {
      const selectedId = item.id;
      const selectedItem = item;

      this.stateData.map(i => {
        i.member.name = i.member.name ? i.member.name.replace("_*A*_", "") : "";
        i.edit = false;
        return i;
      });

      // debug

      this.editItem_FirstName = selectedItem.member.name || "";
      this.editItem_SurName = selectedItem.member.surname || "";
      this.editItem_Email = selectedItem.member.email.includes("internal.penguinpass.it")
        ? ""
        : selectedItem.member.email || "";
      this.editItem_Tel = selectedItem.member.telephone || "";
      this.editItem_CustomFieldDataMap = selectedItem.customFieldDataMap;
      this.editItem_Status = selectedItem.status;
      this.editItem_MaxAcquaintance = selectedItem.maxAcquaintance;
      this.copy_editItem_FirstName = this.editItem_FirstName;
      this.copy_editItem_SurName = this.editItem_SurName;
      this.copy_editItem_Email = this.editItem_Email;
      this.copy_editItem_Tel = this.editItem_Tel;
      this.copy_editItem_Status = this.editItem_Status;
      this.copy_editItem_MaxAcquaintance = this.editItem_MaxAcquaintance;

      selectedItem.edit = true;
      selectedItem.member.name = "_*A*_" + selectedItem.member.name;

      const tempList = [];
      if (selectedItem.member && selectedItem.member.listEmails) {
        for (const email of selectedItem.member.listEmails) {
          tempList.push({ title: email, edit: false });
        }
      }
      this.editItem_EmailList = tempList;
    },
    saveEditItem: function(item) {
      const newEmailList = [];
      this.editItem_EmailList.forEach(email => newEmailList.push(email.title.toLowerCase().trim()));

      const guestId = item.id;
      const selectedIndex = this.stateData.indexOf(item);
      const memberId = this.stateData[selectedIndex].member.id;
      const guestUpdates = {};
      const memberUpdates = {};
      let controlLimit = false;
      if (this.copy_editItem_FirstName !== this.editItem_FirstName) {
        memberUpdates["name"] = this.editItem_FirstName;
      }
      if (this.copy_editItem_SurName !== this.editItem_SurName) {
        memberUpdates["surname"] = this.editItem_SurName;
      }
      if (this.copy_editItem_Email !== this.editItem_Email) {
        memberUpdates["email"] = this.editItem_Email;
      }
      if (this.copy_editItem_Tel !== this.editItem_Tel) {
        memberUpdates["telephone"] = this.editItem_Tel;
      }
      if (this.copy_editItem_Status !== this.editItem_Status) {
        guestUpdates["status"] = this.editItem_Status.toUpperCase();
        if (
          this.copy_editItem_Status === "PENDING" &&
          ["CONFIRMED", "CHECK_OUT", "WILL_BE_BACK", "CHECK_IN"].indexOf(this.editItem_Status) > -1
        ) {
          controlLimit = true;
        }
      }
      if (this.copy_editItem_MaxAcquaintance !== this.editItem_MaxAcquaintance) {
        guestUpdates["maxAcquaintance"] = this.editItem_MaxAcquaintance;
      }
      memberUpdates["listEmails"] = newEmailList;
      guestUpdates["customFieldDataMap"] = this.editItem_CustomFieldDataMap;

      this.$emit("updateGuest", { memberId, guestId, guestUpdates, memberUpdates, controlLimit });

      this.undoEditItem(item.id);
    },
    undoEditItem: function(id) {
      this.stateData = this.stateData.map(i => {
        i.member.name = i.member.name ? i.member.name.replace("_*A*_", "") : "";
        i.edit = false;
        return i;
      });
    },
    sortBy: function(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
    },
    deleteItem: function(id) {
      this.$emit("deleteListItem", { id: id });
    },
    downloadBadge: function(entry) {
      this.$store
        .dispatch("getBadge", entry)
        .then(result => {
          if (result.status === "SUCCESS" && result.pdf) {

            const arr = new Uint8Array(result.pdf.data);
            const blob = new Blob([arr], { type: 'application/pdf' });
            const href = window.URL.createObjectURL(blob, { type: 'application/pdf' });
            const theLink = document.createElement('a');
            theLink.href = href;
            theLink.download = `${entry.member.surname}_${entry.member.name}_badge.pdf`;
            document.body.appendChild(theLink);
            theLink.click();
            document.body.removeChild(theLink);
          }
        })
        .catch(error => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            this.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000
            });
          }
        });
    },
    getKey: function(entry, key) {
      if (key.toLowerCase() === "firstname") {
        return entry.member.name;
      }
      if (key.toLowerCase() === "surname") {
        return entry.member.surname;
      }
      if (key.toLowerCase() === "email") {
        const email = entry.member.email ? entry.member.email : "";
        return email.includes("internal.penguinpass.it") ? "" : email;
      }
      if (key.toLowerCase() === "telephone") {
        return entry.member.telephone;
      }
      if (key.toLowerCase() === "isregistered") {
        return entry.member.isRegistered ? "Yes" : "No";
      }

      if (this.customFieldObject[key] && typeof this.customFieldObject[key] !== "undefined") {
        return entry.customFieldDataMap[key] ? entry.customFieldDataMap[key] : "";
      }

      return entry[key];
    },
    addNewEmail: function(id) {
      const newEmail = this.newEmail.trim();

      if (!this.reg.test(newEmail)) {
        return this.$root.bus.emit("simpleToast", "Email not valid");
      }
      for (const emailObj of this.editItem_EmailList) {
        if (emailObj.title === newEmail) {
          return this.$root.bus.emit("simpleToast", "Email already present");
        }
      }
      this.editItem_EmailList = [...this.editItem_EmailList, { title: newEmail, edit: false }];
      this.newEmail = "";
    },
    handleEditMail: function(email) {
      email.edit = true;
    },
    handleDelete: function(email) {
      const deleteIndex = this.editItem_EmailList.indexOf(email);
      this.editItem_EmailList.splice(deleteIndex, 1);
    }
  }
};
</script>
<style scoped>
.data-grid-table-container {
  border: 2px solid #0057B8;
  border-right: 0;
  border-bottom: 0;
  max-height: 630px;
  overflow-y: auto;
  overflow-x: auto;
}

.status {
  text-align: center;
}

td.formContainer {
  padding: 0 !important;
}

td.formContainer table {
  border: 0;
  margin: 0;
}

.invitato-penguinpass {
  color: #eea236 !important;
}
.checked-penguinpass {
  color: crimson !important;
}
.invitato-non-penguinpass {
  color: #c40d0d !important;
}
.join-penguinpass {
  color: #0ac407 !important;
}
a.actions_link {
  display: inline-block;
  cursor: pointer;
  transition: 1s ease;
  background-color: transparent !important;
  color: #ffffff !important;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  padding: 0px !important;
  margin-top: 0px !important;
}
a.actions_link:hover {
  background-color: transparent !important;
  color: #000 !important;
}
td.colForm {
  padding: 3px !important;
  /*max-width: 200px !important;*/
  min-width: 50px !important;
  align-content: center !important;
  align-items: center !important;
}

td.colFormFirst {
  padding-left: 6px !important;
  align-content: center !important;
}

td.colForm .form-control {
  border-radius: 0;
  border-color: #333;
  align-content: center !important;
  text-align: center !important;
  min-height: 60px !important;
  /*max-width: 200px !important;*/
  padding: 3px !important;
  align-items: center !important;
  min-width: 50px !important;
}

select.form-control,
option.form-control {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  text-align: center !important;
  appearance: none !important;
  /*border: 1px solid #333 !important;*/
  border-radius: 0 !important;
  align-content: center !important;
  min-height: 60px !important;
  /*max-width: 200px !important;*/
  padding: 3px !important;
  align-items: center !important;
  min-width: 50px !important;
  /*background-color: #333 !important ;*/
  text-align-last: center;
  /*background: transparent url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat 98% 98% !important;*/
}

a.actions_link:hover {
  color: #333333 !important;
}

.data-grid-container td.actions {
  text-align: center;
}

.data-grid-container {
  /*padding-right: 15px;*/
  /*padding-left: 15px;*/
  overflow: auto;
}

td.actions,
th.actions {
  /*width: 55px !important;*/
  min-width: 57px !important;
  text-align: center;
  padding-right: 8px;
  padding-left: 0;
}
td.actions.actionsStyle {
  display: block;
  min-height: 64px;
  padding: 21px 0px;
  /*width: 200px !important;*/
}

table {
  position: relative;
  border: 2px solid #0057B8;
  border-radius: 3px;
  background-color: #000000;
  width: 100%;
}
.headCellStyle {
  width: 170px;
}
div.name-wrapper {
  text-align: center;
  width: 100%;
  height: 100%;
}
tr.tableRowItem:hover td {
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}
tr.tableRowItem {
  max-height: 45px;
  min-height: 45px;
  height: 45px;
}

tr:hover td {
  background-color: #fff;
}

th {
  background-color: #0057B8;
  color: rgba(255, 255, 255, 0.66);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

td {
  position: relative;
  max-width: 200px !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
}

/*
    th, td {
    min-width: 120px;
    padding: 10px 20px;
    text-align: left;
    }
    */

th,
td {
  position: relative;
  min-width: 120px !important;
  padding: 10px 20px;
  /*padding: 7px 15px;*/
  text-align: center;
}

td {
  background-color: transparent;
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}
</style>
<style>
.list-email-row {
  text-align: left;
  margin-bottom: 10px;
  padding: 0px 10px;
}
div.dropdown.open > div.dropdown-menu {
  position: relative;
  background: transparent !important;
}
.el-popover.table-popper {
  background: #111213e0;
  border: 1px solid #0057B8;
  padding: 0;
}

.el-popover.email-edit-popper {
  background: #111213e0;
  border: 1px solid #0057B8;
}
.el-popover.email-list-popper {
  background: #111213e0;
  border: 1px solid #0057B8;
}
.el-popover.email-edit-popper > div.row {
  padding-left: 15px;
}
div.name-wrapper.el-popover__reference > .el-tag {
  background: transparent;
  color: #ffff;
  border: 0px;
  border-radius: 0px;
}
div.name-wrapper.el-popover__reference > .el-tag:hover {
  background-color: transparent;
  color: #000;
  border-width: 0px;
  cursor: pointer;
}
.el-popover.email-edit-popper > div.row > input {
  width: 88.666667%;
}
.el-popover.email-edit-popper > div.row > .el-button {
  padding: 7px 20px;
}
.el-popover.email-edit-popper > div.row > .el-button > span > i {
  font-size: 25px;
}
.el-popover.email-edit-popper > ul > div.row > div {
  margin-right: -29px;
}
.el-popover.email-edit-popper > ul > div.row > div > p {
  padding-left: 8px;
}
.el-popover.email-edit-popper > ul {
  padding: 10px 0px 0px 15px;
  list-style-type: none;
}
.el-popover.email-edit-popper > ul > div {
  padding: 0px 5px;
}
.el-popover.email-edit-popper > ul > li > div > p {
  padding-top: 5px;
  padding-bottom: 5px;
}
.el-popover.email-edit-popper > ul > li > .el-button.el-button--default {
  /*background: #0057B8 !important;*/
  background: transparent !important;
  color: #0057B8 !important;
  margin-left: 8px;
  margin-right: -5px;
  padding-left: 0px;
  padding-right: 0px;
}
.el-popover.email-edit-popper > ul > li > .el-button.el-button--danger {
  color: #0057B8 !important;
  /*background-color: #F56C6C !important;*/
  background: transparent !important;
  border-color: #f56c6c !important;
  padding-left: 0px;
  padding-right: 0px;
}

.el-popover.email-edit-popper > ul > li > input:focus,
.el-popover.email-edit-popper > ul > li > input {
  width: 75%;
  border: none !important;
  outline: none !important;
}
.name-wrapper.el-popover__reference {
  padding-top: 0px;
  margin-top: -5px;
  border: 1px solid #0057B8;
}
.form-control.email-list > span > .name-wrapper.el-popover__reference {
  padding-top: 15px;
  border: 0px;
}
</style>
