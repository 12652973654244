var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        ref: "fileinput",
        attrs: {
          id: _vm.fileInputId,
          type: "file",
          accept: "application/pdf",
          required: _vm.mandatory,
        },
        on: { change: _vm.onFileChange },
      }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "component-fade-fast", mode: "out-in", appear: "" } },
        [
          _vm.fileToSelect === true
            ? _c("div", [
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-secondary text-decoration-none btn--upload",
                    attrs: { title: _vm.string.listUpload._browse },
                    on: { click: _vm.open },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-folder-open",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.string.listUpload._browse.toUpperCase()) +
                        "\n    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.incorrectFormat === true
                  ? _c("span", { staticClass: "extension-incorrect" }, [
                      _vm._v("\n      Caricare file in formato PDF\n    "),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.fileToSelect === false
            ? _c("div", [
                _c("span", { staticClass: "filename-container" }, [
                  _vm._v("\n        " + _vm._s(_vm.fileListName) + "\n      "),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-secondary text-decoration-none btn--clear",
                    attrs: { title: _vm.string.listUpload._clear },
                    on: { click: _vm.clear },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-times",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.string.listUpload._clear.toUpperCase()) +
                        "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }