var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section-title", {
        attrs: {
          title: _vm.$root.strings.organizerSettings._eventGroupTitle,
          subtitle: _vm.$root.strings.organizerSettings._eventGroupSubtitle,
        },
      }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "loader-section",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [
          _vm.loadingEffectshow
            ? _c("LoadingCover", {
                attrs: { "loading-cover-msg": _vm.loadingMessage },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "add-new-identity",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "team-custom-fields-manager advance-settings-component-container",
            },
            [
              _c("button-tab", {
                staticStyle: { "margin-bottom": "30px" },
                attrs: { tabs: _vm.tabs, "initial-tab": _vm.selectedTab },
                on: { tabChanged: _vm.onTabChanged },
              }),
              _vm._v(" "),
              _vm.selectedTab === "SHOW_EVENT_GROUP_FORM"
                ? _c(
                    "div",
                    { staticClass: "component-form" },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "form-field-row zero-margin-row",
                          staticStyle: {
                            display: "flex",
                            "margin-bottom": "10px",
                          },
                          attrs: { gutter: 30 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "align-self": "start",
                                padding: "10px !important",
                              },
                              attrs: { span: 6 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$root.strings.eventGroup._groupName
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              _c("custom-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "text",
                                  placeholder:
                                    _vm.$root.strings.eventGroup._newGroup,
                                },
                                model: {
                                  value: _vm.newGroupName,
                                  callback: function ($$v) {
                                    _vm.newGroupName =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "newGroupName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "form-field-row zero-margin-row",
                          staticStyle: {
                            display: "flex",
                            "margin-bottom": "10px",
                          },
                          attrs: { gutter: 30 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "align-self": "start",
                                padding: "10px !important",
                              },
                              attrs: { span: 6 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$root.strings.eventGroup._addEvent
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              _vm._l(
                                _vm.selectedEvents,
                                function (selectedEvent) {
                                  return _c(
                                    "el-row",
                                    {
                                      key: selectedEvent.id,
                                      staticClass:
                                        "form-field-row zero-margin-row",
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                        "margin-bottom": "10px",
                                      },
                                      attrs: { gutter: 20 },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 20 } },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticClass:
                                                "input-group-control",
                                              attrs: { readonly: "" },
                                              model: {
                                                value: selectedEvent.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    selectedEvent,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedEvent.name",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    height: "100%",
                                                    "align-items": "center",
                                                    "margin-right": "5px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [
                                                  selectedEvent.isEventGroupController
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-check el-input__icon",
                                                        staticStyle: {
                                                          color: "#0057BB",
                                                          "margin-right": "3px",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      style:
                                                        _vm.itemStyle(
                                                          selectedEvent
                                                        ),
                                                      on: {
                                                        click: function () {
                                                          return _vm.toggleControllerEvent(
                                                            selectedEvent
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            selectedEvent.isEventGroupController
                                                              ? _vm.$root
                                                                  .strings
                                                                  .eventGroup
                                                                  ._markedAsController
                                                              : _vm.$root
                                                                  .strings
                                                                  .eventGroup
                                                                  ._markAsController
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 4 } }, [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "full-column-button btn gray-btn save",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function () {
                                                return _vm.removeNewEvent(
                                                  selectedEvent
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$root.strings.eventGroup
                                                    ._removeFromGroup
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-autocomplete",
                                    {
                                      staticClass: "input-group-control",
                                      staticStyle: {
                                        "text-align": "start",
                                        width: "100%",
                                      },
                                      attrs: {
                                        "popper-class": "default-popper",
                                        size: "large",
                                        type: "text",
                                        "fetch-suggestions": _vm.findEvents,
                                        debounce: 500,
                                        placeholder:
                                          _vm.$root.strings.eventGroup
                                            ._searchEvents,
                                      },
                                      on: { select: _vm.addNewEvent },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "el-row",
                                                  {
                                                    staticClass:
                                                      "form-field-row zero-margin-row",
                                                    staticStyle: {
                                                      "margin-bottom": "5px",
                                                    },
                                                    attrs: { gutter: 10 },
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 16 } },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "value",
                                                            staticStyle: {
                                                              flex: "1",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 4 } },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "link",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  item.genre.formatEnum()
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "el-col",
                                                      { attrs: { span: 4 } },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "link",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  new Date(
                                                                    item.startDate
                                                                  ).format()
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        747738028
                                      ),
                                      model: {
                                        value: _vm.addEventFilter,
                                        callback: function ($$v) {
                                          _vm.addEventFilter =
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                        },
                                        expression: "addEventFilter",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-plus el-input__icon",
                                        attrs: { slot: "prefix" },
                                        slot: "prefix",
                                      }),
                                      _vm._v(" "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-input__icon",
                                        attrs: { slot: "suffix" },
                                        slot: "suffix",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-section submit" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn gray-btn save",
                            on: {
                              click: function () {
                                return _vm.preCreateEventGroup()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$root.strings.eventGroup._submit) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm.selectedTab === "SHOW_EVENT_GROUP_TABLE"
                ? _c(
                    "div",
                    { staticClass: "custom-fields-list" },
                    [
                      _c("data-table", {
                        ref: "table",
                        attrs: {
                          "get-data": _vm.getData,
                          "filter-enabled": false,
                          "show-pagination": false,
                          columns: _vm.columns,
                          "span-method": _vm.groupRows,
                          "row-class-name": _vm.rowClassName,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "eventGroupId",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row.eventGroupId) +
                                      "\n            "
                                  ),
                                ]),
                              ])
                            },
                          },
                          {
                            key: "name",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row.name) +
                                      "\n            "
                                  ),
                                ]),
                              ])
                            },
                          },
                          {
                            key: "isEventGroupController",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                row.isEventGroupController
                                  ? _c("div", [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-check el-input__icon",
                                        staticStyle: {
                                          color: "#0057BB",
                                          "margin-right": "3px",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-decoration": "none",
                                            color: "#0057BB",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$root.strings.eventGroup
                                                  ._markedAsController
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _c("div"),
                              ])
                            },
                          },
                          {
                            key: "createdAt",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.createdAt
                                          ? "" +
                                              new Date(row.createdAt).format()
                                          : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ])
                            },
                          },
                          {
                            key: "Actions",
                            fn: function (ref) {
                              var row = ref.row
                              return _c(
                                "span",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "action_link",
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: { title: "open editor" },
                                      on: {
                                        click: function () {
                                          return _vm.openRow(row)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-pencil" })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "action_link",
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: { title: "remove option" },
                                      on: {
                                        click: function () {
                                          return _vm.preDeleteGroup(row)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fa fa-trash" })]
                                  ),
                                ]
                              )
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            visible: _vm.editItem.visible,
                            width: "90vw",
                            "custom-class": "default-popper",
                            "append-to-body": "",
                          },
                          on: {
                            "update:visible": function ($event) {
                              return _vm.$set(_vm.editItem, "visible", $event)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "dialog-title",
                              staticStyle: { "text-transform": "uppercase" },
                              attrs: { slot: "title" },
                              slot: "title",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$root.strings.eventGroup._editGroup
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "form-field-row zero-margin-row",
                              staticStyle: {
                                display: "flex",
                                "margin-bottom": "10px",
                                color: "white",
                              },
                              attrs: { gutter: 30 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    "align-self": "start",
                                    padding: "10px !important",
                                  },
                                  attrs: { span: 4 },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$root.strings.eventGroup
                                            ._groupName
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _c("custom-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        _vm.$root.strings.eventGroup._newGroup,
                                    },
                                    model: {
                                      value: _vm.editItem.eventGroupId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.editItem,
                                          "eventGroupId",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "editItem.eventGroupId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "form-field-row zero-margin-row",
                              staticStyle: {
                                display: "flex",
                                "margin-bottom": "10px",
                                color: "white",
                              },
                              attrs: { gutter: 30 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    "align-self": "start",
                                    padding: "10px !important",
                                  },
                                  attrs: { span: 4 },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$root.strings.eventGroup._addEvent
                                      ) + " "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 20 } },
                                [
                                  _vm._l(
                                    _vm.editItem.selectedEvents,
                                    function (selectedEvent) {
                                      return _c(
                                        "el-row",
                                        {
                                          key: selectedEvent.id,
                                          staticClass:
                                            "form-field-row zero-margin-row",
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "row",
                                            "margin-bottom": "10px",
                                          },
                                          attrs: { gutter: 20 },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 20 } },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass:
                                                    "input-group-control",
                                                  attrs: { readonly: "" },
                                                  model: {
                                                    value: selectedEvent.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        selectedEvent,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "selectedEvent.name",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        height: "100%",
                                                        "align-items": "center",
                                                        "margin-right": "5px",
                                                      },
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [
                                                      selectedEvent.isEventGroupController
                                                        ? _c("i", {
                                                            staticClass:
                                                              "el-icon-check el-input__icon",
                                                            staticStyle: {
                                                              color: "#0057BB",
                                                              "margin-right":
                                                                "3px",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "a",
                                                        {
                                                          style:
                                                            _vm.itemStyle(
                                                              selectedEvent
                                                            ),
                                                          on: {
                                                            click: function () {
                                                              return _vm.toggleControllerEvent(
                                                                selectedEvent
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                selectedEvent.isEventGroupController
                                                                  ? _vm.$root
                                                                      .strings
                                                                      .eventGroup
                                                                      ._markedAsController
                                                                  : _vm.$root
                                                                      .strings
                                                                      .eventGroup
                                                                      ._markAsController
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("el-col", { attrs: { span: 4 } }, [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "full-column-button btn gray-btn save",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function () {
                                                    return _vm.removeNewEventFromExistingGroup(
                                                      selectedEvent
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$root.strings
                                                        .eventGroup
                                                        ._removeFromGroup
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-autocomplete",
                                        {
                                          staticClass: "input-group-control",
                                          staticStyle: {
                                            "text-align": "start",
                                            width: "100%",
                                          },
                                          attrs: {
                                            "popper-class": "default-popper",
                                            size: "large",
                                            type: "text",
                                            "fetch-suggestions": _vm.findEvents,
                                            debounce: 500,
                                            placeholder: "Add Event",
                                          },
                                          on: {
                                            select:
                                              _vm.addNewEventToExistingGroup,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticClass:
                                                        "form-field-row zero-margin-row",
                                                      staticStyle: {
                                                        "margin-bottom": "5px",
                                                      },
                                                      attrs: { gutter: 10 },
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 16 } },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 4 } },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  item.genre.formatEnum()
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 4 } },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  new Date(
                                                                    item.createdAt
                                                                  ).format()
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.addEventFilter,
                                            callback: function ($$v) {
                                              _vm.addEventFilter =
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                            },
                                            expression: "addEventFilter",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-plus el-input__icon",
                                            attrs: { slot: "prefix" },
                                            slot: "prefix",
                                          }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-arrow-down el-input__icon",
                                            attrs: { slot: "suffix" },
                                            slot: "suffix",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "form-field-row zero-margin-row",
                                  staticStyle: {
                                    display: "flex",
                                    "margin-bottom": "10px",
                                  },
                                  attrs: { gutter: 30 },
                                },
                                [
                                  _c("el-col", {
                                    staticStyle: {
                                      "align-self": "start",
                                      padding: "10px !important",
                                    },
                                    attrs: { span: 4 },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 20 } },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticClass:
                                            "form-field-row zero-margin-row",
                                          staticStyle: {
                                            display: "flex",
                                            "margin-bottom": "10px",
                                          },
                                          attrs: { gutter: 30 },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "full-column-button btn gray-btn save",
                                                  on: {
                                                    click: function () {
                                                      return _vm.closeRow(
                                                        _vm.editItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$root.strings
                                                          .eventGroup._cancel
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "full-column-button btn gray-btn save primary",
                                                  on: {
                                                    click: function () {
                                                      return _vm.saveRow(
                                                        _vm.editItem
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$root.strings
                                                          .eventGroup._save
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }