var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TransitionGroup",
    { staticClass: "event-list", attrs: { name: "list", tag: "ul" } },
    _vm._l(_vm.outputEvents, function (item) {
      return _c(
        "li",
        {
          key: item.id,
          on: {
            click: function ($event) {
              return _vm.goSingleEvent(item.id, item.status)
            },
          },
        },
        [
          item.status === "INACTIVE" || _vm.archive === true
            ? _c("div", { staticClass: "notPublishedLayerBg" }, [
                _vm._v("\n       \n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          item.status === "INACTIVE" || _vm.archive === true
            ? _c("div", { staticClass: "notPublishedLayerFg" }, [
                _c("div", { staticClass: "containerButtonLayer" }, [
                  _vm.archive === false && item.status !== "ARCHIVED"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn-public",
                          attrs: { title: _vm.string.eventList._publish },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.publishSingleEvent(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-check" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn-edit",
                      attrs: { title: _vm.string.eventList._edit },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.editSingleEvent(item.id)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-pencil" })]
                  ),
                  _vm._v(" "),
                  _vm.archive === false && item.status !== "ARCHIVED"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn-archive",
                          attrs: { title: _vm.string.eventList._archive },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.archiveSingleEvent(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-archive" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.archive === true && item.status === "ARCHIVED"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn-archive",
                          attrs: { title: _vm.string.eventList._unarchive },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.unArchiveSingleEvent(item)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fa fa-archive" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn-delete",
                      attrs: { title: _vm.string.eventList._delete },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.deleteSingleEvent(item)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-trash" })]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "time",
            { class: item.smartBg, attrs: { datetime: item.fulldate } },
            [
              _c(
                "span",
                { staticClass: "type-event category", class: item.smartBg },
                [_vm._v("\n        " + _vm._s(item.privateLabel) + "\n      ")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "day" }, [
                _vm._v("\n        " + _vm._s(item.startdate_gg) + "\n      "),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "month" }, [
                _vm._v("\n        " + _vm._s(item.startdate_mm) + "\n      "),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "year" }, [
                _vm._v("\n        " + _vm._s(item.startdate_yy) + "\n      "),
              ]),
            ]
          ),
          _vm._v(" "),
          item.image != null
            ? _c("img", { attrs: { alt: item.name, src: item.image } })
            : _vm._e(),
          _vm._v(" "),
          item.image == null
            ? _c("img", { attrs: { alt: item.name, src: _vm.defaultImage } })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "info" }, [
            _c("h2", { staticClass: "title" }, [
              _vm._v("\n        " + _vm._s(item.name) + "\n      "),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "category" }, [
              _vm._v(
                "\n        " + _vm._s(item.customCategoryLabel) + "\n      "
              ),
            ]),
            _vm._v(" "),
            item.place != null && item.place.address !== "undefined"
              ? _c("p", { staticClass: "desc" }, [
                  _c("i", {
                    staticClass: "fa fa-map-marker",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(item.place.address) +
                      ", " +
                      _vm._s(item.place.postalcode) +
                      " " +
                      _vm._s(item.place.city) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                {
                  staticClass: "tooltips confermati",
                  attrs: {
                    "data-placement": "top",
                    "data-original-title": "confermati",
                  },
                },
                [
                  _vm._v("\n          " + _vm._s(item.confirmed) + " "),
                  _c("span", { staticClass: "glyphicon glyphicon-ok" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "tooltips checkin",
                  attrs: {
                    "data-placement": "top",
                    "data-original-title": "check-in",
                  },
                },
                [
                  _vm._v("\n          " + _vm._s(item.checkedIn) + " "),
                  _c("span", { staticClass: "glyphicon glyphicon-ok" }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "glyphicon glyphicon-ok",
                    staticStyle: { "margin-left": "-8px" },
                    attrs: { "data-v-00b34a85": "" },
                  }),
                ]
              ),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }