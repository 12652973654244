var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "direct-email-marketing-component-container" },
    [
      _c(
        "section-content",
        {
          attrs: {
            "has-icon": true,
            "icon-progress": _vm.steps.campaign.progress,
            "icon-text": _vm.steps.campaign.progressLabel,
          },
        },
        [
          _c("section-title", {
            attrs: {
              title: _vm.$root.strings.directEmailMarketing._title,
              subtitle: _vm.$root.strings.directEmailMarketing._subtitle,
            },
          }),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.loading === true
                ? _c("PulseLoader", {
                    attrs: {
                      loading: _vm.loading,
                      color: "#0057B8",
                      size: "15px",
                    },
                  })
                : _vm.demEntryVisible === false && _vm.loading === false
                ? _c(
                    "button",
                    {
                      staticClass: "button save",
                      staticStyle: { width: "100% !important" },
                      on: { click: _vm.createCampaign },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$root.strings.directEmailMarketing._create
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm.loadingEffectshow
                ? _c("LoadingCover", {
                    attrs: { "loading-cover-msg": _vm.loadingMessage },
                  })
                : _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "form-field-row zero-margin-row",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-label",
                              attrs: { span: 3 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$root.strings.directEmailMarketing
                                        ._name
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              attrs: { span: 21 },
                            },
                            [
                              _c("custom-input", {
                                attrs: {
                                  placeholder:
                                    _vm.$root.strings.directEmailMarketing
                                      ._senderName,
                                },
                                on: {
                                  change: function () {
                                    _vm.onChanges(false)
                                    _vm.autoSaveCampaign(this$1, {
                                      eventId: _vm.eventId,
                                      senderName: _vm.name,
                                    })
                                  },
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        {
                          staticClass: "form-field-row zero-margin-row",
                          attrs: { gutter: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-label",
                              attrs: { span: 3 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$root.strings.directEmailMarketing
                                        ._email
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              !_vm.editEmail &&
                              !(
                                _vm.identity === "DEFAULT" ||
                                (_vm.verifiedEmailIdentities.length < 1 &&
                                  _vm.verifiedDomainIdentities.length < 1)
                              )
                                ? _c("custom-input", {
                                    attrs: {
                                      placeholder: "Email",
                                      readonly: "",
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "email",
                                    },
                                  })
                                : _vm.identity === "DEFAULT" ||
                                  (_vm.verifiedEmailIdentities.length < 1 &&
                                    _vm.verifiedDomainIdentities.length < 1)
                                ? _c("custom-input", {
                                    attrs: {
                                      placeholder: "Email",
                                      readonly: "",
                                    },
                                    model: {
                                      value: _vm.defaultEmail,
                                      callback: function ($$v) {
                                        _vm.defaultEmail =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "defaultEmail",
                                    },
                                  })
                                : _vm.editEmail &&
                                  _vm.identity === "DOMAIN" &&
                                  _vm.verifiedDomainIdentities.length >= 1
                                ? _c(
                                    "el-row",
                                    { staticStyle: { padding: "0" } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 11 } },
                                        [
                                          _c("custom-input", {
                                            attrs: {
                                              placeholder:
                                                _vm.$root.strings
                                                  .directEmailMarketing
                                                  ._username,
                                            },
                                            on: {
                                              change: function () {
                                                return _vm.onDomainInputChanged()
                                              },
                                            },
                                            model: {
                                              value: _vm.domainUser,
                                              callback: function ($$v) {
                                                _vm.domainUser =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "domainUser",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 2 } },
                                        [
                                          _c("custom-input", {
                                            staticStyle: {
                                              width: "100%",
                                              "text-align": "center",
                                            },
                                            attrs: { value: "@", readonly: "" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 11 } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "domain-selector",
                                              attrs: {
                                                "popper-class":
                                                  "default-popper",
                                                size: "large",
                                                clearable: "",
                                                placeholder:
                                                  _vm.$root.strings
                                                    .directEmailMarketing
                                                    ._selectOneVerifiedDomain,
                                              },
                                              on: {
                                                change: function () {
                                                  return _vm.onDomainInputChanged()
                                                },
                                              },
                                              model: {
                                                value: _vm.domainName,
                                                callback: function ($$v) {
                                                  _vm.domainName = $$v
                                                },
                                                expression: "domainName",
                                              },
                                            },
                                            _vm._l(
                                              _vm.verifiedDomainIdentities,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item,
                                                  attrs: {
                                                    label: item,
                                                    value: item,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.editEmail &&
                                  _vm.identity === "EMAIL" &&
                                  _vm.verifiedEmailIdentities.length >= 1
                                ? _c(
                                    "el-row",
                                    { staticStyle: { padding: "0" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "domain-selector",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "popper-class": "default-popper",
                                            size: "large",
                                            clearable: "",
                                            "aria-label": "",
                                            placeholder:
                                              _vm.$root.strings
                                                .directEmailMarketing
                                                ._selectOneVerifiedEmail,
                                          },
                                          on: {
                                            change: function (value) {
                                              _vm.email = value
                                              _vm.onChanges(false)
                                              _vm.autoSaveCampaign(this$1, {
                                                eventId: _vm.eventId,
                                                senderEmail: value,
                                              })
                                            },
                                          },
                                          model: {
                                            value: _vm.email,
                                            callback: function ($$v) {
                                              _vm.email = $$v
                                            },
                                            expression: "email",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-option",
                                            {
                                              attrs: {
                                                value: "",
                                                disabled: "",
                                                hidden: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$root.strings
                                                      .directEmailMarketing
                                                      ._selectOneVerifiedEmail
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.verifiedEmailIdentities,
                                            function (verifiedIdentity) {
                                              return _c(
                                                "el-option",
                                                {
                                                  key: verifiedIdentity,
                                                  attrs: {
                                                    label: verifiedIdentity,
                                                    value: verifiedIdentity,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(verifiedIdentity) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "btn gray-btn save full-column-button",
                                on: {
                                  click: function () {
                                    return (_vm.editEmail = !_vm.editEmail)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.editEmail &&
                                        _vm.identity !== "DEFAULT" &&
                                        (_vm.verifiedEmailIdentities.length !==
                                          0 ||
                                          _vm.verifiedDomainIdentities
                                            .length !== 0)
                                        ? _vm.$root.strings.directEmailMarketing
                                            ._save
                                        : _vm.$root.strings.directEmailMarketing
                                            ._modify
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      (_vm.identity === "DEFAULT" ||
                        (_vm.verifiedEmailIdentities.length < 1 &&
                          _vm.verifiedDomainIdentities.length < 1)) &&
                      _vm.editEmail
                        ? _c(
                            "el-row",
                            {
                              staticStyle: { "margin-bottom": "30px" },
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [_vm._v("   ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-field",
                                  attrs: { span: 21 },
                                },
                                [
                                  _c("p", { staticStyle: { padding: "5px" } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$root.strings.directEmailMarketing
                                            ._defaultEmailExplanation1
                                        ) +
                                        "\n              "
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "page-link",
                                        attrs: {
                                          href: "/settings#domain-setup",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$root.strings
                                                .directEmailMarketing
                                                ._goToProfileSettings
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$root.strings.directEmailMarketing
                                            ._defaultEmailExplanation2
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subjectDisabled === false
                        ? _c(
                            "el-row",
                            {
                              staticClass: "form-field-row zero-margin-row",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$root.strings.directEmailMarketing
                                            ._subject
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-field",
                                  attrs: { span: 21 },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        effect: "dark",
                                        content:
                                          _vm.$root.strings.directEmailMarketing
                                            ._subjectExplanation,
                                        placement: "bottom-start",
                                      },
                                    },
                                    [
                                      _c("custom-input", {
                                        attrs: { readonly: "" },
                                        model: {
                                          value: _vm.subject,
                                          callback: function ($$v) {
                                            _vm.subject =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "subject",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.demEntryVisible === true && _vm.loading === false
                        ? _c(
                            "section-content",
                            [
                              _c("section-title", {
                                attrs: {
                                  title:
                                    _vm.$root.strings.directEmailMarketing
                                      ._emailBuilderTitle,
                                  subtitle:
                                    _vm.$root.strings.directEmailMarketing
                                      ._emailBuilderSubtitle,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    name: "component-fade-fast",
                                    mode: "out-in",
                                    appear: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticClass:
                                            "form-field-row zero-margin-row",
                                          attrs: { gutter: 30 },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "btn gray-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return (function () {
                                                        return _vm.loadTemplates()
                                                      })($event)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$root.strings
                                                          .directEmailMarketing
                                                          ._createEmail
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 12 } },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "btn gray-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return (function () {
                                                        return _vm.loadTemplates()
                                                      })($event)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$root.strings
                                                          .directEmailMarketing
                                                          ._startfromtemplate
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.campaignEmailPreview
                                        ? _c("section-title", {
                                            attrs: {
                                              title: "Email preview",
                                              "show-separator": false,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.campaignEmailPreview
                                        ? _c(
                                            "el-row",
                                            {
                                              staticClass:
                                                "form-field-row zero-margin-row",
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "email-preview",
                                                  attrs: { span: 24 },
                                                },
                                                [
                                                  _c("iframe", {
                                                    attrs: {
                                                      id: "emailPreviewFrame",
                                                      srcdoc:
                                                        _vm.campaignEmailPreview,
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.campaignEmailPreview
                                        ? _c(
                                            "el-row",
                                            {
                                              staticClass:
                                                "form-field-row zero-margin-row",
                                              attrs: { gutter: 30 },
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 24 } },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "btn gray-btn",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return (function () {
                                                            return _vm.loadTemplateBuilder(
                                                              _vm.campaignEmailTemplate
                                                            )
                                                          })($event)
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$root.strings
                                                              .directEmailMarketing
                                                              ._modifyEmail
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.demEntryVisible === true && _vm.loading === false
                        ? _c(
                            "section-content",
                            [
                              _c("section-title", {
                                attrs: {
                                  title:
                                    _vm.$root.strings.directEmailMarketing
                                      ._additionalSettingsTitle,
                                  "show-separator": false,
                                },
                              }),
                              _vm._v(" "),
                              _vm.isPrivate
                                ? _c(
                                    "el-row",
                                    {
                                      staticClass:
                                        "form-field-row zero-margin-row",
                                      staticStyle: { "margin-bottom": "30px" },
                                      attrs: { gutter: 10 },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "form-field-col-label",
                                          attrs: { span: 3 },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$root.strings
                                                    .directEmailMarketing
                                                    ._setpartnermax
                                                ) +
                                                ": "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "form-field-col-field",
                                          staticStyle: {
                                            "align-self": "center",
                                            "vertical-align": "middle",
                                            "text-align": "center",
                                          },
                                          attrs: { span: 1 },
                                        },
                                        [
                                          _c("custom-checkbox", {
                                            on: {
                                              change: function () {
                                                return _vm.onChanges()
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.setMaxAcquaintanceOnJoin,
                                              callback: function ($$v) {
                                                _vm.setMaxAcquaintanceOnJoin =
                                                  $$v
                                              },
                                              expression:
                                                "setMaxAcquaintanceOnJoin",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "form-field-col-field",
                                          attrs: { span: 20 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                margin: "0",
                                                padding: "0",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$root.strings
                                                      .directEmailMarketing
                                                      ._setpartnermax_explain
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isPrivate
                                ? _c(
                                    "el-row",
                                    {
                                      staticClass:
                                        "form-field-row zero-margin-row",
                                      staticStyle: { "margin-bottom": "30px" },
                                      attrs: { gutter: 10 },
                                    },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "form-field-col-label",
                                          attrs: { span: 3 },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$root.strings
                                                    .directEmailMarketing
                                                    ._setnote
                                                ) +
                                                ": "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "form-field-col-field",
                                          staticStyle: {
                                            "align-self": "center",
                                            "vertical-align": "middle",
                                            "text-align": "center",
                                          },
                                          attrs: { span: 1 },
                                        },
                                        [
                                          _c("custom-checkbox", {
                                            on: {
                                              change: function () {
                                                return _vm.onChanges()
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.setAcquaintanceInfoOnJoin,
                                              callback: function ($$v) {
                                                _vm.setAcquaintanceInfoOnJoin =
                                                  $$v
                                              },
                                              expression:
                                                "setAcquaintanceInfoOnJoin",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "form-field-col-field",
                                          attrs: { span: 20 },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                margin: "0",
                                                padding: "0",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$root.strings
                                                      .directEmailMarketing
                                                      ._setnote_explain
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                {
                                  staticClass: "form-field-row zero-margin-row",
                                  staticStyle: { "margin-bottom": "30px" },
                                  attrs: { gutter: 10 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-label",
                                      attrs: { span: 3 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$root.strings
                                                .directEmailMarketing
                                                ._customFields_title
                                            ) +
                                            ": "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-field",
                                      staticStyle: {
                                        "align-self": "center",
                                        "vertical-align": "middle",
                                        "text-align": "center",
                                      },
                                      attrs: { span: 1 },
                                    },
                                    [
                                      _c("custom-checkbox", {
                                        attrs: { hidden: "" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-field",
                                      attrs: { span: 20 },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            margin: "0",
                                            padding: "0",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$root.strings
                                                  .directEmailMarketing
                                                  ._customFields_explain1
                                              ) +
                                              "\n                "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "page-link",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.goToSettings(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$root.strings
                                                      .directEmailMarketing
                                                      ._goToSettings
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$root.strings
                                                  .directEmailMarketing
                                                  ._customFields_explain2
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                {
                                  staticClass: "form-field-row zero-margin-row",
                                  attrs: { gutter: 10 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-label",
                                      attrs: { span: 3 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$root.strings
                                                .directEmailMarketing
                                                ._customPolicy_title
                                            ) +
                                            ": "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-field",
                                      staticStyle: {
                                        "align-self": "center",
                                        "vertical-align": "middle",
                                        "text-align": "center",
                                      },
                                      attrs: { span: 1 },
                                    },
                                    [
                                      _c("custom-checkbox", {
                                        attrs: { hidden: "" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-field",
                                      attrs: { span: 20 },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            margin: "0",
                                            padding: "0",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$root.strings
                                                  .directEmailMarketing
                                                  ._customPolicy_explain1
                                              ) +
                                              "\n                "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "page-link",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.goToSettings(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$root.strings
                                                      .directEmailMarketing
                                                      ._goToSettings
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$root.strings
                                                  .directEmailMarketing
                                                  ._customPolicy_explain2
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.steps.confirmationEmail.enabled &&
      _vm.demEntryVisible === true &&
      _vm.loading === false
        ? _c(
            "section-content",
            {
              attrs: {
                "has-icon": true,
                "icon-progress": _vm.steps.confirmationEmail.progress,
                "icon-text": _vm.steps.confirmationEmail.progressLabel,
              },
            },
            [
              _c("section-title", {
                attrs: {
                  title:
                    _vm.$root.strings.directEmailMarketing
                      ._confirmationEmail_title,
                  subtitle:
                    _vm.$root.strings.directEmailMarketing
                      ._confirmationEmail_subtitle,
                },
              }),
              _vm._v(" "),
              _c("button-tab", {
                staticStyle: { "margin-bottom": "30px" },
                attrs: {
                  tabs: _vm.confirmationEmailTabs,
                  "initial-tab": _vm.confirmationEmailTab,
                },
                on: { tabChanged: _vm.toggleConfirmationEmail },
              }),
              _vm._v(" "),
              _c(
                "transition",
                {
                  attrs: {
                    name: "component-fade-fast",
                    mode: "out-in",
                    appear: "",
                  },
                },
                [
                  _vm.confirmationEmailTab === "CONFIRMATION_EMAIL_ENABLED"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "form-field-row zero-margin-row",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$root.strings.directEmailMarketing
                                            ._name
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-field",
                                  attrs: { span: 21 },
                                },
                                [
                                  _c("custom-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$root.strings.directEmailMarketing
                                          ._senderName,
                                    },
                                    on: {
                                      change: function () {
                                        _vm.onChanges(false)
                                        _vm.autoSaveConfirmationEmail(this$1, {
                                          eventId: _vm.eventId,
                                          campaignId: _vm.campaignId,
                                          senderName:
                                            _vm.confirmationEmailSenderName,
                                        })
                                      },
                                    },
                                    model: {
                                      value: _vm.confirmationEmailSenderName,
                                      callback: function ($$v) {
                                        _vm.confirmationEmailSenderName =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "confirmationEmailSenderName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "form-field-row zero-margin-row",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$root.strings.directEmailMarketing
                                            ._email
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: 18 } },
                                [
                                  !_vm.editConfirmationEmail &&
                                  !(
                                    _vm.identity === "DEFAULT" ||
                                    (_vm.verifiedEmailIdentities.length < 1 &&
                                      _vm.verifiedDomainIdentities.length < 1)
                                  )
                                    ? _c("custom-input", {
                                        attrs: {
                                          placeholder: "Email",
                                          readonly: "",
                                        },
                                        model: {
                                          value:
                                            _vm.confirmationEmailSenderEmail,
                                          callback: function ($$v) {
                                            _vm.confirmationEmailSenderEmail =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression:
                                            "confirmationEmailSenderEmail",
                                        },
                                      })
                                    : _vm.identity === "DEFAULT" ||
                                      (_vm.verifiedEmailIdentities.length < 1 &&
                                        _vm.verifiedDomainIdentities.length < 1)
                                    ? _c("custom-input", {
                                        attrs: {
                                          placeholder: "Email",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.defaultEmail,
                                          callback: function ($$v) {
                                            _vm.defaultEmail =
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                          },
                                          expression: "defaultEmail",
                                        },
                                      })
                                    : _vm.editConfirmationEmail &&
                                      _vm.identity === "DOMAIN" &&
                                      _vm.verifiedDomainIdentities.length >= 1
                                    ? _c(
                                        "el-row",
                                        { staticStyle: { padding: "0" } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 11 } },
                                            [
                                              _c("custom-input", {
                                                attrs: {
                                                  placeholder:
                                                    _vm.$root.strings
                                                      .directEmailMarketing
                                                      ._username,
                                                },
                                                on: {
                                                  change: function () {
                                                    return _vm.onDomainInputChanged(
                                                      true
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.confirmationEmailDomainUser,
                                                  callback: function ($$v) {
                                                    _vm.confirmationEmailDomainUser =
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                  },
                                                  expression:
                                                    "confirmationEmailDomainUser",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 2 } },
                                            [
                                              _c("custom-input", {
                                                staticStyle: {
                                                  width: "100%",
                                                  "text-align": "center",
                                                },
                                                attrs: {
                                                  value: "@",
                                                  readonly: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 11 } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass:
                                                    "domain-selector",
                                                  attrs: {
                                                    "popper-class":
                                                      "default-popper",
                                                    size: "large",
                                                    clearable: "",
                                                    placeholder:
                                                      _vm.$root.strings
                                                        .directEmailMarketing
                                                        ._selectOneVerifiedDomain,
                                                  },
                                                  on: {
                                                    change: function () {
                                                      return _vm.onDomainInputChanged(
                                                        true
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.confirmationEmailDomainName,
                                                    callback: function ($$v) {
                                                      _vm.confirmationEmailDomainName =
                                                        $$v
                                                    },
                                                    expression:
                                                      "confirmationEmailDomainName",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.verifiedDomainIdentities,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item,
                                                      attrs: {
                                                        label: item,
                                                        value: item,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.editConfirmationEmail &&
                                      _vm.identity === "EMAIL" &&
                                      _vm.verifiedEmailIdentities.length >= 1
                                    ? _c(
                                        "el-row",
                                        { staticStyle: { padding: "0" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "domain-selector",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                "popper-class":
                                                  "default-popper",
                                                size: "large",
                                                clearable: "",
                                                "aria-label": "",
                                                placeholder:
                                                  _vm.$root.strings
                                                    .directEmailMarketing
                                                    ._selectOneVerifiedEmail,
                                              },
                                              on: {
                                                change: function (value) {
                                                  _vm.confirmationEmailSenderEmail =
                                                    value
                                                  _vm.autoSaveConfirmationEmail(
                                                    this$1,
                                                    {
                                                      eventId: _vm.eventId,
                                                      campaignId:
                                                        _vm.campaignId,
                                                      senderEmail:
                                                        _vm.confirmationEmailSenderEmail,
                                                    }
                                                  )
                                                  _vm.onChanges(false)
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.confirmationEmailSenderEmail,
                                                callback: function ($$v) {
                                                  _vm.confirmationEmailSenderEmail =
                                                    $$v
                                                },
                                                expression:
                                                  "confirmationEmailSenderEmail",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: "",
                                                    hidden: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$root.strings
                                                          .directEmailMarketing
                                                          ._selectOneVerifiedEmail
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.verifiedEmailIdentities,
                                                function (verifiedIdentity) {
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: verifiedIdentity,
                                                      attrs: {
                                                        label: verifiedIdentity,
                                                        value: verifiedIdentity,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            verifiedIdentity
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn gray-btn save full-column-button",
                                    on: {
                                      click: function () {
                                        return (_vm.editConfirmationEmail =
                                          !_vm.editConfirmationEmail)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.editConfirmationEmail &&
                                            _vm.identity !== "DEFAULT" &&
                                            (_vm.verifiedEmailIdentities
                                              .length !== 0 ||
                                              _vm.verifiedDomainIdentities
                                                .length !== 0)
                                            ? _vm.$root.strings
                                                .directEmailMarketing._save
                                            : _vm.$root.strings
                                                .directEmailMarketing._modify
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          (_vm.identity === "DEFAULT" ||
                            (_vm.verifiedEmailIdentities.length < 1 &&
                              _vm.verifiedDomainIdentities.length < 1)) &&
                          _vm.editConfirmationEmail
                            ? _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-label",
                                      attrs: { span: 3 },
                                    },
                                    [_vm._v("   ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-field",
                                      attrs: { span: 21 },
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticStyle: { padding: "5px" } },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$root.strings
                                                  .directEmailMarketing
                                                  ._defaultEmailExplanation1
                                              ) +
                                              "\n              "
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "page-link",
                                              attrs: {
                                                href: "/settings#domain-setup",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$root.strings
                                                      .directEmailMarketing
                                                      ._goToProfileSettings
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$root.strings
                                                  .directEmailMarketing
                                                  ._defaultEmailExplanation2
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.confirmationEmailSubjectDisabled === false
                            ? _c(
                                "el-row",
                                {
                                  staticClass: "form-field-row zero-margin-row",
                                  attrs: { gutter: 20 },
                                },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-label",
                                      attrs: { span: 3 },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$root.strings
                                                .directEmailMarketing._subject
                                            ) +
                                            "\n            "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "form-field-col-field",
                                      attrs: { span: 21 },
                                    },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          attrs: {
                                            effect: "dark",
                                            content:
                                              _vm.$root.strings
                                                .directEmailMarketing
                                                ._subjectExplanation,
                                            placement: "bottom-start",
                                          },
                                        },
                                        [
                                          _c("custom-input", {
                                            attrs: { readonly: "" },
                                            model: {
                                              value:
                                                _vm.confirmationEmailSubject,
                                              callback: function ($$v) {
                                                _vm.confirmationEmailSubject =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression:
                                                "confirmationEmailSubject",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.demEntryVisible === true && _vm.loading === false
                            ? _c(
                                "section-content",
                                [
                                  _c("section-title", {
                                    attrs: {
                                      title:
                                        _vm.$root.strings.directEmailMarketing
                                          ._emailBuilderTitle,
                                      subtitle:
                                        _vm.$root.strings.directEmailMarketing
                                          ._emailBuilderSubtitle,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "component-fade-fast",
                                        mode: "out-in",
                                        appear: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-row",
                                            {
                                              staticClass:
                                                "form-field-row zero-margin-row",
                                              attrs: { gutter: 30 },
                                            },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "btn gray-btn",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return (function () {
                                                            return _vm.loadTemplates(
                                                              true
                                                            )
                                                          })($event)
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$root.strings
                                                              .directEmailMarketing
                                                              ._createEmail
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 12 } },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "btn gray-btn",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return (function () {
                                                            return _vm.loadTemplates(
                                                              true
                                                            )
                                                          })($event)
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$root.strings
                                                              .directEmailMarketing
                                                              ._startfromtemplate
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.confirmationEmailPreview
                                            ? _c("section-title", {
                                                attrs: {
                                                  title: "Email preview",
                                                  "show-separator": false,
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.confirmationEmailPreview
                                            ? _c(
                                                "el-row",
                                                {
                                                  staticClass:
                                                    "form-field-row zero-margin-row",
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticClass:
                                                        "email-preview",
                                                      attrs: { span: 24 },
                                                    },
                                                    [
                                                      _c("iframe", {
                                                        attrs: {
                                                          id: "confirmationEmailPreviewFrame",
                                                          srcdoc:
                                                            _vm.confirmationEmailPreview,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.confirmationEmailPreview
                                            ? _c(
                                                "el-row",
                                                {
                                                  staticClass:
                                                    "form-field-row zero-margin-row",
                                                  attrs: { gutter: 30 },
                                                },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "btn gray-btn",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return (function () {
                                                                return _vm.loadTemplateBuilder(
                                                                  _vm.confirmationEmailTemplate,
                                                                  true
                                                                )
                                                              })($event)
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                _vm.$root
                                                                  .strings
                                                                  .directEmailMarketing
                                                                  ._modifyEmail
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.demEntryVisible === true && _vm.loading === false
        ? _c(
            "section-content",
            {
              attrs: {
                "has-icon": true,
                "icon-progress": _vm.steps.audience.progress,
                "icon-text": _vm.steps.audience.progressLabel,
              },
            },
            [
              _c("section-title", {
                attrs: {
                  title: _vm.$root.strings.directEmailMarketing._audience_title,
                  subtitle:
                    _vm.$root.strings.directEmailMarketing._audience_subtitle,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "group-manager-members-list" },
                [
                  _c("i", {
                    staticClass: "fa fa-users",
                    staticStyle: {
                      "margin-right": "15px",
                      "font-size": "20px",
                      "margin-bottom": "20px",
                    },
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "margin-bottom": "20px",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$root.strings.datagrid._selected) +
                          ": " +
                          _vm._s(_vm.audienceSize) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "data-table",
                    {
                      ref: "table",
                      attrs: {
                        "get-data": _vm.getData,
                        columns: _vm.columns,
                        "selection-enabled": _vm.audienceOption === "CUSTOM",
                        "page-size": 15,
                        "on-selection-change": _vm.onSelectionChange,
                        "bulk-options": ["clearSelection"],
                        "on-bulk-action": _vm.onBulkAction,
                        "bulk-option-placeholder": "_options",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "memberName",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(row.member.name) +
                                    "\n        "
                                ),
                              ])
                            },
                          },
                          {
                            key: "memberSurname",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(row.member.surname) +
                                    "\n        "
                                ),
                              ])
                            },
                          },
                          {
                            key: "memberEmail",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      row.member.email.includes(
                                        "internal.penguinpass.it"
                                      )
                                        ? ""
                                        : row.member.email
                                    ) +
                                    "\n        "
                                ),
                              ])
                            },
                          },
                          {
                            key: "deliveryStatus",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                [
                                  "SEND",
                                  "DELIVERED",
                                  "OPENED",
                                  "CLICKED",
                                  "BOUNCED",
                                  "REJECTED",
                                  "COMPLAINED",
                                ].indexOf(row.deliveryStatus) !== -1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "p-table-cell",
                                        staticStyle: {
                                          "text-align": "left !important",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "dark",
                                              content: row.deliveryStatus
                                                ? row.deliveryStatus ===
                                                  "COMPLAINED"
                                                  ? "unsubscribed"
                                                  : row.deliveryStatus.toLowerCase()
                                                : "-",
                                              placement: "bottom",
                                            },
                                          },
                                          [
                                            _c(
                                              "font-awesome-layers",
                                              { staticClass: "fa-lg" },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: ["fas", "circle"],
                                                    transform: "grow-6",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("font-awesome-icon", {
                                                  style: { color: "black" },
                                                  attrs: {
                                                    icon: _vm
                                                      .deliveryStatusIcons[
                                                      row.deliveryStatus
                                                    ],
                                                    transform: "shrink-4",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "p-table-cell",
                                        staticStyle: {
                                          "text-align": "left !important",
                                        },
                                      },
                                      [_vm._v("-")]
                                    ),
                              ])
                            },
                          },
                          {
                            key: "status",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "p-table-cell",
                                    staticStyle: {
                                      "text-align": "left !important",
                                    },
                                  },
                                  [
                                    _c(
                                      "font-awesome-layers",
                                      {
                                        staticClass: "fa-lg",
                                        staticStyle: { "margin-right": "10px" },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          style: _vm.statusStyle[row.status],
                                          attrs: {
                                            icon: ["fas", "circle"],
                                            transform: "grow-6",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("font-awesome-icon", {
                                          style: { color: "white" },
                                          attrs: {
                                            icon: _vm.statusIcons[row.status],
                                            transform: "shrink-4",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.statusDescription[row.status]
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        297520280
                      ),
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "options" }, slot: "options" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-gray full-column-button",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return (function () {
                                    return (_vm.filtersVisible =
                                      !_vm.filtersVisible)
                                  })($event)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "filter", transform: "grow-4" },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { attrs: { slot: "filterView" }, slot: "filterView" },
                        [
                          _c("audience-option-group", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.filtersVisible,
                                expression: "filtersVisible",
                              },
                            ],
                            attrs: {
                              "audience-options": _vm.audienceOptions,
                              "event-id": _vm.eventId,
                              "custom-field-filter": _vm.customFieldFilter,
                              "on-audience-option-changed":
                                _vm.onAudienceOptionChanged,
                              "initial-value": _vm.initialOption,
                              "hide-counter": true,
                              "stop-calculate-eligible-recipient-count": true,
                            },
                          }),
                          _vm._v(" "),
                          _vm.audienceOption === "CUSTOM_FIELD" &&
                          _vm.filtersVisible
                            ? _c("custom-field-filter", {
                                key: "CUSTOM_FIELD",
                                attrs: {
                                  "custom-field-map":
                                    _vm.event.combinedCustomFields,
                                  "on-filter-changed": _vm.onFilterChanged,
                                  "initial-value": _vm.initialFilter,
                                },
                              })
                            : _vm.audienceOption === "TIME_SLOT" &&
                              _vm.filtersVisible
                            ? _c("custom-field-filter", {
                                key: "TIME_SLOT",
                                attrs: {
                                  "custom-field-map": _vm.event.timeSlotMap,
                                  "on-filter-changed": _vm.onFilterChanged,
                                  "initial-value": _vm.initialFilter,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "zero-margin-row" },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _vm.hasChanges
                      ? _c(
                          "a",
                          {
                            staticClass: "btn gray-btn",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.updateCampaign($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$root.strings.directEmailMarketing
                                    ._save_changes
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "btn gray-btn",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.sendCampaign($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$root.strings.directEmailMarketing._send
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section-content",
        { attrs: { "has-icon": true } },
        [
          _c("section-title", {
            attrs: {
              title: _vm.$root.strings.directEmailMarketing._recap,
              subtitle: _vm.$root.strings.directEmailMarketing._recapsubtitle,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "group-manager-members-list" },
            [
              _c("data-table", {
                ref: "campaignsTable",
                attrs: {
                  "get-data": _vm.getPastCampaigns,
                  columns: _vm.campaignGridColumns,
                  "selection-enabled": false,
                  "filter-enabled": false,
                  "no-data-text":
                    _vm.$root.strings.directEmailMarketing._noCampaigns,
                },
                scopedSlots: _vm._u([
                  {
                    key: "refresh-header",
                    fn: function (ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "background-color": "transparent",
                                padding: "0",
                                margin: "0",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.updateCampaignTableStatistics(
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: "sync",
                                  spin: _vm.refreshingPastCampaigns,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    },
                  },
                  {
                    key: "refresh",
                    fn: function (ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          row.status === "QUEUED"
                            ? _c("font-awesome-icon", {
                                attrs: { icon: "sync", spin: "" },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    },
                  },
                  {
                    key: "sentAt",
                    fn: function (ref) {
                      var row = ref.row
                      return _c("span", {}, [
                        row.status === "QUEUED"
                          ? _c(
                              "a",
                              {
                                staticClass: "action_link",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return (function () {
                                      return _vm.onCancelCampaign(row)
                                    })($event)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "times" },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$root.strings.directEmailMarketing
                                        ._cancel_campaign
                                    ) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    row.sentAt
                                      ? new Date(row.sentAt).format()
                                      : " - "
                                  ) +
                                  "\n          "
                              ),
                            ]),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }