<template>
  <div
    class="data-grid-container"
    style="overflow:auto;">
    <table>
      <thead>
        <tr>
          <th
            v-for="key in columns"
            :key="key"
            :class="{ active: sortKey === key }"
            @click="sortBy(key)">
            {{ key | capitalize }}
            <span
              class="arrow"
              :class="sortOrders[key] > 0 ? 'asc' : 'dsc'"></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="entry in filteredData"
          :id="entry['id']"
          :key="entry['id']"
          :class="[entry['status'] === 'QUEUED' ? 'tableRowItem-queued' : 'tableRowItem']">
          <td v-for="key in columns"
                :key="key">
            <div v-if="entry['status'] === 'QUEUED' && key === 'queued'">
                {{ entry['queued'] }}<loading-icon />
            </div>
            <div v-else-if="entry['status'] === 'QUEUED' && key === 'date'">
                <a
                    class="action_link"
                    style=""
                    @click="onCancelCampaign(entry)"
                    >
                    <i class="fa fa-times" /> {{ string.directEmailMarketing._cancel_campaign }}
                </a>
            </div>

            <div v-else
                v-html="getKey(entry,key)">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>

    table {
        border: 2px solid #0057B8;
        border-radius: 3px;
        background-color: #000000;
        width: 100%;
    }

    th {
        background-color: #0057B8;
        color: rgba(255,255,255,0.66);
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    td {
        background-color: transparent;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    th, td {
        min-width: 120px;
        padding: 10px 20px;
        text-align: left;
    }

    th.active .arrow {
        opacity: 1;
    }

    .arrow {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        margin-left: 5px;
        opacity: 0.66;
    }
    /* tr.tableRowItem:hover td {
        background-color: rgba(255,255,255,0.3);
        color: #fff;
    } */
    tr.tableRowItem-queued td {
        background-color: rgba(255,255,255,0.3);
        color: #fff;
    }
    tr.tableRowItem{
        max-height: 45px;
        min-height: 45px;
        height: 45px;
    }

</style>
<script>
import userdata from './../userdata.js';
import LoadingIcon from "../components/LoadingIcon.vue";

export default {
    components: {
            LoadingIcon
        },
    filters: {
        capitalize: function (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        columns: {
            type: Array,
            default: () => []
        },
        filterKey: {
            type: String,
            default: ""
        },
        onCancelCampaign: {
            type: Function,
            default: (row) => {}
        }
    },
    data: function () {
        const sortOrders = {};

        this.columns.forEach((key) => {
            sortOrders[key] = 1;
        });

        return {
            sortKey: '',
            sortOrders: sortOrders,
            elements : this.data,
            filteredData : [],
            string: {}
        };
    },
    computed: {
        rowClass: function(entry) {
            return entry['status'] === 'QUEUED' ? { 'tableRowItem-queued': true, 'tableRowItem': false } : { 'tableRowItem-queued': false,'tableRowItem': true };
        }
    },
    watch: {
        data: {
            immediate: true,
            handler(newVal, oldVal) {
              this.setFilteredData();
            }
        }
    },
    created(){
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener("setLanguage", () => {
        context.string = context.$root.strings;
        });
    },
    mounted (){
        this.setFilteredData();
    },
    methods: {
        setFilteredData: function (){

            let sortKey = this.sortKey;

            const filterKey = this.filterKey &&  this.filterKey.toLowerCase();

            const order = this.sortOrders[sortKey] || 1;

            let data = this.data;

            if (filterKey) {

                data = data.filter((row) => {
                    return Object.keys(row).some((key) => {
                        return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
                    });
                });

            }

            if (sortKey) {

                data = data.slice().sort((a, b) => {
                    sortKey = (sortKey === 'date') ? 'sentAt' : sortKey;
                    a = a[sortKey];
                    b = b[sortKey];
                    return (a === b ? 0 : a > b ? 1 : -1) * order;
                });

            }

            this.filteredData = data;
        },
        sortBy: function (key) {

            this.sortKey = key;

            this.sortOrders[key] = this.sortOrders[key] * -1;

            this.setFilteredData();

        },
        tryReturnDateAsString: function (d) {

            try {

                return new Date(d).toLocaleDateString();

            } catch (e) {

                return '';

            }

        },
        getKey: function (entry, key) {

            if (key.toLowerCase() === 'date'){
                return entry['sentAt'] ? (new Date(entry['sentAt'])).format() : ' - ';
            }
            if (key.toLowerCase() === 'sent'){
                return entry['send'] || ' - ';
            }

            if (key.toLowerCase() === 'subject'){
                return entry['finalSubject'] || '';
            }

            if (key.toLowerCase() === 'report')
            {return this.getReportField(entry);}

            return entry[key];
        },
        getReportField : function (entry) {
            const url = 'web/api/mailing/campaign/exportXsl';

            const downloadUrl = '/' + url + '/' + userdata.client_security_code + '/' + entry['id'];

            return '<a style=";background-color: transparent;margin-left:10px;color:green;" href="' + downloadUrl + '" title="Report">' + '<i class="fa fa-file-excel-o" aria-hidden="true"></i></a>';

            // return '<a style=";background-color: white;margin-left:10px;color:green;" title="Report">' + '<i class="fa fa-file-excel-o" aria-hidden="true"></i></a>';
        },

        onRowClicked (entry, key){
            if (key.toLowerCase() === 'report') {
                this.exportReport(entry);
            }
        },

        exportReport : function (entry) {
            const context = this;

            const url = 'web/api/mailing/campaign/exportXsl';

            const downloadUrl = '/' + url + '/' + userdata.client_security_code + '/' + entry['id'];

            return this.$root.axios.get(downloadUrl)
                .then((response) => {

                    if (response.data === 'CAMPAIGN_ID_REQUIRED'){
                        context.$root.bus.emit('simpleToast', 'Internal Error');
                    } else if (response.data === 'EMPTY_LOG'){
                        context.$root.bus.emit('simpleToast', 'Nothing to download');
                    } else if (response.data === 'OK'){
                        console.log('DOWNLOADING');
                    }
                })
                .catch((error) => {

                    if (error.response.message === 'CAMPAIGN_ID_REQUIRED'){
                        context.$root.toastError.emit('modalView', 'error', 'Internal error');
                        console.log(error.response.message);
                    } else if (error.response.message === 'EMPTY_LOG'){
                        context.$root.bus.emit('simpleToast', 'Nothing to download');
                    }
                });

        }
    }

};
</script>
