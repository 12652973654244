<template>
  <MainLayout>
    <div class="uk-theme uk-background-primary uk-light">
      <div ref="container">
        <div class="layer">
          <div data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
            <nav class="uk-navbar-container uk-margin uk-light">
              <div class="uk-container">
                <div class="uk-navbar">
                  <div class="uk-navbar-left">
                    <a class="uk-navbar-item uk-logo uk-text-small" href="/helpdesk/en-home">
                      HELP DESK
                    </a>
                  </div>
                  <div class="uk-navbar-center uk-visible@m">
                    <ul class="uk-navbar-nav">
                      <li>
                        <router-link to="/helpdesk/en-home">
                          Support
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/helpdesk/en-faq">
                          Faq
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="uk-navbar-right">
                    <ul class="uk-navbar-nav">
                      <li>
                        <router-link to="/helpdesk/it-home">
                          <b>IT</b>
                        </router-link>
                      </li>
                      <li class="uk-active"><router-link to="/helpdesk/en-home"><b>EN</b></router-link></li> 
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <router-view></router-view>
          <div class="uk-margin-large-top uk-section-primary">
            <div class="uk-container">
              <hr class="uk-margin-remove">
              <div class="uk-grid-large uk-flex-middle uk-margin-xlarge-top uk-margin-xlarge-bottom" data-uk-grid
                   data-uk-scrollspy="cls: uk-animation-slide-bottom; repeat: true">
                <div class="uk-width-auto@m">
                  <p class="uk-text-large">
                    Non hai trovato una risposta alla tua domanda? Contattaci a <i>
                    <a
                      href="mailto: info@penguinpass.it">
                      info@penguinpass.it
                    </a>
                  </i>
                  </p>
                </div>
                <!--
                <div class="uk-width-expand@m">
                    <a class="tm-button tm-button-xlarge" href="contact.html">Contattaci all' info@penguinpass.it</a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>
<script>
import MainLayout from "../../layouts/Main.vue";

export default {
  name: "HelpDeskEN",
  components: {
    MainLayout
  },
  data() {
    return {};
  }
};
</script>
<style scoped>
@import "../../assets/css/helpdesk/main.css";
</style>
