<template>
  <MainLayout>
    <div id="wrapper">
      <div id="page-content-wrapper">
        <!-- <div id="nav-icon3">
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                </div> -->
        <div class="container-fluid">
          <div class="event-wrapper">
            <!-- Header -->
            <div class="createevent__header">
              <!-- Title -->
              <el-row class="createevent__title" type="flex" align="middle">
                <el-col :span="8">
                  <div style="background: transparent">
                    <h1 style="color: white">
                      {{ string.organizerSettings._pageTitle }}
                    </h1>
                  </div>
                </el-col>
                <el-col :span="6" :offset="10" style="text-align: end">
                  <a
                    class="btn gray-btn save"
                    style="
                      margin: 0px;
                      padding: 10px 20px;
                      display: inline-block;
                      align-content: end;
                    "
                    @click="goBack"
                  >
                    <i class="fa fa-arrow-left"></i>
                  </a>
                  <a
                    v-if="team.uniqueName !== '' && features.publicPages"
                    class="btn gray-btn save"
                    style="
                      margin: 0px;
                      padding: 10px 20px;
                      display: inline-block;
                      align-content: end;
                    "
                    @click="openPublicPage"
                  >
                    <i class="fa fa-external-link"></i>
                  </a>
                </el-col>
              </el-row>
              <!-- Event NavBar -->
              <el-row class="createevent__navbar" type="flex" align="middle">
                <el-col :span="24">
                  <nav id="navigation" class="nav-tabs">
                    <!-- Menu Tab -->
                    <div id="navigationtab" class="navigation-container" role="tablist">
                      <div id="info-tab" role="presentation" class="navigation-item">
                        <a
                          href="#info"
                          class="btn gray-btn save"
                          aria-controls="info"
                          role="tab"
                          data-toggle="tab"
                          @click="() => changeTab('info-tab')"
                        >
                          {{ string.organizerSettings._tabProfile }}
                        </a>
                      </div>
                      <div id="edit-password-tab" role="presentation" class="navigation-item">
                        <a
                          href="#edit-password"
                          class="btn gray-btn save"
                          aria-controls="edit-password-tab"
                          role="tab"
                          data-toggle="tab"
                          @click="() => changeTab('edit-password-tab')"
                        >
                          {{ string.organizerSettings._tabPassword }}
                        </a>
                      </div>
                      <div
                        v-if="$can('access', marketingIdentityTab)"
                        id="event-setup-tab"
                        role="presentation"
                        class="navigation-item"
                      >
                        <a
                          href="#event-setup"
                          class="btn gray-btn save"
                          aria-controls="event-setup-tab"
                          role="tab"
                          data-toggle="tab"
                          @click="() => changeTab('event-setup-tab')"
                        >
                          {{ string.organizerSettings._tabEventSettings }}
                        </a>
                      </div>
                      <div
                        v-if="$can('access', marketingIdentityTab)"
                        id="custom-categories-tab"
                        role="presentation"
                        class="navigation-item"
                      >
                        <a
                          href="#custom-categories"
                          class="btn gray-btn save"
                          aria-controls="custom-categories-tab"
                          role="tab"
                          data-toggle="tab"
                          @click="() => changeTab('custom-categories-tab')"
                        >
                          {{ string.organizerSettings._tabCustomCategories }}
                        </a>
                      </div>
                      <div
                        v-if="$can('access', marketingIdentityTab)"
                        id="domain-setup-tab"
                        role="presentation"
                        class="navigation-item"
                      >
                        <a
                          href="#domain-setup"
                          class="btn gray-btn save"
                          aria-controls="domain-setup-tab"
                          role="tab"
                          data-toggle="tab"
                          @click="() => changeTab('domain-setup-tab')"
                        >
                          {{ string.organizerSettings._tabDomain }}
                        </a>
                      </div>
                      <div
                        v-if="$can('read', unsubscribersTab)"
                        id="unsubscribers-tab"
                        role="presentation"
                        class="navigation-item"
                      >
                        <a
                          href="#unsubscribers"
                          class="btn gray-btn save"
                          aria-controls="unsubscribers-tab"
                          role="tab"
                          data-toggle="tab"
                          @click="() => changeTab('unsubscribers-tab')"
                        >
                          {{ string.organizerSettings._tabUnsubscribers }}
                        </a>
                      </div>
                    </div>
                  </nav>
                </el-col>
              </el-row>
            </div>

            <!-- Dynamic Content -->
            <div class="createevent__body col-xs-12 col-sm-12 col-lg-12">
              <!-- Tab panes -->
              <div class="tab-content">
                <!--              PROFILO -->
                <div id="info" role="tabpanel" class="tab-pane fade active">
                  <div id="panel" class="panel panel-default">
                    <div class="panel-body">
                      <UserInfo></UserInfo>
                    </div>
                  </div>
                </div>

                <!--&lt;!&ndash;              Payment Details&ndash;&gt;-->
                <!--              <div role="tabpanel" class="tab-pane fade" id="payment">-->
                <!--                <div class="panel panel-default">-->
                <!--                  <div class="panel-body">-->
                <!--                    <h4 class="form-section-title first">Dati di Pagamento-->
                <!--                      <small>Inserisci i dati a cui è associato il tuo conto Paypal su cui verranno accreditate le somme relative ai prodotti che venderai.</small>-->
                <!--                    </h4>-->
                <!--                    <div class="smallseparator"></div>-->
                <!--                    <div class="paypal-instructions-box">-->
                <!--                      <div class="row">-->
                <!--                        <div class="col-md-12">Scarica il file con le istruzioni per fornirci le tue credenziali di Paypal</div>-->
                <!--                      </div>-->
                <!--                      <div class="row">-->
                <!--                        <div class="col-md-12"><a href="/download/paypal_istruzioni_account.pdf" download class="btn btn-primary paypal-instructions-link">Download istruzioni</a></div>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                    <div class="row">-->
                <!--                      <div class="col-md-12">-->
                <!--                        <button class="btn btn-primary BtnTogglePaypalFields">Apri pannello dati</button>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                    <form id="bank-account-settings-form" class="form-horizontal">-->
                <!--                      <h4 class="form-section-title no-border-bottom">Nome utente API</h4>-->
                <!--                      <input id="nome-utente-api" name="nome-utente-api" value="{{(!is_null($settings['paypal_nvp_express_account'])) ? $settings['paypal_nvp_express_account']->paypal_api_user : ''}}" type="text" class="form-control bank-account-field" disabled>-->
                <!--                      <h4 class="form-section-title no-border-bottom">Password API</h4>-->
                <!--                      <input id="password-api" name="password-api" type="text" value="{{(!is_null($settings['paypal_nvp_express_account'])) ? $settings['paypal_nvp_express_account']->paypal_api_pwd : ''}}" class="form-control bank-account-field" disabled>-->
                <!--                      <h4 class="form-section-title no-border-bottom">Firma</h4>-->
                <!--                      <input id="firma" name="firma" type="text" value="{{(!is_null($settings['paypal_nvp_express_account'])) ? $settings['paypal_nvp_express_account']->paypal_api_signature : ''}}" class="form-control bank-account-field" disabled>-->
                <!--                      <div class="settings-btn-area">-->
                <!--                        <button id="bank-account-settings-btn-enable-fields" class="btn btn-primary">Abilita modifiche</button>-->
                <!--                        <button id="bank-account-settings-btn-save" class="btn btn-success"><span class="save-btn">Salva</span> <span class="loading-icon glyphicon glyphicon-refresh glyphicon-refresh-animate"></span><span class="loading-txt"> Loading...</span></button>-->
                <!--                        <span class="showAndHide bank success">Dati modificati</span>-->
                <!--                        <span class="showAndHide bank wait">Attendere...</span>-->
                <!--                        <span class="showAndHide bank fail">Errore di operazione</span>-->
                <!--                      </div>-->
                <!--                    </form>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->

                <!--              Password settings-->
                <div id="edit-password" role="tabpanel" class="tab-pane fade">
                  <div class="panel panel-default">
                    <div class="panel-body">
                      <h4 class="form-section-title first">
                        {{ string.organizerSettings._passwordHeader }}
                      </h4>
                      <div class="smallseparator"></div>
                      <form id="form-settings-edit-password" onsubmit="return handleSavePassword()">
                        <input
                          id="settings-modify-password"
                          v-model="organizerPassword"
                          type="password"
                          class="form-control"
                          placeholder="Modifica password"
                          required
                        />
                        <input
                          id="settings-confirm-password"
                          v-model="organizerPasswordConfirm"
                          type="password"
                          class="form-control"
                          placeholder="Conferma password"
                          required
                        />
                        <small>{{ string.commonActions.passwordPolicy }}</small>
                        <div class="settings-btn-area">
                          <a
                            :loading="savingChanges"
                            class="btn gray-btn save"
                            @click="
                              (e) => {
                                e.preventDefault();
                                handleSavePassword();
                              }
                            "
                          >
                            {{ string.organizerSettings._modify }}
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <!--              Event Setup -->
                <div
                  v-if="$can('access', marketingIdentityTab)"
                  id="event-setup"
                  role="tabpanel"
                  class="tab-pane fade active"
                >
                  <div class="panel panel-default">
                    <div class="panel-body">
                      <EventSettings> </EventSettings>
                    </div>
                  </div>
                </div>
                <div
                  v-if="$can('access', marketingIdentityTab)"
                  id="custom-categories"
                  role="tabpanel"
                  class="tab-pane fade active"
                >
                  <div class="panel panel-default">
                    <div class="panel-body">
                      <CustomCategories> </CustomCategories>
                    </div>
                  </div>
                </div>

                <!--              Domain Setup -->
                <div
                  v-if="$can('access', marketingIdentityTab)"
                  id="domain-setup"
                  role="tabpanel"
                  class="tab-pane fade active"
                >
                  <div class="panel panel-default">
                    <div class="panel-body">
                      <IdentitySettings> </IdentitySettings>
                    </div>
                  </div>
                </div>

                <!--              Unsubscribers -->
                <div
                  v-if="$can('read', unsubscribersTab)"
                  id="unsubscribers"
                  role="tabpanel"
                  class="tab-pane fade active"
                >
                  <div class="panel panel-default">
                    <div class="panel-body">
                      <Unsubscribers> </Unsubscribers>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br /><br /><br />
        </div>
      </div>
    </div>
  </MainLayout>
</template>
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../assets/css/bootstrap-toggle.css";
@import "../assets/css/customsidebar.css";
@import "../assets/css/formstyle.css";
@import "../assets/css/preloader.css";
@import "../assets/less/style.css";
@import "../assets/less/createevent.css";
@import "../assets/less/settings.css";
small {
  color: white;
}
#loadingCover {
  background-color: rgba(107, 104, 102, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 500;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loadingCover .loadingCoverMessage {
  width: auto;
  text-transform: uppercase;
  font-size: 30px;
}
.refresh-stats-button {
  margin-bottom: 15px;
}
a.settings {
  cursor: pointer;
  color: #0057B8 !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.settings:hover {
  cursor: pointer;
  color: #0057B8;
  font-size: 20px !important;
  text-decoration: underline;
}
.pagination > li {
  color: white;
}
ul.settings .pagination {
  background-color: white;
}
.settings-wrapper {
  background-image: url("../assets/img/v2/misc/bg-5.jpg");
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-attachment: fixed;
}
#select-identities {
  color: white;
}
option:disabled.settings-option {
  color: white;
}
.panel-body .smallseparator {
  margin-top: -10px;
}
.dark-layer {
  background-color: rgba(0, 7, 10, 0.8);
}

.data-grid-container tbody {
  font-size: 15px !important;
}

.row.tabs-content h4 {
  text-align: center;
}

.list-upload a {
  text-align: center !important;
}

.table {
  margin-top: 50px;
  font-size: 14px;
}

.data {
  font-size: 20px;
}

.no-data {
  padding: 0 30px 30px;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  color: #e8e8e8;
}

a.btn {
  cursor: pointer;
  display: block;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  border: 0 !important;
  margin-bottom: 30px;
  font-family: inherit;
  font-weight: 400;
}

.datatable-container {
  margin-top: 30px;
}

.btn:hover {
  background-color: #dadada !important;
  color: #333;
  cursor: pointer;
  border: 0 !important;
}

small.textSmall {
  font-size: 12px;
}
.downloads_style {
  padding-left: 15px;
}
.downloads_style > i {
  padding-right: 15px;
}
.linkStyle {
  display: inline;
  cursor: pointer;
}
button.linkStyle {
  background: transparent !important;
  border: transparent !important;
  cursor: pointer;
  padding: 1px 0;
}
.row.guestList_links > div > .downloads_style > .linkStyle:hover {
  color: #0057B8;
  text-decoration: underline;
}
.row.guestList_links > button > .downloads_style > .linkStyle:hover {
  color: #0057B8;
  text-decoration: underline;
}
.downloads_style > div > a:hover {
  color: #0057B8 !important;
}
.navigation-container {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.navigation-item {
  display: table-cell;
  padding: 0 5px;
}
.navigation-item.active a {
  background: #0057B8;
}
.navigation-item:first-child {
  padding-left:0;
}
.navigation-item:last-child {
  padding-right:0;
}
</style>
<script>
import $ from "jquery";
import LoadingCover from "@/components/LoadingCover.vue";
import IdentitySettings from "@/components/settings/IdentitySettings";
import EventSettings from "@/components/settings/EventSettings";
import CustomCategories from "@/components/settings/CustomCategories";
import Unsubscribers from "@/components/settings/Unsubscribers";
import UserInfo from "@/components/settings/UserInfo";
import DataGridReport from "@/components/DataGridReport";
import MainLayout from "@/layouts/Main";
import { onLogout } from "@/vue-apollo";
import { UPDATE_USER_PASSWORD } from "@/graphql";
import { mapGetters } from "vuex";

export default {
  components: {
    DataGridReport,
    LoadingCover,
    MainLayout,
    IdentitySettings,
    EventSettings,
    Unsubscribers,
    UserInfo,
    CustomCategories
  },
  computed: {
    ...mapGetters(["features", "team", "user"]),
    marketingIdentityTab() {
      return this.$root.setSubject("marketing_identity", { ...this.team });
    },
    unsubscribersTab() {
      return this.$root.setSubject("team_unsubscriptions", { ...this.team });
    },
  },
  data() {
    return {
      name: "OrganizerSettings",
      string: {},
      organizerPassword: "",
      organizerPasswordConfirm: "",

      loadingEffectshow: false,
      loadingMessage: "",
      savingChanges: false,
    };
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  metaInfo: {
    title: "Settings - Penguinpass",
    bodyAttrs: {
      class: "settings",
    },
  },
  mounted() {
    $(document).ready(() => {
      const navigatorTab = $("#navigationtab");
      const param = location.hash.slice(1);
      navigatorTab.find('a[href="#'+(param || "info")+'"]').tab("show");
      $(`#${(param || "info") + '-tab'}`).addClass("active");
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$root.bus.emit("beforePageClose", {});
    next();
  },
  methods: {
    changeTab(tabName) {
      $("#navigationtab>.active").removeClass("active");
      $(`#${tabName}`).addClass("active");
    },
    goBack() {
      history.back();
    },
    openPublicPage() {
      window.open(`${document.location.origin}/p/${this.team.uniqueName}`);
    },
    handleSavePassword: function () {
      const context = this;
      if (!this.organizerPassword || this.organizerPassword === "") {
        this.$root.bus.emit("simpleToast", this.string.organizerSettings._email_exists);
        return;
      }
      if (!this.isPasswordValid()) {
        this.$root.bus.emit(
          "simpleToastError",
          this.string.organizerSettings._newPassword_warning_message
        );
        return;
      }
      this.savingChanges = true;
      return this.$apollo
        .mutate({
          mutation: UPDATE_USER_PASSWORD,
          variables: {
            password: this.organizerPassword,
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.savingChanges = false;
            return;
          }
          if (response.data && response.data.resetPassUser) {
            const payload = response.data.resetPassUser;
            context.organizerPassword = "";
            context.organizerPasswordConfirm = "";
            context.savingChanges = false;
            context.$root.bus.emit(
              "simpleToast",
              context.string.organizerSettings._password_updated
            );
          }
        })
        .catch((error) => {
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length
          ) {
            if (error.graphQLErrors[0].message === "SAME_PASSWORD") {
              context.$root.bus.emit("simpleToastError", context.string.organizerSettings._same_password);
            }
            if (error.graphQLErrors[0].message === "PASSWORD_NOT_MATCHING_POLICY") {
              context.$root.bus.emit("simpleToastError", context.string.commonActions.passwordPolicy);
            }
          } else {
            console.log(error);
          }
          context.savingChanges = false;
        });
    },
    isPasswordValid: function () {
      return this.organizerPassword === this.organizerPasswordConfirm;
    },
  },
};
</script>
