<template>
    <div class="banner-area">
        <img  v-if="logoEnabled" v-bind:src="srcImage" :event-id="eventId" alt="logo Image" width="50%" class="center" style="margin-bottom: 50px;"/>
        <div class="sample-text- area-black" :style="{ paddingTop: teamUniqueName === 'icc' ? '0px' : '70px' }">
            <img v-if="teamUniqueName === 'icc'" src="https://penguinpass-media.s3.eu-west-1.amazonaws.com/Banner-icc.png" width="100%" style="margin-bottom: 50px;"/>
            <div class="row justify-content-center generic-height align-items-center">
                <div class="banner-content text-center">
                    <img v-if="teamUniqueName === 'lamborghini'" src="https://penguinpass-media.s3.eu-west-1.amazonaws.com/Logo_della_Lamborghini.png" width="150" style="margin-bottom: 50px;"/>
                    <img v-if="teamUniqueName === 'buccellati'" src="https://penguinpass-media.s3.eu-west-1.amazonaws.com/Buccellati_white_logo.png" width="343" style="margin-bottom: 50px;"/>
                    <div class="text-white top text-uppercase">{{title}}</div><br><br>
                    <h1 v-if="joinStatus === 0 || joinStatus === 1" class="text-white text-uppercase">{{ guestName }} {{ guestSurname }}</h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {      
        eventId: {
            type: String,
            required: false,
            default: function () {
                return '';
            }
        },
        event: {
           type: Object
        },
        /**
         * 0 Ok
         * 1 Waiting
         * 2 Declined_OK
         * 3 Already_Joined
         * 4 Already_Declined
         * 5 Bad Token
         * 6 Event not found
         * 7 Event is full
         * 8 Guest not found
         * 9 Policies are not accepted
         * 10 Unhandled
         */
        joinStatus: {
            type: Number,
            required: true,
            default: 0
        },
        guestName: {
            type: String,
            required: false,
            default: function () {
                return '';
            }
        },
        guestSurname: {
            type: String,
            required: false,
            default: function () {
                return '';
            }
        },
        teamUniqueName: {
            type: String,
            required: false,
            default: function () {
                return null;
            }
        },
        srcImage: {
            type: String,
            required: false,
            default: function () {
                return null;
            }
        },
        logoEnabled: {
            type: Boolean,
            required: false,
            default: function () {
                return false;
            }
        },
    },
    data (){
        return {
            componentName : 'join-header',
            string: {},
            title: ''
        };
    },
    watch: {
        joinStatus: {
            immediate: true,
            handler (newVal, oldVal) {
                if(!this.string) {
                    this.string = this.$root.strings
                }
               switch (newVal) {
                    case 0:{
                        this.title = this.$root.strings.joinPage._join_title_accepted;
                        break;
                    }
                    case 1:{
                        this.title = this.$root.strings.joinPage._join_reservedfor;
                        break;
                    }
                    case 2:{
                        this.title = this.$root.strings.joinPage._join_title_declined;
                        break;
                    }
                    case 3:{
                        this.title = this.$root.strings.joinPage._join_title_already_accept;
                        break;
                    }
                    case 4:{
                        this.title = this.$root.strings.joinPage._join_title_already_decline;
                        break;
                    }
                    case 6:{
                        this.title = this.$root.strings.joinPage._join_error_event_not_found;
                        break;
                    }
                    case 7:{
                        this.title = this.$root.strings.joinPage._join_error_event_full;
                        break;
                    }
                    case 9:{
                        this.title = this.$root.strings.joinPage._join_error_policies_not_accepted;
                        break;
                    }
                    default: {
                        this.title = this.$root.strings.joinPage._join_error_invalid_invitation;
                        break;
                    }
               }
            }
        }
    },
    created (){
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
};
</script>

<style scoped>
    @import '~bootstrap/dist/css/bootstrap.min.css';
    @import '~font-awesome/css/font-awesome.min.css';
    @import '../../assets/css/v2/style.css';
    @import '../../assets/css/v2/custom.css';
    @import '../../assets/css/v2/join.css';

    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 20%;
    }

    .field{
        padding: 10px 0 10px 0;
        margin: 0 0 5px 0;
    }

    .actions{
        display: inline-block;
        width: 20%;
        text-align: left;
        vertical-align: top;
    }

    /*checkbox*/

    .control {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;
        padding-top: 3px;
        font-weight: 400;
        cursor: pointer;
        color: #555555;
    }
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .control_indicator {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background: #e6e6e6;
        border: 0px solid #000000;
    }

    .control:hover input ~ .control_indicator,
    .control input:focus ~ .control_indicator {
        background: #cccccc;
    }

    .control input:checked ~ .control_indicator {
        background: #000;
    }
    .control:hover input:not([disabled]):checked ~ .control_indicator,
    .control input:checked:focus ~ .control_indicator {
        background: #000;
    }
    .control input:disabled ~ .control_indicator {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        box-sizing: unset;
        content: '';
        position: absolute;
        display: none;
    }
    .control input:checked ~ .control_indicator:after {
        display: block;
    }
    .control-checkbox .control_indicator:after {
        left: 8px;
        top: 4px;
        width: 3px;
        height: 8px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .control-checkbox input:disabled ~ .control_indicator:after {
        border-color: #7b7b7b;
    }

    .policy-action {
        margin-top: 15px;
    }

    .confirm-button {
        display: block;
        color: #777;
        border: 1px solid #000;
        background: #fff;
        width: 100%;
        max-width: 50%;
        margin: 50px auto 30px;
        padding: 13px;
        cursor: pointer;
        transition: 1s ease;
    }

    .confirm-button:hover {
        color: #fff;
        background: #000;
        border: 1px solid transparent;
    }

    .actions .title {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 30px;
    }
    .text-white.top.text-uppercase{
        font-size: 18px;
    }
    .sample-text-area>i{
        font-size: 130px;
    }
</style>