var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "form-section-title first" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.string.organizerSettings._domainHeader) +
            "\n    "
        ),
        _c("small", [
          _vm._v(_vm._s(_vm.string.organizerSettings._recapSubtitle)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "smallseparator" }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "loader-section",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [
          _vm.loadingEffectshow
            ? _c("LoadingCover", {
                attrs: { "loading-cover-msg": _vm.loadingMessage },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "add-new-identity",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [
          _c(
            "section",
            { staticClass: "add-identity-section" },
            [
              _c("h4", { staticClass: "form-section-title" }, [
                _vm._v(
                  _vm._s(_vm.string.organizerSettings._select_identity_method)
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { color: "white" }, attrs: { hidden: "" } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.listenStoreChanges) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    disabled:
                      _vm.emailIdentities.length <= 0 &&
                      _vm.domainIdentityTabs.length <= 0,
                    content: _vm.string.organizerSettings._remove_existing,
                    placement: "right-end",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled:
                          _vm.emailIdentities.length > 0 ||
                          _vm.domainIdentityTabs.length > 0,
                        "popper-class": "default-popper",
                        clearable: "",
                        size: "large",
                        placeholder:
                          _vm.string.organizerSettings._selectIdentityType,
                      },
                      on: {
                        change: function (e) {
                          _vm.onOptionChanged(e)
                        },
                      },
                      model: {
                        value: _vm.identityType,
                        callback: function ($$v) {
                          _vm.identityType = $$v
                        },
                        expression: "identityType",
                      },
                    },
                    _vm._l(_vm.identityOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.label,
                          value: item.value,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.updatingOption
                    ? _c("span", [_c("loading-icon")], 1)
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.identityType === "DOMAIN"
                ? _c("div", [
                    _c("h4", { staticClass: "form-section-title" }, [
                      _vm._v(
                        _vm._s(_vm.string.organizerSettings._newDomainSection)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputDomainIdentity,
                          expression: "inputDomainIdentity",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.string.organizerSettings._domain,
                      },
                      domProps: { value: _vm.inputDomainIdentity },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.inputDomainIdentity = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "settings-btn-area btn gray-btn btn-primary",
                        attrs: { id: "add-identity-form-submit-domain" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addNewDomain()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.string.organizerSettings._add_new_domain
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.identityType === "EMAIL"
                ? _c("div", [
                    _c("h4", { staticClass: "form-section-title" }, [
                      _vm._v(
                        _vm._s(_vm.string.organizerSettings._newEmailSection)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.inputEmailIdentity,
                          expression: "inputEmailIdentity",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.string.organizerSettings._email,
                      },
                      domProps: { value: _vm.inputEmailIdentity },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.inputEmailIdentity = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "settings-btn-area btn btn-primary",
                        attrs: { id: "add-identity-form-submit-domain" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addNewEmail()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "save-btn" }, [
                          _vm._v(
                            _vm._s(_vm.string.organizerSettings._add_new_email)
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "email-identities",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [
          _vm.identityType === "EMAIL" && _vm.emailIdentities.length > 0
            ? _c(
                "section",
                { staticClass: "identity-section" },
                [
                  _c("h4", { staticClass: "form-section-title first" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.string.organizerSettings._email_recap) +
                        "\n        "
                    ),
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm.string.organizerSettings._email_recap_subtitle
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "smallseparator" }),
                  _vm._v(" "),
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "component-fade-fast",
                        mode: "out-in",
                        appear: "",
                      },
                    },
                    [
                      _vm.emailIdentities.length
                        ? [
                            _c("div", { staticClass: "refresh-stats-button" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "settings",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleUpdateIdentities()
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-refresh",
                                    class: !!_vm.updatingEmailIdentities
                                      ? "rotate-center"
                                      : "",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.string.organizerSettings
                                          ._refreshIdentities
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "component-fade-fast",
                        mode: "out-in",
                        appear: "",
                      },
                    },
                    [
                      _vm.identityType === "EMAIL"
                        ? _c(
                            "div",
                            {
                              class: !!_vm.updatingEmailIdentities
                                ? "datatable no-data"
                                : "",
                            },
                            [
                              !!_vm.updatingEmailIdentities
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-grid-container-status-message",
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.string.organizerSettings
                                                ._updatingIdentities
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.updatingEmailIdentities
                                ? [
                                    _c("DataGridReport", {
                                      attrs: {
                                        data: _vm.emailIdentities,
                                        columns: _vm.emailIdentityColumns,
                                        "enable-actions": true,
                                      },
                                      on: {
                                        deleteIdentity: _vm.deleteIdentity,
                                      },
                                    }),
                                  ]
                                : !_vm.updatingEmailIdentities
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "data-grid-container-status-message",
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.string.organizerSettings
                                                ._no_data
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "domain-identities",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [
          _vm.identityType === "DOMAIN" && _vm.domainIdentityTabs.length > 0
            ? _c(
                "el-tabs",
                { attrs: { type: "border-card" } },
                _vm._l(_vm.domainIdentityTabs, function (domain) {
                  return _c(
                    "el-tab-pane",
                    { key: domain, attrs: { label: domain } },
                    [
                      _c(
                        "section",
                        { staticClass: "identity-section" },
                        [
                          _c(
                            "h4",
                            { staticClass: "form-section-title first" },
                            [
                              _c(
                                "small",
                                { staticStyle: { "text-transform": "none" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.string.organizerSettings
                                        ._domain_desc_1
                                    ) + "\n              "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "show-more",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.setShowMore(!_vm.showMore)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.showMore
                                            ? _vm.string.organizerSettings
                                                ._read_more
                                            : _vm.string.organizerSettings
                                                ._read_less
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showMore
                                ? _c("div", [
                                    _c(
                                      "small",
                                      {
                                        staticStyle: {
                                          "text-transform": "none",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.organizerSettings
                                              ._domain_desc_2
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticStyle: {
                                          "text-transform": "none",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.organizerSettings
                                              ._domain_desc_3
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticStyle: {
                                          "text-transform": "none",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.organizerSettings
                                              ._domain_desc_4
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticStyle: {
                                          "text-transform": "none",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.organizerSettings
                                              ._domain_desc_5
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticStyle: {
                                          "text-transform": "none",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.organizerSettings
                                              ._domain_desc_6
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticStyle: {
                                          "text-transform": "none",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.organizerSettings
                                              ._domain_desc_7
                                          )
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-steps",
                            {
                              staticStyle: { padding: "10px" },
                              attrs: { space: "33.33%", "align-center": "" },
                            },
                            [
                              _c("el-step", {
                                attrs: {
                                  title:
                                    _vm.string.organizerSettings._step + " 1",
                                  description:
                                    _vm.string.organizerSettings._step_1,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-step", {
                                attrs: {
                                  title:
                                    _vm.string.organizerSettings._step + " 2",
                                  description:
                                    _vm.string.organizerSettings._step_2,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-step", {
                                attrs: {
                                  title:
                                    _vm.string.organizerSettings._step + " 3",
                                  description:
                                    _vm.string.organizerSettings._step_3,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "transition",
                            {
                              attrs: {
                                name: "component-fade-fast",
                                mode: "out-in",
                                appear: "",
                              },
                            },
                            [
                              _vm.identityType === "DOMAIN" &&
                              _vm.domainIdentityContents[domain] &&
                              _vm.domainIdentityContents[domain].length
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "refresh-stats-button" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "settings",
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.handleUpdateIdentities()
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-refresh",
                                              class:
                                                !!_vm.updatingDomainIdentities
                                                  ? "rotate-center"
                                                  : "",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.string.organizerSettings
                                                    ._refreshIdentities
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "transition",
                            {
                              attrs: {
                                name: "component-fade-fast",
                                mode: "out-in",
                                appear: "",
                              },
                            },
                            [
                              _vm.identityType === "DOMAIN"
                                ? _c(
                                    "div",
                                    {
                                      class: !!_vm.updatingDomainIdentities
                                        ? "datatable no-data"
                                        : "",
                                    },
                                    [
                                      !!_vm.updatingDomainIdentities
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-grid-container-status-message",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.string
                                                        .organizerSettings
                                                        ._updatingIdentities
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm.domainIdentityContents[domain] &&
                                          _vm.domainIdentityContents[domain]
                                            .length &&
                                          !_vm.updatingDomainIdentities
                                        ? [
                                            _c("DataGridReport", {
                                              attrs: {
                                                data: _vm
                                                  .domainIdentityContents[
                                                  domain
                                                ],
                                                columns:
                                                  _vm.domainIdentityColumns,
                                                "enable-actions": false,
                                              },
                                            }),
                                          ]
                                        : [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-grid-container-status-message",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.string
                                                        .organizerSettings
                                                        ._no_data
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ],
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.domainIdentityContents[domain] &&
                          _vm.domainIdentityContents[domain].length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "row guestList_links data",
                                  staticStyle: { padding: "10px 0px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-12 col-md-12 col-lg-12",
                                      staticStyle: { padding: "0" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "downloads_style" },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-cloud-download",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "vue-excel-xlsx",
                                            {
                                              staticClass: "linkStyle",
                                              attrs: {
                                                data: _vm
                                                  .domainIdentityContents[
                                                  domain
                                                ],
                                                columns:
                                                  _vm.domainIdentitiesReportColumns,
                                                filename: "dns_records",
                                                sheetname: "Sheet 1",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.string.organizerSettings
                                                      ._download_record_set
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "downloads_style",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-text":
                                                (_vm.mailFromEnabled[domain]
                                                  ? "Deactivate"
                                                  : "Activate") +
                                                " custom MAIL FROM option (Optional but recommended if possible)",
                                              "active-color": "#0057B8",
                                            },
                                            on: {
                                              change: function (e) {
                                                _vm.toggleMailFrom(e, domain)
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.mailFromEnabled[domain],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.mailFromEnabled,
                                                  domain,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "mailFromEnabled[domain]",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mailFromEnabled[domain]
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "40px",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "form-section-title first",
                                      staticStyle: { "margin-bot": "20px" },
                                    },
                                    [
                                      _c(
                                        "small",
                                        {
                                          staticStyle: {
                                            "text-transform": "none",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.string.organizerSettings
                                                ._mail_from_desc_1
                                            ) + "\n                "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "show-more",
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.setShowMoreMailFrom(
                                                    !_vm.showMoreMailFrom
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  !_vm.showMoreMailFrom
                                                    ? _vm.string
                                                        .organizerSettings
                                                        ._read_more
                                                    : _vm.string
                                                        .organizerSettings
                                                        ._read_less
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.showMoreMailFrom
                                        ? _c("div", [
                                            _c(
                                              "small",
                                              {
                                                staticStyle: {
                                                  "text-transform": "none",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.string.organizerSettings
                                                      ._mail_from_desc_2
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "small",
                                              {
                                                staticStyle: {
                                                  "text-transform": "none",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.string.organizerSettings
                                                      ._mail_from_desc_3
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "component-fade-fast",
                                        mode: "out-in",
                                        appear: "",
                                      },
                                    },
                                    [
                                      _vm.mailFromEnabled[domain] &&
                                      _vm.mailFromContents[domain] &&
                                      _vm.mailFromContents[domain].length
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "refresh-stats-button",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "settings",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.handleUpdateIdentities()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-refresh",
                                                      class:
                                                        !!_vm.updatingDomainIdentities
                                                          ? "rotate-center"
                                                          : "",
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.string
                                                            .organizerSettings
                                                            ._refreshIdentities
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        name: "component-fade-fast",
                                        mode: "out-in",
                                        appear: "",
                                      },
                                    },
                                    [
                                      _vm.mailFromContents[domain] &&
                                      _vm.mailFromContents[domain].length
                                        ? _c(
                                            "div",
                                            {
                                              class:
                                                !!_vm.updatingDomainIdentities
                                                  ? "datatable no-data"
                                                  : "",
                                            },
                                            [
                                              !!_vm.updatingDomainIdentities
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-grid-container-status-message",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.string
                                                                .organizerSettings
                                                                ._updatingIdentities
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                : _vm.mailFromContents[
                                                    domain
                                                  ] &&
                                                  _vm.mailFromContents[domain]
                                                    .length &&
                                                  !_vm.updatingDomainIdentities
                                                ? [
                                                    _c("DataGridReport", {
                                                      attrs: {
                                                        data: _vm
                                                          .mailFromContents[
                                                          domain
                                                        ],
                                                        columns:
                                                          _vm.domainIdentityColumns,
                                                        "enable-actions": false,
                                                      },
                                                    }),
                                                  ]
                                                : [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-grid-container-status-message",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.string
                                                                .organizerSettings
                                                                ._no_data
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.mailFromContents[domain].length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row guestList_links data",
                                          staticStyle: { padding: "10px 0px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-sm-12 col-md-12 col-lg-12",
                                              staticStyle: { padding: "0" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "downloads_style",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-cloud-download",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "vue-excel-xlsx",
                                                    {
                                                      staticClass: "linkStyle",
                                                      attrs: {
                                                        data: _vm
                                                          .mailFromContents[
                                                          domain
                                                        ],
                                                        columns:
                                                          _vm.domainIdentitiesReportColumns,
                                                        filename:
                                                          "mail_from_dns_records",
                                                        sheetname: "Sheet 1",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.string
                                                              .organizerSettings
                                                              ._download_record_set
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.domainIdentityContents[domain] &&
                          _vm.domainIdentityContents[domain].length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "row guestList_links data",
                                  staticStyle: { padding: "40px 0px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-sm-12 col-md-12 col-lg-12",
                                      staticStyle: { padding: "0" },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "delete-domain",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteDomainIdentity(
                                                domain
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.string.organizerSettings
                                                ._deleteDomain
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }