import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
<template>
  <div class="row list-upload">
    <h4>{{ string.listUpload._title }}</h4>
    <div v-if="loading" class="col-sm-12 col-md-12 col-lg-12">
        <transition
        name="component-fade-fast"
        mode="out-in"
        appear>
         <ClipLoader :loading="loading" color="#0057B8"
       size="15px" />
        </transition>
    </div>
    <div v-else class="col-sm-12 col-md-12 col-lg-12">
      <div id="uploadInteractionContainer">
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 left">
            &nbsp;
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 left">
            <input
              ref="fileinput"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFileChange" />
            <transition
              name="component-fade-fast"
              mode="out-in"
              appear>
              <a
                v-if="fileToSelect === true"
                :title="string.listUpload._browse"
                @click="open">
                <i
                  aria-hidden="true"
                  class="fa fa-folder-open"></i>
                {{ string.listUpload._browse }}
              </a>
              <div v-if="fileToSelect === false">
                <p>{{ selectedFileName }}</p>
                <a
                  :title="string.listUpload._clear"
                  class="clear"
                  @click="clear">
                  <i
                    aria-hidden="true"
                    class="fa fa-times"></i>
                  {{ string.listUpload._clear }}
                </a>
              </div>
            </transition>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 left">
            &nbsp;
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 left">
            <div class="template-link-container">
              <a
                :href="xlsTemplateURL"
                class="simple">
                <span class="glyphicon glyphicon-download">
                    &nbsp;
                </span>
                {{ string.listUpload._downloadInstruction }} XLSX
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="formbutton">
        <a
          class="save"
          :title="string.listUpload._load"
          @click="submit">
          {{ string.listUpload._load }}
        </a>
      </div>
    </div>
  </div>
</template>
<style>
    #uploadInteractionContainer a {
        font-size: 16px;
        margin: 0 0 10px;
        padding: 8px;
        color: #fff;
        text-transform: uppercase;
        background-color: rgba(255,255,255,0.4);
        width: 100%;
        display: block;
        cursor: pointer;
        text-align: center;
    }

    #uploadInteractionContainer a.simple {
        cursor: pointer;
        color: #fff !important;
        font-size: 12px !important;
        text-decoration: none;
        background-color: transparent !important;
    }
    #uploadInteractionContainer a.simple:hover {
        text-decoration: underline;
        background-color: transparent !important;
    }

    #uploadInteractionContainer a.clear {
        font-size: 16px;
        margin: 0 0 10px;
        padding: 8px;
        color: #333;
        background-color: transparent !important;
        cursor: pointer;
        width: 100%;
    }

    #uploadInteractionContainer a:hover {
        background-color: #dadada !important;
        color: #333333 !important;
    }

    .formbutton {
        position: relative;
    }

    .formbutton a {
        cursor: pointer !important;
    }

    #uploadInteractionContainer {
        padding: 60px 20px;
        border: solid 1px #333;
        margin-bottom: 20px;
        background-color: rgba(255,255,255,0.4);
    }

    .list-upload .formbutton > a {
        margin: 0 auto;
        display: block;
        width: 100%;
    }

    .list-upload label {
        color: #333;
        font-weight: bold;
        padding-left: 0 !important;
        text-transform: capitalize;
    }

    .list-upload h4 {
        margin-bottom: 20px;
        text-transform: uppercase;
        text-align: center;
    }

</style>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default {
    components: {
        ClipLoader
    },
    props: {
        onSubmit: {
            type: Function,
            required: true,
            default: (file) => Promise.resolve({})
        },
        templateUrl: {
            type: String,
            required: false,
            default: "/download/guest_template.xlsx"
        }
    },
    data () {
        return {
            clearButtonHide: true,
            fileToSelect: true,
            xlsTemplateURL: this.templateUrl,
            string: {},
            selectedFile: '',
            selectedFileName: '',
            loading: false,
        };
    },
    created () {
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    methods: {
        isFileValid() {
            if (this.$refs.fileInput.value === "") {
                this.$root.bus.emit('simpleToast',this.string.listUpload._mandatoryFields);
                return false;
            }
            return true;
        },
        submit: function () {
            if (this.isFileValid()) {
                this.loading = true;
                this.onSubmit(this.selectedFile).finally(() => this.loading = false);
                this.clear();
                return ;
            }
        },
        onFileChange: function (e) {
            this.fileToSelect = false;
            const files = e.target.files || e.dataTransfer.files;
            if (!files.length) {return;}

            this.selectedFile = files[0];
            this.selectedFileName = files[0].name;
        },
        clear: function () {
            this.fileToSelect = true;
            this.$refs.fileInput.value = "";
        },
        open: function () {
            this.$refs.fileInput.click();
        }
    }
};
</script>
