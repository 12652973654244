var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "default-layout" }, [
    _c(
      "div",
      { staticClass: "imageupload-component", attrs: { id: "imageUpload" } },
      [
        _c("div", { staticClass: "imageupload accordion" }, [
          _c("div", { staticClass: "row mt-3" }, [
            _c(
              "div",
              { staticClass: "col-sm-4" },
              [
                _c(
                  "el-upload",
                  {
                    ref: "upload",
                    attrs: {
                      action: "#",
                      "auto-upload": false,
                      "show-file-list": true,
                      multiple: false,
                      "on-change": _vm.uploadActionUrl,
                      "on-remove": _vm.logoRemove,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "small", type: "primary" } },
                      [_vm._v(_vm._s(_vm.string.badgePrinting._formFileUpload))]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "el-upload__tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.string.badgePrinting._formFileUploadTips)
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }