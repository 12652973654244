import gql from "graphql-tag";
import { TEXT_ONLY, FILE_UPLOAD } from '../utils/constants';

const EMAIL_TEMPLATE_DETAIL_FRAGMENT = gql`
  fragment emailTemplateDetailFragment on EmailTemplate {
    id
    displayName
    uniqueName
    subject
    templateContent
    bodyHtml
    createdAt
  }
`;

const CONFIRMATION_EMAIL_DETAIL_FRAGMENT = gql`
  fragment confirmationEmailDetailFragment on EmailConfirmation {
    id
    eventId
    registrationFormId
    campaignId
    senderName
    senderEmail
    emailTemplateId
    emailTemplate {
      ...emailTemplateDetailFragment
    }
    tags
  }
  ${EMAIL_TEMPLATE_DETAIL_FRAGMENT}
`;

const CAMPAIGN_DETAIL_FRAGMENT = gql`
  fragment campaignDetailFragment on EmailCampaigns {
    id
    eventId
    senderEmail
    senderName
    setMaxAcquaintanceOnJoin
    setAcquaintanceInfoOnJoin
    emailTemplateId
    emailTemplate {
      ...emailTemplateDetailFragment
    }
    confirmationEmailOption
    confirmationEmailId
    emailConfirmation {
      ...confirmationEmailDetailFragment
    }
    tags
    selectedGuestIdList
    audienceOption
    audienceOptionFilter
  }
  ${EMAIL_TEMPLATE_DETAIL_FRAGMENT}
  ${CONFIRMATION_EMAIL_DETAIL_FRAGMENT}
`;
const REGISTRATION_FORM_DETAIL_FRAGMENT = gql`
  fragment registrationFormDetailFragment on RegistrationForms {
    id
    eventId
    confirmationEmailOption
    confirmationEmailId
    confirmationEmail {
      ...confirmationEmailDetailFragment
    }
  }
  ${CONFIRMATION_EMAIL_DETAIL_FRAGMENT}
`;
const PAST_CAMPAIGN_DETAIL_FRAGMENT = gql`
  fragment pastCampaignDetailFragment on EmailCampaigns {
    id
    senderEmail
    senderName
    audienceOption
    audienceOptionFilter
    sentAt
    status
    tags
    total
    rejected
    send
    delivered
    opened
    clicked
    queued
    failed
    finalSubject
    isCancelled
  }
`;

export const DELETE_CUSTOM_FIELD_OPTION = gql`
  mutation DELETE_CUSTOM_FIELD_OPTION($eventId: ID! $customFieldSlug: String! $answerSlug: String! $disabled: Boolean) {
    deleteCustomFieldOption(eventId: $eventId customFieldSlug: $customFieldSlug answerSlug: $answerSlug, disabled: $disabled)
  }
`;
export const ENABLE_CUSTOM_FIELD_OPTION = gql`
  mutation ENABLE_CUSTOM_FIELD_OPTION($eventId: ID! $customFieldSlug: String! $answerSlug: String!) {
    enableCustomFieldOption(eventId: $eventId customFieldSlug: $customFieldSlug answerSlug: $answerSlug)
  }
`;
export const DELETE_MEMBER_CUSTOM_FIELD_OPTION = gql`
  mutation DELETE_MEMBER_CUSTOM_FIELD_OPTION($teamId: ID! $memberCustomFieldSlug: String! $answerSlug: String! $disabled: Boolean) {
    deleteMemberCustomFieldOption(teamId: $teamId memberCustomFieldSlug: $memberCustomFieldSlug answerSlug: $answerSlug, disabled: $disabled)
  }
`;
export const ENABLE_MEMBER_CUSTOM_FIELD_OPTION = gql`
  mutation ENABLE_MEMBER_CUSTOM_FIELD_OPTION($teamId: ID! $memberCustomFieldSlug: String! $answerSlug: String!) {
    enableMemberCustomFieldOption(teamId: $teamId memberCustomFieldSlug: $memberCustomFieldSlug answerSlug: $answerSlug)
  }
`;
export const SUBSCRIBE_TO_TEAM_MEMBER_NEWS = gql`
  subscription SUBSCRIBE_TO_TEAM_MEMBER_NEWS($teamId: ID!) {
    teamMember(teamId: $teamId) {
      mutation
      data {
        id
        name
        surname
        email
        accountStatus
        profileStatus
        teamId
        role
        assignedEvents
      }
    }
  }
`;

export const UPDATE_MY_USER = gql`
  mutation UPDATE_MY_USER($updates: UpdateUserInput!) {
    updateMyUser(updates: $updates) {
      id
      name
      surname
      email
      password
      language
      nation
      city
      site
      company
      vatNumber
      privacyCondition
      dataCondition
      processingCondition
      accountStatus
      profileStatus
      teamId
      role
      assignedEvents
      abilities {
        action
        subject
        conditions
        inverted
      }
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation UPDATE_USER_PASSWORD($password: String!) {
    resetPassUser(password: $password) {
      id
      name
      surname
      email
      password
      language
      nation
      city
      site
      company
      vatNumber
      privacyCondition
      dataCondition
      processingCondition
      accountStatus
      profileStatus
      teamId
      role
    }
  }
`;
export const USER_INFO = gql`
  query USER_INFO {
    whoAmIUser {
      id
      name
      surname
      email
      password
      language
      nation
      city
      site
      company
      vatNumber
      privacyCondition
      dataCondition
      processingCondition
      accountStatus
      profileStatus
      assignedEvents
      teamId
      role
      abilities {
        action
        subject
        conditions
        inverted
      }
    }
  }
`;

export const MY_EVENTS = gql`
  query MY_EVENTS($statuses: [EventStatus]) {
    myEvents(statuses: $statuses) {
      id
      name
      image
      vRoomImage
      address
      startDate
      endDate
      genre
      category
      customCategory
      customCategoryLabel
      status
      confirmed
      checkedIn
      virtualRoomJSON
      virtualRoomPages {
        id,
        eventId
        allocatedVideoRoomIds
        externalRoomId
        externalRoomPin
        videoRoomType
        pageStatus
        html
        json
        slug
      }
    }
  }
`;
export const MY_PAGINATED_EVENTS = gql`
  query MY_PAGINATED_EVENTS($exclude: [ID] $onlyEventGroup: Boolean $startDate: DateTime $endDate: DateTime $categories: [EventCategories], $customCategories: [String], $statuses: [EventStatus], $filter: String, $address: String, $eventGroupId: String $pageInfo: PaginationInput) {
    myPaginatedEvents(exclude: $exclude, onlyEventGroup: $onlyEventGroup, startDate: $startDate endDate: $endDate categories: $categories, customCategories: $customCategories, statuses: $statuses, address: $address, filter: $filter, eventGroupId: $eventGroupId pageInfo: $pageInfo) {
      total
      pageSize
      currentPage
      sortOrder
      sortField
      data {
        id
        name
        image
        vRoomImage
        address
        startDate
        endDate
        genre
        category
        customCategory
        customCategoryLabel
        status
        confirmed
        checkedIn
        isEventGroupController
        eventGroupId
        createdAt
        updatedAt
      }
    }
  }
`;

// TODO replace with export report api
export const GUEST_LIST_FOR_REPORT = gql`
  query GUEST_LIST_FOR_REPORT($eventId: ID) {
    Event(eventId: $eventId) {
      id
      guestList {
        id
        memberId
        eventId
        status
        virtualCheckInAt
        deliveryStatus
        customFieldDataMap
        acquaintance
        maxAcquaintance
        acquaintanceAdded
        activity {
          activity
          createdAt
        }
        acquaintanceInfoList {
          name
          surname
          email
        }
        qrCode
        member {
          id
          name
          surname
          telephone
          email
          password
          country
          listEmails
          isRegistered
          memberCustomFieldDataMap
        }
        rsvpDate
        fromCheckinApp
        createdAt
        memberName
        memberSurname
        memberEmail
        memberTelephone
      }
    }
  }
`;
export const GET_EVENT_DETAIL = gql`
  query GET_EVENT_DETAIL($eventId: ID) {
    Event(eventId: $eventId) {
      id
      name
      address
      venue
      descriptionEn
      descriptionIt
      category
      customCategory
      customCategoryLabel
      genre
      typology
      status
      startDate
      endDate
      image
      vRoomImage
      maxGuests
      maxGuestsPerMember
      maxLimitPerSlot
      timeSlotEnabled
      badgePrintingEnabled
      virtualRoomEnabled
      virtualRoomEditorOpened
      virtualRoomOption
      virtualRoomHTML
      virtualRoomJSON
      virtualRoomId
      virtualRoomPin
      virtualVenue
      contactEmail
      customFieldMap
      customFieldMapByType(customFieldTypes: ["MULTIPLE_CHOICE", "OPEN_ANSWER", "MATRICOLA", "${TEXT_ONLY}", "${FILE_UPLOAD}", "MAX_CHARACTER_ANSWER", "DATE"])
      combinedCustomFields(customFieldTypes: ["MULTIPLE_CHOICE", "OPEN_ANSWER", "MATRICOLA", "${TEXT_ONLY}", "${FILE_UPLOAD}", "MAX_CHARACTER_ANSWER", "DATE"])
      allCombinedCustomFields
      timeSlotMap
      showOnApp
      c19c
      c19TH
      logoEnabled
      logoUrl
    }
  }
`;

export const GET_EVENT_VIRTUAL_ROOM_CODE = gql`
  query GET_EVENT_VIRTUAL_ROOM_CODE($eventId: ID, $videoRoomPageId: ID) {
    getEventVirtualRoomCodeById(eventId: $eventId, videoRoomPageId: $videoRoomPageId) {
      id
      code
    }
  }
`;

export const GET_GUEST_STAT = gql`
  query GET_GUEST_STAT($eventId: ID) {
    Event(eventId: $eventId) {
      overview {
        TOTAL
        PENDING
        CONFIRMED
        CONFIRMED_PLUS_ONE
        CHECK_IN
      }
    }
  }
`;
export const CREATE_EVENT = gql`
  mutation CREATE_EVENT($createEventInput: CreateEventInput!) {
    createEvent(createEventInput: $createEventInput) {
      id
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UPDATE_EVENT($eventId: ID!, $updateEventInput: UpdateEventInput!) {
    updateEvent(eventId: $eventId, updateEventInput: $updateEventInput) {
      id
    }
  }
`;
export const UPDATE_GUEST_LIST_MAX_ACQUAINTANCE = gql`
  mutation UPDATE_GUEST_LIST_MAX_ACQUAINTANCE($eventId: ID!, $maxAcquaintance: Int) {
    setGuestListMaxAcquaintance(eventId: $eventId, maxAcquaintance: $maxAcquaintance)
  }
`;
export const GET_ACTIVITY_REPORT = gql`
  query GET_ACTIVITY_REPORT($eventId: ID!) {
    getActivityReport(eventId: $eventId) {
      memberName
      memberSurname
      memberEmail
      memberTelephone
      activity
      createdAt
      scanningForAcquaintance
      fromCheckinApp
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DELETE_EVENT($eventId: ID!) {
    deleteEvent(eventId: $eventId) {
      id
    }
  }
`;

export const SUBSCRIBE_TO_SEND_CAMPAIGN = gql`
  subscription SUBSCRIBE_TO_SEND_CAMPAIGN($eventId: ID!) {
    sendCampaignTask(eventId: $eventId) {
      status
      message
      progress
      campaign {
        ...pastCampaignDetailFragment
      }
    }
  }
  ${PAST_CAMPAIGN_DETAIL_FRAGMENT}
`;

export const SUBSCRIBE_TO_GUEST_LIST_IMPORT = gql`
  subscription SUBSCRIBE_TO_GUEST_LIST_IMPORT($eventId: ID!) {
    uploadGuestList(eventId: $eventId) {
      status
      message
      progress
      hasNewCustomFields
      discardedGuestList {
        reason
        rowNumber
        name
        surname
        email
      }
    }
  }
`;

export const GET_DRAFT_CAMPAIGN = gql`
  query GET_DRAFT_CAMPAIGN($eventId: ID!) {
    Event(eventId: $eventId) {
      id
      draftCampaign {
        ...campaignDetailFragment
      }
    }
  }
  ${CAMPAIGN_DETAIL_FRAGMENT}
`;

export const GET_PAST_CAMPAIGNS = gql`
  query GET_PAST_CAMPAIGNS($eventId: ID!, $pageInfo: PaginationInput!, $filter: String) {
    getCampaigns(eventId: $eventId, pageInfo: $pageInfo, filter: $filter) {
      total
      pageSize
      currentPage
      sortOrder
      sortField
      data {
        ...pastCampaignDetailFragment
      }
    }
  }
  ${PAST_CAMPAIGN_DETAIL_FRAGMENT}
`;

export const UPDATE_PAST_CAMPAIGNS = gql`
  mutation UPDATE_PAST_CAMPAIGNS($eventId: ID!) {
    updatePastCampaigns(eventId: $eventId)
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation CREATE_CAMPAIGN($campaignInput: EmailCampaignInput!) {
    createCampaign(campaign: $campaignInput) {
        ...campaignDetailFragment
    }
  }
  ${CAMPAIGN_DETAIL_FRAGMENT}
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UPDATE_CAMPAIGN($campaignId: ID!, $campaignInput: EmailCampaignInput!) {
    updateCampaign(campaignId: $campaignId, campaign: $campaignInput) {
      ...campaignDetailFragment
    }
  }
  ${CAMPAIGN_DETAIL_FRAGMENT}
`;

export const DELETE_CAMPAIGN = gql`
  mutation DELETE_CAMPAIGN($campaignId: ID!) {
    deleteCampaign(campaignId: $campaignId)
  }
`;

export const SEND_CAMPAIGN = gql`
  mutation($campaignId: ID!) {
    sendCampaign(campaignId: $campaignId) {
      ...pastCampaignDetailFragment
    }
  }
  ${PAST_CAMPAIGN_DETAIL_FRAGMENT}
`;

export const GET_MY_VERIFIED_IDENTITIES = gql`
  query GET_MY_VERIFIED_IDENTITIES($identityType: MarketingIdentityType) {
    myMarketingIdentities(verificationStatus: SUCCESS, identityType: $identityType) {
      ... on DomainIdentity {
        id
        identity
        identityType
        verificationStatus
        verificationToken
      }
      ... on EmailIdentity {
        id
        identity
        identityType
        verificationStatus
      }
    }
  }
`;

export const MY_IDENTITIES = gql`
  query MY_IDENTITIES($identityType: MarketingIdentityType) {
    myMarketingIdentities(identityType: $identityType) {
      ... on EmailIdentity {
        id
        identity
        identityType
        verificationStatus
        createdAt
        updatedAt
      }

      ... on DomainIdentity {
        id
        identity
        identityType
        verificationStatus
        verificationToken
        dkimVerificationStatus
        dkimTokens
        mailFromEnabled
        mailFromDomain
        mailFromStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_IDENTITY = gql`
  mutation CREATE_IDENTITY($identityInput: MarketingIdentityInput!) {
    createMarketingIdentity(marketingIdentity: $identityInput) {
      ... on EmailIdentity {
        id
        identity
        identityType
        verificationStatus
        createdAt
        updatedAt
      }

      ... on DomainIdentity {
        id
        identity
        identityType
        verificationStatus
        verificationToken
        dkimVerificationStatus
        dkimTokens
        mailFromEnabled
        mailFromDomain
        mailFromStatus
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_IDENTITIES = gql`
  mutation UPDATE_IDENTITIES {
    updateMarketingIdentities
  }
`;

export const DELETE_IDENTITY = gql`
  mutation DELETE_IDENTITY($identity: String!) {
    deleteMarketingIdentity(identity: $identity)
  }
`;

export const CREATE_EMAIL_TEMPLATE = gql`
  mutation CREATE_EMAIL_TEMPLATE($emailTemplateInput: EmailTemplateInput!) {
    createEmailTemplate(emailTemplate: $emailTemplateInput) {
      ...emailTemplateDetailFragment
    }
  }
  ${EMAIL_TEMPLATE_DETAIL_FRAGMENT}
`;
export const SEND_TEST_EMAIL = gql`
  mutation SEND_TEST_EMAIL($body: String!) {
    sendTestEmail(body: $body)
  }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UPDATE_EMAIL_TEMPLATE($templateId: ID!, $emailTemplateInput: EmailTemplateInput!) {
    updateEmailTemplate(templateId: $templateId, emailTemplate: $emailTemplateInput) {
      ...emailTemplateDetailFragment
    }
  }
  ${EMAIL_TEMPLATE_DETAIL_FRAGMENT}
`;

export const EXPORT_TIME_SLOT_BOOKINGS = gql`
  query EXPORT_TIME_SLOT_BOOKINGS($eventId: ID!) {
  timeSlotBookings(eventId: $eventId) {
    title
    startDate
    endDate
    description
  }
}

`;
export const GET_EMAIL_TEMPLATES = gql`
  query GET_EMAIL_TEMPLATES {
    getEmailTemplates {
      ...emailTemplateDetailFragment
    }
  }
  ${EMAIL_TEMPLATE_DETAIL_FRAGMENT}
`;

export const DELETE_EMAIL_TEMPLATE = gql`
  mutation DELETE_EMAIL_TEMPLATE($uniqueName: String!) {
    deleteEmailTemplate(uniqueName: $uniqueName)
  }
`;

export const REGISTER_MEMBER_TO_EVENT = gql`
  mutation REGISTER_MEMBER_TO_EVENT($memberInput: MemberInput!, $eventId: ID!) {
    addMemberToEvent(member: $memberInput, eventId: $eventId) {
      id
    }
  }
`;

export const ENUM_QUERY = gql`
  query ENUM_QUERY($enumName: String!) {
    __type(name: $enumName) {
      name
      enumValues {
        name
      }
    }
  }
`;

export const DELETE_ONE_GUEST = gql`
  mutation DELETE_ONE_GUEST($memberListId: ID!, $eventId: ID!) {
    setOneGuestOnListInactive(memberListId: $memberListId, eventId: $eventId)
  }
`;

export const DELETE_ALL = gql`
  mutation DELETE_ALL($eventId: ID!) {
    setGuestListInactive(eventId: $eventId)
  }
`;

export const UPDATE_RECIPIENT_LIST = gql`
  mutation UPDATE_RECIPIENT_LIST($eventId: ID, $campaign: ID!, $guestList: [ID!]!, $audienceOption: AudienceOption!) {
    updateDraftEmailCommunication(eventId: $eventId campaign: $campaign, selectedGuestList: $guestList, audienceOption: $audienceOption)
  }
`;

export const CREATE_TEAM_PRIVACY_POLICY = gql`
  mutation CREATE_TEAM_PRIVACY_POLICY(
    $createTeamPrivacyPolicyInput: CreateTeamPrivacyPolicyInput!
  ) {
    createTeamPrivacyPolicy(createTeamPrivacyPolicyInput: $createTeamPrivacyPolicyInput) {
      id
      name
      text
    }
  }
`;

export const UPDATE_TEAM_PRIVACY_POLICY = gql`
  mutation UPDATE_TEAM_PRIVACY_POLICY(
    $updateTeamPrivacyPolicyInput: UpdateTeamPrivacyPolicyInput!
  ) {
    updateTeamPrivacyPolicy(updateTeamPrivacyPolicyInput: $updateTeamPrivacyPolicyInput) {
      id
      name
      text
    }
  }
`;

export const DELETE_TEAM_PRIVACY_POLICY = gql`
  mutation DELETE_TEAM_PRIVACY_POLICY($teamPrivacyPolicyId: ID!) {
    deleteTeamPrivacyPolicy(teamPrivacyPolicyId: $teamPrivacyPolicyId)
  }
`;

export const GET_TEAM_PRIVACY_POLICY = gql`
  query GET_TEAM_PRIVACY_POLICY {
    teamPrivacyPolicies {
      id
      name
      text
    }
  }
`;

export const CREATE_EVENT_PRIVACY_POLICY = gql`
  mutation CREATE_EVENT_PRIVACY_POLICY(
    $createEventPrivacyPolicyInput: CreateEventPrivacyPolicyInput!
  ) {
    createEventPrivacyPolicy(createEventPrivacyPolicyInput: $createEventPrivacyPolicyInput) {
      id
      eventId
      teamPrivacyPolicyId
      active
      mandatory
      limited
    }
  }
`;

export const UPDATE_EVENT_PRIVACY_POLICY = gql`
  mutation UPDATE_EVENT_PRIVACY_POLICY(
    $updateEventPrivacyPolicyInput: UpdateEventPrivacyPolicyInput!
  ) {
    updateEventPrivacyPolicy(updateEventPrivacyPolicyInput: $updateEventPrivacyPolicyInput) {
      id
      eventId
      teamPrivacyPolicyId
      active
      mandatory
      limited
    }
  }
`;

export const GET_EVENT_PRIVACY_POLICY = gql`
  query GET_EVENT_PRIVACY_POLICY($eventId: ID) {
    policyEvents(eventId: $eventId) {
      id
      teamPrivacyPolicyId
      active
      mandatory
      limited
    }
  }
`;

export const GET_EVENT_CHECKED_PRIVACY_POLICY = gql`
  query GET_EVENT_CHECHED_PRIVACY_POLICY($eventId: ID) {
    checkedPolicyEvents(eventId: $eventId) {
      result
    }
  }
`;

export const GET_EMAIL_CONFIRMATION_CAMPAIGN = gql`
  query GET_EMAIL_CONFIRMATION_CAMPAIGN($eventId: ID) {
    Event(eventId: $eventId) {
      id
      draftCampaign {
        ...campaignDetailFragment
      }
    }
  }
  ${CAMPAIGN_DETAIL_FRAGMENT}
`;

export const GET_REGISTRATION_FORM = gql`
  query GET_REGISTRATION_FORM($eventId: ID, $registrationFormId: ID) {
    getRegistrationForm(eventId: $eventId, registrationFormId: $registrationFormId) {
      ...registrationFormDetailFragment
    }
  }
  ${REGISTRATION_FORM_DETAIL_FRAGMENT}
`;
export const CREATE_REGISTRATION_FORM = gql`
  mutation CREATE_REGISTRATION_FORM($input: CreateRegistrationFormInput!) {
    createRegistrationForm(input: $input) {
      ...registrationFormDetailFragment
    }
  }
  ${REGISTRATION_FORM_DETAIL_FRAGMENT}
`;
export const UPDATE_REGISTRATION_FORM = gql`
  mutation UPDATE_REGISTRATION_FORM($id: ID! $updates: UpdateRegistrationFormInput!) {
    updateRegistrationForm(id: $id, updates: $updates) {
      ...registrationFormDetailFragment
    }
  }
  ${REGISTRATION_FORM_DETAIL_FRAGMENT}
`;
export const DELETE_REGISTRATION_FORM = gql`
  mutation DELETE_REGISTRATION_FORM($id: ID!) {
    deleteRegistrationForm(id: $id)
  }
`;
export const CREATE_EMAIL_CONFIRMATION = gql`
  mutation CREATE_EMAIL_CONFIRMATION($emailConfirmationInput: EmailConfirmationInput!) {
    createEmailConfirmation(emailConfirmation: $emailConfirmationInput) {
      ...confirmationEmailDetailFragment
    }
  }
  ${CONFIRMATION_EMAIL_DETAIL_FRAGMENT}
`;
export const DELETE_EMAIL_CONFIRMATION_BY_EVENT_ID = gql`
  mutation DELETE_EMAIL_CONFIRMATION_BY_EVENT_ID($eventId: ID!) {
    deleteEmailConfirmationByEventId(eventId: $eventId)
  }
`;

export const DELETE_EMAIL_CONFIRMATION = gql`
  mutation DELETE_EMAIL_CONFIRMATION($emailConfirmationId: ID!) {
    deleteEmailConfirmation(emailConfirmationId: $emailConfirmationId)
  }
`;
export const UPDATE_EMAIL_CONFIRMATION = gql`
  mutation UPDATE_EMAIL_CONFIRMATION($emailConfirmationId: ID! $emailConfirmationInput: EmailConfirmationInput! ) {
    updateEmailConfirmation( emailConfirmationId: $emailConfirmationId emailConfirmation: $emailConfirmationInput) {
      ...confirmationEmailDetailFragment
    }
  }
  ${CONFIRMATION_EMAIL_DETAIL_FRAGMENT}
`;

export const UPDATE_GUEST_BY_ID = gql`
  mutation UPDATE_GUEST_BY_ID($guestId: ID!, $eventId: ID!, $guestInput: UpdateGuestInput!) {
    updateGuestById(guestId: $guestId, eventId: $eventId, guestInput: $guestInput) {
      id
    }
  }
`;
export const UPDATE_MEMBER_BY_ID = gql`
  mutation UPDATE_MEMBER_BY_ID($memberId: ID!, $memberInput: UpdateMemberInput!) {
    updateMemberById(memberId: $memberId, memberInput: $memberInput) {
      id
    }
  }
`;

export const GET_EVENT_ANALYTICS = gql`
  query GET_EVENT_ANALYTICS($input: EventAnalyticsInput!) {
    getEventAnalytics(input: $input) {
      ... on TotalAttendeeResponse {
        data
        totalAttendee
      }
      ... on TimelineCheckinResponse {
        data
      }
      ... on AttendeeConfirmationResponse {
        data
        labels
      }
    }
  }
`;

export const UPLOAD_GUEST_LIST = gql`
  mutation UPLOAD_GUEST_LIST($file: Upload!, $eventId: ID!) {
    uploadFile(file: $file, eventId: $eventId) {
      id
      fileType
      keyName
    }
  }
`;

export const MY_UNSUBSCRIBERS = gql`
  query MY_UNSUBSCRIBERS {
    myUnsubscribers {
      id
      memberEmail
      createdAt
      updatedAt
    }
  }
`;

export const MY_TEAM = gql`
  query MY_TEAM {
    myTeam {
      id
      displayName
      uniqueName
      userLimit
      status
      subscriptionType
      identityOption
      identityLimit
      emailTemplateLimit
      features {
        virtualRoom
        teamMembers
        badgePrinting
        guestListWidget
        nomesia
        fairbooth
        pageAuthRequired
        pageModeratedChat
        pushNotification
        publicPages
        c19Compliance
        eventGroups
        matricolaGenerator
        nProtocollo
      }
      customCategories {
        key
        label
        deletedAt
      }
      memberCustomFieldMap
    }
  }
`;

export const UPDATE_MY_TEAM = gql`
  mutation UPDATE_MY_TEAM($updates: UpdateTeamInput!) {
    updateMyTeam(updates: $updates) {
      id
      displayName
      uniqueName
      userLimit
      status
      subscriptionType
      identityOption
      identityLimit
      emailTemplateLimit
      features {
        virtualRoom
        teamMembers
        badgePrinting
        guestListWidget
        nomesia
        fairbooth
        pageAuthRequired
        pageModeratedChat
        pushNotification
        publicPages
        c19Compliance
        eventGroups
        matricolaGenerator
        nProtocollo
      }
      customCategories {
        key
        label
        deletedAt
      }
    }
  }
`;

export const UPDATE_MANY_EVENTS = gql`
  mutation UPDATE_MANY_EVENTS($where: EventQueryInput! $updates: UpdateEventInput!) {
    updateManyEvents(where: $where, updates: $updates)
  }
`;

export const DELETE_MY_ACCOUNT = gql`
  mutation DELETE_MY_ACCOUNT {
    deleteMyUser
  }
`;

export const TOOGLE_MAIL_FROM = gql`
  mutation TOOGLE_MAIL_FROM($enable: Boolean!, $identity: String!, $subDomain: String) {
    toggleMailFrom(enable: $enable, identity: $identity, subDomain: $subDomain)
  }
`;

export const GET_MY_TEAM = gql`
  query GET_MY_TEAM($pageInfo: PaginationInput, $filter: String) {
    myTeamMembers(pageInfo: $pageInfo, filter: $filter) {
      total
      pageSize
      currentPage
      sortOrder
      sortField
      data {
        id
        accountStatus
        email
        name
        surname
        role
        teamId
        assignedEventsData{
          id,
          name
        }
      }
    }
  }
`;

export const GET_MY_RECORDINGS = gql`
  query GET_MY_RECORDINGS {
    myRecordings {
      id
      urls
      lifespan
      videoRoom {
        type
      }
      event {
        name
      }
    }
  }
`;

export const DELETE_RECORDING = gql`
  mutation DELETE_RECORDING($id: ID!, $url: String!, $recordingId: String!) {
    deleteRecording(id: $id, url: $url, recordingId: $recordingId)
  }
`;

export const SEND_INVITATION = gql`
  mutation SEND_INVITATION($user: InviteUserInput!) {
    createAndInviteUser(user: $user) {
      id
      accountStatus
      email
      name
      surname
      role
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($userId: ID!, $updates: UpdateUserInput!) {
    updateUser(userId: $userId, updates: $updates) {
      id
      accountStatus
      email
      name
      surname
      role
    }
  }
`;

export const DELETE_USER = gql`
  mutation DELETE_USER($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

export const ASSIGN_USERS_TO_EVENT = gql`
  mutation ASSIGN_USERS_TO_EVENT($eventId: [ID]!, $userIds: [ID]!) {
    assignUsersToEvent(eventId: $eventId, userIds: $userIds)
  }
`;

export const REMOVE_USERS_FROM_EVENT = gql`
  mutation REMOVE_USERS_FROM_EVENT($eventId: [ID]!, $userIds: [ID]!) {
    removeUsersFromEvent(eventId: $eventId, userIds: $userIds)
  }
`;

export const ALLOCATE_VIDEO_ROOM = gql`
mutation ALLOCATE_VIDEO_ROOM($input: AllocatedVideoRoomInput!) {
  createVideoRoomAllocation(input: $input) {
    id,
    videoRoomId,
    status,
    callStatus,
    locked,
    lifespan,
    utcOffset,
    publisherPin,
    moderatorPin,
    sessionId,
    sessionCreatedAt
  }
}
`;

export const DELETE_VIDEO_ROOM_ALLOCATION = gql`
mutation DELETE_VIDEO_ROOM_ALLOCATION($id: ID!) {
  deleteVideoRoomAllocation(id: $id)
}`;

export const CREATE_VIDEO_ROOM_PAGE = gql`
mutation CREATE_VIDEO_ROOM_PAGE($input: VideoRoomPageInput!) {
  createVideoRoomPage(input: $input) {
    id
    eventId
    pageStatus
    allocatedVideoRoomIds
    html,
    json
  }
}
`;

export const UPDATE_VIDEO_ROOM_PAGE = gql`
mutation UPDATE_VIDEO_ROOM_PAGE($videoRoomPageId: ID!, $updates: UpdateVideoRoomPageInput!) {
  updateVideoRoomPage(id: $videoRoomPageId, updates: $updates) {
    id
    eventId
    pageStatus
    allocatedVideoRoomIds
    html,
    json
  }
}
`;

export const UPDATE_ALLOCATED_VIDEO_ROOM = gql`
mutation UPDATE_ALLOCATED_VIDEO_ROOM($allocatedVideoRoomId: ID!, $updates: UpdateAllocatedVideoRoomInput!) {
  updateAllocatedVideoRoom(id: $allocatedVideoRoomId, updates: $updates) {
    id,
    videoRoomId,
    status,
    callStatus,
    locked,
    lifespan,
    utcOffset,
    publisherPin,
    moderatorPin,
    sessionId,
    sessionCreatedAt
  }
}
`;

export const EVENT_VIDEO_ROOM_PAGES = gql`
  query EVENT_VIDEO_ROOM_PAGES($eventId: ID) {
    getVideoRoomPages(eventId: $eventId) {
      id,
      eventId,
      allocatedVideoRoomIds,
      externalRoomId,
      externalRoomPin,
      videoRoomType,
      pageStatus,
      authNeeded,
      roomConfig,
      hasCoverImage,
      hasCustomSlug,
      html,
      json,
      slug
    }
  }
`;

export const EVENT_ALLOCATED_VIDEO_ROOMS = gql`
  query EVENT_ALLOCATED_VIDEO_ROOMS($eventId: ID) {
    getEventAllocatedVideoRooms(eventId: $eventId) {
      id,
      videoRoomId,
      status,
      callStatus,
      locked,
      lifespan,
      utcOffset,
      publisherPin,
      moderatorPin,
      sessionId,
      sessionCreatedAt
    }
  }
`;

export const GET_VIDEO_ROOMS = gql`
  query GET_VIDEO_ROOMS {
    getVideoRooms {
      id,
      customSessionId,
      type,
      maxLifespanAsHours
    }
  }
`;

export const GUEST_LIST = gql`
  query GUEST_LIST($eventId: ID! $filter: FilterGuestList, $pageInfo: PaginationInput $customFields: [String] $audienceOption: AudienceOption! $audienceOptionFilter: Any ) {
    guests(eventId: $eventId pageInfo: $pageInfo filter: $filter customFields: $customFields audienceOption: $audienceOption audienceOptionFilter: $audienceOptionFilter ) {
      total
      pageSize
      currentPage
      sortOrder
      sortField
      data {
        id
        memberId
        eventId
        status
        virtualCheckInAt
        nProtocollo
        deliveryStatus
        customFieldDataMap
        acquaintance
        maxAcquaintance
        acquaintanceAdded
        activity {
          activity
          createdAt
        }
        acquaintanceInfoList {
          name
          surname
          email
        }
        qrCode
        member {
          id
          name
          surname
          telephone
          email
          password
          country
          listEmails
          isRegistered
          memberCustomFieldDataMap
        }
        rsvpDate
        fromCheckinApp
        createdAt
        memberName
        memberSurname
        memberEmail
        memberTelephone
      }
    }
  }
`;
export const GET_BADGE = gql`
  query GET_BADGE($eventId: ID) {
    Event(eventId: $eventId) {
      id
      eventBadge {
        id
        title
        description
        logo
        textColor
        borderColor
        pageFormat
        tags
        html
      }
    }
  }
`;
export const SUBSCRIBE_TO_GUEST_NEWS = gql`
  subscription SUBSCRIBE_TO_GUEST_NEWS($eventId: ID!) {
    guestNews(eventId: $eventId) {
      mutation
      data {
        id
        memberId
        eventId
        status
        virtualCheckInAt
        deliveryStatus
        customFieldDataMap
        acquaintance
        maxAcquaintance
        acquaintanceAdded
        activity {
          activity
          createdAt
        }
        acquaintanceInfoList {
          name
          surname
          email
        }
        qrCode
        member {
          id
          name
          surname
          telephone
          email
          password
          country
          listEmails
          isRegistered
          memberCustomFieldDataMap
        }
        rsvpDate
        fromCheckinApp
        createdAt
        memberName
        memberSurname
        memberEmail
        memberTelephone
      }
    }
  }
`;

export const SUBSCRIBE_TO_STAT_NEWS = gql`
  subscription SUBSCRIBE_TO_STAT_NEWS($eventId: ID!) {
    statisticsNews(eventId: $eventId) {
      data {
        key
        value
      }
    }
  }
`;
export const CREATE_BADGE = gql`
  mutation CREATE_BADGE($eventBadgeInput: EventBadgeInput!) {
    createEventBadge(eventBadge: $eventBadgeInput) {
      id
    }
  }
`;
export const DELETE_BADGE = gql`
  mutation DELETE_BADGE($eventBadgeId: ID!) {
    deleteEventBadge(eventBadgeId: $eventBadgeId)
  }
`;

export const UPDATE_BADGE = gql`
  mutation UPDATE_BADGE(
    $eventBadgeId: ID!
    $eventBadgeInput: EventBadgeInput!
  ) {
    updateEventBadge(
      eventBadgeId: $eventBadgeId
      eventBadge: $eventBadgeInput
    ) {
      id
      title
      description
      logo
      textColor
      borderColor
      pageFormat
      tags
      html
    }
  }
`;
export const ELIGIBLE_RECIPIENT_COUNT = gql`
  query ELIGIBLE_RECIPIENT_COUNT(
    $eventId: ID!
    $audienceOption: AudienceOption!
    $audienceOptionFilter: Any
  ) {
    eligibleRecipientCount(
      eventId: $eventId
      audienceOption: $audienceOption
      audienceOptionFilter: $audienceOptionFilter
    )
  }
`;

export const PUSH_NOTIFICATIONS = gql`
  query PUSH_NOTIFICATIONS($filter: String, $pageInfo: PaginationInput) {
    pushNotifications(filter: $filter, pageInfo: $pageInfo) {
      total
      pageSize
      currentPage
      data {
        id
        teamId
        title
        description
        total
        delivered
        queued
        send
        failed
        scheduledTo
        sentAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEND_PUSH_NOTIFICATION = gql`
  mutation SEND_PUSH_NOTIFICATION($input: CreatePushNotificationInput!) {
    sendPushNotification(input: $input) {
      id
      teamId
      title
      description
      total
      delivered
      failed
      queued
      send
      scheduledTo
      sentAt
      createdAt
      updatedAt
    }
  }
`;

