<template>
  <div>
    <el-dialog
        :visible.sync="modal.visible"
        width="30%"
        custom-class="default-popper"
        append-to-body>
        <span slot="title"  class="dialog-title" >
          {{modal.title}}
        </span>
        <div class="dialog-description">{{modal.users ? modal.users.length + ' ' + string.modalBulkAction._user_selected : ''}}</div>

         <div class="filter-field">
            <input
                v-model="filter"
                type="text"
                :placeholder="string.modalBulkAction._search_event"
                class="datagridsSearchBar"
              >
          </div>
          <ul  class="options-container">
            <li
              v-for="(item, $index) in options"
              :key="$index"
              :data-num="$index + 1"
              class="option-item"
              :class="[modal.events[$index] === item.id ? 'active' : '', 'option-item']"
              @click.prevent="selectedEvent = item.id"
              >
              {{item.name}}

            </li>
          <infinite-loading
              :identifier="infiniteId"
              @infinite="infiniteHandler">
                <div slot="error" slot-scope="{ trigger }">
                  Couldn't loaded. Click <a href="javascript:;" @click="trigger">here</a> to retry
                </div>
            </infinite-loading>
          </ul>
        <span slot="footer" class="dialog-footer">
          <el-button @click="onCancel">{{string.modalBulkAction._cancel}}</el-button>
          <el-button :loading="loading" type="primary" @click="onConfirm">{{string.modalBulkAction._confirm}}</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<style>
  ul.options-container {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .options-container {
    height: 150px;
    overflow:auto;
    color:white;
  }
  .option-item {
    padding: 5px;
    height: 45px;
    line-height: 45px;
  }
  .option-item.active{
    background-color: #0057B8;
  }
  .option-item:hover,
  .option-item:focus,
  .option-item:active {
    padding: 5px;
    cursor: pointer;
    background-color: rgba(255,255,255,0.3);
    color: #fff;
  }
  .dialog-description {
    padding-bottom: 10px;
    padding-top: 10px;
    color: white;
    text-align: center;
  }
</style>
<script>
import {MY_PAGINATED_EVENTS, REMOVE_USERS_FROM_EVENT, ASSIGN_USERS_TO_EVENT} from "../../graphql";
let selectedEvent= [];
export default {
    name: 'ModelBulkAction',
    props: {
      users: {
        type: Array,
        default: () => []
      },
      visible: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        required: false,
        default: 'Add to event'
      },
      action: {
        type: String,
        required: false,
        default: ''
      },
      events: {
        type: Array,
        default: () => []
      },
    },
    data (){
        return {
            componentName: 'modal-bulk-action',
            modal: {
              visible: this.visible,
              title: this.title,
              users: this.users,
              action: this.action,
              events: this.events
            },
            string : {},
            selectedEvent: [],
            selectedItem: [],
            arr: [],
            res: [],
            duplicateValue: false,
            loading: false,
            options: [],
            page: 1,
            pageSize: 6,
            filter: '',
            sortField: 'createdAt',
            sortOrder: -1,
            infiniteId: +new Date()
        };
    },

    created () {
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
          context.string = context.$root.strings;
      });
    },
    watch: {
      filter: {
          handler(newVal, oldVal) {
              if((newVal.length >= 3) || (newVal.length < oldVal.length) && newVal !== oldVal) {
                  // trigger request
                  this.page = 1;
                  this.options = [];
                  this.infiniteId += 1;
              }
          }
      }
    },

    methods: {
      /*manageEvent(item, index){

        selectedEvent.forEach(item)=>{
          selectedEvent
        }
    },*/
      infiniteHandler($state) {
          const context = this;
          this.$apollo
                .query({
                  fetchPolicy: 'no-cache',
                  query: MY_PAGINATED_EVENTS,
                  variables: {
                    statuses: ['ACTIVE'],
                    filter: this.filter && this.filter.trim() !== '' ? this.filter: null,
                    pageInfo: {
                        pageSize: this.pageSize,
                        currentPage: this.page,
                        sortField: this.sortField,
                        sortOrder: this.sortOrder,
                    }
                  }
                })
                .then(response => {
                  if (response.errors){
                    console.log(response.errors);
                    $state.complete();
                    return;
                  }
                  if(response.data && response.data.myPaginatedEvents) {
                    const payload = response.data.myPaginatedEvents
                    if(payload.data.length) {
                      context.page += 1;
                       context.options.push(...payload.data);
                      $state.loaded();
                    }else {
                      $state.complete();
                    }
                  }
                })
                .catch(error => {
                  console.log(error);
                  $state.complete();
                });
       },
       onCancel() {
         this.$root.bus.emit('toggleModalBulkAction', { show: false});

       },
       onConfirm() {
         if(!this.isDataValid()) {
           return;
         }
         if(this.modal.action === 'addToEvent') {
           this.addToEvent();
         }else if (this.modal.action === 'removeFromEvent') {
           this.removeFromEvent();
         }
       },
       addToEvent() {
         const context = this;
         this.loading = true
         this.$apollo
                .mutate({
                  fetchPolicy: 'no-cache',
                  mutation: ASSIGN_USERS_TO_EVENT,
                  variables: {
                    eventId: this.selectedEvent,
                    userIds: this.modal.users
                  }
                })
                .then(response => {
                  if (response.errors){
                    console.log(response.errors);
                    context.$root.bus.emit('simpleToast', context.string.modalBulkAction._failed);
                    context.loading = false;
                    context.$root.bus.emit('toggleModalBulkAction', { show: false});
                    return;
                  }
                  if(response.data && response.data.assignUsersToEvent) {
                    const payload = response.data.assignUsersToEvent
                    context.$root.bus.emit('simpleToast', context.string.modalBulkAction._users_added);
                  }
                  context.$root.bus.emit('toggleModalBulkAction', { show: false});
                  context.loading = false;
                })
                .catch(error => {
                  console.log(error);
                  context.$root.bus.emit('simpleToast', context.string.modalBulkAction._failed);
                  context.loading = false;
                  context.$root.bus.emit('toggleModalBulkAction', { show: false});
                });
       },
       removeFromEvent() {
         const context = this;
         this.loading = true
         this.$apollo
                .mutate({
                  fetchPolicy: 'no-cache',
                  mutation: REMOVE_USERS_FROM_EVENT,
                  variables: {
                    eventId: this.selectedEvent,
                    userIds: this.modal.users
                  }
                })
                .then(response => {
                  if (response.errors){
                    console.log(response.errors);
                    context.$root.bus.emit('simpleToast', context.string.modalBulkAction._failed);
                    context.loading = false;
                    context.$root.bus.emit('toggleModalBulkAction', { show: false});
                    return;
                  }
                  if(response.data && response.data.removeUsersFromEvent) {
                    const payload = response.data.removeUsersFromEvent
                    context.$root.bus.emit('simpleToast', context.string.modalBulkAction._users_removed);
                  }
                  context.$root.bus.emit('toggleModalBulkAction', { show: false});
                  context.loading = false;
                })
                .catch(error => {
                  console.log(error);
                  context.$root.bus.emit('simpleToast', context.string.modalBulkAction._failed);
                  context.loading = false;
                  context.$root.bus.emit('toggleModalBulkAction', { show: false});
                });
       },
       isDataValid(){
         if(!this.modal || !this.modal.users || this.modal.users.length < 1) {
           this.$root.bus.emit('simpleToast', this.string.modalBulkAction._warning_select_users);
           return false;
         }
         if(!this.selectedEvent || this.selectedEvent === '') {
           this.$root.bus.emit('simpleToast', this.string.modalBulkAction._warning_select_event);
           return false;
         }
         return true;
       }
    }

};
</script>
