import { config } from "dotenv";
config();

export const APP_NAME = "Penguinpass Events";
export const VERSION = "2.3.1";

export const IS_DEV = process.env.NODE_ENV === "development";
export const IS_STAGING = process.env.NODE_ENV === "staging";
export const IS_PROD = process.env.NODE_ENV === "production";

export const DOMAIN_NAME = IS_PROD ? "events.penguinpass.it" : "staging.events.figc.forms.penguinpass.it";

export const CALENDAR_CONFIG = {
  uidDomain: DOMAIN_NAME,
  prodId: APP_NAME + " - " + VERSION
};

export const getExhibitorVirtualRoomUrl = () => {
  return IS_PROD ?  "https://virtual.events.penguinpass.it/vr3/index.html#/" : "https://virtual.events.penguinpass.it/vr-staging/index.html"
};