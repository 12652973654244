<template>
  <div class="advance-settings-component-container accordion">
    <h4 class="form-section-title first accordion-toggle">
      {{ string.RSVPImageHeader._title }}
    </h4>    
    <div class="form-container accordion-content">
      <el-row>
        <el-col :span="18">
          <small>
            {{ $root.strings.RSVPImageHeader._subtitle }}
          </small>
            <div class="ImageToggleSelection">
              <label class="control control-checkbox">
                {{ string.RSVPImageHeader._innertitle }}
                <input
                  id="checkbox"
                  v-model="showEditor"
                  type="checkbox"
                  @change="toggleImage"
                />
                <div class="control_indicator"></div>
              </label>
            </div>
             <div class="rsvp-message-intent">
                <div v-if="showEditor">
                  <form>
                    <el-col :span="18" :offset="3">
                      <div class="default-layout">
                        <div id="imageUpload" class="imageupload-component">
                        <ImageUpload :src="this" :event-id="this.eventId"  />
                        </div>
                      </div>                   
                   </el-col>
                  </form>              
                </div>
             </div>
        </el-col>
      </el-row>
    </div>
  </div>
  
</template>
<style scoped>
.external-puchase-component {
  margin-bottom: 30px;
}

/** Disable Classes */

.form-container {
  position: relative;
}

.disable-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(255, 255, 255, 0.8);*/
  background-color: #00000000;
}

/** /Disable Classes */

/** In Action Layer */
.inaction-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
/** In Action Layer */

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #0057B8;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
</style>
<script>
import { UPDATE_EVENT } from "@/graphql";
import ImageUpload from "@/components/ImageUpload.vue";


export default {
  components: {
    ImageUpload
  },
  props: {
    eventId: String,
    event: Object,
  },
  data() {
    return {
      componentName: "RSVPImageHeader",
      string: {},
      showEditor: false,
      selectedFile: null,
      eventCover: "",
    };
  },
  watch: {
    event: function () {
        this.showEditor = this.event.logoEnabled;
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },

      setCover: function (cover, defaultCover) {
      if (typeof cover != "undefined") {
        return cover;
      }
      return defaultCover;
    },
  methods: {
    toggleImage: function() {
      const context = this;
      context.loadingEffectshow = true;
      return this.$root.$apollo
      .mutate({
        mutation: UPDATE_EVENT,
        variables: {
          eventId: context.eventId,
          updateEventInput: { logoEnabled: context.showEditor }
        }
      })
      .then(value => {
        if (value.errors) {
          context.loadingEffectshow = false;
        } else {
          //success
          context.loadingEffectshow = false;
        }
      })
      .catch(reason => {
        context.loadingEffectshow = false;
        context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
      });
    },
  }
};
</script>
