var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header black-bg" },
    [
      _c("div", { staticClass: "sidebar-toggle-box" }, [
        _c("div", {
          staticClass: "fa fa-bars tooltips",
          attrs: { "data-placement": "right", "data-original-title": "menu" },
          on: { click: _vm.toogleSideBar },
        }),
      ]),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "logo", attrs: { to: { path: "/" }, tag: "a" } },
        [_c("b", [_vm._v(_vm._s(_vm.appNameLocal))])]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "containerHeaderRightMenu" },
        [
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" }, on: { command: _vm.handleCommand } },
            [
              _c("p", [_vm._v(_vm._s(this.langSet))]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "it" } }, [
                    _vm._v(_vm._s(_vm.string.header._italian)),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { command: "en" } }, [
                    _vm._v(_vm._s(_vm.string.header._english)),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { command: "fr" } }, [
                    _vm._v(_vm._s(_vm.string.header._francaise)),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { command: "ar" } }, [
                    _vm._v(_vm._s(_vm.string.header._arab)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }