<template>
    <span style="min-height: 30px; min-width: 30px; line-height: 30px">
        <span :style="{color: textColor}" v-if="!!text">{{text}}</span>
        <font-awesome-layers class="fa-lg penguin-icon-layer" style="margin-right: 10px;">
      <font-awesome-icon
              :icon="['fas', 'circle']"
              transform="grow-6"
              :size="size"
              :style="{color: bgColor}"
              class="penguin-icon-bg">
      </font-awesome-icon>
      <font-awesome-icon
              :icon="icon"
              transform="shrink-4"
              :size="size"
              :style="{color: fgColor}"
              class="penguin-icon-fg"
              >
      </font-awesome-icon>
  </font-awesome-layers>
    </span>
</template>

<script>


export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true
        },
        fgColor: {
            type: String,
            required: false,
            default: '#fff'
        },
        bgColor: {
            type: String,
            required: false,
            default: '#0057BB'
        },
        size: {
            type: String,
            required: false,
            default: '1x'
        },
        text: {
            type: String,
            required: false,
            default: null
        },
        textColor: {
            type: String,
            required: false,
            default: "#fff"
        }
    },
};
</script>

<style scoped>
  @-webkit-keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes rotate-center {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .penguin-icon-layer:hover {
        cursor: pointer;
    }
</style>
