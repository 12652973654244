<template>
  <div class="default-layout">
    <div id="badgePrinting" class="badgePrinting-component">
      <div v-if="loadingEffectshow" class="higherPosition">
        <transition name="component-fade-fast" mode="out-in" appear>
          <LoadingCover
            v-if="loadingEffectshow"
            :loading-cover-msg="string.badgePrinting._loadingMessage"
          />
        </transition>
      </div>
      <div class="badgeprinting accordion">
        <h4 class="form-section-title first accordion-toggle">
          {{ string.badgePrinting._title }}
        </h4>
        <div class="accordion-content">
          <div class="badgeToggleSelection">
            <label class="control control-checkbox">
              {{ string.badgePrinting._innertitle }}
              <input
                id="checkbox"
                v-model="showEditor"
                type="checkbox"
                @change="toggleBadgePrinting"
              />
              <div class="control_indicator"></div>
            </label>
          </div>
          <div class="badge-message-intent">
            <div v-if="showEditor">
              <form>
                <div class="row mt-3">
                  <div class="col-sm-6">
                    <div class="row mt-3">
                      <div class="col-sm-6">
                        <small class="text-left">
                          {{ string.badgePrinting._formTitle }}
                        </small>
                        <input
                          v-model="badgeForm.title"
                          type="text"
                          placeHolder="Title"
                          class="form-control"
                        />
                      </div>
                      <div class="col-sm-6">
                        <small class="text-left">
                          {{ string.badgePrinting._formDescription }}
                        </small>
                        <input
                          v-model="badgeForm.description"
                          type="text"
                          placeHolder="Subtitle"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-6">
                        <small class="text-left">
                          {{ string.badgePrinting._formPageW }}
                        </small>
                        <input
                          v-model="badgeForm.pageFormatW"
                          type="text"
                          placeHolder="10in or 10cm"
                          class="form-control"
                        />
                      </div>
                      <div class="col-sm-6">
                        <small class="text-left">
                          {{ string.badgePrinting._formPageH }}
                        </small>
                        <input
                          v-model="badgeForm.pageFormatH"
                          type="text"
                          placeHolder="10in or 10cm"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-4">
                        <el-upload
                          ref="upload"
                          action="#"
                          :auto-upload="false"
                          :show-file-list="true"
                          :multiple="false"
                          :on-change="handleBadgeLogoChange"
                          :on-remove="handleBadgeLogoRemove"
                        >
                          <el-button size="small" type="primary">{{ string.badgePrinting._formFileUpload }}</el-button>
                          <div slot="tip" class="el-upload__tip">{{ string.badgePrinting._formFileUploadTips }}</div>
                        </el-upload>
                      </div>
                      <div class="col-sm-4">
                        <small class="text-left">
                          {{ string.badgePrinting._formTextColor }}
                        </small>
                        <el-color-picker class="form-control" v-model="badgeForm.textColor"></el-color-picker>
                      </div>
                      <div class="col-sm-4">
                        <small class="text-left">
                          {{ string.badgePrinting._formBorderColor }}
                        </small>
                        <el-color-picker class="form-control" v-model="badgeForm.borderColor"></el-color-picker>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-6">
                        <small class="text-left">
                          {{ string.badgePrinting._formField1 }}
                        </small><br/>
                        <el-select v-model="field1Name" clearable placeholder="Select">
                          <el-option
                            v-for="[slug, cField] in customFieldArray"
                            :key="slug"
                            :label="cField.options.question"
                            :value="slug">
                          </el-option>
                        </el-select>
                      </div>
                      <div class="col-sm-6">
                        <small class="text-left">
                          {{ string.badgePrinting._formField2 }}
                        </small><br/>
                        <el-select v-model="field2Name" clearable placeholder="Select">
                          <el-option
                            v-for="[slug, cField] in customFieldArray"
                            :key="slug"
                            :label="cField.options.question"
                            :value="slug">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-sm-12 htmlEditorContainer">
                        <el-collapse>
                          <el-collapse-item :title="string.badgePrinting._formCustomLayout" name="1">
                            <textarea v-model="badgeForm.html" class="htmlEditor"></textarea>
                          </el-collapse-item>
                        </el-collapse>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="row mt-3">
                      <div class="badge-container col-sm-12" style="background-color: #FFFFFF; min-height: 300px;">
                        <iframe
                          id="badgePreview"
                          class="embedded-html"
                          :srcdoc="renderedBadge"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6">
                    <a
                      class="btn btn-block"
                      @click="createOrUpdateBadge">
                      {{ string.badgePrinting._save }}
                    </a>
                  </div>
                  <div class="col-sm-6">
                    <a
                      class="btn btn-block"
                      @click="resetBadge">
                      {{ string.badgePrinting._reset }}
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.htmlEditorContainer {
  padding-top: 15px;
}
.htmlEditorContainer .el-collapse-item__header {
  background-color: transparent;
  color: #FFFFFF;
}
.htmlEditorContainer .el-collapse-item__content {
  padding-bottom: 0px;
}
</style>
<style scoped>
.form-control.el-color-picker {
  background: none !important;
}
.el-color-picker {
  border: none !important;
}
.el-color-picker__panel input {
  color: #000000 !important;
}
.el-color-picker__panel .el-button {
  padding: 10px;
}
.mt-3 {
  margin-top: 3em;
}
.badge-container {
  padding: 10px;
  background-image: linear-gradient(45deg, #a8a8a8 46.88%, #ebebeb 46.88%, #ebebeb 50%, #a8a8a8 50%, #a8a8a8 96.88%, #ebebeb 96.88%, #ebebeb 100%);
  background-size: 22.63px 22.63px;
}
.badge-body {
  color: #000000;
}
.badge-border {
  width: 100%;
  height: 100%;
  border: #FFFFFF 2px solid;
}
.htmlEditor {
  width: 100%;
  height: 200px;
  color: #000000;
}
.p-5 {
  padding: 5px;
}
.form-section-title{
        border-bottom: solid 1px #dddddd;
        padding: 20px 0;
        color: #0057B8;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .form-section-title small{
        font-size: 12px;
        display: block;
        line-height: 1.5;
        margin-top: 5px;
        color: #e8e8e8;
    }
</style>
<script>
import { mapGetters } from "vuex";

import LoadingCover from "./LoadingCover.vue";
import {
  CREATE_BADGE,
  DELETE_BADGE,
  GET_BADGE,
  UPDATE_BADGE,
  UPDATE_EVENT
} from "@/graphql";

const defaultBadgeHTML = '<!doctype html><html> <head> <style> html, body { margin: 0; padding 0; } .row { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; } .col-sm-6 { -webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; } .col-sm-12 { -webkit-box-flex: 0; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; } .mt-3 { margin-top: 3em; } .badge-container { width: {{pageW}}; height: {{pageH}}; margin: 0 auto; padding: 10px; } .badge-body { color: #000000; } .badge-border { width: 100%; height: 100%; border: #FFFFFF 2px solid; } .p-5 { padding: 5px; } .text-center { text-align: center; } </style> </head> <body> <div class="badge-container" style="background-color: #FFFFFF;"> <div class="badge-border" style="border-color: {{borderColor}}"> <div class="row p-5"> <div class="col-sm-6 text-center"> <img src="{{logo}}" width="90%"/> </div> <div class="col-sm-6 text-center"> <img src="{{qrcode}}" width="90%"/> </div> </div> <div class="row mt-3 p-5"> <div class="col-sm-12 text-center badge-body" style="color: {{textColor}}"> <p>{{title}}</p> <p>{{description}}</p> <p>{{firstName}} {{lastName}}</p> <p>{{field1Name}}</p> <p>{{field2Name}}</p> </div> </div> </div> </div> </body></html>';

export default {
  components: {
    LoadingCover
  },
  props: {
    eventId: String,
    event: Object
  },
  data() {
    return {
      componentName: "BadgePrinting",
      string: {},
      loadingEffectshow: false,
      showEditor: false,
      eventBadgeId: null,
      field1Name: '',
      field2Name: '',
      badgeForm: {
        title: '',
        description: '',
        textColor: '#000000',
        borderColor: '#FFFFFF',
        pageFormat: '210mm|297mm',
        pageFormatW: '210mm',
        pageFormatH: '297mm',
        logo: null,
        html: defaultBadgeHTML
      },
      customFieldArray: [],
      customFieldObject: {}
    };
  },
  computed: {
    ...mapGetters(["team", "memberCustomFieldMap"]),
    getDefaultEmail() {
      return this.$store.getters.defaultEmail;
    },
    getIdentityOption() {
      return this.$store.getters.identity;
    },
    renderedBadge() {
      return this.badgeForm.html
      .replace("{{title}}", this.badgeForm.title)
      .replace("{{description}}", this.badgeForm.description)
      .replace("{{firstName}}", "MARIO")
      .replace("{{lastName}}", "ROSSI")
      .replace("{{field1Name}}", this.field1Name &&  this.customFieldObject[this.field1Name] ? this.customFieldObject[this.field1Name].options.question : "")
      .replace("{{field2Name}}", this.field2Name &&  this.customFieldObject[this.field2Name] ? this.customFieldObject[this.field2Name].options.question : "")
      .replace("{{qrcode}}", "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAS4AAAEyBAMAAAC2eiCQAAAAJFBMVEX////8/Pz+/v7ExMTIyMjW1tbT09Pi4eHl5eX09PTMzMzY2NjONtjSAAAB+klEQVR4Ae3dgYbcUBSA4bumBYXdPslUqBZo6ZuUfYE+QmEWQKEL9FFLl+H0znHmrjRJN98PJBtnvgG51k2mrZIkSZJu7ga6/WeT37a/OkwDfWp/ejo4tpYMezp4PQ30jouLi4uLa3uu9/dFX5/tum5y5mpFwRU6UxJXK/q2cRcXFxcXF9ep72Ee189T3/Wu71PXx8IVdFPq+jF1feDi4uLi4nqBrvq+vb6Li4uLi4uLKyDWWU9wcXFxcXFxPV6ocAVKKLjSybUrbwZX3vouLi4uLi6u+ffLVf8HKNuei4uLi4uL69WXgX6NuN6MTP7cZii4loqLi4uLi4srrnOOF05VlHhxP3n8S3J1cXFxcXFxhaa+ZHpx8UC1m4uLi4uLiyvvkC4hqoNQ/iUufMw6Li4uLi4uroISyj49vy+nugo57uLi4uLi4uIqqkZdd/GhmszFxcXFxVXENd++tJwS/sLFxcXFxTUU1wzPgZXvSysO6kafm9uPi4uLi4uLa4b3rjxjPXH8P1xcXFxcXFyPF9qCq37PabYFbmgJcVjMxcXFxcXFxZW8L+3c0LY7Li4uLi4urty1wO+KVJRjdoqLi4uLi2uLrlPfQ3Xfrh7pTlxFwZW0VxcXFxcXF9d9Uec6l5wKLbBfbg8uLi4uLi6um7uBbtuukyRJkn4Dx0KEi1AhzGIAAAAASUVORK5CYII=")
      .replace("{{logo}}", this.badgeForm.logo)
      .replace("{{borderColor}}", this.badgeForm.borderColor)
      .replace("{{textColor}}", this.badgeForm.textColor)
      .replace("{{pageW}}", this.badgeForm.pageFormatW)
      .replace("{{pageH}}", this.badgeForm.pageFormatH);
    }
  },
  watch: {
    event: function() {
      const memberFields = this.memberCustomFieldMap ? this.memberCustomFieldMap : {};
      const allFieldsObject = { ...memberFields, ...(this.event.customFieldMap ? this.event.customFieldMap : {}) };

      this.customFieldObject = allFieldsObject;
      this.customFieldArray = Object.entries(allFieldsObject);
      this.showEditor = this.event.badgePrintingEnabled;
      if (this.showEditor) {
        this.loadBadge();
      }
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;

    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    resetBadge: function() {
      this.badgeForm.html = defaultBadgeHTML
      .replace("{{pageW}}", this.badgeForm.pageFormatW)
      .replace("{{pageH}}", this.badgeForm.pageFormatH);
    },
    toggleBadgePrinting: function() {
      const context = this;
      context.loadingEffectshow = true;
      return this.$root.$apollo
      .mutate({
        mutation: UPDATE_EVENT,
        variables: {
          eventId: context.eventId,
          updateEventInput: { badgePrintingEnabled: context.showEditor }
        }
      })
      .then(value => {
        if (value.errors) {
          console.log("error " + value.errors);
          context.loadingEffectshow = false;
        } else {
          //success
          context.loadingEffectshow = false;
        }
      })
      .catch(reason => {
        console.log(reason.message);
        context.loadingEffectshow = false;
        context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
      });
    },
    loadBadge: function() {
      const context = this;
      return this.$apollo
      .query({
        fetchPolicy: "no-cache",
        query: GET_BADGE,
        variables: {
          eventId: context.eventId
        }
      })
      .then(response => {
        if (response.errors) {
          console.log(response.errors);
          return;
        }
        if (response.data && response.data.Event && response.data.Event.eventBadge !== null) {
          context.badgeForm = response.data.Event.eventBadge;
          context.eventBadgeId = response.data.Event.eventBadge.id;
          if (context.badgeForm.tags.length > 0) {
            context.field1Name = context.badgeForm.tags[0];
          }
          if (context.badgeForm.tags.length > 1) {
            context.field2Name = context.badgeForm.tags[1];
          }
          if (context.badgeForm.pageFormat.indexOf('|') > -1) {
            const pageFormatSize = context.badgeForm.pageFormat.split('|');
            context.badgeForm.pageFormatW = pageFormatSize[0];
            context.badgeForm.pageFormatH = pageFormatSize[1];
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    createOrUpdateBadge() {
      const context = this;
      context.loadingMessage = "Saving...";
      context.loadingEffectshow = true;
      return this.$apollo
      .mutate({
        mutation: context.eventBadgeId===null?CREATE_BADGE:UPDATE_BADGE,
        variables: {
          eventBadgeId: context.eventBadgeId,
          eventBadgeInput: {
            eventId: context.eventId,
            title: context.badgeForm.title,
            description: context.badgeForm.description,
            logo: context.badgeForm.logo,
            textColor: context.badgeForm.textColor,
            borderColor: context.badgeForm.borderColor,
            pageFormat: context.badgeForm.pageFormatW+'|'+context.badgeForm.pageFormatH,
            html: context.badgeForm.html,
            tags: [context.field1Name, context.field2Name]
          }
        }
      })
      .then(response => {
        if (response.errors) {
          console.log(response.errors);
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
          return;
        }
        if (response.data && (response.data.createEventBadge || response.data.updateEventBadge)) {
          const payload = response.data.createEventBadge || response.data.updateEventBadge;
          context.eventBadgeId = payload.id;
          context.$root.bus.emit("simpleToast", context.string.badgePrinting._saved);
          context.loadingEffectshow = false;
        }
      })
      .catch(error => {
        console.log(error);
        context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
      });
    },
    deleteBadge() {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
      .mutate({
        mutation: DELETE_BADGE,
        variables: {
          eventBadgeId: context.eventBadgeId
        }
      })
      .then(response => {
        if (response.errors) {
          console.log(response.errors);
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
          return;
        }
        if (response.data && response.data.deleteEventBadge) {
          context.eventBadgeId = null;
          context.field1Name = '';
          context.field2Name = '';
          context.badgeForm = {
            title: '',
            description: '',
            textColor: '#000000',
            borderColor: '#FFFFFF',
            logo: null
          }
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadsuccess);
        }
      })
      .catch(error => {
        console.log(error);
        context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
      });
    },
    handleBadgeLogoRemove(file, fileList) {
      this.badgeForm.logo = null
    },
    handleBadgeLogoChange(file) {
      this.fileToBase64(file.raw).then(result => {
        this.badgeForm.logo = result;
      })
      this.badgeForm.logo = URL.createObjectURL(file.raw);
    },
    handleBadgeLogoExceed(files, fileList) {
      this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + fileList.length} totally`);
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
};
</script>
