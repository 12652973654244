<template>
  <div>
    <div
      id="modalPageBuilder"
      class="modal fade in"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="false"
      style="display: block;"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" aria-hidden="true" @click="fadeout">
              ×
            </button>
            <h4 id="myModalLabel" class="modal-title">
              PENGUINPASS VIRTUAL ROOM BUILDER
            </h4>
          </div>
          <div class="modal-body">
            <transition name="component-fade-fast" mode="out-in" appear>
              <div v-if="templateSaving === true" id="template_save_area">
                <div id="template-save-command-area">
                  <button
                    v-if="templateSaving === true"
                    id="email-template-save"
                    class="btn btn-primary"
                    @click="saveAsTemplateApi"
                  >
                    {{ string.modalEmailBuilder._confirm }}
                  </button>
                  <button
                    id="email-template-cancel"
                    class="btn btn-default"
                    @click="templateSavingAbort"
                  >
                    {{ string.modalEmailBuilder._cancel }}
                  </button>
                </div>
              </div>
            </transition>

            <transition name="component-fade-fast-email-builder" mode="out-in" appear>
              <div v-if="pageBuilder" id="bee-plugin-container">
                <!-- EMAIL BUILDER -->
              </div>
            </transition>
          </div>
        </div>
      </div>

      <div class="modalBackgroundColor" @click="warnThenClose">
        &nbsp;
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.btn-primary {
  color: #fff !important;
  background-color: #0057B8 !important;
  border-color: #0057B8 !important;
}

#backtotemplate {
  position: absolute;
  bottom: 5px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #505659;
  padding: 10px;
  z-index: 999;
  text-transform: uppercase;
  cursor: pointer;
}

#backtotemplate:hover {
  border: 0;
  box-shadow: 0 5px 10px -3px;
}

#template-list {
  height: 100%;
}

#start-menu {
  display: block;
  height: 100%;
}

#template_save_area {
  background-color: #505659;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999;

  #template-save-command-area {
    width: 450px;
    margin: auto;
    position: absolute;
    top: 35%;
    left: 0;
    bottom: 0;
    right: 0;

    input {
      font-size: 30px;
      color: #565656;
      display: block;
      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
    }

    button#email-template-save {
      padding: 10px;
      display: block;
      width: 100%;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    button#email-template-update {
      padding: 10px;
      display: block;
      width: 100%;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    button#email-template-cancel {
      padding: 10px;
      display: block;
      width: 100%;
      font-size: 30px;
      text-transform: uppercase;
    }
  }
}

#modalPageBuilder {
  background-color: rgba(51, 51, 51, 0.6);

  .modal-header {
    margin-top: 5px !important;
  }

  .modal-dialog {
    width: 90%;
    height: 90%;
    padding: 0;
    background-color: #ffffff;
    z-index: 1;
  }

  .modal-body {
    background-color: #505659;
    height: 93%;
  }

  .modal-content {
    height: 100%;
    border-radius: 0;
  }

  .modal-btn-close {
    background-color: #333333;
    color: #fff !important;
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .modalBackgroundColor {
    background-color: transparent;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 0;
  }
}

#bee-plugin-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #505659;
}
</style>
<script>
import Bee from "@mailupinc/bee-plugin";
import { CREATE_VIDEO_ROOM_PAGE, UPDATE_VIDEO_ROOM_PAGE } from "@/graphql";

String.prototype.replaceAll = function(find, replace) {
  return this.replace(new RegExp(find, "g"), replace);
};

import { mapGetters } from 'vuex';

export default {
  components: {},
  props: {
    eventId: {
      type: String,
      default: ''
    },
    virtualRoomPageId: {
      type: String,
      default: ''
    },
    videoRooms: {
      type: Array,
      default: () => []
    },
    page: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      mainContext: this,
      componentName: "modal-email-builder",
      string: {
        modalEmailBuilder: {}
      },
      clientId: "b1e799ad-69ee-47b5-b49a-b9d5cba8c95b",
      clientSecret: "BEoZ8fGd6xxfupkdoEs8MDyGN3MBNpWVCL08UUEh6zdYTYvcmzJl",
      beeConfig: {
        uid: null,
        container: "bee-plugin-container",
        autosave: 15,
        language: "it-IT"
      },
      template: {
        page: {
          body: {
            container: { style: { "background-color": "#FFFFFF" } },
            content: {
              computedStyle: {
                linkColor: "#7c4b96",
                messageBackgroundColor: "transparent",
                messageWidth: "500px"
              },
              style: {
                color: "#000000",
                "font-family": "Arial, Helvetica Neue, Helvetica, sans-serif"
              }
            },
            type: "mailup-bee-page-properties",
            webFonts: []
          },
          description: "",
          rows: [
            {
              type: "one-column-empty",
              container: {
                style: {
                  "background-color": "transparent",
                  "background-image": "none",
                  "background-repeat": "no-repeat",
                  "background-position": "top left"
                }
              },
              content: {
                style: {
                  "background-color": "transparent",
                  color: "#000000",
                  width: "500px",
                  "background-image": "none",
                  "background-repeat": "no-repeat",
                  "background-position": "top left"
                }
              },
              columns: [
                {
                  "grid-columns": 12,
                  modules: [],
                  style: {
                    "background-color": "transparent",
                    "padding-top": "5px",
                    "padding-right": "0px",
                    "padding-bottom": "5px",
                    "padding-left": "0px",
                    "border-top": "0px solid transparent",
                    "border-right": "0px solid transparent",
                    "border-bottom": "0px solid transparent",
                    "border-left": "0px solid transparent"
                  }
                }
              ]
            }
          ],
          template: { name: "template-base", type: "basic", version: "2.0.0" },
          title: ""
        }
      },
      templateSaving: false,
      templateJsonFileToSave: "",
      templateContentFileToSave: "",
      pageBuilder: true,
      isNewTemplate: true,
      isModified: false
    };
  },
  computed : {
    ...mapGetters(['features']),
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  mounted() {
    // Bee initialization call
    this.pageBuilder = true;
    if (this.page && this.page !== null) {
      this.template = this.page;
    }
    this.initPageBuilder();
  },
  methods: {
    warnThenClose: function() {
      if (this.isModified) {
        this.$confirm(this.string.modalEmailBuilder._areyousure_discard)
          .then(_ => {
            this.fadeout();
          })
          .catch(_ => {});
      } else {
        this.fadeout();
      }
    },
    // Component fadeout
    fadeout: function() {
      this.isModified = false;
      this.$root.bus.emit("modalView", "fadeout");
      /** Emit di al gestore globale */
    },
    onChange: function() {
      this.isModified = true;
    },
    // Bee initialization
    initPageBuilder: function() {
      const context = this;
      const beeConfig = context.beeConfig;
      const user = this.$store.getters.user;

      if (!user["id"]) {
        this.$store.dispatch("logout");
        return;
      }

      beeConfig.uid = "userId_" + user["id"];
      const lang = user && user["language"] ? user["language"] : "it";
      beeConfig.language = context.getLocaleForBee(lang);
      beeConfig.onSave = context.saveAsTemplate;
      beeConfig.onSaveAsTemplate = context.disableTemplateAction;
      beeConfig.onAutoSave = context.autoSave;
      beeConfig.onSend = context.sendTest;
      beeConfig.onError = context.error;
      beeConfig.onChange = context.onChange;
      beeConfig.editorFonts = context.getCustomFonts();
      beeConfig.mergeTags = context.getMergeTags();
      beeConfig.specialLinks = context.getSpecialLinks();
      beeConfig.mergeContents = context.getMergeContents();

      const beeEditor = new Bee();
      beeEditor
        .getToken(this.clientId, this.clientSecret)
        .then(() => beeEditor.start(beeConfig, this.template));
    },
    // Bee language setting
    getLocaleForBee: function(appLang) {
      const it = "it-IT";
      const en = "en-US";
      if (appLang !== "it") {
        return en;
      }
      return it;
    },
    getMergeTags: function() {
      return [];
    },
    getCustomFonts: function() {
      return {
        showDefaultFonts: true,
        customFonts: [
          {
            name: "GothamPro",
            fontFamily: "GothamPro",
            url:
              "https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.css"
          }
        ]
      };
    },
      getMergeContents: function (){ //custom components
        let availableVideoRooms = this.videoRooms.map(videoRoom => {
          return {name: `Room: ${videoRoom.customSessionId} (${videoRoom.type})`, value: `{penguinroom roomId="${videoRoom.id}"}`}
        })
        let dynamicDContents = []
        if(this.features.nomesia) {
          dynamicDContents.push({
            name: "Nomesia logo",
            value: "{nomesia_immagine}"
          },
          {
            name: "Nomesia ragione sociale",
            value: "{nomesia_ragione_sociale}"
          },
          {
            name: "Nomesia nome settore",
            value: "{nomesia_nome_settore}"
          },
          {
            name: "Nomesia dettagli",
            value: "{nomesia_dettagli}"
          },
          {
            name: "Nomesia contatta via email",
            value: "{nomesia_email}"
          },
          {
            name: "Nomesia prodotti",
            value: "{nomesia_products}"
          },
          {
            name: "Nomesia comunicati",
            value: "{nomesia_comunicati}"
          },
          {
            name: "Nomesia novità di prodotto",
            value: "{nomesia_product_news}"
          })
        }
        if(this.features.fairbooth) {
          dynamicDContents.push({
            name: "Fairbooth Videoroom",
            value: "{fairbooth_videoroom}"
          })
        }
        if(this.features.guestListWidget) {
          dynamicDContents.push({
            name: "GuestList",
            value: "{guestlist}"
          })
        }
        return [
        // {
        //   name: "Penguinpass Virtualroom",
        //   value: "{penguinmeetings}"
        // },
        // {
        //   name: "Penguinpass Webinar",
        //   value: "{penguinwebinar}"
        // },
        // {
        //   name: "Livewebinar",
        //   value: "{livewebinar}"
        // },
        {
          name: "Login",
          value: "{login}"
        },
        {
          name: "Registration Form",
          value: "{registrationform}"
        },
        ...dynamicDContents,
        ...availableVideoRooms]
      },
      getSpecialLinks : function (){
        return [];
      },
      saveAsTemplate: function (jsonFile, htmlFile) {
        this.templateJsonFileToSave =  jsonFile;
        this.templateContentFileToSave =  htmlFile;
        this.templateSaving =  true;
      },
      autoSave: function (jsonFile) {
      },
      error: function (errorMessage) {
        alert(errorMessage);
        // console.log("onError ", errorMessage);
      },
      disableTemplateAction: function (JsonFile) {
        this.$root.bus.emit("simpleToast",  this.string.modalEmailBuilder._use_save_button);
      },
      templateSavingAbort : function () {
        this.templateSaving = false;
        this.templateUpdating = false;
      },
      saveAsTemplateApi : function (){
        const context = this;

      if (!context.templateSaving) {
        return;
      }

      try {
        if (this.virtualRoomPageId) {
          return this.$root.$apollo
            .mutate({
              mutation: UPDATE_VIDEO_ROOM_PAGE,
              variables: {
                videoRoomPageId: this.virtualRoomPageId,
                updates: {
                  eventId: context.eventId,
                  videoRoomType: "INTERNAL",
                  html: context.templateContentFileToSave,
                  json: context.templateJsonFileToSave
                }
              }
            })
            .then(value => {
              if (value.errors) {
                console.log("error " + value.errors[0]);
              } else {
                if (
                  value &&
                  value.data &&
                  value.data.updateVideoRoomPage &&
                  value.data.updateVideoRoomPage.id
                ) {
                  context.$root.bus.emit("confirmPageSaved", {
                    htmlcode: context.templateContentFileToSave,
                    jsoncode: context.templateJsonFileToSave,
                    virtualRoomPageId: value.data.updateVideoRoomPage.id
                  });
                  context.templateSaving = false;
                  context.fadeout();
                }
              }
            })
            .catch(error => {
              console.log(error.message);
              context.templateSaving = false;
              context.$root.bus.emit("simpleToast", context.string.attendeeMaxLimit._error + " " +error.message);
            });
        } else {
          return this.$root.$apollo
            .mutate({
              mutation: CREATE_VIDEO_ROOM_PAGE,
              variables: {
                input: {
                  eventId: context.eventId,
                  videoRoomType: "INTERNAL",
                  html: context.templateContentFileToSave,
                  json: context.templateJsonFileToSave
                }
              }
            })
            .then(value => {
              if (value.errors) {
                console.log("error " + value.errors[0]);
              } else {
                if (
                  value &&
                  value.data &&
                  value.data.createVideoRoomPage &&
                  value.data.createVideoRoomPage.id
                ) {
                  context.$root.bus.emit("confirmPageSaved", {
                    htmlcode: context.templateContentFileToSave,
                    jsoncode: context.templateJsonFileToSave,
                    virtualRoomPageId: value.data.createVideoRoomPage.id
                  });
                  context.templateSaving = false;
                  context.fadeout();
                }
              }
            })
            .catch(reason => {
              console.log(reason.message);
              context.templateSaving = false;
              context.$root.bus.emit("simpleToast", context.string.attendeeMaxLimit._error + error);
            });
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  }
};
</script>
