var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    !_vm.pageLoaded
      ? _c("div", { attrs: { id: "preloader" } }, [
          _c("div", { staticClass: "pulse" }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      staticClass: "customised-body",
      domProps: { innerHTML: _vm._s(_vm.virtualRoomHTML) },
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "coverBackground",
      style: { backgroundImage: "url(" + _vm.eventBackground + ")" },
      attrs: { id: "cover-background" },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        attrs: { id: "login-modal" },
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.closeLoginForm()
          },
        },
      },
      [
        _c("div", { staticClass: "login-form" }, [
          _c(
            "form",
            {
              staticClass: "form-signin",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.loginResetDo()
                },
              },
            },
            [
              _vm.selectedForm === "login"
                ? _c("h2", { staticClass: "form-signin-heading" }, [
                    _vm._v("Login"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedForm === "reset"
                ? _c("h2", { staticClass: "form-signin-heading" }, [
                    _vm._v("Reset password"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "sr-only", attrs: { for: "inputEmail" } },
                [_vm._v("Email")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginForm.email,
                    expression: "loginForm.email",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "email",
                  id: "inputEmail",
                  placeholder: "Email address",
                  required: "true",
                  autofocus: "true",
                },
                domProps: { value: _vm.loginForm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.loginForm, "email", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.selectedForm === "reset"
                ? _c(
                    "p",
                    { staticStyle: { "margin-top": "10px", color: "#ffffff" } },
                    [
                      _vm._v(
                        "You will receive an email from mailing@penguinpass.it to set a new password."
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedForm === "login"
                ? _c(
                    "label",
                    { staticClass: "sr-only", attrs: { for: "inputPassword" } },
                    [_vm._v("Password")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedForm === "login"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.loginForm.password,
                        expression: "loginForm.password",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "password",
                      id: "inputPassword",
                      placeholder: "Password",
                      required: "true",
                    },
                    domProps: { value: _vm.loginForm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.loginForm, "password", $event.target.value)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loginForm.errors
                ? _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(_vm._s(_vm.loginForm.errors)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.loginForm.info
                ? _c("span", { staticStyle: { color: "green" } }, [
                    _vm._v(_vm._s(_vm.loginForm.info)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedForm === "login"
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "text-align": "right",
                        margin: "10px 0px",
                        cursor: "pointer",
                        color: "#ffffff",
                      },
                      on: {
                        click: function ($event) {
                          if ($event.target !== $event.currentTarget) {
                            return null
                          }
                          return _vm.formSwitch("reset")
                        },
                      },
                    },
                    [_vm._v("Forgot your password?")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedForm === "reset"
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "text-align": "right",
                        margin: "10px 0px",
                        cursor: "pointer",
                        color: "#ffffff",
                      },
                      on: {
                        click: function ($event) {
                          if ($event.target !== $event.currentTarget) {
                            return null
                          }
                          return _vm.formSwitch("login")
                        },
                      },
                    },
                    [_vm._v("Back to Login")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedForm === "login"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-lg btn-primary btn-block",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("Login")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedForm === "reset"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-lg btn-primary btn-block",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("Reset")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm.teamId === "5e9dcc42b9fadb7790b76a28"
                  ? _c(
                      "a",
                      {
                        attrs: {
                          target: "_blank",
                          href: "https://events.penguinpass.it/room/809c2b8374b428fe20d03f1d9949c2a067eb739b317c34ee8eb60b780b385b6e768e34de0aadbf4437359f84f91575e7dc3d66761970e4c6576262a84d74d4dc",
                        },
                      },
                      [_vm._v("Crea un account")]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        attrs: { id: "signup-modal" },
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.closeRegisterForm()
          },
        },
      },
      [
        _c("div", { staticClass: "signup-form" }, [
          _c("form", { staticClass: "form-signin" }, [
            _c("h2", { staticClass: "form-signin-heading" }, [
              _vm._v("Register"),
            ]),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "sr-only", attrs: { for: "inputName" } },
              [_vm._v("Name")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.signupForm.name,
                  expression: "signupForm.name",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "inputName",
                placeholder: "Name",
                required: "",
                autofocus: "",
              },
              domProps: { value: _vm.signupForm.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.signupForm, "name", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "sr-only", attrs: { for: "inputSurname" } },
              [_vm._v("Surname")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.signupForm.surname,
                  expression: "signupForm.surname",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "inputSurname",
                placeholder: "Surname",
                required: "",
                autofocus: "",
              },
              domProps: { value: _vm.signupForm.surname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.signupForm, "surname", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "sr-only", attrs: { for: "inputEmail" } },
              [_vm._v("Email")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.signupForm.email,
                  expression: "signupForm.email",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "email",
                id: "inputEmail",
                placeholder: "Email address",
                required: "",
                autofocus: "",
              },
              domProps: { value: _vm.signupForm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.signupForm, "email", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              { staticClass: "sr-only", attrs: { for: "inputPassword" } },
              [_vm._v("Password")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.signupForm.password,
                  expression: "signupForm.password",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "password",
                id: "inputPassword",
                placeholder: "Password",
                required: "",
              },
              domProps: { value: _vm.signupForm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.signupForm, "password", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("small", [
              _vm._v(_vm._s(_vm.string.commonActions.passwordPolicy)),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-lg btn-primary btn-block",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.registerDo()
                  },
                },
              },
              [_vm._v("Register")]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "footer",
      {
        staticStyle: {
          "margin-top": "5px",
          "margin-bottom": "0px",
          "background-color": "transparent",
        },
      },
      [
        _c(
          "p",
          {
            staticClass: "footer-text text-center",
            staticStyle: { padding: "15px" },
          },
          [
            _vm._v(
              "\n      powered by Penguinpass. The smartest event management system for your events. |\n      "
            ),
            _c(
              "a",
              {
                attrs: {
                  href: "https://www.penguinpass.it",
                  target: "_blank",
                  title: "Penguinpass",
                },
              },
              [_vm._v("\n        www.penguinpass.it\n      ")]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }