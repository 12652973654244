var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainLayout", [
    _c("div", { staticClass: "uk-theme uk-background-primary uk-light" }, [
      _c("div", { ref: "container" }, [
        _c(
          "div",
          { staticClass: "layer" },
          [
            _c(
              "div",
              {
                attrs: {
                  "data-uk-sticky":
                    "sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky",
                },
              },
              [
                _c(
                  "nav",
                  { staticClass: "uk-navbar-container uk-margin uk-light" },
                  [
                    _c("div", { staticClass: "uk-container" }, [
                      _c("div", { staticClass: "uk-navbar" }, [
                        _c("div", { staticClass: "uk-navbar-left" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "uk-navbar-item uk-logo uk-text-small",
                              attrs: { href: "/helpdesk/en-home" },
                            },
                            [
                              _vm._v(
                                "\n                    HELP DESK\n                  "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "uk-navbar-center uk-visible@m" },
                          [
                            _c("ul", { staticClass: "uk-navbar-nav" }, [
                              _c(
                                "li",
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/helpdesk/en-home" } },
                                    [
                                      _vm._v(
                                        "\n                        Support\n                      "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/helpdesk/en-faq" } },
                                    [
                                      _vm._v(
                                        "\n                        Faq\n                      "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-navbar-right" }, [
                          _c("ul", { staticClass: "uk-navbar-nav" }, [
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/helpdesk/it-home" } },
                                  [_c("b", [_vm._v("IT")])]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "uk-active" },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/helpdesk/en-home" } },
                                  [_c("b", [_vm._v("EN")])]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("router-view"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-margin-large-top uk-section-primary" },
              [
                _c("div", { staticClass: "uk-container" }, [
                  _c("hr", { staticClass: "uk-margin-remove" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "uk-grid-large uk-flex-middle uk-margin-xlarge-top uk-margin-xlarge-bottom",
                      attrs: {
                        "data-uk-grid": "",
                        "data-uk-scrollspy":
                          "cls: uk-animation-slide-bottom; repeat: true",
                      },
                    },
                    [
                      _c("div", { staticClass: "uk-width-auto@m" }, [
                        _c("p", { staticClass: "uk-text-large" }, [
                          _vm._v(
                            "\n                  Non hai trovato una risposta alla tua domanda? Contattaci a "
                          ),
                          _c("i", [
                            _c(
                              "a",
                              {
                                attrs: { href: "mailto: info@penguinpass.it" },
                              },
                              [
                                _vm._v(
                                  "\n                    info@penguinpass.it\n                  "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }