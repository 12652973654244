var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "sidebar-menu",
      style: "height: " + _vm.height + "px;",
      attrs: { id: "nav-accordion" },
    },
    [
      _c(
        "li",
        {
          class:
            "avatar-row " + (_vm.activePath === "settings" ? " active" : ""),
        },
        [
          _c(
            "router-link",
            { attrs: { to: { path: "/settings" }, replace: "" } },
            [
              _c(
                "font-awesome-layers",
                { staticClass: "fa-lg" },
                [
                  _c("div", {
                    staticClass: "sidebar-icon-bg sidebar-avatar-icon-bg ",
                    style: "background: " + _vm.icon.iconBackground,
                  }),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    style: { color: _vm.icon.iconForeground },
                    attrs: {
                      icon: ["fas", "user-circle"],
                      size: _vm.icon.avatarIconSize,
                      transform: "shrink-4 down-1 left-1",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.user && _vm.user.name ? _vm.user.name : "") +
                    "\n            "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("li", [
        _c(
          "a",
          {
            attrs: { title: "" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onLogout($event)
              },
            },
          },
          [
            _c(
              "font-awesome-layers",
              { staticClass: "fa-lg" },
              [
                _c("div", {
                  staticClass: "sidebar-icon-bg",
                  style: "background: " + _vm.icon.iconBackground,
                }),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  style: { color: _vm.icon.iconForeground },
                  attrs: {
                    icon: ["far", "moon"],
                    size: _vm.icon.iconSize,
                    transform: _vm.icon.shrink + " rotate--25 " + _vm.icon.down,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.$root.strings.header._logout))]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "li",
        { class: _vm.activePath === "home" ? " active" : "" },
        [
          _c(
            "router-link",
            { attrs: { to: { path: "/" }, replace: "" } },
            [
              _c(
                "font-awesome-layers",
                { staticClass: "fa-lg" },
                [
                  _c("div", {
                    staticClass: "sidebar-icon-bg",
                    style: "background: " + _vm.icon.iconBackground,
                  }),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    style: { color: _vm.icon.iconForeground },
                    attrs: {
                      icon: ["fas", "tachometer-alt"],
                      size: _vm.icon.iconSize,
                      transform: _vm.icon.shrink + " left-1 " + _vm.icon.down,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.strings.sidebar._dashboard) +
                    "\n          "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.canShow("newEvent")
        ? _c("li", { class: _vm.activePath === "newEvent" ? " active" : "" }, [
            _c(
              "a",
              { attrs: { id: "newEvent" }, on: { click: _vm.action_newEvent } },
              [
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg" },
                  [
                    _c("div", {
                      staticClass: "sidebar-icon-bg",
                      style: "background: " + _vm.icon.iconBackground,
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      style: { color: _vm.icon.iconForeground },
                      attrs: {
                        icon: ["fas", "plus"],
                        size: _vm.icon.iconSize,
                        transform:
                          _vm.icon.shrink + " right-1 " + _vm.icon.down,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.strings.sidebar._newevent) +
                      "\n          "
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canShow("allEvents")
        ? _c("li", { class: _vm.activePath === "events" ? " active" : "" }, [
            _c(
              "a",
              {
                attrs: { id: "allEvents" },
                on: { click: _vm.action_allEvents },
              },
              [
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg" },
                  [
                    _c("div", {
                      staticClass: "sidebar-icon-bg",
                      style: "background: " + _vm.icon.iconBackground,
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      style: { color: _vm.icon.iconForeground },
                      attrs: {
                        icon: ["fas", "calendar-alt"],
                        size: _vm.icon.iconSize,
                        transform:
                          _vm.icon.shrink + " right-1 " + _vm.icon.down,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.strings.sidebar._allevents) +
                      "\n          "
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canShow("recordings")
        ? _c("li", [
            _c(
              "a",
              { on: { click: _vm.action_recordings } },
              [
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg" },
                  [
                    _c("div", {
                      staticClass: "sidebar-icon-bg ",
                      style: "background: " + _vm.icon.iconBackground,
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      style: { color: _vm.icon.iconForeground },
                      attrs: {
                        icon: ["fas", "play-circle"],
                        size: _vm.icon.iconSize,
                        transform: _vm.icon.shrink + " " + _vm.icon.down,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v("Recordings")]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canShow("notify")
        ? _c("li", [
            _c(
              "a",
              { on: { click: _vm.action_notify } },
              [
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg" },
                  [
                    _c("div", {
                      staticClass: "sidebar-icon-bg ",
                      style: "background: " + _vm.icon.iconBackground,
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      style: { color: _vm.icon.iconForeground },
                      attrs: {
                        icon: ["far", "paper-plane"],
                        size: _vm.icon.iconSize,
                        transform: _vm.icon.shrink + " " + _vm.icon.down,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v("Notify")]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canShow("eventArchive")
        ? _c("li", { class: _vm.activePath === "archive" ? " active" : "" }, [
            _c(
              "a",
              {
                attrs: { id: "allArchivedEvents" },
                on: { click: _vm.action_allEventArchived },
              },
              [
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg" },
                  [
                    _c("div", {
                      staticClass: "sidebar-icon-bg",
                      style: "background: " + _vm.icon.iconBackground,
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      style: { color: _vm.icon.iconForeground },
                      attrs: {
                        icon: ["fas", "archive"],
                        size: _vm.icon.iconSize,
                        transform: _vm.icon.shrink + " " + _vm.icon.down,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.strings.sidebar._eventArchived) +
                      "\n          "
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canShow("team-members")
        ? _c(
            "li",
            { class: _vm.activePath === "teamMembers" ? " active" : "" },
            [
              _c(
                "a",
                {
                  attrs: { id: "team-members" },
                  on: { click: _vm.action_team_members },
                },
                [
                  _c(
                    "font-awesome-layers",
                    { staticClass: "fa-lg" },
                    [
                      _c("div", {
                        staticClass: "sidebar-icon-bg",
                        style: "background: " + _vm.icon.iconBackground,
                      }),
                      _vm._v(" "),
                      _c("font-awesome-icon", {
                        style: { color: _vm.icon.iconForeground },
                        attrs: {
                          icon: ["fas", "users"],
                          size: _vm.icon.iconSize,
                          transform:
                            _vm.icon.shrink + " left-2 " + _vm.icon.down,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.strings.sidebar._members) +
                        "\n          "
                    ),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canShow("eventGroup")
        ? _c(
            "li",
            { class: _vm.activePath === "eventGroup" ? " active" : "" },
            [
              _c(
                "a",
                {
                  attrs: { id: "event-group" },
                  on: { click: _vm.action_eventGroup },
                },
                [
                  _c(
                    "font-awesome-layers",
                    { staticClass: "fa-lg" },
                    [
                      _c("div", {
                        staticClass: "sidebar-icon-bg",
                        style: "background: " + _vm.icon.iconBackground,
                      }),
                      _vm._v(" "),
                      _c("font-awesome-icon", {
                        style: { color: _vm.icon.iconForeground },
                        attrs: {
                          icon: ["fas", "calendar-alt"],
                          size: _vm.icon.iconSize,
                          transform:
                            _vm.icon.shrink + " right-1 " + _vm.icon.down,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$root.strings.sidebar._eventGroup) +
                        "\n          "
                    ),
                  ]),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isExhibitor
        ? _c("li", [
            _c(
              "a",
              { on: { click: _vm.action_helpdesk } },
              [
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg" },
                  [
                    _c("div", {
                      staticClass: "sidebar-icon-bg ",
                      style: "background: " + _vm.icon.iconBackground,
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      style: { color: _vm.icon.iconForeground },
                      attrs: {
                        icon: ["fas", "question-circle"],
                        size: _vm.icon.iconSize,
                        transform: _vm.icon.shrink + " " + _vm.icon.down,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v("HelpDesk")]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canShow("checkInLink") && !_vm.isExhibitor
        ? _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: _vm.strings.sidebar
                    ._downloadpenguinpasscheckinURL_android,
                  title: "Check-in App Android",
                },
              },
              [
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg" },
                  [
                    _c("div", {
                      staticClass: "sidebar-icon-bg",
                      style: "background: " + _vm.icon.iconBackground,
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      style: { color: _vm.icon.iconForeground },
                      attrs: {
                        icon: ["fas", "download"],
                        size: _vm.icon.iconSize,
                        transform: _vm.icon.shrink + " " + _vm.icon.down,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-right": "0px",
                      "margin-top": "-40px",
                      "margin-left": "50px",
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.strings.sidebar._downloadpenguinpasscheckin_android
                      )
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canShow("checkInLink") && !_vm.isExhibitor
        ? _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: _vm.strings.sidebar._downloadpenguinpasscheckinURL_ios,
                  title: "Check-in App IOS",
                },
              },
              [
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg" },
                  [
                    _c("div", {
                      staticClass: "sidebar-icon-bg",
                      style: "background: " + _vm.icon.iconBackground,
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      style: { color: _vm.icon.iconForeground },
                      attrs: {
                        icon: ["fas", "download"],
                        size: _vm.icon.iconSize,
                        transform: _vm.icon.shrink + " " + _vm.icon.down,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.strings.sidebar._downloadpenguinpasscheckin_ios)
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.canShow("contacts") && !_vm.isExhibitor
        ? _c("li", [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: _vm.strings.sidebar._linkContacts,
                  title: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.downloadMenuInvisible()
                  },
                },
              },
              [
                _c(
                  "font-awesome-layers",
                  { staticClass: "fa-lg" },
                  [
                    _c("div", {
                      staticClass: "sidebar-icon-bg",
                      style: "background: " + _vm.icon.iconBackground,
                    }),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      style: { color: _vm.icon.iconForeground },
                      attrs: {
                        icon: ["fas", "phone"],
                        size: _vm.icon.iconSize,
                        transform: _vm.icon.shrink + " " + _vm.icon.down,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.strings.sidebar._contacts))]),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", { staticClass: "menu-divider" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }