<template>
  <footer class="public-footer text-white">
    <div :class="fullWidth ? '' : 'container'" class="footer__container">
      <div class="footer__text">
        powered by <a href="http://www.penguinpass.it">Penguinpass Forms</a>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </footer>
</template>
<style>
.public-footer {
  color: #ffffff;
  padding: 30px 0;
  width: 100%;
  font-family: 'Raleway', Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  line-height: 26px;
  font-size: 16px;
  font-weight: 300;
}
.dashboard-footer.public-footer {
  padding: 30px 15px;
}

.footer__container {
  display: flex;
}

.footer__text {
  flex: 1;
  text-align: center;
  font-size: 14px;
}
.footer__text a {
  color: #fff;
  font-weight: 600;
}
</style>
<script>
export default {
  components: {},
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      componentName: "publicFooter",
      strings: {},
    };
  },
  created() {
    const context = this;
    this.strings = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.strings = context.$root.strings;
    });
  },
  mounted() {},
  methods: {},
  events: {},
  ready() {},
};
</script>
