var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "public-footer text-white" }, [
    _c(
      "div",
      {
        staticClass: "footer__container",
        class: _vm.fullWidth ? "" : "container",
      },
      [_vm._m(0)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer__text" }, [
      _vm._v("\n      powered by "),
      _c("a", { attrs: { href: "http://www.penguinpass.it" } }, [
        _vm._v("Penguinpass Forms"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }