export default function getAllTokens(currentCustomFieldObject) {
  const defaultCustomFields = [
    "{{name}}",
    "{{surname}}",
    "{{full_name}}",
    "{{rsvp}}",
    "{{page_link_token}}",
    "{{download_ticket_url}}",
    "{{page_link}}",
    "{{decline}}",
    "{{qr_code}}",
    "{{cf_summary}}",
    "{{show_pdf}}",
  ];
  return currentCustomFieldObject
    ? [...defaultCustomFields, ...Object.keys(currentCustomFieldObject).map(key => `{{${key}}}`)]
    : defaultCustomFields;
}
