<template>
  <div
    v-if="app_settings.app_name === 'gobella'"
    class="gobella-layout">
    <div class="data-grid-container">
      <table>
        <thead>
          <tr>
            <th
              v-for="key in columns"
              :class="{ active: sortKey == key }"
              @click="sortBy(key)">
              {{ key | capitalize }}
              <span
                class="arrow"
                :class="sortOrders[key] > 0 ? 'asc' : 'dsc'"></span>
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="entry in filteredData"
            class="tableRowItem">
            <td
              v-for="key in columns"
              v-html="getKey(entry,key)"></td>
            <td class="actions">
              <a
                class="delete_tickets"
                data-placement="top"
                title=""
                @click="deleteItem(entry['id'])"
              >
                <i class="fa fa-trash-o">
&nbsp;
                </i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div
    v-else
    class="default-layout">
    <div class="data-grid-container">
      <table>
        <thead>
          <tr>
            <th
              v-for="key in columns"
              :class="{ active: sortKey == key }"
              @click="sortBy(key)">
              {{ key | capitalize }}
              <span
                class="arrow"
                :class="sortOrders[key] > 0 ? 'asc' : 'dsc'"></span>
            </th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="entry in filteredData"
            class="tableRowItem">
            <td
              v-for="key in columns"
              v-html="getKey(entry,key)"></td>
            <td class="actions">
              <a
                class="delete_tickets"
                data-placement="top"
                title=""
                @click="deleteItem(entry['id'])"
              >
                <i class="fa fa-trash-o">
&nbsp;
                </i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<style scoped>

td.actions{
    text-align: center;
}

a.delete_tickets{
    transition: 1s ease;
    background-color: transparent !important;
    color: #dadada !important;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}

a.delete_tickets:hover{
    color: #333333 !important;
}

.data-grid-container{
    /*
    padding-right: 15px;
    padding-left: 15px;
    */
    overflow: auto;
}

.default-layout table {
  border: 2px solid #0057B8;
  border-radius: 3px;
  background-color: transparent;
  width: 100%;
}

.default-layout  tr.tableRowItem:hover td {
  background-color: rgba(255,255,255,0.3);
}

.default-layout  th {
  background-color: #0057B8;
  color: rgba(255,255,255,0.66);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gobella-layout table {
    border: 2px solid #e7b8c1;
    border-radius: 3px;
    background-color: #fff;
    width: 100%;
}

.gobella-layout  tr.tableRowItem:hover td {
    background-color: #efefef;
}

.gobella-layout  th {
    background-color: #e7b8c1;
    color: rgba(255,255,255,0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
td {
  background-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
}

th, td {
  min-width: 120px;
  padding: 10px 20px;
  text-align: left;
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

table {
  border: 2px solid #0057B8;
  border-radius: 3px;
  background-color: #000000;
  width: 100%;
}
</style>
<script>

import userdata from './../userdata.js';

export default {
    filters: {
        capitalize: function (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    },
    props: {
        data: Array,
        columns: Array,
        filterKey: String
    },
    data: function () {
        const sortOrders = {};
        this.columns.forEach((key) => {
            sortOrders[key] = 1;
        });
        return {
            app_settings: userdata.app_settings,
            sortKey: '',
            sortOrders: sortOrders
        };
    },
    computed: {
        filteredData: function () {
            const sortKey = this.sortKey;
            const filterKey = this.filterKey && this.filterKey.toLowerCase();
            const order = this.sortOrders[sortKey] || 1;
            let data = this.data;
            if (filterKey) {
                data = data.filter((row) => {
                    return Object.keys(row).some((key) => {
                        return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
                    });
                });
            }
            if (sortKey) {
                data = data.slice().sort((a, b) => {
                    a = a[sortKey];
                    b = b[sortKey];
                    return (a === b ? 0 : a > b ? 1 : -1) * order;
                });
            }

            return data.map((item) => {
                const magicNumber = 999999999;
                if (item.availability==magicNumber || item.availability == null){
                    item.availability = 'nessun limite';
                }

                return item;
            });

            //return data
        }
    },
    methods: {
        sortBy: function (key) {
            this.sortKey = key;
            this.sortOrders[key] = this.sortOrders[key] * -1;
        },
        deleteItem: function (id){
            this.$emit('deleteListItem', { item : id });
        },
        getKey : function (entry,key){

            if (key.toLowerCase() == 'price')
            {return entry['price'] + ' ' + entry['currency']['code'];}

            return entry[key];
        }
    }

};
</script>
