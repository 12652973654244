<template>
  <PublicLayout>
    <div class="container">
        <div class="row wow fadeIn">
            <!-- Left column -->
            <div class="col-md-7">
                <!-- Begin heading -->
                <div class="heading heading-md heading-uppercase heading-hover">
                    <br>
                    <h2
                            id="title"
                            class="heading-title text-white">
                        {{string.unsubscribePage._title}}
                    </h2>

                    <!-- Begin divider -->
                    <hr class="hr-width-1 hr-5x border-main">
                    <!-- End divider -->

                    <p
                            v-if="state.action"
                            id="description"
                            class="heading-tescription lead text-gray-2">
                        {{ state.isUserInput ? (state.action === 'unsubscribe' ? string.unsubscribePage._thank_you_for_unsubscription : string.unsubscribePage._action_confirm) : string.unsubscribePage._action_confirm }}
                        <a
                                v-if="state.isUserInput && state.action === 'unsubscribe' && organizerEmail"
                                :href="'mailto:'+organizerEmail"
                                target="_blank">
                            {{ organizerEmail }}
                        </a>
                        <a v-if="!state.isUserInput && state.action" class="subscribe-button" @click="confirmAction">
                            {{ state.action === 'unsubscribe' ? string.unsubscribePage._unsubscribe_confirm : string.unsubscribePage._resubscribe_confirm }}
                        </a>
                        <a v-else-if="state.isUserInput && state.action" class="subscribe-button" @click="reverseAction">
                            {{ state.action === 'unsubscribe' ? string.unsubscribePage._resubscribe_confirm : string.unsubscribePage._unsubscribe_confirm }}
                        </a>
                    </p>
                </div>
                <!-- End heading -->
            </div> <!-- /.col -->
        </div> <!-- /.row -->
    </div>
    </PublicLayout>
</template>

<script >
  import PublicLayout  from '../layouts/PublicLayout';
  import LoadingCover from '../components/LoadingCover';

  export default {
      components: {
          PublicLayout,
          LoadingCover
      },
      props: {
          code: String,
          action: String
      },
      data (){
      return {
          state: {
              code: '',
              action: null,
              isUserInput: false,
          },
        string: {},
        loadingEffectshow: false,
        organizerEmail: '',
      };

    },
    metaInfo: {
      title: 'Unsubscribe - Penguinpass',
      bodyAttrs: {
        class: 'login'
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      }, this);
        this.state.code = this.code;
        this.state.action = this.action;
    },
      methods: {
        confirmAction: function() {
            this.sendAction(this.code, this.state.action);
        },
        reverseAction: function() {
            this.sendAction(this.code, this.state.action === 'unsubscribe' ? 'subscribe' : 'unsubscribe');
        },
        sendAction: function (code, action) {
          const context = this;
          this.loadingEffectshow = true;
        const loading = this.$loading({
          text: '',
          spinner: 'fa fa-spinner fa-pulse',
          background: 'rgba(0, 0, 0, 0.7)',
          customClass: 'fa-3x'
        });
          this.$store.dispatch('unsubscribe', {code, action})
              .then((result) => {
                  this.state.isUserInput = true;
                  if(result.status === 'SUCCESS') {
                      context.organizerEmail = result.organizerEmail;
                  }
                  context.state.action = action;
                  context.loadingEffectshow = false;
                    loading.close();
              })
              .catch(error => {
              if(error.data && error.data.status && error.data.status === 'FAILED'){
                  context.$notify({
                      title: 'Oops !',
                      message: error.data.message,
                      position: 'bottom-right',
                      type: 'error',
                      duration: 3000,
                  });
              }
                  context.loadingEffectshow = false;

                    loading.close();
              });
        }
    }
  };

</script>

<style scoped>
  .subscribe-button {
      display: block;
      color: #fff;
      border: 1px solid #0057B8;
      background: #0057B8;
      width: 100%;
      max-width: 50%;
      margin: 70px auto 30px 0;
      padding: 13px;
      cursor: pointer;
      transition: 1s ease;
      text-align: center;
  }

  .subscribe-button:hover {
      color: #fff;
      background: #777;
      border: 1px solid transparent;
  }
</style>
