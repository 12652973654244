<template>
  <MainLayout>
    <div>
      <FunctionBar style="padding-bottom:10px;" />
      <div class="event-list-title">
        <h3>{{ string.events._all_events }}</h3>
      </div>
      <div v-if="!!loading">
        <transition name="component-fade-fast" mode="out-in" appear>
          <ClipLoader
            :loading="loading === true"
            color="#0057B8"
            style="margin: 0 auto;"
            size="45px"
          ></ClipLoader>
        </transition>
      </div>
      <div v-else-if="data.length <= 0" class="center-it container">
        <transition name="component-fade-fast" mode="out-in" appear>
          <div style="text-align: center;">
            {{ string.events._noEvents }}
          </div>
        </transition>
      </div>
      <div v-else>
        <transition name="component-fade-fast" mode="out-in" appear>
          <div>
            <EventList :items="data" />
            <Paginate
              v-model="currentPage"
              :page-count="pageCount"
              :click-handler="getData"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
            ></Paginate>
          </div>
        </transition>
      </div>
    </div>
  </MainLayout>
</template>
<style scoped>
ul.event-list {
  margin-top: 20px !important;
}
.pagination {
}
.page-item {
  display: inline;
}
</style>
<script>
import MainLayout from "../layouts/Main.vue";
import EventList from "../components/EventList.vue";
import FunctionBar from "../components/FunctionBar.vue";
import Paginate from "vuejs-paginate";
import { MY_EVENTS, MY_PAGINATED_EVENTS } from "@/graphql";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  components: {
    EventList,
    MainLayout,
    FunctionBar,
    Paginate,
    ClipLoader
  },
  props: {},
  data() {
    return {
      string: {
        events: {
          _noEvents: "No events.",
          _progress: "Searching..."
        }
      },
      currentPage: 1,
      pageSize: 12,
      sortField: "createdAt",
      sortOrder: -1,
      filter: "",
      total: 0,
      data: [],
      loading: false
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    }
  },
  watch: {},
  metaInfo: {
    title: "Events - Penguinpass",
    bodyAttrs: {
      class: "dashboard"
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
    this.getData();
  },
  methods: {
    async getData(currentPage) {
      this.loading = true;
      const context = this;
      try {
        await this.$apollo
          .query({
            fetchPolicy: "no-cache",
            query: MY_PAGINATED_EVENTS,
            variables: {
              statuses: ["ACTIVE", "INACTIVE"],
              filter: this.filter && this.filter !== "" ? this.filter : null,
              pageInfo: {
                pageSize: this.pageSize,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
                currentPage: currentPage || this.currentPage
              }
            }
          })
          .then(response => {
            if (response.errors) {
              console.log(response.errors);
              return;
            }
            if (response.data && response.data.myPaginatedEvents) {
              const payload = response.data.myPaginatedEvents;
              context.data = payload.data;
              context.total = payload.total;
            }
          })
          .catch(error => {
            console.error(error.message);
          });
      } finally {
        context.loading = false;
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>
