var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-fluid",
      class: { "public-profile-landing-tracking": _vm.trackingId },
    },
    [
      !_vm.pageLoaded
        ? _c("div", { attrs: { id: "preloader" } }, [
            _c("div", { staticClass: "pulse" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "topnav", class: { "bb-shadow": _vm.singleEventData } },
        [
          _c(
            "div",
            { staticClass: "topnav__home" },
            [
              _c(
                "router-link",
                {
                  staticClass: "back-to-list",
                  attrs: {
                    to: {
                      name: "OrganizerPublicProfile",
                      params: { code: _vm.code },
                    },
                  },
                },
                [_vm._v("\n          HOME\n        ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "topnav__buttons" }, [
            _vm.selectedForm === "logout"
              ? _c(
                  "span",
                  { staticClass: "topnav__username" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "back-to-list",
                        attrs: {
                          to: {
                            name: "OrganizerPublicLayout",
                            params: { code: _vm.code },
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-user-circle fa-2x profile__icon",
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.loggedInUser.name) +
                            " " +
                            _vm._s(_vm.loggedInUser.surname) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedForm === "logout"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary logout-btn",
                    on: {
                      click: function ($event) {
                        return _vm.logoutDo()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.string.registrationPage._logout) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedForm === null
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-secondary",
                      on: {
                        click: function ($event) {
                          return _vm.loginDo()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.string.registrationPage._login) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-secondary",
                      on: {
                        click: function ($event) {
                          return _vm.signUpDo()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.string.registrationPage._signup) +
                          "\n          "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "public-profile-body public-profile-body-v2" }, [
        _c("div", {
          staticClass: "heroImage",
          class: { singleEvent: _vm.singleEventData },
        }),
        _vm._v(" "),
        !_vm.singleEventData
          ? _c("div", { staticClass: "bb-shadow mb-2" }, [_vm._m(0)])
          : _vm._e(),
        _vm._v(" "),
        !_vm.singleEventData
          ? _c("div", { staticClass: "container" }, [
              !_vm.singleEventData
                ? _c("div", { staticClass: "event-list" }, [
                    _c("div", { staticClass: "event-list__bar" }, [
                      _c("div", { staticClass: "event-list__categories" }, [
                        _vm.loggedInUser
                          ? _c(
                              "div",
                              { staticClass: "filters" },
                              _vm._l(
                                _vm.teamCustomCategories,
                                function (customCategory, index) {
                                  return _c(
                                    "span",
                                    { key: customCategory.key },
                                    [
                                      index !== 0
                                        ? _c(
                                            "span",
                                            { staticClass: "separator" },
                                            [_vm._v("·")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          class: {
                                            active:
                                              _vm.eventCategory ===
                                              customCategory.key,
                                          },
                                          attrs: { href: "" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.changeEventCategory(
                                                customCategory.key
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(customCategory.label))]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "event-list__searchbar" }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "event-list__items" },
                      [
                        _vm.publicEvents.data.length === 0
                          ? _c("div", { staticClass: "no_events" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.string.events._noEvents) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.publicEvents.data, function (event) {
                          return _c(
                            "el-row",
                            { key: event._id, attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.eventClick()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "el-card",
                                        {
                                          attrs: {
                                            "body-style": {
                                              padding: "0px",
                                              display: "flex",
                                              alignItems: "center",
                                            },
                                            shadow: "always",
                                          },
                                        },
                                        [
                                          event.image
                                            ? _c("img", {
                                                staticClass: "card-image",
                                                attrs: { src: event.image },
                                              })
                                            : _c("img", {
                                                staticClass: "card-image",
                                                attrs: {
                                                  src: "https://cdn.pixabay.com/photo/2021/08/02/20/35/architecture-6517841_1280.jpg",
                                                },
                                              }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "card-content" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "event-description",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "title" },
                                                    [_vm._v(_vm._s(event.name))]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "datetime" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "event__time-location",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "event__time",
                                                            },
                                                            [
                                                              _c("span", {
                                                                staticClass:
                                                                  "glyphicon glyphicon-time",
                                                                attrs: {
                                                                  "aria-hidden":
                                                                    "true",
                                                                },
                                                              }),
                                                              _vm._v(
                                                                "\n                              Dal " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      event.startDate,
                                                                      "D/M/Y"
                                                                    )
                                                                  ) +
                                                                  " al " +
                                                                  _vm._s(
                                                                    _vm.formatDate(
                                                                      event.endDate,
                                                                      "D/M/Y"
                                                                    )
                                                                  ) +
                                                                  "\n                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "description",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.lang === "it"
                                                            ? event.descriptionIt
                                                            : event.descriptionEn
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.loggedInUser
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "event-actions",
                                                        },
                                                        [
                                                          event.c19c &&
                                                          _vm.getTicketQRCode(
                                                            event._id
                                                          ) === "0"
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.string
                                                                        .publicPages
                                                                        ._book_test
                                                                    ) + " "
                                                                  ),
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fa fa-external-link",
                                                                  }),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.getTicket(
                                                            event._id
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "event-actions-row",
                                                                },
                                                                [
                                                                  _vm.hasMultipleRegistrations(
                                                                    event._id
                                                                  )
                                                                    ? [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticClass:
                                                                              "button mr-1",
                                                                            attrs:
                                                                              {
                                                                                type: "text",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.showMultipleApplications(
                                                                                    event._id
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                " +
                                                                                _vm._s(
                                                                                  (_vm.areMultipleApplicationsShown(
                                                                                    event._id
                                                                                  )
                                                                                    ? "Nascondi"
                                                                                    : "Mostra") +
                                                                                    " moduli già compilati"
                                                                                ) +
                                                                                "\n                              "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "router-link",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                to: {
                                                                                  name: "OrganizerPublicProfileSingleEvent",
                                                                                  params:
                                                                                    {
                                                                                      code: _vm.code,
                                                                                      eventId:
                                                                                        event._id,
                                                                                    },
                                                                                },
                                                                                custom:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                staticClass:
                                                                                  "button mr-1",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                    Compila un nuovo modulo\n                                  "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    : [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticClass:
                                                                              "button mr-1",
                                                                            class:
                                                                              {
                                                                                disabled:
                                                                                  _vm.getTicketQRCode(
                                                                                    event._id
                                                                                  ) ===
                                                                                  "0",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  _vm.getTicketQRCode(
                                                                                    event._id
                                                                                  ) ===
                                                                                  "0",
                                                                                type: "text",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.downloadTicket(
                                                                                    event,
                                                                                    false
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .string
                                                                                  .publicPages
                                                                                  ._download_ticket
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        event.c19c &&
                                                                        _vm.getTicketQRCode(
                                                                          event._id
                                                                        ) ===
                                                                          "0"
                                                                          ? _c(
                                                                              "el-button",
                                                                              {
                                                                                staticClass:
                                                                                  "button mr-1",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.checkC19Test(
                                                                                        event
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .string
                                                                                      .publicPages
                                                                                      ._certify_test
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "router-link",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                to: {
                                                                                  name: "OrganizerPublicProfileSingleEvent",
                                                                                  params:
                                                                                    {
                                                                                      code: _vm.code,
                                                                                      eventId:
                                                                                        event._id,
                                                                                    },
                                                                                },
                                                                                custom:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-button",
                                                                              {
                                                                                staticClass:
                                                                                  "button mr-1",
                                                                                attrs:
                                                                                  {
                                                                                    type: "text",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .string
                                                                                      .publicPages
                                                                                      ._join
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                ],
                                                                2
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "event-actions-row",
                                                                },
                                                                [
                                                                  _vm.hasMultipleRegistrations(
                                                                    event._id
                                                                  )
                                                                    ? _c(
                                                                        "el-button",
                                                                        {
                                                                          staticClass:
                                                                            "button mr-1",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showMultipleApplications(
                                                                                  event._id
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                (_vm.areMultipleApplicationsShown(
                                                                                  event._id
                                                                                )
                                                                                  ? "Nascondi"
                                                                                  : "Mostra") +
                                                                                  " moduli già compilati"
                                                                              ) +
                                                                              "\n                            "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "router-link",
                                                                    {
                                                                      attrs: {
                                                                        to: {
                                                                          name: "OrganizerPublicProfileSingleEvent",
                                                                          params:
                                                                            {
                                                                              code: _vm.code,
                                                                              eventId:
                                                                                event._id,
                                                                            },
                                                                        },
                                                                        custom:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-button",
                                                                        {
                                                                          staticClass:
                                                                            "button mr-1",
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .string
                                                                                .publicPages
                                                                                ._join
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.areMultipleApplicationsShown(event._id)
                                    ? _c(
                                        "el-card",
                                        { staticClass: "event-details" },
                                        [
                                          _vm._l(
                                            _vm.eventApplications[event._id]
                                              .data,
                                            function (evtApplication, index) {
                                              return _c(
                                                "el-row",
                                                {
                                                  key: _vm.getEventApplicationKey(
                                                    evtApplication,
                                                    index
                                                  ),
                                                  staticClass:
                                                    "event-actions-row",
                                                  attrs: { type: "flex" },
                                                },
                                                [
                                                  _c("el-col", [
                                                    _vm._v(
                                                      "\n                      Identificativo: " +
                                                        _vm._s(
                                                          _vm.getEventApplicationIdentifier(
                                                            evtApplication
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticClass: "text-right",
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticClass:
                                                            "button mr-1",
                                                          class: {
                                                            disabled:
                                                              _vm.getTicketQRCode(
                                                                event._id
                                                              ) === "0",
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              _vm.getTicketQRCode(
                                                                event._id
                                                              ) === "0",
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.downloadTicket(
                                                                event,
                                                                false,
                                                                _vm.getEventApplicationIdentifier(
                                                                  evtApplication
                                                                )
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.string
                                                                .publicPages
                                                                ._download_ticket
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      event.c19c &&
                                                      _vm.getTicketQRCode(
                                                        event._id
                                                      ) === "0"
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "button mr-1",
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkC19Test(
                                                                      event
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.string
                                                                    .publicPages
                                                                    ._certify_test
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to: {
                                                              name: "OrganizerPublicProfileSingleEvent",
                                                              params: {
                                                                code: _vm.code,
                                                                eventId:
                                                                  event._id,
                                                                identifier:
                                                                  _vm.getEventApplicationIdentifier(
                                                                    evtApplication
                                                                  ),
                                                              },
                                                            },
                                                            custom: "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "button mr-1",
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.string
                                                                    .publicPages
                                                                    ._upload
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("el-pagination", {
                                            attrs: {
                                              "page-size": 5,
                                              layout: "prev, pager, next",
                                              total:
                                                _vm.eventApplications[event._id]
                                                  .total,
                                            },
                                            on: {
                                              "current-change": function (
                                                page
                                              ) {
                                                return _vm.changeEventApplicationsPage(
                                                  event,
                                                  page
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _c("el-pagination", {
                          attrs: {
                            "page-size": _vm.publicEvents.pageSize,
                            layout: "prev, pager, next",
                            total: _vm.publicEvents.total,
                          },
                          on: { "current-change": _vm.goToPage },
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm.trackingId && _vm.singleEventData
          ? _c(
              "div",
              { staticClass: "container single-event tracking" },
              [
                _c(
                  "el-row",
                  { attrs: { type: "flex", gutter: 20 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("img", {
                        staticClass: "cover-image",
                        attrs: { src: _vm.singleEventData.image },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { staticClass: "event-details", attrs: { span: 12 } },
                      [
                        _c("h1", [_vm._v(_vm._s(_vm.singleEventData.name))]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.eventWhenWhere(_vm.singleEventData))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.lang === "it"
                                  ? _vm.singleEventData.descriptionIt
                                  : _vm.singleEventData.descriptionEn
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.qrCode
                          ? [
                              _c("vue-qrcode", {
                                attrs: { value: _vm.qrCode },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "event-actions-row" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "button mr-1",
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadTicket(
                                            _vm.singleEventData,
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.string.publicPages
                                            ._download_ticket
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c("h2", [_vm._v(_vm._s(_vm.errorMessage))]),
                              _vm._v(" "),
                              _c("p", [_vm._v(_vm._s(_vm.subErrorMessage))]),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "container single-event" },
              [
                _vm.singleEventData && _vm.eventId
                  ? _c(
                      "el-row",
                      { attrs: { type: "flex", gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { staticClass: "event-details", attrs: { span: 24 } },
                          [
                            _c("RegistrationForm", {
                              attrs: {
                                "team-id": _vm.teamId,
                                "event-id": _vm.eventId,
                                data: _vm.singleEventData,
                                string: _vm.string,
                                store: _vm.$store,
                                root: _vm.$root,
                                "logged-in-user": _vm.loggedInUser,
                                "hide-login-bar": true,
                                code: _vm.code,
                                identifier: _vm.identifier,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _vm.selectedForm === "login" || _vm.selectedForm === "reset"
        ? _c("div", {
            staticClass: "coverBackground",
            attrs: { id: "cover-background" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedForm === "login" || _vm.selectedForm === "reset"
        ? _c(
            "div",
            {
              attrs: { id: "login-modal" },
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.closeLoginForm()
                },
              },
            },
            [
              _c("div", { staticClass: "login-form" }, [
                _c(
                  "form",
                  {
                    staticClass: "form-signin",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.loginResetDo()
                      },
                    },
                  },
                  [
                    _vm.selectedForm === "login"
                      ? _c("h2", { staticClass: "form-signin-heading" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.string.registrationPage._login) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "reset"
                      ? _c("h2", { staticClass: "form-signin-heading" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.string.publicPages._reset_password) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "sr-only", attrs: { for: "inputEmail" } },
                      [_vm._v(_vm._s(_vm.string.registrationPage._email))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.loginForm.email,
                          expression: "loginForm.email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "email",
                        id: "inputEmail",
                        placeholder: "Email address",
                        required: "true",
                        autofocus: "true",
                      },
                      domProps: { value: _vm.loginForm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.loginForm, "email", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.selectedForm === "reset"
                      ? _c(
                          "p",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              color: "#ffffff",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.string.publicPages._reset_message) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "login"
                      ? _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: "inputPassword" },
                          },
                          [_vm._v(_vm._s(_vm.string.publicPages._password))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "login"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.loginForm.password,
                              expression: "loginForm.password",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            id: "inputPassword",
                            placeholder: "Password",
                            required: "true",
                          },
                          domProps: { value: _vm.loginForm.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.loginForm,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.loginForm.errors
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.loginForm.errors)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.loginForm.info
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.loginForm.info)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "login"
                      ? _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "right",
                              margin: "10px 0px",
                              cursor: "pointer",
                              color: "#ffffff",
                            },
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget) {
                                  return null
                                }
                                return _vm.formSwitch("reset")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.string.publicPages._forgot_password
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "reset"
                      ? _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "right",
                              margin: "10px 0px",
                              cursor: "pointer",
                              color: "#ffffff",
                            },
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget) {
                                  return null
                                }
                                return _vm.formSwitch("login")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.string.publicPages._back_login) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "login"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-lg btn-primary btn-block",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.string.registrationPage._login) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "reset"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-lg btn-primary btn-block",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.string.publicPages._reset) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticStyle: { height: "40px" },
                        on: {
                          click: function ($event) {
                            return _vm.signUpDo()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.string.registrationPage._or) +
                            " " +
                            _vm._s(_vm.string.registrationPage._register) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedForm === "signup"
        ? _c(
            "div",
            {
              attrs: { id: "signup-modal" },
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.closeRegisterForm()
                },
              },
            },
            [
              _c("div", { staticClass: "signup-form" }, [
                _vm.thankYouMessage === ""
                  ? _c("form", { staticClass: "form-signin" }, [
                      _c("h2", { staticClass: "form-signin-heading" }, [
                        _vm._v(_vm._s(_vm.string.registrationPage._register)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        { staticClass: "sr-only", attrs: { for: "inputName" } },
                        [_vm._v(_vm._s(_vm.string.registrationPage._name))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signupForm.name,
                            expression: "signupForm.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputName",
                          placeholder: "Name",
                          required: "",
                          autofocus: "",
                        },
                        domProps: { value: _vm.signupForm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.signupForm,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "inputSurname" },
                        },
                        [_vm._v(_vm._s(_vm.string.registrationPage._surname))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signupForm.surname,
                            expression: "signupForm.surname",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputSurname",
                          placeholder: "Surname",
                          required: "",
                          autofocus: "",
                        },
                        domProps: { value: _vm.signupForm.surname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.signupForm,
                              "surname",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "inputEmail" },
                        },
                        [_vm._v(_vm._s(_vm.string.registrationPage._email))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signupForm.email,
                            expression: "signupForm.email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          id: "inputEmail",
                          placeholder: "Email address",
                          required: "",
                          autofocus: "",
                        },
                        domProps: { value: _vm.signupForm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.signupForm,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "inputPassword" },
                        },
                        [_vm._v(_vm._s(_vm.string.registrationPage._password))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signupForm.password,
                            expression: "signupForm.password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          id: "inputPassword",
                          placeholder: "Password",
                          required: "",
                        },
                        domProps: { value: _vm.signupForm.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.signupForm,
                              "password",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "error-message",
                        domProps: { innerHTML: _vm._s(_vm.joinMessage) },
                      }),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(_vm._s(_vm.string.commonActions.passwordPolicy)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-lg btn-primary btn-block",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.registerDo()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.string.registrationPage._register) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticStyle: { height: "40px" },
                          on: {
                            click: function ($event) {
                              return _vm.loginDo()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.string.registrationPage._or) +
                              " " +
                              _vm._s(_vm.string.registrationPage._login)
                          ),
                        ]
                      ),
                    ])
                  : _c("div", { staticClass: "thankyou" }, [
                      _c("i", {
                        staticClass: "icon fa fa-check-circle fa-3x",
                        staticStyle: { "font-weight": "900" },
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("h2", [_vm._v(_vm._s(_vm.thankYouMessage))]),
                    ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container mb-2" }, [
      _c("h1", [_vm._v("PORTALE FIGC AGENTI SPORTIVI")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "footer",
      {
        staticStyle: {
          "margin-top": "5px",
          "margin-bottom": "0px",
          "background-color": "transparent",
        },
      },
      [
        _c(
          "p",
          {
            staticClass: "footer-text text-center",
            staticStyle: { padding: "15px" },
          },
          [
            _vm._v("\n        powered by "),
            _c("a", { attrs: { href: "http://www.penguinpass.it" } }, [
              _vm._v("Penguinpass Forms"),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }