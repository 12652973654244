var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container-fluid",
      class: { "public-profile-landing-tracking": _vm.trackingId },
    },
    [
      !_vm.pageLoaded
        ? _c("div", { attrs: { id: "preloader" } }, [
            _c("div", { staticClass: "pulse" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "topnav", class: { "bb-shadow": _vm.singleEventData } },
        [
          _c(
            "div",
            { staticClass: "topnav__home" },
            [
              _c(
                "router-link",
                {
                  staticClass: "back-to-list",
                  attrs: {
                    to: {
                      name: "OrganizerPublicProfile",
                      params: { code: _vm.code },
                    },
                  },
                },
                [_vm._v("\n        HOME\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "topnav__buttons" }, [
            _vm.selectedForm === "logout"
              ? _c("span", { staticClass: "topnav__username back-to-list" }, [
                  _c("i", {
                    staticClass: "fa fa-user-circle fa-2x profile__icon",
                  }),
                  _vm._v(
                    "  " +
                      _vm._s(_vm.loggedInUser.name) +
                      " " +
                      _vm._s(_vm.loggedInUser.surname) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedForm === "logout"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary logout-btn",
                    on: {
                      click: function ($event) {
                        return _vm.logoutDo()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.string.registrationPage._logout) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedForm === null
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-secondary",
                      on: {
                        click: function ($event) {
                          return _vm.loginDo()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.string.registrationPage._login) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "btn btn-secondary",
                      on: {
                        click: function ($event) {
                          return _vm.signUpDo()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.string.registrationPage._signup) +
                          "\n        "
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "public-profile-body public-profile-body-v2" }, [
        !_vm.singleEventData
          ? _c("div", { staticClass: "container" }, [
              _vm.loggedInUser
                ? _c(
                    "div",
                    { staticClass: "profile clearfix" },
                    [
                      _c("div", { staticClass: "profile__title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.string.publicPages._profile) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "profile__name col-xs-12" },
                        [
                          _c(
                            "transition",
                            {
                              attrs: {
                                name: "component-fade-fast",
                                mode: "out-in",
                              },
                            },
                            [
                              !_vm.isEditing
                                ? _c("span", { key: "profile__name-view" }, [
                                    _vm._v(
                                      _vm._s(_vm.loggedInUser.name) +
                                        " " +
                                        _vm._s(_vm.loggedInUser.surname)
                                    ),
                                  ])
                                : _c(
                                    "div",
                                    {
                                      key: "profile__name-edit",
                                      staticClass: "form-inline",
                                    },
                                    [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.userName,
                                              expression: "userName",
                                            },
                                          ],
                                          staticClass:
                                            "form-control input-rounded",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              _vm.string.publicPages
                                                ._guest_name,
                                          },
                                          domProps: { value: _vm.userName },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.userName = $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.userSurname,
                                              expression: "userSurname",
                                            },
                                          ],
                                          staticClass:
                                            "form-control input-rounded",
                                          attrs: {
                                            type: "text",
                                            placeholder:
                                              _vm.string.publicPages
                                                ._guest_surname,
                                          },
                                          domProps: { value: _vm.userSurname },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.userSurname =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "pull-right" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group",
                                            class: {
                                              "has-error":
                                                _vm.passwordError !== "",
                                            },
                                          },
                                          [
                                            (_vm.showPassword
                                              ? "text"
                                              : "password") === "checkbox"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.password1,
                                                      expression: "password1",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control input-rounded",
                                                  attrs: {
                                                    placeholder:
                                                      _vm.string.publicPages
                                                        ._password,
                                                    type: "checkbox",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.password1
                                                    )
                                                      ? _vm._i(
                                                          _vm.password1,
                                                          null
                                                        ) > -1
                                                      : _vm.password1,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a = _vm.password1,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.password1 =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.password1 = $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                        }
                                                      } else {
                                                        _vm.password1 = $$c
                                                      }
                                                    },
                                                  },
                                                })
                                              : (_vm.showPassword
                                                  ? "text"
                                                  : "password") === "radio"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.password1,
                                                      expression: "password1",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control input-rounded",
                                                  attrs: {
                                                    placeholder:
                                                      _vm.string.publicPages
                                                        ._password,
                                                    type: "radio",
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.password1,
                                                      null
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.password1 = null
                                                    },
                                                  },
                                                })
                                              : _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.password1,
                                                      expression: "password1",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control input-rounded",
                                                  attrs: {
                                                    placeholder:
                                                      _vm.string.publicPages
                                                        ._password,
                                                    type: _vm.showPassword
                                                      ? "text"
                                                      : "password",
                                                  },
                                                  domProps: {
                                                    value: _vm.password1,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.password1 =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group",
                                            class: {
                                              "has-error":
                                                _vm.passwordError !== "",
                                            },
                                          },
                                          [
                                            (_vm.showPassword
                                              ? "text"
                                              : "password") === "checkbox"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.password2,
                                                      expression: "password2",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control input-rounded",
                                                  attrs: {
                                                    placeholder:
                                                      _vm.string.publicPages
                                                        ._password2,
                                                    type: "checkbox",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.password2
                                                    )
                                                      ? _vm._i(
                                                          _vm.password2,
                                                          null
                                                        ) > -1
                                                      : _vm.password2,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a = _vm.password2,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.password2 =
                                                              $$a.concat([$$v]))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.password2 = $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                        }
                                                      } else {
                                                        _vm.password2 = $$c
                                                      }
                                                    },
                                                  },
                                                })
                                              : (_vm.showPassword
                                                  ? "text"
                                                  : "password") === "radio"
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.password2,
                                                      expression: "password2",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control input-rounded",
                                                  attrs: {
                                                    placeholder:
                                                      _vm.string.publicPages
                                                        ._password2,
                                                    type: "radio",
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      _vm.password2,
                                                      null
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.password2 = null
                                                    },
                                                  },
                                                })
                                              : _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.password2,
                                                      expression: "password2",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control input-rounded",
                                                  attrs: {
                                                    placeholder:
                                                      _vm.string.publicPages
                                                        ._password2,
                                                    type: _vm.showPassword
                                                      ? "text"
                                                      : "password",
                                                  },
                                                  domProps: {
                                                    value: _vm.password2,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.password2 =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "checkbox" }, [
                                          _c(
                                            "label",
                                            { staticClass: "label-showPwd" },
                                            [
                                              _c("input", {
                                                attrs: { type: "checkbox" },
                                                on: {
                                                  change:
                                                    _vm.toggleShowPassword,
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.string.publicPages
                                                      ._show_password
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form-group",
                                          class: {
                                            "has-error":
                                              _vm.passwordError !== "",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "help-block" },
                                            [_vm._v(_vm._s(_vm.passwordError))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "profile__email col-xs-12 col-md-4" },
                        [
                          _c(
                            "transition",
                            {
                              attrs: {
                                name: "component-fade-fast",
                                mode: "out-in",
                              },
                            },
                            [
                              !_vm.isEditing
                                ? _c("span", [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.loggedInUser.email) +
                                        "\n          "
                                    ),
                                  ])
                                : _c("div", [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.userEmail,
                                            expression: "userEmail",
                                          },
                                        ],
                                        staticClass:
                                          "form-control input-rounded",
                                        attrs: {
                                          type: "text",
                                          placeholder:
                                            _vm.string.organizerSettings._email,
                                        },
                                        domProps: { value: _vm.userEmail },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.userEmail = $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-group",
                                        class: {
                                          "has-error": _vm.emailError !== "",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "help-block" },
                                          [_vm._v(_vm._s(_vm.emailError))]
                                        ),
                                      ]
                                    ),
                                  ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isEditing
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-secondary profile__edit-btn pull-right",
                              on: {
                                click: function ($event) {
                                  return _vm.startEditing()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.string.publicPages._editButton) +
                                  "\n        "
                              ),
                            ]
                          )
                        : [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary profile__edit-btn pull-right",
                                on: {
                                  click: function ($event) {
                                    return _vm.commitChanges()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.string.commonActions.save
                                        .charAt(0)
                                        .toUpperCase() +
                                        _vm.string.commonActions.save.slice(1)
                                    ) +
                                    "\n          "
                                ),
                                _vm.isLoading
                                  ? [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-spinner fa-spin fa-fw",
                                      }),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("..."),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary profile__cancel-btn pull-right",
                                on: {
                                  click: function ($event) {
                                    _vm.isEditing = false
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.string.registrationPage._cancel
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "event-list" }, [
                _c("div", { staticClass: "event-list__bar" }, [
                  _c("div", { staticClass: "event-list__categories" }, [
                    _vm.loggedInUser
                      ? _c("div", { staticClass: "filters" }, [
                          _c(
                            "span",
                            { staticClass: "active", attrs: { href: "" } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.string.publicPages._myForms) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "event-list__items" },
                  [
                    _vm.publicEvents.data.length === 0
                      ? _c("div", { staticClass: "no_events" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.string.events._noEvents) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.publicEvents.data, function (event) {
                      return _c(
                        "el-row",
                        { key: event._id, attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-card",
                                {
                                  attrs: {
                                    "body-style": {
                                      padding: "0px",
                                      display: "flex",
                                      alignItems: "center",
                                    },
                                    shadow: "always",
                                  },
                                },
                                [
                                  event.image
                                    ? _c("img", {
                                        staticClass: "card-image",
                                        attrs: { src: event.image },
                                      })
                                    : _c("img", {
                                        staticClass: "card-image",
                                        attrs: {
                                          src: "https://cdn.pixabay.com/photo/2021/08/02/20/35/architecture-6517841_1280.jpg",
                                        },
                                      }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "card-content" }, [
                                    _c(
                                      "div",
                                      { staticClass: "event-description" },
                                      [
                                        _c("span", { staticClass: "title" }, [
                                          _vm._v(_vm._s(event.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "datetime" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "event__time-location",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "event__time" },
                                                [
                                                  _c("span", {
                                                    staticClass:
                                                      "glyphicon glyphicon-time",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                          Dal " +
                                                      _vm._s(
                                                        _vm.formatDate(
                                                          event.startDate,
                                                          "D/M/Y"
                                                        )
                                                      ) +
                                                      " al\n                          " +
                                                      _vm._s(
                                                        _vm.formatDate(
                                                          event.endDate,
                                                          "D/M/Y"
                                                        )
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "description" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.lang === "it"
                                                  ? event.descriptionIt
                                                  : event.descriptionEn
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.loggedInUser
                                          ? _c(
                                              "div",
                                              { staticClass: "event-actions" },
                                              [
                                                event.c19c &&
                                                _vm.getTicketQRCode(
                                                  event._id
                                                ) === "0"
                                                  ? _c(
                                                      "a",
                                                      { attrs: { href: "" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.string
                                                              .publicPages
                                                              ._book_test
                                                          ) + " "
                                                        ),
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-external-link",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.getTicket(event._id)
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "event-actions-row",
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticClass:
                                                              "button mr-1",
                                                            class: {
                                                              disabled:
                                                                _vm.getTicketQRCode(
                                                                  event._id
                                                                ) === "0",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                _vm.getTicketQRCode(
                                                                  event._id
                                                                ) === "0",
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.downloadTicket(
                                                                  event,
                                                                  false
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.string
                                                                  .publicPages
                                                                  ._download_ticket
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        event.c19c &&
                                                        _vm.getTicketQRCode(
                                                          event._id
                                                        ) === "0"
                                                          ? _c(
                                                              "el-button",
                                                              {
                                                                staticClass:
                                                                  "button mr-1",
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.checkC19Test(
                                                                        event
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.string
                                                                      .publicPages
                                                                      ._certify_test
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "OrganizerPublicProfileSingleEvent",
                                                                params: {
                                                                  code: _vm.code,
                                                                  eventId:
                                                                    event._id,
                                                                },
                                                              },
                                                              custom: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticClass:
                                                                  "button mr-1",
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.string
                                                                      .publicPages
                                                                      ._join
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "event-actions-row",
                                                      },
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: {
                                                                name: "OrganizerPublicProfileSingleEvent",
                                                                params: {
                                                                  code: _vm.code,
                                                                  eventId:
                                                                    event._id,
                                                                },
                                                              },
                                                              custom: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                staticClass:
                                                                  "button mr-1",
                                                                attrs: {
                                                                  type: "text",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.string
                                                                      .publicPages
                                                                      ._join
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c("el-pagination", {
                      attrs: {
                        "page-size": _vm.publicEvents.pageSize,
                        layout: "prev, pager, next",
                        total: _vm.publicEvents.total,
                      },
                      on: { "current-change": _vm.goToPage },
                    }),
                  ],
                  2
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.selectedForm === "login" || _vm.selectedForm === "reset"
        ? _c("div", {
            staticClass: "coverBackground",
            attrs: { id: "cover-background" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedForm === "login" || _vm.selectedForm === "reset"
        ? _c(
            "div",
            {
              attrs: { id: "login-modal" },
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.closeLoginForm()
                },
              },
            },
            [
              _c("div", { staticClass: "login-form" }, [
                _c(
                  "form",
                  {
                    staticClass: "form-signin",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.loginResetDo()
                      },
                    },
                  },
                  [
                    _vm.selectedForm === "login"
                      ? _c("h2", { staticClass: "form-signin-heading" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.string.registrationPage._login) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "reset"
                      ? _c("h2", { staticClass: "form-signin-heading" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.string.publicPages._reset_password) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "sr-only", attrs: { for: "inputEmail" } },
                      [_vm._v(_vm._s(_vm.string.registrationPage._email))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.loginForm.email,
                          expression: "loginForm.email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "email",
                        id: "inputEmail",
                        placeholder: "Email address",
                        required: "true",
                        autofocus: "true",
                      },
                      domProps: { value: _vm.loginForm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.loginForm, "email", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.selectedForm === "reset"
                      ? _c(
                          "p",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              color: "#ffffff",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.string.publicPages._reset_message) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "login"
                      ? _c(
                          "label",
                          {
                            staticClass: "sr-only",
                            attrs: { for: "inputPassword" },
                          },
                          [_vm._v(_vm._s(_vm.string.publicPages._password))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "login"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.loginForm.password,
                              expression: "loginForm.password",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            id: "inputPassword",
                            placeholder: "Password",
                            required: "true",
                          },
                          domProps: { value: _vm.loginForm.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.loginForm,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.loginForm.errors
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.loginForm.errors)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.loginForm.info
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.loginForm.info)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "login"
                      ? _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "right",
                              margin: "10px 0px",
                              cursor: "pointer",
                              color: "#ffffff",
                            },
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget) {
                                  return null
                                }
                                return _vm.formSwitch("reset")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.string.publicPages._forgot_password
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "reset"
                      ? _c(
                          "p",
                          {
                            staticStyle: {
                              "text-align": "right",
                              margin: "10px 0px",
                              cursor: "pointer",
                              color: "#ffffff",
                            },
                            on: {
                              click: function ($event) {
                                if ($event.target !== $event.currentTarget) {
                                  return null
                                }
                                return _vm.formSwitch("login")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.string.publicPages._back_login) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "login"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-lg btn-primary btn-block",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.string.registrationPage._login) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedForm === "reset"
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-lg btn-primary btn-block",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.string.publicPages._reset) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedForm === "signup"
        ? _c(
            "div",
            {
              attrs: { id: "signup-modal" },
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.closeRegisterForm()
                },
              },
            },
            [
              _c("div", { staticClass: "signup-form" }, [
                _vm.thankYouMessage === ""
                  ? _c("form", { staticClass: "form-signin" }, [
                      _c("h2", { staticClass: "form-signin-heading" }, [
                        _vm._v(_vm._s(_vm.string.registrationPage._register)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        { staticClass: "sr-only", attrs: { for: "inputName" } },
                        [_vm._v(_vm._s(_vm.string.registrationPage._name))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signupForm.name,
                            expression: "signupForm.name",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputName",
                          placeholder: "Name",
                          required: "",
                          autofocus: "",
                        },
                        domProps: { value: _vm.signupForm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.signupForm,
                              "name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "inputSurname" },
                        },
                        [_vm._v(_vm._s(_vm.string.registrationPage._surname))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signupForm.surname,
                            expression: "signupForm.surname",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "inputSurname",
                          placeholder: "Surname",
                          required: "",
                          autofocus: "",
                        },
                        domProps: { value: _vm.signupForm.surname },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.signupForm,
                              "surname",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "inputEmail" },
                        },
                        [_vm._v(_vm._s(_vm.string.registrationPage._email))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signupForm.email,
                            expression: "signupForm.email",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "email",
                          id: "inputEmail",
                          placeholder: "Email address",
                          required: "",
                          autofocus: "",
                        },
                        domProps: { value: _vm.signupForm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.signupForm,
                              "email",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "inputPassword" },
                        },
                        [_vm._v(_vm._s(_vm.string.registrationPage._password))]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.signupForm.password,
                            expression: "signupForm.password",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          id: "inputPassword",
                          placeholder: "Password",
                          required: "",
                        },
                        domProps: { value: _vm.signupForm.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.signupForm,
                              "password",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "error-message",
                        domProps: { innerHTML: _vm._s(_vm.joinMessage) },
                      }),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(_vm._s(_vm.string.commonActions.passwordPolicy)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-lg btn-primary btn-block",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.registerDo()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.string.registrationPage._register) +
                              "\n        "
                          ),
                        ]
                      ),
                    ])
                  : _c("div", { staticClass: "thankyou" }, [
                      _c("i", {
                        staticClass: "icon fa fa-check-circle fa-3x",
                        staticStyle: { "font-weight": "900" },
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" "),
                      _c("h2", [_vm._v(_vm._s(_vm.thankYouMessage))]),
                    ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "footer",
      {
        staticStyle: {
          "margin-top": "5px",
          "margin-bottom": "0px",
          "background-color": "transparent",
        },
      },
      [
        _c(
          "p",
          {
            staticClass: "footer-text text-center",
            staticStyle: { padding: "15px" },
          },
          [
            _vm._v("\n      powered by "),
            _c("a", { attrs: { href: "http://www.penguinpass.it" } }, [
              _vm._v("Penguinpass Forms"),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }