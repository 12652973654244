var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "template-list-content" } }, [
    _c(
      "div",
      {
        staticClass: "row selector-container",
        attrs: { id: "template-selector-container" },
      },
      [
        _vm.templateList.length > 0
          ? _c(
              "div",
              { staticClass: "template-list-list " },
              _vm._l(_vm.templateList, function (template) {
                return _c(
                  "div",
                  { key: template.id, staticClass: "card-container" },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.templateLoad(template)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "template-icon" }, [
                            _c(
                              "div",
                              { staticClass: "template-name-first-letter" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(template.displayName.charAt(0)) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-footer" }, [
                      _c("div", { staticClass: "template-name" }, [
                        _c("p", { staticClass: "card-title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(template.displayName) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "template-sub-name" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(new Date(template.createdAt).format()) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "action-icons" }, [
                        _c(
                          "a",
                          {
                            staticClass: "action-icon",
                            staticStyle: { "margin-rigth": "5px" },
                            on: {
                              click: function ($event) {
                                return _vm.templateClone(template)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-clone",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "action-icon",
                            on: {
                              click: function ($event) {
                                return _vm.templateDelete(template)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-trash-o",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.templateList.length < 1 && _vm.apiGetTemplatesCalled === true
          ? _c(
              "div",
              { staticClass: "template-list-placeholder align-middle" },
              [
                _c("div", { staticClass: "template-list-status-message" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.string.modalEmailBuilder._notemplate) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "template-list-btn" }, [
                  _c(
                    "a",
                    { staticClass: "btn", on: { click: _vm.templateCreate } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.string.modalEmailBuilder._create) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }