var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "default-layout" }, [
    _c(
      "div",
      {
        staticClass: "badgePrinting-component",
        attrs: { id: "badgePrinting" },
      },
      [
        _vm.loadingEffectshow
          ? _c(
              "div",
              { staticClass: "higherPosition" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.loadingEffectshow
                      ? _c("LoadingCover", {
                          attrs: {
                            "loading-cover-msg":
                              _vm.string.badgePrinting._loadingMessage,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "badgeprinting accordion" }, [
          _c(
            "h4",
            { staticClass: "form-section-title first accordion-toggle" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.string.badgePrinting._title) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "accordion-content" }, [
            _c("div", { staticClass: "badgeToggleSelection" }, [
              _c("label", { staticClass: "control control-checkbox" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.string.badgePrinting._innertitle) +
                    "\n            "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.showEditor,
                      expression: "showEditor",
                    },
                  ],
                  attrs: { id: "checkbox", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.showEditor)
                      ? _vm._i(_vm.showEditor, null) > -1
                      : _vm.showEditor,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.showEditor,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.showEditor = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.showEditor = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.showEditor = $$c
                        }
                      },
                      _vm.toggleBadgePrinting,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "control_indicator" }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "badge-message-intent" }, [
              _vm.showEditor
                ? _c("div", [
                    _c("form", [
                      _c("div", { staticClass: "row mt-3" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "row mt-3" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("small", { staticClass: "text-left" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.string.badgePrinting._formTitle
                                    ) +
                                    "\n                      "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.badgeForm.title,
                                    expression: "badgeForm.title",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text", placeHolder: "Title" },
                                domProps: { value: _vm.badgeForm.title },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.badgeForm,
                                      "title",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("small", { staticClass: "text-left" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.string.badgePrinting._formDescription
                                    ) +
                                    "\n                      "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.badgeForm.description,
                                    expression: "badgeForm.description",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  placeHolder: "Subtitle",
                                },
                                domProps: { value: _vm.badgeForm.description },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.badgeForm,
                                      "description",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("small", { staticClass: "text-left" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.string.badgePrinting._formPageW
                                    ) +
                                    "\n                      "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.badgeForm.pageFormatW,
                                    expression: "badgeForm.pageFormatW",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  placeHolder: "10in or 10cm",
                                },
                                domProps: { value: _vm.badgeForm.pageFormatW },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.badgeForm,
                                      "pageFormatW",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-sm-6" }, [
                              _c("small", { staticClass: "text-left" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.string.badgePrinting._formPageH
                                    ) +
                                    "\n                      "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.badgeForm.pageFormatH,
                                    expression: "badgeForm.pageFormatH",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  placeHolder: "10in or 10cm",
                                },
                                domProps: { value: _vm.badgeForm.pageFormatH },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.badgeForm,
                                      "pageFormatH",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-4" },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    attrs: {
                                      action: "#",
                                      "auto-upload": false,
                                      "show-file-list": true,
                                      multiple: false,
                                      "on-change": _vm.handleBadgeLogoChange,
                                      "on-remove": _vm.handleBadgeLogoRemove,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.badgePrinting
                                              ._formFileUpload
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "el-upload__tip",
                                        attrs: { slot: "tip" },
                                        slot: "tip",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.badgePrinting
                                              ._formFileUploadTips
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-4" },
                              [
                                _c("small", { staticClass: "text-left" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.string.badgePrinting._formTextColor
                                      ) +
                                      "\n                      "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-color-picker", {
                                  staticClass: "form-control",
                                  model: {
                                    value: _vm.badgeForm.textColor,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.badgeForm, "textColor", $$v)
                                    },
                                    expression: "badgeForm.textColor",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-4" },
                              [
                                _c("small", { staticClass: "text-left" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.string.badgePrinting
                                          ._formBorderColor
                                      ) +
                                      "\n                      "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("el-color-picker", {
                                  staticClass: "form-control",
                                  model: {
                                    value: _vm.badgeForm.borderColor,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.badgeForm,
                                        "borderColor",
                                        $$v
                                      )
                                    },
                                    expression: "badgeForm.borderColor",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-6" },
                              [
                                _c("small", { staticClass: "text-left" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.string.badgePrinting._formField1
                                      ) +
                                      "\n                      "
                                  ),
                                ]),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "Select",
                                    },
                                    model: {
                                      value: _vm.field1Name,
                                      callback: function ($$v) {
                                        _vm.field1Name = $$v
                                      },
                                      expression: "field1Name",
                                    },
                                  },
                                  _vm._l(_vm.customFieldArray, function (ref) {
                                    var slug = ref[0]
                                    var cField = ref[1]
                                    return _c("el-option", {
                                      key: slug,
                                      attrs: {
                                        label: cField.options.question,
                                        value: slug,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-sm-6" },
                              [
                                _c("small", { staticClass: "text-left" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.string.badgePrinting._formField2
                                      ) +
                                      "\n                      "
                                  ),
                                ]),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "Select",
                                    },
                                    model: {
                                      value: _vm.field2Name,
                                      callback: function ($$v) {
                                        _vm.field2Name = $$v
                                      },
                                      expression: "field2Name",
                                    },
                                  },
                                  _vm._l(_vm.customFieldArray, function (ref) {
                                    var slug = ref[0]
                                    var cField = ref[1]
                                    return _c("el-option", {
                                      key: slug,
                                      attrs: {
                                        label: cField.options.question,
                                        value: slug,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 htmlEditorContainer" },
                              [
                                _c(
                                  "el-collapse",
                                  [
                                    _c(
                                      "el-collapse-item",
                                      {
                                        attrs: {
                                          title:
                                            _vm.string.badgePrinting
                                              ._formCustomLayout,
                                          name: "1",
                                        },
                                      },
                                      [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.badgeForm.html,
                                              expression: "badgeForm.html",
                                            },
                                          ],
                                          staticClass: "htmlEditor",
                                          domProps: {
                                            value: _vm.badgeForm.html,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.badgeForm,
                                                "html",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("div", { staticClass: "row mt-3" }, [
                            _c(
                              "div",
                              {
                                staticClass: "badge-container col-sm-12",
                                staticStyle: {
                                  "background-color": "#FFFFFF",
                                  "min-height": "300px",
                                },
                              },
                              [
                                _c("iframe", {
                                  staticClass: "embedded-html",
                                  attrs: {
                                    id: "badgePreview",
                                    srcdoc: _vm.renderedBadge,
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-3" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-block",
                              on: { click: _vm.createOrUpdateBadge },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.string.badgePrinting._save) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-block",
                              on: { click: _vm.resetBadge },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.string.badgePrinting._reset) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }